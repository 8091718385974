import {useId} from 'react';
import {styled} from 'styled-components';

import {Custom} from '@shared-frontend/lib/react';

interface HoobiizLogoSvgProps {
  height: number;
}

export const HoobiizLogoSvg: Custom<HoobiizLogoSvgProps, 'svg'> = props => {
  const {height, ...rest} = props;
  const shadowId = useId();
  const gradientId = useId();

  return (
    <StyledSvg $height={height} viewBox="0 0 374.55 108.93" {...rest}>
      <defs>
        <filter
          // eslint-disable-next-line react/forbid-dom-props
          id={shadowId}
        >
          <feDropShadow dx="3" dy="3" stdDeviation="3" floodOpacity="0.29" />
        </filter>
        <linearGradient
          // eslint-disable-next-line react/forbid-dom-props
          id={gradientId}
          gradientTransform="rotate(85)"
        >
          <stop offset="0%" stopColor="#b49a62" />
          <stop offset="100%" stopColor="#e4cb93" />
        </linearGradient>
      </defs>
      <rect fill={`url('#${gradientId}')`} width="89.36" height="108.93" rx="10.57" ry="10.57" />
      <g filter={`url(#${shadowId})`}>
        <path d="M109.75,100.01c-3.62-1.76-6.38-4.29-8.29-7.61-1.91-3.32-2.86-7.32-2.86-12V28.38c0-4.68.95-8.69,2.86-12.03,1.91-3.34,4.67-5.87,8.29-7.61,3.62-1.73,7.97-2.6,13.04-2.6s9.42.87,13.04,2.6c3.62,1.73,6.38,4.27,8.29,7.61,1.91,3.34,2.86,7.35,2.86,12.03v52.02c0,4.68-.95,8.68-2.86,12-1.91,3.32-4.67,5.85-8.29,7.61-3.62,1.76-7.97,2.63-13.04,2.63s-9.42-.88-13.04-2.63ZM127.04,85.54c1.19-.65,2.09-1.58,2.7-2.8.61-1.21.91-2.69.91-4.42V30.33c0-1.69-.3-3.14-.91-4.36-.61-1.21-1.51-2.15-2.7-2.8-1.19-.65-2.61-.98-4.26-.98s-3.06.33-4.23.98-2.06,1.58-2.67,2.8c-.61,1.21-.91,2.67-.91,4.36v47.99c0,1.73.3,3.21.91,4.42.61,1.21,1.5,2.15,2.67,2.8s2.58.98,4.23.98,3.07-.33,4.26-.98Z" />
        <path d="M169.02,99.98c-3.45-1.91-6.07-4.68-7.87-8.3-1.8-3.62-2.7-7.96-2.7-13.01V6.08h16.4v73.05c0,2.33.57,4.14,1.71,5.43,1.14,1.3,2.72,1.94,4.74,1.94s3.68-.65,4.84-1.94c1.16-1.29,1.75-3.11,1.75-5.43V6.08h16.4v72.59c0,5.05-.91,9.39-2.73,13.01-1.82,3.62-4.46,6.39-7.9,8.3-3.45,1.91-7.56,2.86-12.35,2.86s-8.84-.96-12.29-2.86Z" />
        <g fill={`url('#${gradientId}')`}>
          <path d="M263.1,15.72c-1.79-3.28-4.39-5.76-7.83-7.46-3.44-1.69-7.56-2.54-12.38-2.54h-25.42v97.28h16.59v-37.74h8.83c4.77,0,8.88-.84,12.31-2.51,3.43-1.67,6.06-4.1,7.86-7.29,1.81-3.19,2.71-7.04,2.71-11.54v-16.39c0-4.59-.89-8.53-2.68-11.81ZM248.98,41.38c0,1.65-.27,3.08-.8,4.28-.54,1.2-1.29,2.12-2.27,2.74-.98.63-2.14.94-3.48.94h-8.36v-27.7h8.36c1.38,0,2.56.32,3.55.97.98.65,1.73,1.6,2.24,2.84.51,1.25.77,2.74.77,4.48v11.44Z" />
          <path d="M276.33,33.37h16.82v70.4h-16.82V33.37Z" />
          <path d="M304.92,33.27h16.82v70.4h-16.82V33.27Z" />
          <path d="M373.88,20.3l-24.09,66.46h24.76v15.88h-41.77v-14.68l24.29-66.46h-23.09V5.62h39.9v14.68Z" />
          <rect x="276.33" y="5.05" width="16.82" height="16.82" />
          <rect x="304.92" y="5.05" width="16.82" height="16.82" />
        </g>
        <path
          fill="#fff"
          d="M63.26,83.42l3.92,25.51h-20.38l-3.45-22.45L10.45,17.81l20.72-3.18,18.34,46.66,3.01-49.94,20.72-3.18-9.97,75.25Z"
        />
      </g>
    </StyledSvg>
  );
};

HoobiizLogoSvg.displayName = 'HoobiizLogoSvg';

const StyledSvg = styled.svg<{$height: number}>`
  height: ${p => p.$height}px;
  overflow: visible;
`;
