import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizActivityAddress,
  HoobiizActivityAddressType,
  HoobiizActivityItem,
  HoobiizVisibility,
  Markdown,
} from '@shared/dynamo_model';
import {isNull, removeUndefined} from '@shared/lib/type_utils';
import {FullItem, SanitizedItem} from '@shared/model/search_tables';

import {Button, ButtonAsLink, NavLink} from '@shared-frontend/components/core/button';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {ActivityAddressForm} from '@src/components/admin/activity/activity_address_form';
import {AnyCat, CategoryMultiInput} from '@src/components/admin/category/category_multi_input';
import {ExternalUrlLink} from '@src/components/admin/form/external_url_link';
import {
  FormBlock,
  FormBlockFull,
  FormFlex,
  FormLabel,
} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {HoobiizVisibilityForm} from '@src/components/admin/form/hoobiiz_visibility_form';
import {MarkdownHelpLabel} from '@src/components/admin/form/markdown_help_label';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {VendorInput} from '@src/components/admin/vendor/vendor_input';
import {adminInputTheme, AlertButton} from '@src/components/core/theme';
import {Sizes} from '@src/components/core/theme_base';
import {HoobiizActivityPageView} from '@src/components/ui/hoobiiz_activity_page_view';
import {getHeaderText} from '@src/components/ui/hoobiiz_stock_module';
import {getActivityUrl, toSlugName} from '@src/lib/hoobiiz_urls';

interface ActivityFormProps {
  initialData?: Partial<FullItem<'HoobiizActivity'>>;
  submitButtonText?: string;
  onSubmit?: (data: HoobiizData<HoobiizActivityItem>) => Promise<void>;
  onDelete?: () => Promise<void>;
}

export const ActivityForm: FC<ActivityFormProps> = props => {
  const {initialData, submitButtonText, onSubmit, onDelete} = props;

  const [cse, setCse] = useState(initialData?.cse ?? false);
  const [vendor, setVendor] = useState(initialData?.vendor);
  const [label, setLabel] = useState(initialData?.label ?? '');
  const [slugName, setSlugName] = useState(
    initialData?.slugName ?? toSlugName(initialData?.label ?? '')
  );
  const setLabelAndSlugName = useCallback((str: string) => {
    setLabel(str);
    setSlugName(toSlugName(str));
  }, []);
  const [comments, setComments] = useState(initialData?.comments ?? '');
  const [headerOverride, setHeaderOverride] = useState(getHeaderText(initialData?.headerOverride));

  const [cats, setCats] = useState<(AnyCat | undefined)[]>(initialData?.cat ?? []);
  const handleCatChange = useCallback((cat: AnyCat | undefined, element: HTMLDivElement) => {
    const indexStr = element.getAttribute('data-index');
    if (isNull(indexStr)) {
      setCats(cats => [...cats, cat]);
    } else {
      const index = parseFloat(indexStr);
      setCats(cats => [...cats.slice(0, index), cat, ...cats.slice(index + 1)]);
    }
  }, []);

  const [address, setAddress] = useState<HoobiizActivityAddress | undefined>(
    initialData?.address ?? {type: HoobiizActivityAddressType.Vendor}
  );

  const [markdown, setMarkdown] = useState(initialData?.markdown ?? ('' as Markdown));
  const mediaIds = initialData?.mediaIds ?? [];
  const mediaWithIds = mediaIds.map(id => ({
    id,
    media: initialData?.media?.find(m => m.id === id),
  }));
  const [media, setMedia] = useState<SanitizedItem<'HoobiizMedia'>[]>(mediaWithIds);
  const [visibility, setVisibility] = useState(initialData?.visibility ?? HoobiizVisibility.Public);

  const [previewHidden, setPreviewHidden] = useState(false);
  const handlePreviewClick = useCallback(() => setPreviewHidden(hidden => !hidden), []);

  const handleCreateClick = useCallback(async () => {
    if (!vendor || !address) {
      return;
    }
    return onSubmit?.({
      vendorId: vendor.id,
      catIds: removeUndefined(cats).map(cat => cat.id),
      label,
      slugName,
      cse,
      address,
      mediaIds: media.map(media => media.id),
      markdown,
      visibility,
      comments,
      headerOverride,
    });
  }, [
    vendor,
    address,
    onSubmit,
    cats,
    label,
    slugName,
    cse,
    media,
    markdown,
    visibility,
    comments,
    headerOverride,
  ]);

  const handleMediaChange = useCallback((statuses: MediaUploadStatus[]) => {
    const media = [] as SanitizedItem<'HoobiizMedia'>[];
    for (const status of statuses) {
      if (status.status === 'success') {
        media.push({media: status.media, id: status.id});
      }
    }
    setMedia(media);
  }, []);

  const url =
    initialData?.id === undefined ? undefined : getActivityUrl({id: initialData.id, slugName});

  return (
    <Wrapper>
      <FormWrapper>
        {url === undefined ? EmptyFragment : <StyledNavLink to={url}>{url}</StyledNavLink>}
        {/* INFO */}
        <FormSection title="INFO">
          <FormFlex>
            <FormBlock>
              <Input
                width="100%"
                value={label}
                syncState={setLabelAndSlugName}
                placeholder={initialData?.label}
                label="NOM DE L'ACTIVITÉ"
                autoFocus
                overrides={adminInputTheme}
              />
            </FormBlock>
            <FormBlock>
              <Input
                width="100%"
                value={slugName}
                syncState={setSlugName}
                placeholder={initialData?.slugName}
                label="NOM DANS L'URL"
                overrides={adminInputTheme}
              />
            </FormBlock>
          </FormFlex>
          <FormFlex>
            <FormBlock>
              <Checkbox checked={cse} syncState={setCse}>
                Activité premium
              </Checkbox>
            </FormBlock>
          </FormFlex>
          <FormFlex>
            <FormBlock>
              <VendorInput vendor={vendor} syncState={setVendor} />
            </FormBlock>
            <FormBlock>
              <HoobiizVisibilityForm value={visibility} syncState={setVisibility} />
            </FormBlock>
          </FormFlex>
          <FormBlockFull>
            <Textarea
              width="100%"
              value={headerOverride}
              syncState={setHeaderOverride}
              placeholder={getHeaderText(initialData?.headerOverride)}
              label="ENTÊTE RÉSERVATION"
              overrides={adminInputTheme}
              height={100}
            />
          </FormBlockFull>
          <FormBlockFull>
            <Textarea
              width="100%"
              value={comments}
              syncState={setComments}
              placeholder={initialData?.comments}
              label="BLOC NOTES (non visible sur le site)"
              overrides={adminInputTheme}
              height={200}
            />
          </FormBlockFull>
          {initialData?.externalUrl === undefined ? (
            EmptyFragment
          ) : (
            <ExternalUrlLink url={initialData.externalUrl} />
          )}
        </FormSection>

        {/* CATÉGORIES */}
        <FormSection title="CATÉGORIES">
          {cats.length === 0 ? (
            <CategoryMultiInput onChange={handleCatChange} />
          ) : (
            cats.map((cat, i) => (
              <CategoryMultiInput
                data-index={i}
                key={i}
                initialData={cat}
                onChange={handleCatChange}
              />
            ))
          )}
        </FormSection>

        {/* ADRESSE */}
        <FormSection title="ADRESSE">
          <ActivityAddressForm initialData={address} onChange={setAddress} />
        </FormSection>

        {/* DÉTAILS */}
        <FormSection title="DÉTAILS">
          <FormFlex>
            <FormBlockFull>
              <Textarea
                width="100%"
                height={400}
                value={markdown}
                syncState={setMarkdown}
                placeholder={initialData?.markdown}
                label={<MarkdownHelpLabel title="CONTENU" />}
                overrides={adminInputTheme}
              />
            </FormBlockFull>
            <FormBlockFull>
              <FormLabel>IMAGES</FormLabel>
              <MediaUpload initialMedia={mediaWithIds} multiple onChange={handleMediaChange} />
            </FormBlockFull>
          </FormFlex>
        </FormSection>

        {/* BUTTONS */}
        <ButtonsWrapper>
          <Button onClickAsync={handleCreateClick} disabled={!vendor || !address}>
            {submitButtonText}
          </Button>
          {onDelete ? <AlertButton onClickAsync={onDelete}>Supprimer</AlertButton> : EmptyFragment}
        </ButtonsWrapper>
      </FormWrapper>
      <PreviewSection
        title="APERÇUS"
        titleComponent={
          <ButtonAsLink onClick={handlePreviewClick}>
            {previewHidden ? 'Agrandir' : 'Réduire'}
          </ButtonAsLink>
        }
        noMargin
        $hidden={previewHidden}
      >
        <StyledHoobiizActivityPageView
          activityId={initialData?.id}
          label={label}
          cat={removeUndefined(cats)}
          activityAddress={address}
          vendor={vendor}
          media={media}
          markdown={markdown}
          headerOverride={headerOverride}
        />
      </PreviewSection>
    </Wrapper>
  );
};
ActivityForm.displayName = 'ActivityForm';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PreviewSection = styled(FormSection)<{$hidden: boolean}>`
  position: sticky;
  top: 32px;
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
  flex-shrink: 0;
  ${p => p.$hidden && `margin-right: -450px;`}
  transition: margin-right ease-in-out 300ms;
`;

const StyledHoobiizActivityPageView = styled(HoobiizActivityPageView)`
  zoom: 0.5;
  width: ${Sizes.Content};
  margin-top: 69px;
`;

const StyledNavLink = styled(NavLink)`
  position: fixed;
  top: 0;
  right: 0;
  padding: 6px 12px;
  background: white;
`;
