import {FC, useEffect, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockId,
  HoobiizStockModePregenerated,
  HoobiizStockReservationFlexible,
} from '@shared/dynamo_model';
import {hoobiizTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {removeUndefined} from '@shared/lib/type_utils';

import {
  HoobiizStockModePregeneratedForm,
  HoobiizStockModePregeneratedTicket,
} from '@src/components/admin/activity_stock/hoobiiz_stock_mode_pregenerated_form';
import {HoobiizStockReservationFlexibleForm} from '@src/components/admin/activity_stock/hoobiiz_stock_reservation_flexible_form';
import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {MaybeTicket} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {HoobiizTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_multi_form';
import {FormBlockFull, FormWrapper} from '@src/components/admin/form/form_fragments';

export interface FlexibleAndPregeneratedTicket {
  id?: HoobiizStockId;
  ticket: {
    reservation: HoobiizStockReservationFlexible;
    mode: HoobiizStockModePregenerated;
    quantity: number;
    ticketInfo: MaybeTicket[];
    terms: string;
  };
}

interface ActivityStockFlexibleAndPregeneratedFormProps {
  vendorHours: HoobiizOpeningHours;
  initial?: FlexibleAndPregeneratedTicket[];
  onChange: (tickets: FlexibleAndPregeneratedTicket[]) => void;
}

export const ActivityStockFlexibleAndPregeneratedForm: FC<
  ActivityStockFlexibleAndPregeneratedFormProps
> = props => {
  const {vendorHours, initial, onChange} = props;

  const [reservation, setReservation] = useState(initial?.[0]?.ticket.reservation);
  const [tickets, setTickets] = useState<HoobiizStockModePregeneratedTicket[]>(
    initial?.map(i => ({id: i.id, mode: i.ticket.mode})) ?? []
  );

  const [ticketInfo, setTicketInfo] = useState<MaybeTicket[]>(
    initial?.[0]?.ticket.ticketInfo ?? [{id: hoobiizTicketInfoId()}]
  );
  const [terms, setTerms] = useState<string>(initial?.[0]?.ticket.terms ?? '');

  useEffect(() => {
    if (!reservation) {
      return;
    }
    onChange(
      tickets.map(({mode, id}) => ({
        id,
        ticket: {reservation, mode, quantity: 1, ticketInfo: removeUndefined(ticketInfo), terms},
      }))
    );
  }, [onChange, reservation, terms, ticketInfo, tickets]);

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <HoobiizTicketInfoMultiForm initialData={ticketInfo} onChange={setTicketInfo} />
      <FormBlockFull>
        <HoobiizStockReservationFlexibleForm
          vendorHours={vendorHours}
          initialData={reservation}
          onChange={setReservation}
        />
      </FormBlockFull>
      <FormBlockFull>
        <HoobiizStockModePregeneratedForm initialData={tickets} onChange={setTickets} />
      </FormBlockFull>
    </FormWrapper>
  );
};
ActivityStockFlexibleAndPregeneratedForm.displayName = 'ActivityStockFlexibleAndPregeneratedForm';
