import {GoogleMap} from '@react-google-maps/api';
import {FC, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {AddressLookupResult} from '@shared/dynamo_model';
import {env} from '@shared/env_constants';
import {addressToLines} from '@shared/lib/hoobiiz/activity_address_and_phone';
import {LYON_CENTER_COORDINATE} from '@shared/model/google_maps_constants';

import {ErrorBoundary} from '@shared-frontend/components/core/error_boundary';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {getWindowUnsafe} from '@shared-frontend/window';

import {HoobiizGoogleMapsTooltip} from '@src/components/ui/hoobiiz_google_maps_tooltip';

interface HoobiizGoogleMapsProps {
  name?: string;
  address?: AddressLookupResult;
}

const MAP_INITIAL_ZOOM = 11;

interface GoogleMapsWindow {
  google?: {
    maps?: {
      importLibrary?: (f: string, ...n: unknown[]) => Promise<void>;
    } & Record<string, unknown>;
  };
  initMap?: () => void;
}

let loadingPromise: Promise<void> | undefined;

async function loadGoogleMap(): Promise<void> {
  if (loadingPromise !== undefined) {
    return loadingPromise;
  }

  loadingPromise = new Promise<void>((resolve, reject) => {
    // Initialize global variables
    const w = window as unknown as GoogleMapsWindow;
    if (!w.google) {
      w.google = {};
    }
    if (!w.google.maps) {
      w.google.maps = {};
    }

    // Register the callback
    w['initMap'] = resolve;

    // Create the URL
    const urlParams = new URLSearchParams();
    urlParams.set('key', env.googleMapsApiKey);
    urlParams.set('callback', `initMap`);
    urlParams.set('v', `weekly`);
    urlParams.set('loading', `async`);
    const url = `https://maps.googleapis.com/maps/api/js?${urlParams.toString()}`;

    // Create the script tag
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.onerror = (): void => {
      reject(Error(`Google Maps could not load.`));
    };
    scriptTag.nonce = getWindowUnsafe().NONCE_ID;
    document.head.append(scriptTag);
  });
  return loadingPromise;
}

function useGoogleMap(): {isLoaded: boolean} {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    loadGoogleMap()
      .then(() => {
        setIsLoaded(true);
      })
      .catch(notifyError);
  }, []);
  return {isLoaded};
}

export const HoobiizGoogleMaps: FC<HoobiizGoogleMapsProps> = props => {
  const {isLoaded} = useGoogleMap();

  const {name, address} = props;
  const {line1, line2} = address ? addressToLines(address) : {line1: undefined, line2: undefined};
  const addressLocation = address?.geometry.location ?? LYON_CENTER_COORDINATE;

  return (
    <ErrorBoundary>
      <Wrapper>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '100%',
              borderRadius: 8,
            }}
            center={addressLocation}
            //   onLoad={setMaps}
            zoom={MAP_INITIAL_ZOOM}
            options={{
              clickableIcons: false,
              disableDefaultUI: true,
              isFractionalZoomEnabled: true,
              styles: UBER_GOOGLE_MAPS_STYLES,
            }}
          >
            <HoobiizGoogleMapsTooltip
              position={addressLocation}
              title={name}
              line1={line1 ?? `Adresse non renseignée`}
              line2={line2}
            />
          </GoogleMap>
        ) : (
          EmptyFragment
        )}
      </Wrapper>
    </ErrorBoundary>
  );
};
HoobiizGoogleMaps.displayName = 'HoobiizGoogleMaps';

const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  background-color: #e4e2df;
  .gmnoprint,
  .gm-style-cc,
  .gm-style-iw-c > button,
  .gm-style-iw-chr {
    display: none;
  }
`;

const UBER_GOOGLE_MAPS_STYLES = [
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text',
    stylers: [{color: '#556275'}],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.stroke',
    stylers: [{color: '#e9edf3'}],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [{color: '#90a8dd'}],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [{color: '#e6e9ec'}],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{color: '#e6e9ec'}],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [{color: '#e6e9ec'}],
  },
  {
    featureType: 'landscape.natural.terrain',
    stylers: [{color: '#e6e9ec'}, {visibility: 'off'}],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [{color: '#e6e9ec'}],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{saturation: -100}],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{color: '#6890df'}, {saturation: -65}, {lightness: -20}],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [{color: '#a7dfb6'}],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{color: '#5ea678'}, {saturation: -25}],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{saturation: -30}, {lightness: 10}],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{color: '#40484d'}],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [{color: '#ffffff'}],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [{color: '#ffffff'}],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{color: '#a6b5db'}],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{color: '#96acd0'}],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.fill',
    stylers: [{color: '#8b9fd4'}],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [{color: '#7996c9'}],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [{color: '#ffffff'}],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [{color: '#ffffff'}],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{saturation: -65}],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [{color: '#29b4e3'}, {saturation: -60}, {lightness: 60}],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.stroke',
    stylers: [{color: '#29b4e3'}, {saturation: -60}, {lightness: 60}],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.fill',
    stylers: [{color: '#c9d4e3'}],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.text',
    stylers: [{saturation: -40}, {lightness: 5}],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{color: '#acd5f5'}],
  },
];
