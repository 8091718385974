import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizVendorId} from '@shared/dynamo_model';

import {useApiCall} from '@shared-frontend/lib/use_api_call';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {NotFoundPage} from '@src/components/pages/not_found_page';
import {HoobiizVendorPageView} from '@src/components/ui/hoobiiz_vendor_page_view';

export const HoobiizVendorPage: FC = () => {
  const {id = ''} = useParams();
  const hoobiizVendorId = id as HoobiizVendorId;

  const {data} = useApiCall(
    HoobiizApi,
    '/get-vendor',
    {vendorId: hoobiizVendorId},
    {errMsg: `Échec du chargement du partenaire. Vous pouvez rafraichir la page pour réessayer`}
  );

  if (!data) {
    return <Wrapper>Chargement...</Wrapper>;
  }

  const {vendor} = data;
  if (!vendor) {
    return <NotFoundPage />;
  }

  return (
    <HoobiizVendorPageView
      vendorId={vendor.id}
      name={vendor.name}
      media={vendor.media}
      info={vendor.markdown}
      locations={vendor.addresses}
    />
  );
};

HoobiizVendorPage.displayName = 'HoobiizVendorPage';

const Wrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
