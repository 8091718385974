import {FC, useEffect, useMemo, useRef} from 'react';
import {useLocation} from 'react-router';

import {IS_LOCALHOST_ENV} from '@shared/env_constants';
import {ANALYTICS_DOMAIN} from '@shared/lib/analytics/snippet';

import {EmptyFragment} from '@shared-frontend/lib/react';
import {getWindowUnsafe} from '@shared-frontend/window';

interface AnalyticsProps {}

export const Analytics: FC<AnalyticsProps> = () => {
  const {pathname} = useLocation();
  const prevPathname = useRef(pathname);
  const cid = useMemo(() => {
    if (!IS_BROWSER || IS_LOCALHOST_ENV) {
      return undefined;
    }
    try {
      return localStorage.getItem('onescale_ana=') ?? undefined;
    } catch {
      return undefined;
    }
  }, []);

  useEffect(() => {
    if (cid === undefined) {
      return;
    }
    if (prevPathname.current !== pathname) {
      fetch(
        encodeURI(
          `${ANALYTICS_DOMAIN}/${getWindowUnsafe().ANALYTICS_ID}/?cid=${cid}&pa=${pathname}&dr=${
            document.location.origin
          }${prevPathname.current}`
        )
      ).catch(() => {});
      prevPathname.current = pathname;
    }
  }, [cid, pathname]);

  return EmptyFragment;
};
Analytics.displayName = 'Analytics';
