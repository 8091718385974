import {InfoWindow, Marker} from '@react-google-maps/api';
import {FC} from 'react';
import styled from 'styled-components';

import {PlaceLatLngLiteral} from '@shared/api/definitions/google_places_api';
import {removeUndefined} from '@shared/lib/type_utils';

interface HoobiizGoogleMapsTooltipProps {
  position: PlaceLatLngLiteral;
  title?: string;
  line1?: string;
  line2?: string;
}

export const HoobiizGoogleMapsTooltip: FC<HoobiizGoogleMapsTooltipProps> = props => {
  const {title, line1, line2, position} = props;
  const popupElements = removeUndefined([
    title === undefined ? undefined : <MarkerWindowTitle key="title">{title}</MarkerWindowTitle>,
    line1 === undefined ? undefined : <MarkerWindowText key="line1">{line1}</MarkerWindowText>,
    line2 === undefined ? undefined : <MarkerWindowText key="line2">{line2}</MarkerWindowText>,
  ]);
  return popupElements.length > 0 ? (
    <InfoWindow position={position} options={{disableAutoPan: true}}>
      <MarkerWindow>{popupElements}</MarkerWindow>
    </InfoWindow>
  ) : (
    <Marker position={position}></Marker>
  );
};
HoobiizGoogleMapsTooltip.displayName = 'HoobiizGoogleMapsTooltip';

const MarkerWindow = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  border-radius: 6px;
  background-color: #fffffff0;
  padding: 14px 0px 5px 6px;
  font-weight: 500;
`;

const MarkerWindowText = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

const MarkerWindowTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
`;
