async function readEntries(entries: FileSystemEntry[]): Promise<FileSystemFileEntry[]> {
  const filesPromises: Promise<FileSystemFileEntry[]>[] = [];
  for (const entry of entries) {
    if (entry.isFile) {
      filesPromises.push(Promise.resolve([entry as FileSystemFileEntry]));
    } else {
      filesPromises.push(readDirectory(entry as FileSystemDirectoryEntry));
    }
  }
  const recursiveFiles = await Promise.all(filesPromises);
  return recursiveFiles.flat();
}

async function readDirectory(directory: FileSystemDirectoryEntry): Promise<FileSystemFileEntry[]> {
  const dirReader = directory.createReader();
  return new Promise((resolve, reject) => {
    dirReader.readEntries(results => {
      resolve(readEntries(results));
    }, reject);
  });
}

async function entryToFile(entry: FileSystemFileEntry): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    entry.file(resolve, reject);
  });
}

export function fileListToArray(list: FileList): File[] {
  const files: File[] = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of, unicorn/no-for-loop
  for (let i = 0; i < list.length; i++) {
    const file = list[i];
    if (!file) {
      continue;
    }
    files.push(file);
  }
  return files;
}

interface DragEventLike {
  dataTransfer: DataTransfer | null;
}

export async function extractFiles(evt: DragEventLike): Promise<File[]> {
  const dataTransferItems = evt.dataTransfer?.items;
  if (!dataTransferItems) {
    return [];
  }

  const filesPromises: Promise<File[]>[] = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of, unicorn/no-for-loop
  for (let i = 0; i < dataTransferItems.length; i++) {
    const entry = dataTransferItems[i]?.webkitGetAsEntry();
    if (!entry) {
      continue;
    }
    filesPromises.push(
      readEntries([entry]).then(async entries => {
        const files = await Promise.all(entries.map(entryToFile));
        return files.flat();
      })
    );
  }

  const files = await Promise.all(filesPromises);
  return files.flat();
}
