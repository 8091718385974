import {FC, Fragment, JSX} from 'react';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Modal} from '@shared-frontend/components/core/modal';

import {GlobalStyle} from '@src/components/core/global_styles';

interface CleanAppWrapperProps {
  children: JSX.Element;
}

export const CleanAppWrapper: FC<CleanAppWrapperProps> = props => {
  const {children} = props;
  const {
    main: {logo},
  } = useTheme();

  return (
    <Fragment>
      <GlobalStyle />
      <Wrapper>
        <Top>
          <UnthemedNavLink to="/">{logo}</UnthemedNavLink>
        </Top>
        <Bottom>{children}</Bottom>
      </Wrapper>
      <Modal />
    </Fragment>
  );
};
CleanAppWrapper.displayName = 'CleanAppWrapper';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  flex-shrink: 0;
  margin: auto;
  padding-top: 24px;
`;
const Bottom = styled.div`
  flex-grow: 1;
`;
