import {FC} from 'react';
import styled from 'styled-components';

import {FrontendUserId, HoobiizUserGroupId} from '@shared/dynamo_model';
import {arrayJoin} from '@shared/lib/array_utils';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {AdminUserAndGroupChainNodeGroup} from '@src/components/admin/user_and_group/admin_user_and_group_chain_node_group';
import {AdminUserAndGroupChainNodeUser} from '@src/components/admin/user_and_group/admin_user_and_group_chain_node_user';
import {useHoobiizUserGroupHierarchy} from '@src/components/admin/user_and_group/admin_user_and_group_store';

type AdminUserAndGroupChainProps =
  | {
      groupId: HoobiizUserGroupId;
    }
  | {
      userId: FrontendUserId;
    };

export const AdminUserAndGroupChain: FC<AdminUserAndGroupChainProps> = props => {
  const hierarchy = useHoobiizUserGroupHierarchy(props);
  return (
    <Wrapper>
      {arrayJoin(
        hierarchy.map(g =>
          'group' in g ? (
            <UnthemedNavLink key={g.group.groupId} to={g.modalPath}>
              <AdminUserAndGroupChainNodeGroup groupId={g.group.groupId} />
            </UnthemedNavLink>
          ) : (
            <UnthemedNavLink key={g.user.userId} to={g.modalPath}>
              <AdminUserAndGroupChainNodeUser userId={g.user.userId} />
            </UnthemedNavLink>
          )
        ),
        i => (
          <SvgIcon name="ChevronRight" key={`${i}-icon`} size={10} color="#aaa" />
        )
      )}
    </Wrapper>
  );
};

AdminUserAndGroupChain.displayName = 'AdminUserAndGroupChain';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`;
