import {createContext, useContext} from 'react';

import {SsrContextData} from '@shared/frontends/ssr_context';

export const SsrContext = createContext<SsrContextData | undefined>(undefined);

export function useSsrContext(): SsrContextData {
  const ssrContext = useContext(SsrContext);
  if (!ssrContext) {
    throw new Error('SsrContext is missing');
  }
  return ssrContext;
}
