import {HoobiizMediaId} from '@shared/dynamo_model';
import {getStaticDomain} from '@shared/frontends/frontend_constant';
import {groupBy} from '@shared/lib/array_utils';
import {removeUndefined} from '@shared/lib/type_utils';
import {FullItem, SanitizedItem} from '@shared/model/search_tables';
import {awsBucketHashes} from '@shared/terraform';

import {ImageDimension, ImageFullProps} from '@shared-frontend/components/core/image';

type Media = SanitizedItem<'HoobiizMedia'> | {media?: FullItem<'HoobiizMedia'>; id: HoobiizMediaId};

export function mediaUrl(
  host: string,
  id: HoobiizMediaId,
  ext: string,
  size: {width: number; height: number}
): string {
  const {width, height} = size;
  return `${getStaticDomain(host)}/${awsBucketHashes.HoobiizMedia}/${id}_${width}_${height}.${ext}`;
}

export function mediaSrcData(host: string, media?: Media): ImageSrcData {
  const id = media?.id;
  const {versions = [], placeholder} = media?.media ?? {};
  const {base64 = 'UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAkA4JaQAA3AA/vYkAAA='} =
    placeholder ?? {};
  versions.sort((v1, v2) => v2.width - v1.width);
  const [largest] = versions;

  const byMimeTypes = groupBy(versions, v => v.mimeType);
  const preferedMimeTypes = ['image/webp', 'image/png'];
  const sources = removeUndefined([
    ...preferedMimeTypes.map(mimeType => byMimeTypes.get(mimeType)),
    ...[...byMimeTypes.values()].filter(v => !preferedMimeTypes.includes(v[0].mimeType)),
  ]);

  return {
    sources:
      id === undefined
        ? []
        : sources.map(v => ({
            type: v[0].mimeType,
            srcset: v
              .map(
                ({ext, width, height}) => `${mediaUrl(host, id, ext, {width, height})} ${width}w`
              )
              .join(', '),
          })),
    src:
      largest === undefined || id === undefined
        ? `${getStaticDomain(host)}/${awsBucketHashes.HoobiizMedia}/original/${id}`
        : mediaUrl(host, id, largest.ext, {
            width: largest.width,
            height: largest.height,
          }),
    aspectRatio: largest === undefined ? '1/1' : `${largest.width}/${largest.height}`,
    thumbnail: `data:image/webp;base64,${base64}`,
  };
}

export function hoobiizMediaToProps(
  host: string,
  media: Media | undefined,
  size: ImageDimension
): ImageFullProps {
  return {
    alt: '',
    srcAndSizes: {srcData: mediaSrcData(host, media), width: 'width' in size ? size.width : '100%'},
    rawUrls: true,
    ...size,
  };
}
