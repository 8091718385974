import {FC, useEffect} from 'react';

import {HoobiizActivityAddressAtHome, HoobiizActivityAddressType} from '@shared/dynamo_model';

import {EmptyFragment} from '@shared-frontend/lib/react';

interface ActivityAddressAtHomeFormProps {
  initialData?: HoobiizActivityAddressAtHome;
  onChange: (newVal: HoobiizActivityAddressAtHome | undefined) => void;
}

export const ActivityAddressAtHomeForm: FC<ActivityAddressAtHomeFormProps> = props => {
  const {onChange} = props;

  useEffect(() => {
    onChange({type: HoobiizActivityAddressType.AtHome});
  }, [onChange]);

  return EmptyFragment;
};
ActivityAddressAtHomeForm.displayName = 'ActivityAddressAtHomeForm';
