import {useId} from 'react';

import {Custom} from '@shared-frontend/lib/react';

interface HoobiizIconSvgProps {
  height: number;
}

export const HoobiizIconSvg: Custom<HoobiizIconSvgProps, 'svg'> = props => {
  const {height, ...rest} = props;
  const id = useId();

  return (
    <svg viewBox="-1 -2 71 100.77" height={height} {...rest}>
      <filter
        // eslint-disable-next-line react/forbid-dom-props
        id={id}
      >
        <feDropShadow dx="5" dy="1" stdDeviation="2" floodOpacity="0.29" />
      </filter>
      <g filter={`url(#${id})`}>
        <path
          fill="#fff"
          d="M52.82,75.25l3.92,25.51h-20.38l-3.45-22.45L0,9.64l20.72-3.18,18.34,46.66,3.01-49.94L62.79,0l-9.97,75.25Z"
        />
      </g>
    </svg>
  );
};

HoobiizIconSvg.displayName = 'HoobiizIconSvg';
