import {FC, useCallback} from 'react';

import {
  HoobiizFlexibleStockScheduleOverride,
  HoobiizFlexibleStockScheduleType,
  HoobiizOpeningHours,
} from '@shared/dynamo_model';

import {OpeningHoursForm} from '@src/components/admin/form/opening_hours_form';

interface HoobiizFlexibleStockScheduleOverrideFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: HoobiizFlexibleStockScheduleOverride;
  onChange: (newVal: HoobiizFlexibleStockScheduleOverride) => void;
}

export const HoobiizFlexibleStockScheduleOverrideForm: FC<
  HoobiizFlexibleStockScheduleOverrideFormProps
> = props => {
  const {vendorHours, initialData, onChange} = props;

  const handleOpeningHoursChange = useCallback(
    (openingHours: HoobiizOpeningHours) =>
      onChange({type: HoobiizFlexibleStockScheduleType.Override, schedule: openingHours}),
    [onChange]
  );

  return (
    <OpeningHoursForm
      openingHours={initialData?.schedule ?? vendorHours}
      onChange={handleOpeningHoursChange}
    />
  );
};
HoobiizFlexibleStockScheduleOverrideForm.displayName = 'HoobiizFlexibleStockScheduleOverrideForm';
