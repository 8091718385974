import {FC} from 'react';
import styled from 'styled-components';

import {ActivityAndSources} from '@shared/api/definitions/public_api/hoobiiz_api';

import {DummyCard, HoobiizActivityCard} from '@src/components/ui/hoobiiz_activity_card';

interface HoobiizActivityGridProps {
  activities?: ActivityAndSources[];
}

export const HoobiizActivityGrid: FC<HoobiizActivityGridProps> = props => {
  const {activities} = props;
  return (
    <Grid>
      {activities ? (
        activities.map(activityAndSources => (
          <HoobiizActivityCard
            key={activityAndSources.item.id}
            activityAndSources={activityAndSources}
            clickable
          />
        ))
      ) : (
        <DummyCard />
      )}
    </Grid>
  );
};

HoobiizActivityGrid.displayName = 'HoobiizActivityGrid';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  justify-items: center;
`;
