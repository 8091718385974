import {FC, useCallback} from 'react';
import styled from 'styled-components';

import {HoobiizGroupItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {FrontendUserId, HoobiizUserGroupId} from '@shared/dynamo_model';

import {EmptyFragment} from '@shared-frontend/lib/react';
import {useMemoCompare} from '@shared-frontend/lib/use_memo_compare';

import {FormSection} from '@src/components/admin/form/form_section';
import {AdminUserAndGroupChain} from '@src/components/admin/user_and_group/admin_user_and_group_chain';
import {AdminUserAndGroupPicker} from '@src/components/admin/user_and_group/admin_user_and_group_picker';
import {
  getHoobiizUserGroupHierarchy,
  hoobiizUserGroupHierarchyToGroupIds,
  moveHoobiizUserToGroup,
  useHoobiizUserGroupUser,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {AdminUserAndGroupTreeActionContext} from '@src/components/admin/user_and_group/admin_user_and_group_tree';

interface AdminUserAndGroupUserModalProps {
  userId: FrontendUserId;
}

export const AdminUserAndGroupUserModal: FC<AdminUserAndGroupUserModalProps> = props => {
  const {userId} = props;
  const userAndGroup = useHoobiizUserGroupUser({userId});
  const groupIds = useMemoCompare(() => {
    const hierarchy = userAndGroup
      ? hoobiizUserGroupHierarchyToGroupIds(
          getHoobiizUserGroupHierarchy({groupId: userAndGroup.group.groupId})
        )
      : [];
    return hierarchy;
  }, [userAndGroup]);

  const handleGroupSelect = useCallback(
    async (group: HoobiizGroupItem, context: AdminUserAndGroupTreeActionContext): Promise<void> => {
      if (!userAndGroup) {
        return;
      }
      await moveHoobiizUserToGroup({
        userId,
        previousParentGroupId: userAndGroup.group.groupId,
        newParentGroupId: group.groupId,
      });
      context.expandLine();
    },
    [userAndGroup, userId]
  );

  const isHighlighted = useCallback(
    (groupId: HoobiizUserGroupId) => groupIds.includes(groupId),
    [groupIds]
  );

  const isUserHighlighted = useCallback(
    (id: FrontendUserId) => {
      return userId === id;
    },
    [userId]
  );

  const isExpanded = useCallback(
    (groupId: HoobiizUserGroupId) => groupIds.includes(groupId),
    [groupIds]
  );

  if (!userAndGroup) {
    return EmptyFragment;
  }

  const {group} = userAndGroup;

  return (
    <Wrapper>
      <AdminUserAndGroupChain userId={userId} />
      <FormSection title="Déplacer l'utilisateur">
        <AdminUserAndGroupPicker
          onSelect={handleGroupSelect}
          isGroupHighlighted={isHighlighted}
          isCurrentUser={isUserHighlighted}
          isGroupExpanded={isExpanded}
          omitGroupId={group.groupId}
        />
      </FormSection>
    </Wrapper>
  );
};

AdminUserAndGroupUserModal.displayName = 'AdminUserAndGroupUserModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border-radius: 16px;
  background-color: #fff;
  width: 90vw;
  max-width: 800px;
`;
