import {Dispatch, FC, Fragment, ReactNode, SetStateAction, useCallback, useRef} from 'react';
import {styled} from 'styled-components';

import {Button} from '@shared-frontend/components/core/button';
import {NULL_REF} from '@shared-frontend/lib/react';

import {fileListToArray} from '@src/components/admin/form/drop_zone_lib';

interface AddFilesButtonProps {
  syncState: Dispatch<SetStateAction<File[]>>;
  children: ReactNode;
}

export const AddFilesButton: FC<AddFilesButtonProps> = props => {
  const {syncState, children} = props;

  // Trigger native upload dialog when the button is clicked
  const uploadInputRef = useRef<HTMLInputElement>(NULL_REF);
  const handleAddFilesClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  // Callback when the user selected a file with the native file dialog
  const handleFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    evt => {
      // Get the selected files
      const files = fileListToArray(evt.currentTarget.files ?? new FileList());
      evt.currentTarget.files = null; // eslint-disable-line no-null/no-null
      syncState(current => [...current, ...files]);
    },
    [syncState]
  );

  return (
    <Fragment>
      <Button onClick={handleAddFilesClick}>{children}</Button>
      <HiddenInput type="file" multiple ref={uploadInputRef} onChange={handleFileChange} />
    </Fragment>
  );
};
AddFilesButton.displayName = 'AddFilesButton';

const HiddenInput = styled.input`
  display: none;
`;
