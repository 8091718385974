import {FC} from 'react';

import {HoobiizCartItemItemFull} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCartItemId} from '@shared/dynamo_model';
import {computeCartItemPrice} from '@shared/lib/hoobiiz/hoobiiz_prices';

import {HoobiizTicketItem} from '@src/components/ui/hoobiiz_ticket_item';

interface HoobiizCartItemProps {
  fullCartItem: HoobiizCartItemItemFull;
  onDeleteClick: (id: HoobiizCartItemId) => Promise<void>;
}

export const HoobiizCartItem: FC<HoobiizCartItemProps> = props => {
  const {fullCartItem, onDeleteClick} = props;
  const {quantity} = fullCartItem.cartItem.itemData;

  const {total, fees, ticketInfoTotal, options} = computeCartItemPrice(fullCartItem);

  return (
    <HoobiizTicketItem
      activity={fullCartItem.activity}
      quantity={quantity}
      total={total}
      fees={fees}
      ticketInfo={fullCartItem.ticketInfo}
      ticketInfoTotal={ticketInfoTotal}
      options={options}
      validityDateStr={fullCartItem.validityDateStr}
      cartInfo={{id: fullCartItem.cartItem.id, onDeleteClick}}
    />
  );
};

HoobiizCartItem.displayName = 'HoobiizCartItem';
