import {FC} from 'react';
import styled from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Tooltip} from '@shared-frontend/components/core/tooltip';

interface TermsHelpLabelProps {
  title: string;
}

export const TermsHelpLabel: FC<TermsHelpLabelProps> = props => {
  const {title} = props;
  return (
    <Wrapper>
      {title}
      <Tooltip
        content={
          <HelpContent>
            <div>Texte **en gras** en l'entourant avec 2 étoiles.</div>
            <div>Texte ***en gras et rouge*** en l'entourant avec 2 étoiles.</div>
          </HelpContent>
        }
        position="right"
      >
        <HelpLabel>
          <SvgIcon name="Info" size={16} color="#72747c" />
          Aide
        </HelpLabel>
      </Tooltip>
    </Wrapper>
  );
};

TermsHelpLabel.displayName = 'TermsHelpLabel';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const HelpLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const HelpContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
