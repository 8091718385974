import styled from 'styled-components';

import {HoobiizOrderStatus} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';

interface HoobiizOrderItemStatusProps {
  status: HoobiizOrderStatus;
}

const statusToLabel: Record<HoobiizOrderStatus, {label: string; color: string}> = {
  [HoobiizOrderStatus.Pending]: {label: 'En attente de paiement', color: Colors.Grey},
  [HoobiizOrderStatus.Canceled]: {label: 'Annulée', color: Colors.Grey},
  [HoobiizOrderStatus.PendingConfirmation]: {
    label: 'En attente de confirmation',
    color: Colors.Gold,
  },
  [HoobiizOrderStatus.Success]: {label: 'Validée', color: Colors.Green},
  [HoobiizOrderStatus.Rejected]: {label: 'Rejetée', color: Colors.RedLight},
};

export const HoobiizOrderItemStatus: Custom<HoobiizOrderItemStatusProps, 'div'> = props => {
  const {status, ...rest} = props;

  const {color, label} = statusToLabel[status];

  return (
    <ItemSticker {...rest} $color={color}>
      {label}
    </ItemSticker>
  );
};

HoobiizOrderItemStatus.displayName = 'HoobiizOrderItemStatus';

const ItemSticker = styled.div<{$color: string}>`
  padding: 4px 8px;
  background-color: ${p => p.$color};
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  width: max-content;
`;
