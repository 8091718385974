import {FC, useEffect, useRef} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizOrderStatus, StripePaymentIntentId} from '@shared/dynamo_model';
import {arrayJoin} from '@shared/lib/array_utils';

import {useApiCall} from '@shared-frontend/lib/use_api_call';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {HoobiizOrderItem} from '@src/components/ui/hoobiiz_order_item';
import {Total} from '@src/components/ui/total';
import {computeOrdersTotal} from '@src/lib/currency_amount';

const MIN_REFRESH_RATE_MS = 100;
const MAX_REFRESH_RATE_MS = 5000;

export const OrderPage: FC = () => {
  const {id = ''} = useParams();
  const paymentId = id as StripePaymentIntentId;

  const {data: orders, refreshData: refreshPayment} = useApiCall(
    HoobiizApi,
    '/get-purchase',
    {paymentId},
    {errMsg: `Échec du chargement de la commande. Vous pouvez rafraichir la page pour réessayer`}
  );

  const refreshRate = useRef(MIN_REFRESH_RATE_MS);
  useEffect(() => {
    const hasPendingOrder =
      orders?.find(o => o.status === HoobiizOrderStatus.Pending) !== undefined;
    const hasOrderWithoutDocuments = orders?.find(o => o.documentUrls.length === 0) !== undefined;
    if (hasPendingOrder || hasOrderWithoutDocuments) {
      setTimeout(() => {
        refreshPayment().catch(() => {});
      }, refreshRate.current);
      refreshRate.current = Math.min(MAX_REFRESH_RATE_MS, refreshRate.current * 2);
    }
  }, [orders, refreshPayment]);

  if (!orders) {
    return <Wrapper>Chargement...</Wrapper>;
  }

  const [firstOrder] = orders;

  if (!firstOrder) {
    return <Wrapper>Commande vide...</Wrapper>;
  }

  if (firstOrder.status === HoobiizOrderStatus.Pending) {
    return (
      <PendingWrapper>
        <Title>Paiement accepté, nous traitons votre commande.</Title>
      </PendingWrapper>
    );
  }

  const ordersTotal = computeOrdersTotal(orders);

  return (
    <Wrapper>
      <Title>Votre commande</Title>
      <Section>
        <ListWrapper>
          {arrayJoin(
            orders.map(order => <HoobiizOrderItem key={order.id} orderItem={order} />),
            i => (
              <Separator key={i} />
            )
          )}
        </ListWrapper>
        <Total itemCount={orders.length} total={ordersTotal.total} fees={ordersTotal.fees} />
      </Section>
    </Wrapper>
  );
};

OrderPage.displayName = 'OrderPage';

const Wrapper = styled.div`
  width: 100%;
  padding: 32px;
`;
const PendingWrapper = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #dddddd;
`;

const Section = styled.div`
  display: flex;
  gap: 64px;
  align-items: flex-start;
  & > *:first-child {
    flex-grow: 1;
    width: 1px;
  }
  & > *:nth-child(2) {
    flex-shrink: 0;
    position: sticky;
    top: 180px;
    flex-shrink: 0;
    padding: 28px 32px;
    border: solid 2px #00000010;
    border-radius: 16px;
    box-shadow: 0 0 20px -10px #00000036;
  }
`;
