import {FC, MouseEvent, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';
import {HoobiizActivityId, HoobiizStockId} from '@shared/dynamo_model';
import {groupBy} from '@shared/lib/array_utils';
import {asNumberOrThrow} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {Button, ButtonAsLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {showRawModal} from '@shared-frontend/components/core/modal';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {localTime} from '@shared-frontend/lib/date_format';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useOptionalStringQueryString} from '@shared-frontend/lib/use_query_string';

import {
  formatDate,
  parseDate,
} from '@src/components/admin/activity_stock/hoobiiz_admin_stock_calendar';
import {HoobiizAdminStockCalendarPdfModal} from '@src/components/admin/activity_stock/hoobiiz_admin_stock_calendar_pdf_modal';
import {adminInputTheme} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';

interface HoobiizAdminStockCalendarModalProps {
  activityId: HoobiizActivityId;
}

export const HoobiizAdminStockCalendarModal: FC<HoobiizAdminStockCalendarModalProps> = props => {
  const {activityId} = props;
  const [dateStr, setDateStr] = useOptionalStringQueryString('date');
  const date = useMemo(() => (dateStr === undefined ? undefined : parseDate(dateStr)), [dateStr]);

  //
  // Data fetching

  const [data, setData] = useState<ApiDef<typeof HoobiizApi>['/admin/activity-stocks']['res']>();

  const refreshData = useCallback(async () => {
    if (date === undefined) {
      return;
    }
    setData(undefined);
    const endDate = new Date(date);
    endDate.setDate(date.getDate() + 1);
    endDate.setMilliseconds(-1);
    const freshData = await apiCall(HoobiizApi, '/admin/activity-stocks', {
      activityId,
      startTs: date.getTime(),
      endTs: endDate.getTime(),
    });
    setData(freshData);
  }, [activityId, date]);

  //
  // Management of the current date and data refresh

  const handlePreviousClick = useCallback(() => {
    if (date === undefined) {
      return;
    }
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 1);
    setDateStr(formatDate(prevDate));
  }, [date, setDateStr]);

  const handleNextClick = useCallback(() => {
    if (date === undefined) {
      return;
    }
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    setDateStr(formatDate(nextDate));
  }, [date, setDateStr]);

  useEffect(() => {
    setData(undefined);
    refreshData().catch(notifyError);
  }, [date, refreshData]);
  const handleRefreshClick = useCallback(async () => refreshData(), [refreshData]);

  //
  // Data processing

  const stockGroups = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const stocksByTickets = groupBy(data.stocks, s => s.stock.ticketInfo.map(t => t.id).join(':'));
    const stocksByTicketsByPeriod = [...stocksByTickets.values()].map(stocks =>
      groupBy(stocks, s => {
        const {startTs, endTs} = s.stock.reservation.period;
        return [startTs, endTs].join('-');
      })
    );
    return stocksByTicketsByPeriod.map(stocksByPeriod =>
      [...stocksByPeriod.entries()].sort((e1, e2) => e1[0].localeCompare(e2[0])).map(e => e[1])
    );
  }, [data]);

  //
  // PDF simulation

  const handlePdfClick = useCallback(
    (evt: MouseEvent) => {
      const stockGroupIndex1 = evt.currentTarget.getAttribute('data-stock-group-index1');
      const stockGroupIndex2 = evt.currentTarget.getAttribute('data-stock-group-index2');
      // eslint-disable-next-line no-null/no-null
      if (stockGroupIndex1 === null || stockGroupIndex2 === null) {
        return;
      }

      const stockGroups1 = stockGroups?.[parseFloat(stockGroupIndex1)];
      if (!stockGroups1) {
        return;
      }
      const stocks = stockGroups1[parseFloat(stockGroupIndex2)];
      if (!stocks) {
        return;
      }
      const nextStocks = stockGroups1.slice(parseFloat(stockGroupIndex2) + 1);
      showRawModal({
        mode: 'fade-center',
        children: (
          <HoobiizAdminStockCalendarPdfModal
            activityId={activityId}
            stocks={stocks}
            nextStocks={nextStocks}
          />
        ),
      });
    },
    [activityId, stockGroups]
  );

  //
  // Stock adjustement

  const [adjustStockId, setAdjustStockId] = useState<HoobiizStockId>();
  const [adjustStockNewQuantity, setAdjustStockNewQuantity] = useState(0);

  const handleAdjustStockClick = useCallback((evt: MouseEvent) => {
    const stockId = evt.currentTarget.getAttribute('data-stock-id');
    const stockTotalSumStr = evt.currentTarget.getAttribute('data-stock-quantity');
    // eslint-disable-next-line no-null/no-null
    if (stockId === null || stockTotalSumStr === null) {
      return;
    }
    setAdjustStockId(stockId as HoobiizStockId);
    setAdjustStockNewQuantity(parseFloat(stockTotalSumStr));
  }, []);

  const handleAdjustStockCloseClick = useCallback(() => {
    setAdjustStockId(undefined);
    setAdjustStockNewQuantity(0);
  }, []);

  const handleAdjustStockSubmit = useCallback(
    async (evt: MouseEvent) => {
      const stockIdStr = evt.currentTarget.getAttribute('data-stock-id');
      const deltaStr = evt.currentTarget.getAttribute('data-stock-delta');
      // eslint-disable-next-line no-null/no-null
      if (stockIdStr === null || deltaStr === null) {
        return;
      }
      const stockId = stockIdStr as HoobiizStockId;
      const delta = parseFloat(deltaStr);
      await apiCall(HoobiizApi, '/admin/adjust-stock', {stockId, delta});
      await refreshData();
      setAdjustStockId(undefined);
      setAdjustStockNewQuantity(0);
    },
    [refreshData]
  );

  //
  // Header UI

  const header = (
    <Header>
      <ButtonAsLink onClick={handlePreviousClick}>
        <SvgIcon name="ArrowRight" color={Colors.Gold} rotate={180} />
      </ButtonAsLink>
      <Title>
        {date
          ?.toLocaleDateString('fr', {
            weekday: 'long',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
          .toUpperCase()}
      </Title>
      <ButtonAsLink onClick={handleNextClick}>
        <SvgIcon name="ArrowRight" color={Colors.Gold} />
      </ButtonAsLink>
    </Header>
  );

  //
  // Initial loading state UI

  if (!stockGroups) {
    return (
      <Wrapper>
        {header}
        <Loading>Chargement...</Loading>
      </Wrapper>
    );
  }

  //
  // Full UI

  return (
    <Wrapper>
      {header}
      {stockGroups.map((stockGroup, i1) => {
        let boughtSum = 0;
        let quantitySum = 0;
        let remainingSum = 0;
        let reservedSum = 0;
        const tbody = (
          <tbody>
            {stockGroup.flatMap((stocks, i2) => {
              const {id, reservation, bought, quantity, remaining, reserved} = stocks[0].stock;
              boughtSum += bought;
              quantitySum += quantity;
              remainingSum += remaining;
              reservedSum += reserved;
              const {startTs, endTs} = reservation.period;
              const stockRow = (
                <Row key={id} $outOfStock={remaining <= 0} $even={i2 % 2 === 0} $hoverHighlight>
                  <Cell>{`${localTime(startTs)} à ${localTime(endTs)}`}</Cell>
                  <NumberCell>{quantity.toLocaleString()}</NumberCell>
                  <NumberCell>{`${
                    reserved > 0 ? `(+${reserved.toLocaleString()}) ` : ''
                  }${bought.toLocaleString()}`}</NumberCell>
                  <NumberCell>{remaining.toLocaleString()}</NumberCell>
                  <Cell $alignRight>
                    <ButtonAsLink
                      data-stock-group-index1={i1}
                      data-stock-group-index2={i2}
                      onClick={handlePdfClick}
                    >
                      Simuler PDF
                    </ButtonAsLink>
                  </Cell>
                  <Cell $alignRight>
                    {adjustStockId === id ? (
                      <ButtonAsLink onClick={handleAdjustStockCloseClick}>Fermer</ButtonAsLink>
                    ) : (
                      <ButtonAsLink
                        data-stock-id={id}
                        data-stock-quantity={quantity}
                        onClick={handleAdjustStockClick}
                      >
                        Ajuster stock
                      </ButtonAsLink>
                    )}
                  </Cell>
                </Row>
              );
              let stockAdjustRow = EmptyFragment;
              if (id === adjustStockId) {
                const delta = adjustStockNewQuantity - quantity;
                stockAdjustRow = (
                  <Row key={`${id}-adjust`} $even={false}>
                    <td colSpan={6}>
                      <AdjustStockFormWrapper>
                        <AdjustStockFormTitle>{`Stock du créneau de ${localTime(
                          startTs
                        )} à ${localTime(endTs)}`}</AdjustStockFormTitle>
                        <AdjustStockForm>
                          <AdjustStockFormSide>
                            <AdjustStockFormSideTitle>Stocks actuels</AdjustStockFormSideTitle>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total : </td>
                                  <td>{quantity.toLocaleString()}</td>
                                </tr>
                                <tr>
                                  <td>Acheté : </td>
                                  <td>{bought.toLocaleString()}</td>
                                </tr>
                                <tr>
                                  <td>En cours d'achat : </td>
                                  <td>{reserved.toLocaleString()}</td>
                                </tr>
                                <tr>
                                  <td>Disponible : </td>
                                  <td>{remaining.toLocaleString()}</td>
                                </tr>
                              </tbody>
                            </table>
                          </AdjustStockFormSide>
                          <AdjustStockFormCenter>
                            <SvgIcon name="ArrowRight" color="#ccc" size={24} rotate={180} />
                            <AdjustStockFormCenterForm>
                              <AdjustStockFormInput<number>
                                value={adjustStockNewQuantity}
                                syncState={setAdjustStockNewQuantity}
                                fromString={asNumberOrThrow}
                                label="Nouveau total"
                                overrides={{
                                  ...adminInputTheme,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  height: 34,
                                }}
                                width={123}
                              />
                              <Spacing height={8} />
                              <Button
                                data-stock-id={id}
                                data-stock-delta={delta}
                                onClickAsync={handleAdjustStockSubmit}
                                disabled={delta === 0}
                                overrides={{
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                }}
                              >
                                Mettre à jour
                              </Button>
                              <Spacing height={8} />
                              <AdjustStockFormDelta $hidden={delta === 0}>{`${
                                delta < 0 ? '' : '+'
                              } ${delta.toLocaleString()}`}</AdjustStockFormDelta>
                            </AdjustStockFormCenterForm>
                            <SvgIcon name="ArrowRight" color="#ccc" size={24} />
                          </AdjustStockFormCenter>
                          <AdjustStockFormSide>
                            <AdjustStockFormSideTitle>
                              Stocks après ajustement
                            </AdjustStockFormSideTitle>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total : </td>
                                  <td>{adjustStockNewQuantity.toLocaleString()}</td>
                                </tr>
                                <tr>
                                  <td>Acheté : </td>
                                  <td>{bought.toLocaleString()}</td>
                                </tr>
                                <tr>
                                  <td>En cours d'achat : </td>
                                  <td>{reserved.toLocaleString()}</td>
                                </tr>
                                <tr>
                                  <td>Disponible : </td>
                                  <td>{(delta + remaining).toLocaleString()}</td>
                                </tr>
                              </tbody>
                            </table>
                          </AdjustStockFormSide>
                        </AdjustStockForm>
                      </AdjustStockFormWrapper>
                    </td>
                  </Row>
                );
              }
              return [stockRow, stockAdjustRow];
            })}
          </tbody>
        );

        const ticketInfo = stockGroup[0]?.[0].stock.ticketInfo ?? [];

        return (
          <StockSection key={ticketInfo.map(t => t.id).join(':')}>
            <SectionTitle>
              Stock de la journée pour les tickets :
              <SectionTitleList>
                {ticketInfo.map(ticket => (
                  <li key={ticket.id}>{ticket.label}</li>
                ))}
              </SectionTitleList>
            </SectionTitle>
            <Table>
              <thead>
                <tr>
                  <HeaderCell>Créneau</HeaderCell>
                  <HeaderNumberCell>Total</HeaderNumberCell>
                  <HeaderNumberCell>Acheté</HeaderNumberCell>
                  <HeaderNumberCell>Restant</HeaderNumberCell>
                  <HeaderNumberCell>PDF</HeaderNumberCell>
                  <HeaderNumberCell>Ajustement</HeaderNumberCell>
                </tr>
              </thead>
              {tbody}
              <tfoot>
                <tr>
                  <FooterCell>Journée</FooterCell>
                  <FooterNumberCell>{quantitySum.toLocaleString()}</FooterNumberCell>
                  <FooterNumberCell>{`${
                    reservedSum > 0 ? `(+${reservedSum.toLocaleString()}) ` : ''
                  }${boughtSum.toLocaleString()}`}</FooterNumberCell>
                  <FooterNumberCell>{remainingSum.toLocaleString()}</FooterNumberCell>
                  <FooterCell></FooterCell>
                  <FooterCell></FooterCell>
                </tr>
              </tfoot>
            </Table>
          </StockSection>
        );
      })}

      <Form>
        <Button onClickAsync={handleRefreshClick}>Actualiser</Button>
      </Form>
    </Wrapper>
  );
};

HoobiizAdminStockCalendarModal.displayName = 'HoobiizAdminStockCalendarModal';

const Wrapper = styled.div`
  padding: 32px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  min-width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${Colors.Gold};
  text-align: center;
`;

const StockSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const SectionTitle = styled.div`
  line-height: 150%;
`;
const SectionTitleList = styled.ul`
  margin-left: 16px;
`;

const Table = styled.table`
  width: 100%;
  border: solid 2px ${Colors.Gold};
  border-top: none;
`;
const Row = styled.tr<{$outOfStock?: boolean; $even: boolean; $hoverHighlight?: boolean}>`
  background-color: ${p => (p.$outOfStock ? '#f6dede' : p.$even ? '#00000011' : 'transparent')};
  &:hover {
    ${p =>
      p.$hoverHighlight ? `background-color: ${p.$outOfStock ? '#e9bebe' : '#0000001b'}` : false}
  }
`;
const HeaderCell = styled.th`
  padding: 4px 12px;
  background: ${Colors.Gold};
  color: #ffffff;
  vertical-align: middle;
`;
const HeaderNumberCell = styled(HeaderCell)`
  text-align: right;
`;
const Cell = styled.td<{$alignRight?: boolean}>`
  padding: 4px 12px;
  white-space: nowrap;
  vertical-align: middle;
  ${p => p.$alignRight && 'text-align: right;'}
`;
const NumberCell = styled(Cell)`
  text-align: right;
`;
const FooterCell = styled(Cell)`
  border-top: solid 2px ${Colors.Gold};
`;
const FooterNumberCell = styled(FooterCell)`
  text-align: right;
`;

const AdjustStockFormWrapper = styled.div`
  width: 610px;
  border: solid 2px ${Colors.Gold};
  border-left: none;
  border-right: none;
  padding: 16px;
  & table {
    border: solid 2px ${Colors.Gold};
  }
  & tr:nth-child(odd) {
    background-color: #00000011;
  }
  & td {
    padding: 4px 8px;
    white-space: nowrap;
    &:nth-of-type(2) {
      padding-left: 16px;
      text-align: right;
    }
  }
`;

const AdjustStockFormDelta = styled.div<{$hidden?: boolean}>`
  visibility: ${p => (p.$hidden ? 'hidden' : 'visible')};
`;

const AdjustStockFormTitle = styled.div`
  color: #716f6f;
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 16px;
  text-align: center;
`;

const AdjustStockForm = styled.div`
  display: flex;
  gap: 8px;
  & > :nth-child(1),
  & > :nth-child(3) {
    width: 0;
    flex-grow: 1;
  }
  & > :nth-child(2) {
    flex-shrink: 0;
  }
`;

const AdjustStockFormCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AdjustStockFormSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const AdjustStockFormSideTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #6d6b6b;
  text-align: center;
`;

const AdjustStockFormCenterForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & label {
    padding-left: 0;
  }
`;

const AdjustStockFormInput = styled(Input)`
  text-align: center;
`;

const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #aaa;
  padding: 32px;
`;
