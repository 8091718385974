import {FC, useEffect, useState} from 'react';

import {HoobiizVendorContact} from '@shared/dynamo_model';

import {Input} from '@shared-frontend/components/core/input_v2';

import {FormBlock, FormFlex} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

interface VendorContactFormProps {
  initialData: HoobiizVendorContact;
  onChange: (data: HoobiizVendorContact) => void;
}

export const VendorContactForm: FC<VendorContactFormProps> = props => {
  const {initialData, onChange} = props;

  const [firstName, setFirstName] = useState(initialData.firstName);
  const [lastName, setLastName] = useState(initialData.lastName);
  const [email, setEmail] = useState(initialData.email);
  const [phone, setPhone] = useState(initialData.phone);

  useEffect(() => {
    onChange({firstName, lastName, email, phone});
  }, [firstName, lastName, email, phone, onChange]);

  return (
    <FormFlex>
      <FormBlock>
        <Input
          width="100%"
          value={firstName}
          syncState={setFirstName}
          placeholder={initialData.firstName}
          label="NOM"
          overrides={adminInputTheme}
        />
      </FormBlock>
      <FormBlock>
        <Input
          width="100%"
          value={lastName}
          syncState={setLastName}
          placeholder={initialData.lastName}
          label="PRÉNOM"
          overrides={adminInputTheme}
        />
      </FormBlock>
      <FormBlock>
        <Input
          width="100%"
          value={email}
          syncState={setEmail}
          placeholder={initialData.email}
          label="EMAIL"
          overrides={adminInputTheme}
        />
      </FormBlock>
      <FormBlock>
        <Input
          width="100%"
          value={phone}
          syncState={setPhone}
          placeholder={initialData.phone}
          label="TÉLEPHONE"
          overrides={adminInputTheme}
        />
      </FormBlock>
    </FormFlex>
  );
};
VendorContactForm.displayName = 'VendorContactForm';
