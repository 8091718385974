import {FC, useCallback} from 'react';
import {useNavigate} from 'react-router';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat1Item} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {showSuccess} from '@shared-frontend/components/core/notifications';

import {Category1Form} from '@src/components/admin/category/category1_form';
import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';

export const Category1CreatePage: FC = () => {
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (cat1Data: Omit<HoobiizData<HoobiizCat1Item>, 'order'>) => {
      const {cat1} = await apiCall(HoobiizApi, '/admin/create-cat1', cat1Data);
      showSuccess(`Catégorie 1 "${cat1.name}" créé`);
      navigate('/admin/cat1');
    },
    [navigate]
  );

  return (
    <PageWrapper>
      <FormWrapper>
        <FormTitle>Ajouter une nouvelle catégorie 1</FormTitle>
        <Category1Form autoFocus submitButtonText="Créer" onSubmit={handleSubmit} />
      </FormWrapper>
    </PageWrapper>
  );
};
Category1CreatePage.displayName = 'Category1CreatePage';
