import {COMMIT_HASH, env} from '@shared/env_constants';
import {asMap, asString, errorAsString} from '@shared/lib/type_utils';
import {ServiceNodeId} from '@shared/model/devops';
import {Log, LogLevel} from '@shared/model/log';

import {showAlert} from '@shared-frontend/components/core/notifications';

function getStacktrace(err?: unknown): string {
  // eslint-disable-next-line unicorn/error-message
  return asString(asMap(err, {}).stack) ?? new Error().stack ?? '';
}

const UNKNOWN_SERVICE = 'unknown-frontend';

let allErrors = [] as Log[];

export function getFrontendErrors(): Log[] {
  return allErrors;
}

let errorListeners = [] as (() => void)[];
export function addFrontendErrorsListener(listener: () => void): () => void {
  errorListeners.push(listener);
  return () => {
    errorListeners = errorListeners.filter(l => l !== listener);
  };
}

export function clearErrors(): void {
  allErrors = [];
}

interface NotifyErrorOptions {
  extra?: unknown;
  message?: string;
  duration?: number;
  silent?: boolean;
}

export function notifyError(err: unknown, options: NotifyErrorOptions = {}): void {
  const {extra, message, duration, silent} = options;
  const alertMessage = message ?? errorAsString(err);
  if (!silent) {
    showAlert(alertMessage, {duration});
  }

  const errorJson: Log = {
    level: LogLevel.Error,
    time: [Math.floor(Date.now() / 1000), 0],
    service: UNKNOWN_SERVICE,
    env: env.type,
    commitHash: COMMIT_HASH,
    serviceNodeId: 'unknown' as ServiceNodeId,
    mem: 0,
    cpu: 0,
    disk: 0,
    msg: alertMessage,
    stack: getStacktrace(err),
    errMsg: errorAsString(err),
    extra,
    timers: 0,
  };
  allErrors = [...allErrors, errorJson];
  for (const listener of errorListeners) {
    listener();
  }

  // // TODO: Send to the sourcemap server to convert the error stack and log to s3
  // // eslint-disable-next-line no-console
  // console.error('Should send error log to backend', errorJson);

  console.error(err); // eslint-disable-line no-console
  if (extra !== undefined) {
    console.log(extra); // eslint-disable-line no-console
  }
}
