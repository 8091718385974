import {HoobiizTimePeriod} from '@shared/dynamo_model';
import {startOfLocalDay} from '@shared/lib/date_utils';

export function initialPeriod(): HoobiizTimePeriod {
  const startTs = startOfLocalDay().getTime();
  const startNextYear = new Date(startTs);
  startNextYear.setFullYear(startNextYear.getFullYear() + 1);
  const endTs = startNextYear.getTime();
  return {startTs, endTs};
}
