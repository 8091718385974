import {useRef} from 'react';

import {deepEqual} from '@shared/lib/object_utils';

export function useSmartMemo<T>(val: T): T {
  const ref = useRef(val);
  if (!deepEqual(ref.current, val)) {
    ref.current = val;
  }
  return ref.current;
}
