import {FC, useMemo} from 'react';
import styled from 'styled-components';

import {FrontendUserId} from '@shared/dynamo_model';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';

import {AdminUserAndGroupChainNodeUser} from '@src/components/admin/user_and_group/admin_user_and_group_chain_node_user';
import {useHoobiizUserGroupUserModalPath} from '@src/components/admin/user_and_group/admin_user_and_group_paths';
import {useHoobiizUserGroupUser} from '@src/components/admin/user_and_group/admin_user_and_group_store';

interface AdminUserAndGroupUserLineProps {
  userId: FrontendUserId;
  isUserHighlighted?: (id: FrontendUserId) => boolean;
}

export const AdminUserAndGroupUserLine: FC<AdminUserAndGroupUserLineProps> = props => {
  const {userId, isUserHighlighted} = props;
  const userAndGroup = useHoobiizUserGroupUser({userId});
  const modalPath = useHoobiizUserGroupUserModalPath({userId});

  const isHighlighted = useMemo(() => {
    return isUserHighlighted?.(userId) ?? false;
  }, [isUserHighlighted, userId]);

  if (!userAndGroup) {
    return <FailureMesage>Utilisateur non disponible</FailureMesage>;
  }

  return (
    <UnthemedNavLink to={modalPath}>
      <AdminUserAndGroupChainNodeUser userId={userId} isHighlighted={isHighlighted} />
    </UnthemedNavLink>
  );
};

AdminUserAndGroupUserLine.displayName = 'AdminUserAndGroupUserLine';

const FailureMesage = styled.div`
  font-style: italic;
  color: #888;
`;
