import {FC, MouseEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType, SearchApiQueryType} from '@shared/api/definitions/search_api';
import {HoobiizCat2Id, HoobiizCat2Item} from '@shared/dynamo_model';
import {isNull} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {Category2Form} from '@src/components/admin/category/category2_form';
import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {AdminNavLink} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
import {HoobiizCat3View} from '@src/components/ui/hoobiiz_cat3_view';

export const Category2UpdatePage: FC = () => {
  const navigate = useNavigate();
  const {cat2Id: cat2IdStr = ''} = useParams();
  const cat2Id = cat2IdStr as HoobiizCat2Id;

  const [cat2, setCat2] = useState<FullItem<'HoobiizCat2'> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/admin/search/get', {
      table: 'HoobiizCat2',
      id: cat2Id,
      mode: 'full',
    })
      .then(res => {
        const {item} = res as SearchApiGetType<'HoobiizCat2', 'full'>['res'];
        setCat2(item);
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            'Échec du chargement de la catégorie 2. Vous pouvez rafraichir la page pour réessayer',
        });
        setIsLoading(false);
      });
  }, [cat2Id]);

  const handleSubmit = useCallback(
    async (cat2Data: Omit<HoobiizData<HoobiizCat2Item>, 'order'>) => {
      const {cat2} = await apiCall(HoobiizApi, '/admin/update-cat2', {
        id: cat2Id,
        updates: cat2Data,
      });
      showSuccess(`Catégorie 2 "${cat2.name}" mise à jour`);
      navigate(`/admin/cat1/${cat2Data.cat1Id}`);
    },
    [navigate, cat2Id]
  );

  const handleDelete = useCallback(async () => {
    await apiCall(HoobiizApi, '/admin/delete-cat2', {id: cat2Id});
    showSuccess(`Catégorie 2 "${cat2?.name}" supprimé`);
    navigate(`/admin/cat1/${cat2?.cat1Id}`);
  }, [cat2Id, cat2?.name, cat2?.cat1Id, navigate]);

  const {data, refreshData} = useApiCall(HoobiizApi, '/admin/search/query', {
    table: 'HoobiizCat3',
    mode: 'full',
    gsi: {name: 'cat2', where: {type: 'equal', value: cat2Id}},
    sort: {name: 'order', params: undefined},
  });
  const res = data as SearchApiQueryType<'HoobiizCat3', 'full'>['res'] | undefined;
  const cat3 = res?.items.map(i => i.item);

  const handleSwapClick = useCallback(
    async (evt: MouseEvent<HTMLElement>) => {
      const iStr = evt.currentTarget.getAttribute('data-index');
      if (isNull(iStr)) {
        return;
      }
      const i = parseFloat(iStr);
      const firstCat = cat3?.[i];
      const secondCat = cat3?.[i + 1];
      if (!firstCat || !secondCat) {
        return;
      }
      await Promise.all([
        apiCall(HoobiizApi, '/admin/update-cat3', {
          id: firstCat.id,
          updates: {order: secondCat.order},
        }),
        apiCall(HoobiizApi, '/admin/update-cat3', {
          id: secondCat.id,
          updates: {order: firstCat.order},
        }),
      ]);
      await refreshData();
    },
    [cat3, refreshData]
  );

  if (!cat2 || isLoading) {
    return (
      <LoadingPage>
        Chargement du partenaire
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }
  return (
    <PageWrapper>
      <Title>
        Sous catégories
        <AdminNavLink to={`/admin/cat1/${cat2.cat1Id}/cat2/${cat2.id}/cat3/create`}>
          Ajouter
        </AdminNavLink>
      </Title>
      <Carousel>
        {cat3?.map((cat, i, arr) => (
          <CatWrapper key={cat.id}>
            <HoobiizCat3View
              to={`/admin/cat1/${cat2.cat1Id}/cat2/${cat2.id}/cat3/${cat.id}`}
              name={cat.name}
              media={{id: cat.mediaId, media: cat.media}}
            />
            {i < arr.length - 1 ? (
              <SwapButtonWrapper>
                <SwapButton
                  data-index={i}
                  onClickAsync={handleSwapClick}
                  overrides={{backgroundActive: '#00000000', backgroundHover: '#00000011'}}
                >
                  <SvgIcon name="Swap" color="#000000" size={20} />
                </SwapButton>
              </SwapButtonWrapper>
            ) : (
              EmptyFragment
            )}
          </CatWrapper>
        ))}
      </Carousel>
      <FormWrapper>
        <FormTitle>{`Modifier la catégorie 2 ${cat2.name}`}</FormTitle>
        <Category2Form
          initialData={cat2}
          submitButtonText="Modifier"
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </FormWrapper>
    </PageWrapper>
  );
};
Category2UpdatePage.displayName = 'Category2UpdatePage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 200%;
  height: 200%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-size: 24px;
`;

const Carousel = styled.div`
  display: flex;
  height: 100px;
  overflow-x: auto;
  gap: 32px;
  margin-bottom: 32px;
`;

const CatWrapper = styled.div`
  position: relative;
`;

const SwapButtonWrapper = styled.div`
  position: absolute;
  right: -32px;
  top: calc(50% - 16px);
  transform: rotate(90deg);
  z-index: 1;
`;

const SwapButton = styled(Button)`
  padding: 6px;
`;
