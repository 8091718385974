import {useMemo, useRef} from 'react';

interface ReadOnlyRefObject<T> {
  readonly current: T;
}

export function useMemoRef<T>(fn: () => T, deps: unknown[]): [T, ReadOnlyRefObject<T>] {
  const ref = useRef<T | undefined>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const val = useMemo<T>(fn, deps);
  ref.current = val;

  return [
    val,
    // `ref` value is guaranteed to be defined since we always update it
    // the line before.
    ref as ReadOnlyRefObject<T>,
  ];
}
