import {HoobiizStockReservationType} from '@shared/dynamo_model';

export const HoobiizStockReservationTypeLabels: Record<
  HoobiizStockReservationType,
  {label: string; subLabel?: string}
> = {
  [HoobiizStockReservationType.Flexible]: {
    label: 'Flexible',
    subLabel: `tickets valables n'importe quand sur une période fixée`,
  },
  [HoobiizStockReservationType.Fixed]: {
    label: 'Créneaux',
    subLabel: `tickets associé à un jour et une heure précise`,
  },
};
