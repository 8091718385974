import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {SanitizedTicketInfoOption} from '@shared/model/search_tables';

import {HoobiizTicketLabel} from '@src/components/ui/hoobiiz_ticket_label';
import {HoobiizTicketPrices} from '@src/components/ui/hoobiiz_ticket_prices';
import {NumberPicker} from '@src/components/ui/number_picker';

export interface TicketOptionQuantityData {
  ticketInfoOption: SanitizedTicketInfoOption;
  quantity: number;
  order: number;
}

interface HoobiizTicketOptionPickerProps {
  data: TicketOptionQuantityData;
  onChange: (data: TicketOptionQuantityData) => void;
}

export const HoobiizTicketOptionPicker: FC<HoobiizTicketOptionPickerProps> = props => {
  const {
    data: {quantity, ticketInfoOption, order},
    onChange,
  } = props;
  const {label, description, minQuantity, maxQuantity} = ticketInfoOption;

  const handleQuantityChange = useCallback(
    (newQuantity: number) => {
      onChange({ticketInfoOption, quantity: newQuantity, order});
    },
    [onChange, order, ticketInfoOption]
  );

  return (
    <Wrapper>
      <TicketInfoTitle>
        <HoobiizTicketLabel label={label} description={description} />
        <StyledHoobiizPrices ticketInfo={ticketInfoOption} justify="flex-start" />
      </TicketInfoTitle>
      <StyledNumberPicker
        value={quantity}
        min={minQuantity}
        max={maxQuantity}
        onChange={handleQuantityChange}
      />
    </Wrapper>
  );
};

HoobiizTicketOptionPicker.displayName = 'HoobiizTicketOptionsPicker';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TicketInfoTitle = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const StyledHoobiizPrices = styled(HoobiizTicketPrices)``;

const StyledNumberPicker = styled(NumberPicker)`
  flex-shrink: 0;
`;
