import {FC, useEffect, useState} from 'react';

import {HoobiizStockModeAutomatic, HoobiizStockModeType} from '@shared/dynamo_model';

import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {Input} from '@shared-frontend/components/core/input_v2';

import {FormBlockAuto, FormFlex, FormLabel} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

interface HoobiizStockModeAutomaticFormProps {
  initialMode: HoobiizStockModeAutomatic;
  initialQuantity: number;
  onChange?: (mode: HoobiizStockModeAutomatic, quantity: number) => void;
}

const fromString = (val: string): number => {
  const res = Math.max(0, Math.round(parseFloat(val)));
  if (isNaN(res)) {
    throw new Error('Invalid value');
  }
  return res;
};

export const HoobiizStockModeAutomaticForm: FC<HoobiizStockModeAutomaticFormProps> = props => {
  const {initialMode, initialQuantity, onChange} = props;

  const [sendEmail, setSendEmail] = useState(initialMode.sendEmail);
  const [quantity, setQuantity] = useState(initialQuantity);

  useEffect(() => {
    onChange?.({type: HoobiizStockModeType.Automatic, sendEmail}, quantity);
  }, [onChange, quantity, sendEmail]);

  return (
    <div>
      <FormLabel $noMargin>TICKETS</FormLabel>
      <FormFlex>
        <FormBlockAuto>
          <Input
            value={quantity}
            syncState={setQuantity}
            fromString={fromString}
            label={'QUANTITÉ'}
            overrides={adminInputTheme}
          />
        </FormBlockAuto>
        <FormBlockAuto>
          <FormLabel $noMargin>EMAIL</FormLabel>
          <Checkbox checked={sendEmail} syncState={setSendEmail}>
            Envoyer un email au partenaire après chaque achat.
          </Checkbox>
        </FormBlockAuto>
      </FormFlex>
    </div>
  );
};
HoobiizStockModeAutomaticForm.displayName = 'HoobiizStockModeAutomaticForm';
