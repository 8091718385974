import {FC, useCallback, useEffect, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockId,
  HoobiizStockModeAutomatic,
  HoobiizStockModeType,
  HoobiizStockReservationFlexible,
} from '@shared/dynamo_model';
import {hoobiizTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {removeUndefined} from '@shared/lib/type_utils';

import {HoobiizStockModeAutomaticForm} from '@src/components/admin/activity_stock/hoobiiz_stock_mode_automatic_form';
import {HoobiizStockReservationFlexibleForm} from '@src/components/admin/activity_stock/hoobiiz_stock_reservation_flexible_form';
import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {MaybeTicket} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {HoobiizTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_multi_form';
import {FormBlockFull, FormWrapper} from '@src/components/admin/form/form_fragments';

export interface FlexibleAndAutomaticTicket {
  id?: HoobiizStockId;
  ticket: {
    reservation: HoobiizStockReservationFlexible;
    mode: HoobiizStockModeAutomatic;
    quantity: number;
    ticketInfo: MaybeTicket[];
    terms: string;
  };
}

interface ActivityStockFlexibleAndAutomaticFormProps {
  vendorHours: HoobiizOpeningHours;
  initial?: FlexibleAndAutomaticTicket;
  onChange?: (ticket: FlexibleAndAutomaticTicket) => void;
}

export const ActivityStockFlexibleAndAutomaticForm: FC<
  ActivityStockFlexibleAndAutomaticFormProps
> = props => {
  const {vendorHours, initial, onChange} = props;

  const [reservation, setReservation] = useState(initial?.ticket.reservation);
  const [mode, setMode] = useState<HoobiizStockModeAutomatic>(
    initial?.ticket.mode ?? {type: HoobiizStockModeType.Automatic, sendEmail: false}
  );
  const [quantity, setQuantity] = useState(initial?.ticket.quantity ?? 1);

  const [ticketInfo, setTicketInfo] = useState<MaybeTicket[]>(
    initial?.ticket.ticketInfo ?? [{id: hoobiizTicketInfoId()}]
  );
  const [terms, setTerms] = useState<string>(initial?.ticket.terms ?? '');

  useEffect(() => {
    if (!reservation) {
      return;
    }
    onChange?.({
      id: initial?.id,
      ticket: {reservation, mode, quantity, ticketInfo: removeUndefined(ticketInfo), terms},
    });
  }, [onChange, reservation, mode, quantity, initial?.id, ticketInfo, terms]);

  const handleModeChange = useCallback((mode: HoobiizStockModeAutomatic, quantity: number) => {
    setMode(mode);
    setQuantity(quantity);
  }, []);

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <HoobiizTicketInfoMultiForm
        initialData={ticketInfo}
        reservationType={reservation?.type}
        modeType={mode.type}
        onChange={setTicketInfo}
      />
      <FormBlockFull>
        <HoobiizStockReservationFlexibleForm
          vendorHours={vendorHours}
          initialData={reservation}
          onChange={setReservation}
        />
      </FormBlockFull>
      <FormBlockFull>
        <HoobiizStockModeAutomaticForm
          initialMode={mode}
          initialQuantity={quantity}
          onChange={handleModeChange}
        />
      </FormBlockFull>
    </FormWrapper>
  );
};
ActivityStockFlexibleAndAutomaticForm.displayName = 'ActivityStockFlexibleAndAutomaticForm';
