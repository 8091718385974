import {FC} from 'react';
import {styled} from 'styled-components';

import {Link} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {Colors} from '@src/components/core/theme_base';

interface ExternalUrlLinkProps {
  url: string;
}

export const ExternalUrlLink: FC<ExternalUrlLinkProps> = ({url}) => {
  return (
    <StyledLink href={url} target="_blank">
      {url}
      <SvgIcon name="ExternalLink" color={Colors.DarkGold} size={13} />
    </StyledLink>
  );
};

ExternalUrlLink.displayName = 'ExternalUrlLink';

const StyledLink = styled(Link)`
  gap: 8px;
`;
