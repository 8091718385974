import {FC, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  addCurrencyAmount,
  cents,
  currencyAmountToString,
  multiplyCurrencyAmount,
} from '@shared/lib/hoobiiz/currency_amount';
import {applyDiscount} from '@shared/lib/hoobiiz/offer';

import {apiCall} from '@shared-frontend/api';
import {Button, NavButton} from '@shared-frontend/components/core/button';
import {showRawModal} from '@shared-frontend/components/core/modal';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {useSession} from '@shared-frontend/lib/session_store';

import {HoobiizCartSidebar} from '@src/components/ui/hoobiiz_cart_sidebar';
import {
  ExpertTicketQuantityData,
  TicketQuantityData,
} from '@src/components/ui/hoobiiz_ticket_quantity_picker';
import {increaseCartCount} from '@src/lib/stores';

interface AddToCartButtonProps {
  ticketData: (TicketQuantityData | ExpertTicketQuantityData)[];
  onSubmit: () => void;
}

export const AddToCartButton: FC<AddToCartButtonProps> = props => {
  const {ticketData, onSubmit} = props;
  const session = useSession();
  const location = useLocation();

  const tickets = ticketData.filter(data => data.quantity > 0);

  let total = cents(0);
  for (const ticket of tickets) {
    if (ticket.type === 'stock') {
      const {bestOffer, ticketInfo, quantity, options} = ticket;
      const price = multiplyCurrencyAmount(
        quantity,
        bestOffer
          ? applyDiscount(bestOffer.discount, ticketInfo.youpiizPrice)
          : ticketInfo.youpiizPrice
      );
      total = addCurrencyAmount(total, price);
      for (const option of options) {
        const {ticketInfoOption, quantity} = option;
        const price = multiplyCurrencyAmount(quantity, ticketInfoOption.youpiizPrice);
        total = addCurrencyAmount(total, price);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    else if (ticket.type === 'expert-ticket') {
      total = addCurrencyAmount(
        total,
        multiplyCurrencyAmount(ticket.quantity, cents(ticket.price))
      );
    }
  }

  const handleAddToCartClick = useCallback(async () => {
    const res = await Promise.all(
      tickets.map(async ticket => {
        return ticket.type === 'stock'
          ? apiCall(HoobiizApi, '/add-ticket-to-cart', {
              stockIds: ticket.stocks.map(s => s.id),
              offerId: ticket.bestOffer?.id,
              ticketInfoId: ticket.ticketInfo.id,
              quantity: ticket.quantity,
              options: ticket.options
                .filter(o => o.quantity > 0)
                .map(o => ({
                  optionId: o.ticketInfoOption.id,
                  quantity: o.quantity,
                })),
            })
          : apiCall(HoobiizApi, '/add-expert-ticket-to-cart', {
              stockId: ticket.ticket.stockId,
              ticketInfoId: ticket.ticket.ticketInfoId,
              quantity: ticket.quantity,
              date: ticket.date,
            });
      })
    );
    increaseCartCount(res.length);
    showRawModal({
      children: <HoobiizCartSidebar cartItems={res.map(r => r.item)} />,
      mode: 'slide-right',
      noCross: true,
      backdropColor: '#000000aa',
    });
    onSubmit();
  }, [onSubmit, tickets]);

  return (
    <Wrapper>
      {session === undefined ? (
        <NavButton to="/login" state={{from: location.pathname}}>
          CONNECTEZ-VOUS
        </NavButton>
      ) : (
        <Button disabled={tickets.length === 0} onClickAsync={handleAddToCartClick}>
          <CartSvgIcon name="Cart" color="#ffffff" size={18} />
          {`Ajouter${total.cents > 0 ? ` (${currencyAmountToString(total)})` : ''}`}
        </Button>
      )}
    </Wrapper>
  );
};

AddToCartButton.displayName = 'AddToCartButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 26px 0 0 0 !important; // Because we have a strict rule from the parent
`;

const CartSvgIcon = styled(SvgIcon)`
  margin-right: 8px;
`;
