import {CSSProperties} from 'react';
import {styled} from 'styled-components';

import {HoobiizPrices} from '@shared/dynamo_model';
import {currencyAmountToString} from '@shared/lib/hoobiiz/currency_amount';
import {flattenHoobiizPrices} from '@shared/lib/hoobiiz/hoobiiz_prices';

import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {Colors, FontWeight} from '@src/components/core/theme_base';

interface HoobiizPricesProps {
  prices: HoobiizPrices;
  prefix?: string;
  justify?: CSSProperties['justifyContent'];
}

export const HoobiizPricesView: Custom<HoobiizPricesProps, 'div'> = props => {
  const {prices, prefix, justify, ...rest} = props;

  const [price1, price2, price3] = flattenHoobiizPrices(prices).map(p => currencyAmountToString(p));

  // Double discount
  if (price3 !== undefined) {
    return (
      <Wrapper $justify={justify} {...rest}>
        <PriceLabel key="price1" $fontSize={18} $color={Colors.Grey} $lineThrough>
          {price1}
        </PriceLabel>
        <PriceLabel key="price2" $fontSize={20} $color={Colors.Green} $lineThrough>
          {price2}
        </PriceLabel>
        <LastPrice>
          {prefix === undefined ? (
            EmptyFragment
          ) : (
            <PriceLabel key="prefix" $fontSize={16} $color={Colors.Red}>
              {prefix}
            </PriceLabel>
          )}
          <PriceLabel key="price3" $fontSize={20} $color={Colors.Red} $bold>
            {price3}
          </PriceLabel>
        </LastPrice>
      </Wrapper>
    );
  }

  // Single discount
  if (price2 !== undefined) {
    return (
      <Wrapper $justify={justify} {...rest}>
        <PriceLabel key="price1" $fontSize={18} $color={Colors.Grey} $lineThrough>
          {price1}
        </PriceLabel>
        <LastPrice>
          {prefix === undefined ? (
            EmptyFragment
          ) : (
            <PriceLabel key="prefix" $fontSize={16} $color={Colors.Green}>
              {prefix}
            </PriceLabel>
          )}
          <PriceLabel key="price2" $fontSize={20} $color={Colors.Green} $bold>
            {price2}
          </PriceLabel>
        </LastPrice>
      </Wrapper>
    );
  }

  // No discount
  return (
    <Wrapper $justify={justify} {...rest}>
      <LastPrice>
        {prefix === undefined ? (
          EmptyFragment
        ) : (
          <PriceLabel key="prefix" $fontSize={16} $color={Colors.Grey}>
            {prefix}
          </PriceLabel>
        )}
        <PriceLabel key="price1" $fontSize={20} $color={Colors.Grey} $bold>
          {price1}
        </PriceLabel>
      </LastPrice>
    </Wrapper>
  );
};

HoobiizPricesView.displayName = 'HoobiizPrices';

const Wrapper = styled.div<{$justify: CSSProperties['justifyContent']}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 7px;
  justify-content: ${p => p.$justify};
  align-items: baseline;
`;

const PriceLabel = styled.div<{
  $fontSize: number;
  $color: string;
  $lineThrough?: boolean;
  $bold?: boolean;
}>`
  font-size: ${p => p.$fontSize}px;
  color: ${p => p.$color};
  ${p => p.$lineThrough && 'text-decoration: line-through;'}
  ${p => p.$bold && `font-weight: ${FontWeight.Bold700};`}
  white-space: nowrap;
`;

const LastPrice = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;
