import {FC, useCallback, useEffect, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockId,
  HoobiizStockModeAdminConfirm,
  HoobiizStockModeType,
  HoobiizStockReservationFlexible,
} from '@shared/dynamo_model';
import {hoobiizTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {removeUndefined} from '@shared/lib/type_utils';

import {HoobiizStockModeAdminConfirmForm} from '@src/components/admin/activity_stock/hoobiiz_stock_mode_admin_confirm_form';
import {HoobiizStockReservationFlexibleForm} from '@src/components/admin/activity_stock/hoobiiz_stock_reservation_flexible_form';
import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {MaybeTicket} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {HoobiizTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_multi_form';
import {FormBlockFull, FormWrapper} from '@src/components/admin/form/form_fragments';

export interface FlexibleAndAdminConfirmTicket {
  id?: HoobiizStockId;
  ticket: {
    reservation: HoobiizStockReservationFlexible;
    mode: HoobiizStockModeAdminConfirm;
    quantity: number;
    ticketInfo: MaybeTicket[];
    terms: string;
  };
}

interface ActivityStockFlexibleAndAdminConfirmFormProps {
  vendorHours: HoobiizOpeningHours;
  initial?: FlexibleAndAdminConfirmTicket;
  onChange?: (ticket: FlexibleAndAdminConfirmTicket) => void;
}

export const ActivityStockFlexibleAndAdminConfirmForm: FC<
  ActivityStockFlexibleAndAdminConfirmFormProps
> = props => {
  const {vendorHours, initial, onChange} = props;

  const [reservation, setReservation] = useState(initial?.ticket.reservation);
  const [mode, setMode] = useState<HoobiizStockModeAdminConfirm>(
    initial?.ticket.mode ?? {type: HoobiizStockModeType.AdminConfirm}
  );
  const [quantity, setQuantity] = useState(initial?.ticket.quantity ?? 1);

  const [terms, setTerms] = useState<string>(initial?.ticket.terms ?? '');
  const [ticketInfo, setTicketInfo] = useState<MaybeTicket[]>(
    initial?.ticket.ticketInfo ?? [{id: hoobiizTicketInfoId()}]
  );

  useEffect(() => {
    if (!reservation) {
      return;
    }
    onChange?.({
      id: initial?.id,
      ticket: {reservation, mode, quantity, ticketInfo: removeUndefined(ticketInfo), terms},
    });
  }, [onChange, reservation, mode, quantity, initial?.id, ticketInfo, terms]);

  const handleModeChange = useCallback((mode: HoobiizStockModeAdminConfirm, quantity: number) => {
    setMode(mode);
    setQuantity(quantity);
  }, []);

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <HoobiizTicketInfoMultiForm initialData={ticketInfo} onChange={setTicketInfo} />
      <FormBlockFull>
        <HoobiizStockReservationFlexibleForm
          vendorHours={vendorHours}
          initialData={reservation}
          onChange={setReservation}
        />
      </FormBlockFull>
      <FormBlockFull>
        <HoobiizStockModeAdminConfirmForm
          initialMode={mode}
          initialQuantity={quantity}
          onChange={handleModeChange}
        />
      </FormBlockFull>
    </FormWrapper>
  );
};
ActivityStockFlexibleAndAdminConfirmForm.displayName = 'ActivityStockFlexibleAndAdminConfirmForm';
