/* eslint-disable @typescript-eslint/no-magic-numbers */
import {ContrastRatio, getGrayForContrastOnColor, hslToHex} from '@shared-frontend/colors';

interface ColorShade {
  Darker: string;
  Normal: string;
  Brighter: string;
}

interface ColorPalette {
  Back: ColorShade;
  BackText: ColorShade;
  Accent: ColorShade;
  AccentText: ColorShade;
  Success: ColorShade;
  SuccessText: ColorShade;
  Warning: ColorShade;
  WarningText: ColorShade;
  Alert: ColorShade;
  AlertText: ColorShade;
}

const MAX_HUE = 360;
const BACK_SATURATION = 1 / 3;
const ACCENT_SATURATION = 2 / 3;

// `hue` between 0 and 360
export function generatePalette(hue: number): ColorPalette {
  const primaryHue = hue % MAX_HUE;
  const accentHue = (hue + 180) % MAX_HUE;
  const alertHue = 0;
  const successHue = 120;
  const orangeHue = 40;

  const Back: ColorShade = {
    Darker: hslToHex(primaryHue, BACK_SATURATION, 0.05),
    Normal: hslToHex(primaryHue, BACK_SATURATION, 0.1),
    Brighter: hslToHex(primaryHue, BACK_SATURATION, 0.2),
  };
  const BackText: ColorShade = generateTextColor(Back, ContrastRatio.High);

  const Accent: ColorShade = {
    Darker: hslToHex(accentHue, ACCENT_SATURATION, 0.4),
    Normal: hslToHex(accentHue, ACCENT_SATURATION, 0.5),
    Brighter: hslToHex(accentHue, ACCENT_SATURATION, 0.6),
  };
  const AccentText: ColorShade = generateTextColor(Accent, ContrastRatio.Medium);

  const Success: ColorShade = {
    Darker: hslToHex(successHue, BACK_SATURATION, 0.4),
    Normal: hslToHex(successHue, BACK_SATURATION, 0.5),
    Brighter: hslToHex(successHue, BACK_SATURATION, 0.6),
  };
  const SuccessText: ColorShade = generateTextColor(Success, ContrastRatio.Medium);

  const Warning: ColorShade = {
    Darker: hslToHex(orangeHue, 0.5, 0.4),
    Normal: hslToHex(orangeHue, 0.5, 0.5),
    Brighter: hslToHex(orangeHue, 0.5, 0.6),
  };
  const WarningText: ColorShade = generateTextColor(Warning, ContrastRatio.Medium);

  const Alert: ColorShade = {
    Darker: hslToHex(alertHue, BACK_SATURATION, 0.4),
    Normal: hslToHex(alertHue, BACK_SATURATION, 0.5),
    Brighter: hslToHex(alertHue, BACK_SATURATION, 0.6),
  };
  const AlertText: ColorShade = generateTextColor(Alert, ContrastRatio.Medium);

  return {
    Back,
    BackText,
    Accent,
    AccentText,
    Success,
    SuccessText,
    Warning,
    WarningText,
    Alert,
    AlertText,
  };
}

export const Palette = generatePalette(210);

function generateTextColor(c: ColorShade, contrastRatio: ContrastRatio): ColorShade {
  return {
    Darker: getGrayForContrastOnColor(c.Darker, contrastRatio),
    Normal: getGrayForContrastOnColor(c.Normal, contrastRatio),
    Brighter: getGrayForContrastOnColor(c.Brighter, contrastRatio),
  };
}
