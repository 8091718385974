import {
  ComponentPropsWithoutRef,
  Dispatch,
  JSX,
  SetStateAction,
  useCallback,
  useId,
  useMemo,
} from 'react';
import styled from 'styled-components';

import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {useTheme} from '@shared/frontends/theme_context';
import {addPrefix} from '@shared/lib/type_utils';

import {Label} from '@shared-frontend/components/core/label';
import {Radio} from '@shared-frontend/components/core/radio';
import {InputHandler} from '@shared-frontend/lib/react';
import {cssPx, optionalPx, optionalRaw} from '@shared-frontend/lib/styled_utils';

export interface RadiosProps<T> {
  value: T | undefined;
  values: {value: T; label: string | JSX.Element; subLabel?: string}[];
  label?: string | JSX.Element;
  syncState?:
    | Dispatch<SetStateAction<T | undefined>>
    | Dispatch<SetStateAction<T>>
    | ((val: T | undefined, el: HTMLInputElement) => void);
  asString?: (value: T) => string;
  fromString?: (value: string) => T;
  overrides?: Partial<FrontendTheme['radio']>;
  column?: boolean;
  disabled?: boolean;
}
export function Radios<T>(
  props: RadiosProps<T> & Omit<ComponentPropsWithoutRef<'div'>, 'style' | 'children'>
): JSX.Element {
  const {
    value,
    values,
    label,
    syncState,
    asString,
    fromString,
    overrides,
    column,
    disabled,
    ...rest
  } = props;
  const name = useId();
  const {radio} = useTheme();
  const radioTheme = addPrefix({...radio, ...overrides}, '$');

  const handleChange = useCallback<InputHandler>(
    evt => {
      const val =
        fromString === undefined
          ? (evt.currentTarget.value as unknown as T)
          : fromString(evt.currentTarget.value);
      syncState?.(val, evt.currentTarget);
    },
    [fromString, syncState]
  );

  const currentStr = useMemo(
    () =>
      asString === undefined
        ? (value as unknown as string)
        : value === undefined
          ? undefined
          : asString(value),
    [asString, value]
  );

  const radios = (
    <Wrapper $column={column} {...rest}>
      {values.map(v => {
        const str = asString === undefined ? (v.value as unknown as string) : asString(v.value);
        return (
          <Radio
            key={str}
            name={name}
            value={str}
            checked={currentStr === str}
            onChange={handleChange}
            overrides={overrides}
            disabled={disabled}
          >
            {typeof v.label === 'string' ? (
              <span>
                {v.label}
                {v.subLabel === undefined ? '' : <SubLabel>{v.subLabel}</SubLabel>}
              </span>
            ) : (
              v.label
            )}
          </Radio>
        );
      })}
    </Wrapper>
  );

  if (label !== undefined) {
    return (
      <StyledLabel
        value={label}
        $paddingLeft={radioTheme.$labelPaddingLeft ?? 0}
        $fontSize={radioTheme.$fontSize}
        $marginBottom={radioTheme.$titleMarginBottom}
      >
        {radios}
      </StyledLabel>
    );
  }

  return radios;
}
Radios.displayName = 'Radios';

const Wrapper = styled.div<{$column?: boolean}>`
  display: flex;
  ${p => (p.$column ? `flex-direction: column;` : `align-items: center; gap: 8px;`)}
`;

const StyledLabel = styled(Label)<{
  $paddingLeft: number | string | undefined;
  $fontSize: number | string | undefined;
  $marginBottom: number | string;
}>`
  display: inline-block;
  line-height: 120%;
  font-weight: 700;
  opacity: 0.6;
  ${p => optionalRaw(p.$fontSize, v => `font-size: calc(${cssPx(v)} * 0.8);`)}
  ${p => optionalPx('padding-left', p.$paddingLeft)}
  margin-bottom: ${p => cssPx(p.$marginBottom)};
`;

const SubLabel = styled.span`
  color: #00000069;
  font-size: 15px;
  display: inline-block;
  margin-left: 10px;
  font-style: italic;
`;
