import {
  CurrencyAmount,
  HoobiizDiscount,
  HoobiizDiscountAmount,
  HoobiizDiscountPercent,
  HoobiizDiscountType,
} from '@shared/dynamo_model';
import {addCurrencyAmount, cents, roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {neverHappens} from '@shared/lib/type_utils';
import {SanitizedItem} from '@shared/model/search_tables';

export function discountToPercent(
  discount: HoobiizDiscount,
  val: CurrencyAmount
): HoobiizDiscountPercent {
  const newVal = applyDiscount(discount, val);
  return {
    type: HoobiizDiscountType.Percent,
    percent: ((val.cents - newVal.cents) / val.cents) * 100,
  };
}

export function discountToAmount(
  discount: HoobiizDiscount,
  val: CurrencyAmount
): HoobiizDiscountAmount {
  const newVal = applyDiscount(discount, val);
  return {
    type: HoobiizDiscountType.Amount,
    amount: cents(val.cents - newVal.cents),
  };
}

export function applyDiscount(discount: HoobiizDiscount, val: CurrencyAmount): CurrencyAmount {
  if (discount.type === HoobiizDiscountType.Amount) {
    // Can't apply discount if the currency don't match
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (val.currency !== discount.amount.currency) {
      return val;
    }
    return {currency: val.currency, cents: roundCents(val.cents - discount.amount.cents)};
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (discount.type === HoobiizDiscountType.Percent) {
    return {currency: val.currency, cents: roundCents(val.cents * (1 - discount.percent / 100))};
  }
  neverHappens(discount, `Invalid discount type "${(discount as HoobiizDiscountAmount).type}"`);
}

// Return >0 if discount1 is better than discount2
export function compareDiscount(
  discount1: HoobiizDiscount | undefined,
  discount2: HoobiizDiscount | undefined
): number {
  if (!discount1) {
    if (!discount2) {
      return 0;
    }
    return -1;
  } else if (!discount2) {
    return 1;
  }

  const {type: type1} = discount1;
  const {type: type2} = discount2;
  if (type1 === HoobiizDiscountType.Amount) {
    if (type2 === HoobiizDiscountType.Amount) {
      // eslint-disable-next-line unicorn/consistent-destructuring
      return roundCents(discount1.amount.cents) - roundCents(discount2.amount.cents);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (type2 === HoobiizDiscountType.Percent) {
      return 1;
    }
    neverHappens(type2, `Invalid discount type "${type2}"`);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (type1 === HoobiizDiscountType.Percent) {
    if (type2 === HoobiizDiscountType.Amount) {
      return -1;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (type2 === HoobiizDiscountType.Percent) {
      // eslint-disable-next-line unicorn/consistent-destructuring
      return discount1.percent - discount2.percent;
    }
    neverHappens(type2, `Invalid discount type "${type2}"`);
  }
  neverHappens(type1, `Invalid discount type "${type1}"`);
}

export function combineDiscountsPercent(
  discounts: (HoobiizDiscountPercent | undefined)[]
): HoobiizDiscountPercent {
  let combined = 1;
  for (const discount of discounts) {
    if (discount === undefined) {
      continue;
    }
    combined *= 1 - discount.percent / 100;
  }
  return {type: HoobiizDiscountType.Percent, percent: (1 - combined) * 100};
}

export function combineDiscountsAmount(
  discounts: (HoobiizDiscountAmount | undefined)[]
): HoobiizDiscountAmount {
  return {
    type: HoobiizDiscountType.Amount,
    amount: discounts.reduce(
      (prev, curr) => (curr ? addCurrencyAmount(prev, curr.amount) : prev),
      cents(0)
    ),
  };
}

/* eslint-disable unicorn/consistent-destructuring */
export function isPositiveDiscount(discount: HoobiizDiscount): boolean {
  const {type} = discount;
  if (type === HoobiizDiscountType.Amount) {
    return roundCents(discount.amount.cents) > 0;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (type === HoobiizDiscountType.Percent) {
    return discount.percent > 0;
  }
  neverHappens(type, `Invalid discount type "${type}"`);
}
/* eslint-enable unicorn/consistent-destructuring */

export function ticketInfoDiscountPercent<
  T extends {publicPrice: CurrencyAmount; youpiizPrice: CurrencyAmount},
>(ticketInfo: T): HoobiizDiscountPercent {
  const {publicPrice, youpiizPrice} = ticketInfo;
  return {
    type: HoobiizDiscountType.Percent,
    percent:
      (100 * (roundCents(publicPrice.cents) - roundCents(youpiizPrice.cents))) /
      roundCents(publicPrice.cents),
  };
}

export function ticketInfoDiscountAmount<
  T extends {publicPrice: CurrencyAmount; youpiizPrice: CurrencyAmount},
>(ticketInfo: T): HoobiizDiscountAmount {
  const {publicPrice, youpiizPrice} = ticketInfo;
  return {
    type: HoobiizDiscountType.Amount,
    amount: cents(roundCents(publicPrice.cents) - roundCents(youpiizPrice.cents)),
  };
}

export function findBestOffer(
  ticketInfo: {youpiizPrice: CurrencyAmount},
  offers: SanitizedItem<'HoobiizOffer'>[]
): SanitizedItem<'HoobiizOffer'> | undefined {
  let bestDiscountPercent = 0;
  let bestOffer: SanitizedItem<'HoobiizOffer'> | undefined;
  for (const offer of offers) {
    const discount = discountToPercent(offer.discount, ticketInfo.youpiizPrice);
    if (discount.percent > bestDiscountPercent) {
      bestDiscountPercent = discount.percent;
      bestOffer = offer;
    }
  }
  return bestOffer;
}
