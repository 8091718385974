import {FC, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {nonEmptyArrayOrThrow} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {Select} from '@shared-frontend/components/core/select';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {notifyError} from '@shared-frontend/lib/notification';

interface ContactPageProps {}

export const ContactPage: FC<ContactPageProps> = () => {
  const contactTypes = useMemo(
    () =>
      nonEmptyArrayOrThrow([
        'Je suis un particulier',
        'Je suis un professionnel du loisirs',
        'Je représente une entreprise, cse, collectivités ou association',
      ]),
    []
  );

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactType, setContactType] = useState(contactTypes[0]);
  const [message, setMessage] = useState('');

  const handleUpdateSubmit = useCallback(async () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      phoneNumber.length === 0 ||
      contactType.length === 0 ||
      message.length === 0
    ) {
      notifyError(undefined, {message: 'Formulaire incomplet'});
      return;
    }
    await apiCall(HoobiizApi, '/send-message', {
      firstName,
      lastName,
      phoneNumber,
      contactType,
      message,
    });
    showSuccess('Message envoyé');
  }, [contactType, firstName, lastName, message, phoneNumber]);

  return (
    <Form>
      <FormInput>
        <Input width="100%" value={firstName} syncState={setFirstName} label="PRÉNOM" />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input width="100%" value={lastName} syncState={setLastName} label="NOM" />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          value={phoneNumber}
          syncState={setPhoneNumber}
          label="NUMÉRO DE TÉLÉPHONE"
        />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Select
          width="100%"
          values={contactTypes.map(c => ({value: c, label: c}))}
          value={contactType}
          syncState={setContactType}
          label="VOUS ÊTES"
        />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Textarea
          width="100%"
          value={message}
          syncState={setMessage}
          height={256}
          label="MESSAGE"
        />
      </FormInput>
      <Spacing height={28} />
      <Button expand type="button" submit onClickAsync={handleUpdateSubmit}>
        Envoyer
      </Button>
    </Form>
  );
};

ContactPage.displayName = 'ContactPage';

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Form = styled.form`
  width: 95vw;
  max-width: 360px;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;
