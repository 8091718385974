import {FC, Fragment, useState} from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components';

import {HoobiizVendorLocation} from '@shared/dynamo_model';

import {Select} from '@shared-frontend/components/core/select';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {HoobiizLocationComponent} from '@src/components/ui/hoobiiz_location_component';

interface HoobiizContactAndAccessSectionProps {
  locations: Partial<HoobiizVendorLocation>[];
}

export const HoobiizContactAndAccessSection: FC<HoobiizContactAndAccessSectionProps> = ({
  locations,
}) => {
  // First of all, we filter the locations based on the city parameter
  const city = new URLSearchParams(useLocation().search).get('city');
  const filteredLocations =
    // eslint-disable-next-line no-null/no-null
    city !== null
      ? locations.filter(
          ({address}) => address?.addressDetails.city?.toLowerCase() === city.toLowerCase()
        )
      : locations;
  const [firstLocation] = filteredLocations;

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(0);

  return (
    <Fragment>
      <Title>
        Contact et Accès
        {filteredLocations.length > 1 ? (
          <Fragment>
            {` (${filteredLocations.length} adresses)`}
            <SelectWithMargin
              values={filteredLocations.map(({name}, index) => ({label: name ?? '', value: index}))}
              value={selectedIndex}
              syncState={setSelectedIndex}
            />
          </Fragment>
        ) : (
          ''
        )}
      </Title>
      {filteredLocations.length > 1 &&
      selectedIndex !== undefined &&
      filteredLocations[selectedIndex] !== undefined ? (
        <HoobiizLocationComponent {...filteredLocations[selectedIndex]} />
      ) : filteredLocations.length === 1 ? (
        <HoobiizLocationComponent {...firstLocation} />
      ) : (
        EmptyFragment
      )}
    </Fragment>
  );
};

HoobiizContactAndAccessSection.displayName = 'HoobiizContactAndAccessSection';

const SelectWithMargin = styled(Select)`
  margin-left: 13px;
  margin-top: 4px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;
