import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {HoobiizOfferFlash, HoobiizOfferFlashType, HoobiizTimePeriod} from '@shared/dynamo_model';
import {initialPeriod} from '@shared/lib/hoobiiz/period';

import {Checkbox} from '@shared-frontend/components/core/checkbox';

import {HoobiizTimePeriodForm} from '@src/components/admin/form/hoobiiz_time_period_form';

interface HoobiizActivityOfferFlashProps {
  flash: HoobiizOfferFlash;
  onChange: (newVal: HoobiizOfferFlash) => void;
}

export const HoobiizActivityOfferFlash: FC<HoobiizActivityOfferFlashProps> = props => {
  const {flash, onChange} = props;

  const toggleFlash = useCallback(() => {
    onChange(
      flash.type !== HoobiizOfferFlashType.Disabled
        ? {type: HoobiizOfferFlashType.Disabled}
        : {type: HoobiizOfferFlashType.Period, period: initialPeriod()}
    );
  }, [onChange, flash]);

  const handleFlashChange = useCallback(
    (period: HoobiizTimePeriod) => onChange({type: HoobiizOfferFlashType.Period, period}),
    [onChange]
  );

  const enabled = flash.type !== HoobiizOfferFlashType.Disabled;

  return (
    <Wrapper>
      <Checkbox checked={enabled} syncState={toggleFlash}>
        Offre flash
      </Checkbox>
      <FormWrapper $hidden={!enabled}>
        <HoobiizTimePeriodForm
          initialData={enabled ? flash.period : undefined}
          onChange={enabled ? handleFlashChange : undefined}
        />
      </FormWrapper>
    </Wrapper>
  );
};
HoobiizActivityOfferFlash.displayName = 'HoobiizActivityOfferFlash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div<{$hidden: boolean}>`
  visibility: ${p => (p.$hidden ? 'hidden' : 'visible')};
  height: ${p => (p.$hidden ? '1px' : 'auto')};
`;
