import {cloneElement, FC, JSX, useCallback, useLayoutEffect, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {NULL_REF} from '@shared-frontend/lib/react';
import {useClickOutside} from '@shared-frontend/lib/use_click_outside';

interface DropdownProps {
  button: JSX.Element;
  content: JSX.Element;
  align: 'left' | 'right';
  offset?: number;
  ariaLabel?: string;
}

const DEFAULT_OFFSET = 8;

export const Dropdown: FC<DropdownProps> = props => {
  const {button, content, align = 'left', offset = DEFAULT_OFFSET, ariaLabel} = props;
  const [dropdownShown, setDropdownShown] = useState(false);
  const [buttonHeight, setButtonHeight] = useState(0);

  // Toggle dropdown when clicking the button
  const handleButtonClick = useCallback(() => {
    setDropdownShown(current => !current);
  }, []);

  // Hide dropdown when clicking out
  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);
  const handleClickOutside = useCallback(() => {
    setDropdownShown(false);
  }, []);
  useClickOutside(wrapperRef, handleClickOutside);

  // Hide dropdown when clicking on the content
  const handleContentClick = useCallback(() => {
    setDropdownShown(false);
  }, []);

  // Keep track of the button height
  useLayoutEffect(() => {
    setButtonHeight(wrapperRef.current?.children.item(0)?.getBoundingClientRect().height ?? 0);
  }, []);

  return (
    <Wrapper ref={wrapperRef} aria-label={ariaLabel}>
      {cloneElement(button, {onClick: handleButtonClick})}
      <ContentWrapper
        onClick={handleContentClick}
        $shown={dropdownShown}
        $offset={buttonHeight + offset}
        $align={align}
        // tabIndex={dropdownShown ? undefined : -1}
      >
        {content}
      </ContentWrapper>
    </Wrapper>
  );
};

Dropdown.displayName = 'Dropdown';

const Wrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div<{$shown: boolean; $offset: number; $align: 'left' | 'right'}>`
  position: absolute;
  top: ${p => p.$offset}px;
  ${p => (p.$align === 'left' ? 'left: 0;' : 'right: 0;')}
  z-index: 100;

  opacity: ${p => (p.$shown ? 1 : 0)};
  visibility: ${p => (p.$shown ? 'visible' : 'hidden')};
  pointer-events: ${p => (p.$shown ? 'all' : 'none')};
  transition: opacity ease-in-out 100ms;
`;
