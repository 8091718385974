import {FC} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {EmptyFragment} from '@shared-frontend/lib/react';

import {WhiteNavLink} from '@src/components/core/theme';

export const Footer: FC = () => {
  const location = useLocation();
  const {
    main: {accentColor, accentTextColor},
  } = useTheme();

  if (['/404', '/devenir-partenaire'].includes(location.pathname)) {
    return EmptyFragment;
  }

  return (
    <Wrapper $backgroundColor={accentColor} $color={accentTextColor}>
      <Container>
        <WhiteNavLink to="/devenir-partenaire">Proposez vos activités</WhiteNavLink>
        {`Copyright © ${new Date().getFullYear()}`}
      </Container>
    </Wrapper>
  );
};
Footer.displayName = 'PageWrapper';

export const FOOTER_HEIGHT = 50;

const Wrapper = styled.footer<{$backgroundColor: string; $color: string}>`
  background-color: ${p => p.$backgroundColor};
  height: ${FOOTER_HEIGHT}px;
  color: ${p => p.$color};
  display: flex;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  align-items: center;
`;
