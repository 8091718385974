import {CSSProperties, useMemo} from 'react';

import {multiplyHoobiizPrices} from '@shared/lib/hoobiiz/hoobiiz_prices';
import {removeUndefined} from '@shared/lib/type_utils';
import {SanitizedItem, SanitizedTicketInfo} from '@shared/model/search_tables';

import {Custom} from '@shared-frontend/lib/react';

import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import {getDiscount} from '@src/lib/discount';

interface HoobiizTicketPricesProps {
  ticketInfo: Pick<SanitizedTicketInfo, 'publicPrice' | 'youpiizPrice'>;
  offers?:
    | SanitizedItem<'HoobiizOffer'>[]
    | (SanitizedItem<'HoobiizOffer'> | undefined)[]
    | SanitizedItem<'HoobiizOffer'>
    | undefined;
  quantity?: number;
  justify?: CSSProperties['justifyContent'];
}

export const HoobiizTicketPrices: Custom<HoobiizTicketPricesProps, 'div'> = props => {
  const {ticketInfo, offers = [], justify, quantity = 1, ...rest} = props;
  const offersArr = useMemo(
    () => (Array.isArray(offers) ? removeUndefined(offers) : removeUndefined([offers])),
    [offers]
  );
  const {amount} = useMemo(() => getDiscount(ticketInfo, offersArr), [offersArr, ticketInfo]);
  return (
    <HoobiizPricesView
      prices={multiplyHoobiizPrices(quantity, amount)}
      justify={justify}
      {...rest}
    />
  );
};

HoobiizTicketPrices.displayName = 'HoobiizPrices';
