import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat1Id, HoobiizCat2Item} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {Button} from '@shared-frontend/components/core/button';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {CategoryForm, CatInfo} from '@src/components/admin/category/category_form';
import {AlertButton} from '@src/components/core/theme';

interface Category2FormProps {
  initialData?: FullItem<'HoobiizCat2'>;
  submitButtonText?: string;
  onSubmit?: (data: Omit<HoobiizData<HoobiizCat2Item>, 'order'>) => Promise<void>;
  onDelete?: () => Promise<void>;
  autoFocus?: boolean;
}

export const Category2Form: FC<Category2FormProps> = props => {
  const {initialData, submitButtonText, onSubmit, onDelete, autoFocus} = props;
  const {cat1Id: cat1IdStr = ''} = useParams();
  const cat1Id = cat1IdStr as HoobiizCat1Id;

  const [info, setInfo] = useState<CatInfo | undefined>();

  const handleCreateClick = useCallback(async () => {
    if (!info) {
      return;
    }
    return onSubmit?.({...info, cat1Id});
  }, [cat1Id, info, onSubmit]);

  return (
    <Wrapper>
      <CategoryForm initialData={initialData} onChange={setInfo} autoFocus={autoFocus} />
      <ButtonsWrapper>
        <Button onClickAsync={handleCreateClick} disabled={info === undefined}>
          {submitButtonText}
        </Button>
        {onDelete ? <AlertButton onClickAsync={onDelete}>Supprimer</AlertButton> : EmptyFragment}
      </ButtonsWrapper>
    </Wrapper>
  );
};
Category2Form.displayName = 'Category2Form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
