import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';
import {NO_TIMEOUT} from '@shared/constants/uncategorized_constants';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {triggerUrlFileDownload} from '@shared-frontend/lib/download';
import {notifyError} from '@shared-frontend/lib/notification';

interface HoobiizAdminStockCalendarPdfDebugModalProps {
  req: ApiDef<typeof HoobiizApi>['/admin/generate-pdf']['req'];
}

export const HoobiizAdminStockCalendarPdfDebugModal: FC<
  HoobiizAdminStockCalendarPdfDebugModalProps
> = props => {
  const {req} = props;

  const [url, setUrl] = useState<string>();

  const refresh = useCallback(async () => {
    return apiCall(HoobiizApi, '/admin/generate-pdf', req, {timeout: NO_TIMEOUT}).then(({url}) =>
      setUrl(url)
    );
  }, [req]);

  const download = useCallback(() => {
    if (url === undefined) {
      return;
    }
    triggerUrlFileDownload({url});
  }, [url]);

  useEffect(() => {
    refresh().catch(notifyError);
  }, [refresh]);

  if (url === undefined) {
    return <Wrapper>Loading...</Wrapper>;
  }

  return (
    <Wrapper>
      {/* eslint-disable-next-line react/iframe-missing-sandbox */}
      <iframe src={`${url}#toolbar=0&statusbar=0&navpanes=0`} width="100%" height="100%" />
      <RefreshButton onClickAsync={refresh}>Refresh</RefreshButton>
      <DownloadButton onClick={download}>Download</DownloadButton>
    </Wrapper>
  );
};

HoobiizAdminStockCalendarPdfDebugModal.displayName = 'HoobiizAdminStockCalendarPdfDebugModal';

const Wrapper = styled.div`
  position: relative;
  width: 65vh;
  height: 95vh;
`;

const RefreshButton = styled(Button)`
  position: absolute !important;
  top: 0;
  right: -120px;
`;

const DownloadButton = styled(Button)`
  position: absolute !important;
  top: 61px;
  right: -138px;
`;
