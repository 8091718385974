import {FC, useCallback} from 'react';
import styled from 'styled-components';

import {HoobiizMediaId} from '@shared/dynamo_model';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {FullItem} from '@shared/model/search_tables';

import {Image} from '@shared-frontend/components/core/image';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';
import {hoobiizMediaToProps} from '@src/lib/hoobiiz_media';

const DISPLAY_SIZE = 200;

export type MediaUploadStatus =
  | {status: 'in-progress'; sourceFile: File}
  | {status: 'success'; media?: FullItem<'HoobiizMedia'>; id: HoobiizMediaId}
  | {status: 'error'; sourceFile: File; error: string};

interface MediaUploadStatusViewProps {
  uploadStatus: MediaUploadStatus;
  onDeleteClick?: (mediaStatus: MediaUploadStatus) => void;
  noMove?: boolean;
  onMoveBeforeClick?: (mediaStatus: MediaUploadStatus) => void;
  onMoveAfterClick?: (mediaStatus: MediaUploadStatus) => void;
}

export const MediaUploadStatusView: FC<MediaUploadStatusViewProps> = props => {
  const {uploadStatus, onDeleteClick, onMoveBeforeClick, onMoveAfterClick, noMove} = props;
  const {host} = useSsrContext();

  const handleTrashClicked = useCallback(() => {
    onDeleteClick?.(uploadStatus);
  }, [onDeleteClick, uploadStatus]);

  const handleMoveBeforeClicked = useCallback(() => {
    onMoveBeforeClick?.(uploadStatus);
  }, [onMoveBeforeClick, uploadStatus]);

  const handleMoveAfterClicked = useCallback(() => {
    onMoveAfterClick?.(uploadStatus);
  }, [onMoveAfterClick, uploadStatus]);

  let message = EmptyFragment;
  if (uploadStatus.status === 'success') {
    if (!uploadStatus.media) {
      message = <Message>En traitement</Message>;
    }
  } else {
    const messageTxt =
      uploadStatus.status === 'in-progress' ? 'Upload en cours' : uploadStatus.error;
    message = <Message>{messageTxt}</Message>;
  }
  const media =
    'media' in uploadStatus ? {media: uploadStatus.media, id: uploadStatus.id} : undefined;
  const imageName = 'sourceFile' in uploadStatus ? uploadStatus.sourceFile.name : '';

  return (
    <Wrapper>
      <MediaWrapper>
        <Image
          {...hoobiizMediaToProps(host, media, {
            width: DISPLAY_SIZE,
            height: DISPLAY_SIZE,
          })}
        >
          {message}
        </Image>
      </MediaWrapper>
      <Bottom>
        {noMove ? (
          EmptyFragment
        ) : (
          <SvgIcon
            onClick={handleMoveBeforeClicked}
            name="ChevronLeft"
            size={12}
            colorHover={Colors.Gold}
          />
        )}
        <MediaNameLabel>
          {imageName}
          <SvgIcon onClick={handleTrashClicked} name="Trash" size={16} colorHover={Colors.Gold} />
        </MediaNameLabel>
        {noMove ? (
          EmptyFragment
        ) : (
          <SvgIcon
            onClick={handleMoveAfterClicked}
            name="ChevronRight"
            size={12}
            colorHover={Colors.Gold}
          />
        )}
      </Bottom>
    </Wrapper>
  );
};
MediaUploadStatusView.displayName = 'MediaUploadStatusView';

const Wrapper = styled.div`
  width: ${DISPLAY_SIZE}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const MediaWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #0000002e;
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DISPLAY_SIZE}px;
  height: ${DISPLAY_SIZE}px;
  text-align: center;
  padding: 0 16px;
  color: white;
  border-radius: 8px;
  background-color: #00000080;
  box-shadow: 0px 0px 16px -3px #00000059;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MediaNameLabel = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
