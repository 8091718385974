import {NonEmptyArray, nonEmptyArrayOrThrow} from '@shared/lib/type_utils';

export function last<T>(arr: T[]): T | undefined {
  return arr.length > 0 ? arr.at(-1) : undefined;
}

export function arrayJoin<T>(arr: T[], joiner: (index: number) => T): T[] {
  const joined: T[] = [];
  for (const [i, element] of arr.entries()) {
    joined.push(element);
    if (i !== arr.length - 1) {
      joined.push(joiner(i));
    }
  }
  return joined;
}

export function splitOnce(value: string, splitter: string): [string] | [string, string] {
  const splitterIndex = value.indexOf(splitter);
  if (splitterIndex === -1) {
    return [value];
  }

  return [value.slice(0, splitterIndex), value.slice(splitterIndex + splitter.length)];
}

export function splitOnceOrThrow(value: string, splitter: string): [string, string] {
  const splitterIndex = value.indexOf(splitter);
  if (splitterIndex === -1) {
    throw new Error(`Expected two values when splitting "${value}" with "${splitter}"`);
  }
  return [value.slice(0, splitterIndex), value.slice(splitterIndex + splitter.length)];
}

export function splitLastOrThrow(value: string, splitter: string): [string, string] {
  const lastIndex = value.lastIndexOf(splitter);
  if (lastIndex === -1) {
    throw new Error(`Expected two values when splitting "${value}" with "${splitter}"`);
  }
  const first = value.slice(0, lastIndex);
  const last = value.slice(lastIndex + splitter.length);
  return [first, last];
}

export function splitLast(value: string, splitter: string): [string, string] | [string] {
  try {
    return splitLastOrThrow(value, splitter);
  } catch {
    return [value];
  }
}

export function splitBothEndOrThrow(value: string, splitter: string): [string, string, string] {
  const firstSlash = value.indexOf(splitter);
  const lastSlash = value.lastIndexOf(splitter);

  if (firstSlash === -1 || lastSlash === -1) {
    throw new Error(`Expected three values when splitting "${value}" with "${splitter}"`);
  }

  const first = value.slice(0, firstSlash);
  const middle = value.slice(firstSlash + splitter.length, lastSlash);
  const last = value.slice(lastSlash + splitter.length);

  return [first, middle, last];
}

export function halves<T>(arr: T[], isLeft: (val: T) => boolean): [T[], T[]] {
  const left: T[] = [];
  const right: T[] = [];
  for (const val of arr) {
    (isLeft(val) ? left : right).push(val);
  }
  return [left, right];
}

// type Flattened<T> = T extends NestedArray<infer U> ? U[] : T[];
// export function flattenDeep<T>(arr: NestedArray<T>): Flattened<T> {
//   const all: T[] = [];
//   for (const val of arr) {
//     if (!Array.isArray(val)) {
//       return arr as Flattened<T>;
//     }
//     all.push(...(flattenDeep(val) as T[]));
//   }
//   return all as Flattened<T>;
// }

export function flatten<T>(arr: T[][]): T[] {
  const all: T[] = [];
  for (const val of arr) {
    all.push(...val);
  }
  return all;
}

export function groupBy<T, U>(arr: T[], fn: (v: T) => U): Map<U, NonEmptyArray<T>>;
export function groupBy<T, K extends keyof T>(arr: T[], key: K): Map<T[K], NonEmptyArray<T>>;
export function groupBy<
  Value,
  Predicate,
  Key = Predicate extends (v: Value) => infer U
    ? U
    : Predicate extends keyof Value
      ? Value[Predicate]
      : never,
>(arr: Value[], predicate: Predicate): Map<Key, NonEmptyArray<Value>> {
  const groups = new Map<Key, NonEmptyArray<Value>>();
  for (const val of arr) {
    const groupKey: Key =
      typeof predicate === 'function' ? predicate(val) : val[predicate as unknown as keyof Value];
    const group = groups.get(groupKey);
    if (group === undefined) {
      groups.set(groupKey, nonEmptyArrayOrThrow([val]));
    } else {
      group.push(val);
    }
  }
  return groups;
}

export function chunk<T>(arr: T[], maxSize: number): T[][] {
  if (maxSize < 1) {
    throw new Error(`maxSize must be greater or equal to 1`);
  }

  const total = arr.length;
  const chunkCount = Math.ceil(total / maxSize);
  const chunkMinSize = Math.floor(total / chunkCount);
  const chunkMaxSize = Math.ceil(total / chunkCount);
  const lastMaxChunkIndex = total - chunkCount * chunkMinSize - 1;

  const chunks: T[][] = [];
  let elementIndex = 0;

  for (let chunkIndex = 0; chunkIndex < chunkCount; chunkIndex++) {
    const chunkSize = chunkIndex > lastMaxChunkIndex ? chunkMinSize : chunkMaxSize;
    chunks.push(arr.slice(elementIndex, elementIndex + chunkSize));
    elementIndex += chunkSize;
  }
  return chunks;
}

export function findIndex<T>(arr: T[], fn: (item: T) => boolean): number | undefined {
  for (const [index, item] of arr.entries()) {
    if (fn(item)) {
      return index;
    }
  }
  return undefined;
}

function compare(val1: unknown, val2: unknown): number {
  if (typeof val1 === 'string') {
    if (typeof val2 === 'string') {
      return val1.localeCompare(val2);
    }
    return val1.localeCompare(String(val2));
  } else if (typeof val1 === 'number') {
    if (typeof val2 === 'number') {
      return val1 - val2;
    }
    return String(val1).localeCompare(String(val2));
  }
  return String(val1).localeCompare(String(val2));
}

export function indexToInsert<Value>(
  collection: {length: number; get: (index: number) => Value},
  value: Value
): number {
  let left = 0;
  let right: number = collection.length - 1;
  while (left <= right) {
    const mid: number = Math.floor((left + right) / 2);
    if (mid < 0) {
      return 0;
    }
    if (mid >= collection.length) {
      return collection.length;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const comparison = compare(value, collection.get(mid)!);
    if (comparison === 0) {
      return mid;
    }
    if (comparison > 0) {
      if (left === right) {
        return mid + 1;
      }
      left = mid + 1;
    } else {
      if (left === right) {
        return mid;
      }
      right = mid - 1;
    }
  }
  return left;
}

/**
 * Perform a binary search on a sorted collection
 * @param collectionLength The size of the collection
 * @param compare Function which takes an index and returns
 * - a negative value if what we are searching is before the provided index
 * - a positive value if what we are searching is after the provided index
 * - 0 if the item at the index matches what we are searching
 * @param isItem Optional function that can be used reject an element at a specific index
 * even if the `compare` function returns 0.
 * @returns The index of the first matching item (-1 if none were found)
 */
export function binarySearch(
  collectionLength: number,
  compare: (index: number) => number,
  isItem?: (index: number) => boolean
): number {
  let left = 0;
  let right: number = collectionLength - 1;
  // Special case when the collection length is 1
  if (left === right) {
    return compare(0) === 0 && isItem?.(0) !== false ? 0 : -1;
  }
  while (left <= right) {
    const mid: number = Math.floor((left + right) / 2);
    const comparison = compare(mid);

    // If the value is correct, we check all the items (before and after) with that value
    if (comparison === 0) {
      // Check the current item
      if (isItem?.(mid) !== false) {
        return mid;
      }
      // Check item and items before until the result of `compare` is not 0 anymore
      for (let index = mid - 1; index >= 0; index--) {
        if (compare(index) !== 0) {
          break;
        }
        if (isItem(index)) {
          return index;
        }
      }
      // Check items after until the result of `compare` is not 0 anymore
      for (let index = mid + 1; index < collectionLength; index++) {
        if (compare(index) !== 0) {
          break;
        }
        if (isItem(index)) {
          return index;
        }
      }
      return -1;
    }
    if (comparison > 0) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }
  return -1;
}

/**
 * Perform a binary search, looking for a range of indexes in a sorted collection
 * @param collection The size of the collection
 * @param compare Function which takes an index and returns
 * - 0 if the index is in the range
 * - a negative if the start of the range is before the index
 * - a positive if the end of the range is after the index
 * @returns The first and last matching index (or undefined if the range is empty)
 */
export function binarySearchRange(
  collectionLength: number,
  compare: (index: number) => number
): {start: number; end: number} | undefined {
  const start = binarySearch(collectionLength, i => {
    const compareRes = compare(i);
    if (compareRes !== 0) {
      return compareRes;
    }
    if (i === 0) {
      return 0;
    }
    return compare(i - 1) === 0 ? -1 : 0;
  });

  if (start === -1) {
    return undefined;
  }

  // Emulate searching from index `start + 1`
  const searchStart = start + 1;
  const end = binarySearch(collectionLength - searchStart, i => {
    const compareRes = compare(i + searchStart);
    if (compareRes !== 0) {
      return compareRes;
    }
    if (i + searchStart >= collectionLength - 1) {
      return 0;
    }
    return compare(i + searchStart + 1) === 0 ? 1 : 0;
  });

  return {start, end: end === -1 ? start : end + searchStart};
}

export function sum(arr: number[] | Iterable<number>): number {
  let res = 0;
  for (const val of arr) {
    res += val;
  }
  return res;
}

export function mapSum<T>(arr: T[] | Iterable<T>, fn: (val: T) => number): number {
  let res = 0;
  for (const val of arr) {
    res += fn(val);
  }
  return res;
}

export function dedup<T>(arr: T[] | Iterable<T>, dedupAttr: (val: T) => string): T[] {
  const dedupMap = new Map<string, T>();
  for (const element of arr) {
    dedupMap.set(dedupAttr(element), element);
  }
  return [...dedupMap.values()];
}

export function every<T>(arr: T[] | Iterable<T>, condition: (val: T) => boolean): boolean {
  for (const val of arr) {
    if (!condition(val)) {
      return false;
    }
  }
  return true;
}

export function any<T>(arr: T[] | Iterable<T>, condition: (val: T) => boolean): boolean {
  for (const val of arr) {
    if (condition(val)) {
      return true;
    }
  }
  return false;
}

export function zip<T, U>(arr1: T[], arr2: U[]): [T, U][] {
  if (arr1.length !== arr2.length) {
    throw new Error(`Arrays have different sizes: ${arr1.length} and ${arr2.length}`);
  }
  const res: [T, U][] = [];
  for (const [i, element] of arr1.entries()) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    res.push([element, arr2[i]!]);
  }
  return res;
}

export function min<T>(
  arr: (T | undefined)[],
  fn: (val: T) => number | undefined
): number | undefined {
  let min: number | undefined;
  for (const element of arr) {
    if (element === undefined) {
      continue;
    }
    const val = fn(element);
    if (val === undefined) {
      continue;
    }
    min = min === undefined ? val : Math.min(min, val);
  }
  return min;
}

export function max<T>(
  arr: (T | undefined)[],
  fn: (val: T) => number | undefined
): number | undefined {
  let max: number | undefined;
  for (const element of arr) {
    if (element === undefined) {
      continue;
    }
    const val = fn(element);
    if (val === undefined) {
      continue;
    }
    max = max === undefined ? val : Math.max(max, val);
  }
  return max;
}

export function nonEmptyMap<T, U>(
  arr: NonEmptyArray<T>,
  fn: (item: T, index: number) => U
): NonEmptyArray<U> {
  return arr.map(fn) as NonEmptyArray<U>;
}

export function uniq<T>(arr: T[], key?: (val: T) => unknown): T[] {
  const seen = new Set<unknown>();
  const final: T[] = [];
  for (const element of arr) {
    const elementKey = key ? key(element) : element;
    if (seen.has(elementKey)) {
      continue;
    }
    seen.add(elementKey);
    final.push(element);
  }
  return final;
}
