import {useHoobiizUserGroup} from './admin_user_and_group_store';
import {AdminUserAndGroupSubGroups} from './admin_user_and_group_subgroups';
import {FC, JSX, useCallback, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';

import {HoobiizGroupItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {FrontendUserId, HoobiizUserGroupId} from '@shared/dynamo_model';
import {paddings} from '@shared/frontends/frontend_theme_utils';
import {ROOT_USER_GROUP_ID} from '@shared/model/hoobiiz/hoobiiz_common';

import {NavLink} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useStateRef} from '@shared-frontend/lib/use_state_ref';

import {AdminUserAndGroupChainNodeGroup} from '@src/components/admin/user_and_group/admin_user_and_group_chain_node_group';
import {useHoobiizUserGroupModalPath} from '@src/components/admin/user_and_group/admin_user_and_group_paths';
import {AdminUserAndGroupUsers} from '@src/components/admin/user_and_group/admin_user_and_group_users';

export interface AdminUserAndGroupTreeActionContext {
  expandLine: () => void;
}

export interface AdminUserAndGroupTreeProps {
  groupId?: HoobiizUserGroupId;
  groupAction?: (
    group: HoobiizGroupItem,
    context: AdminUserAndGroupTreeActionContext
  ) => JSX.Element;
  isGroupHighlighted?: (groupId: HoobiizUserGroupId) => boolean;
  isCurrentGroup?: (groupId: HoobiizUserGroupId) => boolean;
  isCurrentUser?: (userId: FrontendUserId) => boolean;
  isGroupExpanded?: (groupId: HoobiizUserGroupId) => boolean;
  preventUserAutoLoad?: (groupId: HoobiizUserGroupId) => boolean;
}

export const AdminUserAndGroupTree: FC<AdminUserAndGroupTreeProps> = props => {
  const {
    groupId = ROOT_USER_GROUP_ID,
    groupAction,
    isGroupHighlighted,
    isCurrentGroup,
    isCurrentUser,
    isGroupExpanded,
    preventUserAutoLoad,
  } = props;
  const group = useHoobiizUserGroup({groupId});
  const modalPath = useHoobiizUserGroupModalPath({groupId});

  // Group toggling
  const hackRef = useRef<boolean>(false);
  const initialExpanded = useCallback(() => {
    return (
      (groupId === ROOT_USER_GROUP_ID || hackRef.current || isGroupExpanded?.(groupId)) ?? false
    );
  }, [groupId, hackRef, isGroupExpanded]);
  const [expanded, setExpanded, expandedRef] = useStateRef(() => initialExpanded());
  hackRef.current = expandedRef.current;
  const toggleCollasped = useCallback(() => setExpanded(curr => !curr), [setExpanded]);
  const expandLine = useCallback(() => setExpanded(true), [setExpanded]);
  useEffect(() => {
    setExpanded(initialExpanded());
  }, [initialExpanded, isGroupExpanded, setExpanded]);

  // Extra action
  const actionElement = useMemo((): JSX.Element => {
    if (!group) {
      return EmptyFragment;
    }
    return groupAction?.(group.item, {expandLine}) ?? EmptyFragment;
  }, [group, groupAction, expandLine]);

  const shouldAutoLoadUsers = useMemo(() => {
    return !preventUserAutoLoad?.(groupId);
  }, [groupId, preventUserAutoLoad]);

  // UI flags
  const isCircled = useMemo(
    () => isGroupHighlighted?.(groupId) ?? false,
    [groupId, isGroupHighlighted]
  );
  const isHighlighted = useMemo(
    () => (group && isCurrentGroup?.(groupId)) ?? false,
    [group, isCurrentGroup, groupId]
  );

  if (!group) {
    return <LoadingIndicator color={'#888'} size={18} />;
  }

  const {subGroups, users} = group;

  return (
    <div>
      <Header $noBorder={!expanded}>
        <AdminUserAndGroupChainNodeGroup
          before={
            <ChevronSvgIcon
              name={'ChevronRight'}
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              rotate={!expanded ? 0 : 90}
              color="#888"
              width={14}
              height={12}
            />
          }
          groupId={groupId}
          isCircled={isCircled}
          isHighlighted={isHighlighted}
          onClick={toggleCollasped}
        />
        <NavLink
          to={modalPath}
          overrides={{
            backgroundHover: '#0000000a',
            borderRadius: 4,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...paddings(8),
          }}
        >
          <SvgIcon name="Gear" color={'#888'} size={14} />
        </NavLink>
        {actionElement}
        {subGroups.isFetching || users.isFetching ? (
          <LoadingIndicator color={'#888'} size={18} />
        ) : (
          EmptyFragment
        )}
      </Header>
      {expanded ? (
        <Content>
          <AdminUserAndGroupSubGroups
            key="subgroups"
            groupId={groupId}
            groupAction={groupAction}
            isGroupHighlighted={isGroupHighlighted}
            isCurrentGroup={isCurrentGroup}
            isCurrentUser={isCurrentUser}
            isGroupExpanded={isGroupExpanded}
            preventUserAutoLoad={preventUserAutoLoad}
          />
          {
            <AdminUserAndGroupUsers
              key="users"
              groupId={groupId}
              isUserHighlighted={isCurrentUser}
              shouldAutoLoadUsers={shouldAutoLoadUsers}
            />
          }
        </Content>
      ) : (
        EmptyFragment
      )}
    </div>
  );
};

AdminUserAndGroupTree.displayName = 'AdminUserAndGroupTree';

const Header = styled.div<{$noBorder: boolean}>`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin-left: 14px;
  padding-left: 6px;
  border-left: solid 2px #eaeaea;
`;

const ChevronSvgIcon = styled(SvgIcon)`
  transition: rotate 100ms ease-in-out;
`;
