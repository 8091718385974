import {FC, useCallback} from 'react';

import {HoobiizStockModeType, HoobiizStockModeVendorConfirm} from '@shared/dynamo_model';

import {Input} from '@shared-frontend/components/core/input_v2';

import {adminInputTheme} from '@src/components/core/theme';

interface HoobiizStockModeVendorConfirmFormProps {
  mode: HoobiizStockModeVendorConfirm | undefined;
  onChange?: (mode: HoobiizStockModeVendorConfirm | undefined) => void;
}

export const HoobiizStockModeVendorConfirmForm: FC<
  HoobiizStockModeVendorConfirmFormProps
> = props => {
  const {mode, onChange} = props;

  const handleEmailChange = useCallback(
    (newEmail: string | undefined) => {
      if (newEmail === undefined || newEmail.length === 0) {
        onChange?.(undefined);
      } else {
        onChange?.({type: HoobiizStockModeType.VendorConfirm, email: newEmail});
      }
    },
    [onChange]
  );

  return (
    <Input
      label="EMAIL DU PARTENAIRE POUR VALIDATION"
      value={mode?.email}
      syncState={handleEmailChange}
      overrides={adminInputTheme}
    />
  );
};
HoobiizStockModeVendorConfirmForm.displayName = 'HoobiizStockModeVendorConfirmForm';
