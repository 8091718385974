import {useCallback, useRef, useState} from 'react';
import styled from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizDiscountPercent,
  HoobiizDiscountType,
  HoobiizOfferFlash,
  HoobiizOfferFlashType,
  HoobiizOfferItem,
  HoobiizOfferTargetActivity,
  HoobiizOfferTargetType,
  HoobiizVisibility,
} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {Button} from '@shared-frontend/components/core/button';
import {Custom, EmptyFragment, NULL_REF} from '@shared-frontend/lib/react';

import {HoobiizActivityOfferFlash} from '@src/components/admin/activity_offer/hoobiiz_activity_offer_flash_form';
import {HoobiizActivityOfferTarget} from '@src/components/admin/activity_offer/hoobiiz_activity_offer_target_form';
import {FormBlock, FormBlockFix, FormFlex} from '@src/components/admin/form/form_fragments';
import {PromotionDiscountPercentForm} from '@src/components/admin/form/hoobiiz_percent_form';
import {HoobiizVisibilityForm} from '@src/components/admin/form/hoobiiz_visibility_form';
import {AlertButton} from '@src/components/core/theme';

export interface HoobiizActivityOfferFormProps {
  activity: FullItem<'HoobiizActivity'>;
  initialData?: Partial<FullItem<'HoobiizOffer'>>;
  submitButtonText?: string;
  onSubmit: (data: HoobiizData<HoobiizOfferItem>, el: HTMLDivElement) => Promise<void>;
  onDelete?: (el: HTMLDivElement) => Promise<void>;
}

export const HoobiizActivityOfferForm: Custom<
  HoobiizActivityOfferFormProps,
  'div',
  'onSubmit'
> = props => {
  const {activity, initialData, submitButtonText, onSubmit, onDelete, ...rest} = props;
  const ref = useRef<HTMLDivElement>(NULL_REF);

  const [discount, setDiscount] = useState<HoobiizDiscountPercent | undefined>(
    initialData?.discount?.type === HoobiizDiscountType.Percent
      ? initialData.discount
      : {type: HoobiizDiscountType.Percent, percent: 0}
  );
  const [target, setTarget] = useState<HoobiizOfferTargetActivity>(
    initialData?.target?.type === HoobiizOfferTargetType.Activity
      ? initialData.target
      : {type: HoobiizOfferTargetType.Activity, activityId: activity.id}
  );
  const [flash, setFlash] = useState<HoobiizOfferFlash>(
    initialData?.flash ?? {type: HoobiizOfferFlashType.Disabled}
  );
  const [visibility, setVisibility] = useState(initialData?.visibility ?? HoobiizVisibility.Public);

  const handleCreateClick = useCallback(async () => {
    if (!discount || !ref.current) {
      return;
    }

    return onSubmit(
      {
        label: '',
        discount,
        target,
        flash,
        visibility,
      },
      ref.current
    );
  }, [discount, flash, onSubmit, target, visibility]);

  const handleDeleteClick = useCallback(async () => {
    if (!ref.current) {
      return;
    }

    return onDelete?.(ref.current);
  }, [onDelete]);

  return (
    <Wrapper ref={ref} {...rest}>
      <FormFlex>
        <FormBlockFix $width={116}>
          <PromotionDiscountPercentForm
            label="POURCENTAGE"
            initialData={discount}
            onChange={setDiscount}
          />
        </FormBlockFix>
        <FormBlock>
          <HoobiizVisibilityForm value={visibility} syncState={setVisibility} />
        </FormBlock>
      </FormFlex>
      <FormFlex>
        <HoobiizActivityOfferTarget target={target} onChange={setTarget} />
        <HoobiizActivityOfferFlash flash={flash} onChange={setFlash} />
      </FormFlex>
      {/* BUTTONS */}
      <ButtonsWrapper>
        <Button onClickAsync={handleCreateClick} disabled={!discount}>
          {submitButtonText}
        </Button>
        {onDelete ? (
          <AlertButton onClickAsync={handleDeleteClick}>Supprimer</AlertButton>
        ) : (
          EmptyFragment
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};
HoobiizActivityOfferForm.displayName = 'HoobiizActivityOfferForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
