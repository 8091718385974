import {FC, useEffect, useState} from 'react';

import {HoobiizStockModeAdminConfirm, HoobiizStockModeType} from '@shared/dynamo_model';

import {Input} from '@shared-frontend/components/core/input_v2';

import {FormBlockAuto, FormFlex, FormLabel} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

interface HoobiizStockModeAdminConfirmFormProps {
  initialMode: HoobiizStockModeAdminConfirm;
  initialQuantity: number;
  onChange?: (mode: HoobiizStockModeAdminConfirm, quantity: number) => void;
}

const fromString = (val: string): number => {
  const res = Math.max(0, Math.round(parseFloat(val)));
  if (isNaN(res)) {
    throw new Error('Invalid value');
  }
  return res;
};

export const HoobiizStockModeAdminConfirmForm: FC<
  HoobiizStockModeAdminConfirmFormProps
> = props => {
  const {initialQuantity, onChange} = props;

  const [quantity, setQuantity] = useState(initialQuantity);

  useEffect(() => {
    onChange?.({type: HoobiizStockModeType.AdminConfirm}, quantity);
  }, [onChange, quantity]);

  return (
    <div>
      <FormLabel $noMargin>TICKETS</FormLabel>
      <FormFlex>
        <FormBlockAuto>
          <Input
            value={quantity}
            syncState={setQuantity}
            fromString={fromString}
            label={'QUANTITÉ'}
            overrides={adminInputTheme}
          />
        </FormBlockAuto>
      </FormFlex>
    </div>
  );
};
HoobiizStockModeAdminConfirmForm.displayName = 'HoobiizStockModeAdminConfirmForm';
