import {Fragment, useMemo} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {CurrencyAmount, HoobiizActivityId} from '@shared/dynamo_model';
import {groupBy} from '@shared/lib/array_utils';
import {endOfLocalDay, startOfLocalDay, startOfLocalMonth} from '@shared/lib/date_utils';
import {
  cents,
  compareCurrencyAmount,
  currencyAmountToString,
  roundCents,
} from '@shared/lib/hoobiiz/currency_amount';
import {applyDiscount, findBestOffer} from '@shared/lib/hoobiiz/offer';
import {
  isFixedStockItemWithOffer,
  isFlexibleStockItemWithOffer,
} from '@shared/model/hoobiiz/hoobiiz_stock';

import {calendarWeeks} from '@shared-frontend/components/core/calendar';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizFlexibleTicketPicker} from '@src/components/ui/hoobiiz_flexible_ticket_picker';
import {HoobiizIconSvg} from '@src/components/ui/hoobiiz_icon_svg';
import {HoobiizTicketDayPicker} from '@src/components/ui/hoobiiz_ticket_day_picker';
import {noDiscount} from '@src/lib/discount';

interface HoobiizStockModuleProps {
  activityId: HoobiizActivityId;
  headerOverride?: string;
  firstStockTs?: number;
}

const ACTIVITY_RESERVATION_DEFAULT_HEADER = [
  'Meilleur prix garanti — Service gratuit',
  'Billets envoyés sous 24h après confirmation du partenaire',
].join('\n');

export function getHeaderText(headerOverride: string | undefined): string {
  let header = headerOverride?.trim();
  if (header === undefined || header.length === 0) {
    header = ACTIVITY_RESERVATION_DEFAULT_HEADER;
  }
  return header;
}

export const HoobiizStockModule: Custom<HoobiizStockModuleProps, 'div'> = props => {
  const {activityId, headerOverride, firstStockTs, ...rest} = props;

  const date = useMemo(() => {
    const date = firstStockTs === undefined ? new Date() : new Date(firstStockTs);
    return {month: date.getMonth(), year: date.getFullYear()};
  }, [firstStockTs]);

  const range = useMemo(() => {
    const weeks = calendarWeeks(date);
    const firstDay = weeks[0]?.[0];
    const lastDay = weeks.at(-1)?.at(-1);
    if (!firstDay || !lastDay) {
      // Should never happen
      notifyError(new Error(`Failure to identify startTs and endTs`), {silent: true});
      return {
        startTs: startOfLocalMonth().getTime(),
        endTs: startOfLocalMonth(undefined, 1).getTime(),
      };
    }

    return {startTs: startOfLocalDay(firstDay).getTime(), endTs: endOfLocalDay(lastDay).getTime()};
  }, [date]);

  const {data: stocksRes} = useApiCall(HoobiizApi, '/list-activity-stock', {
    activityId,
    ...range,
  });

  if (!stocksRes) {
    return <Wrapper>Loading...</Wrapper>;
  }

  // console.log(
  //   stocksRes.stocks
  //     .sort((s1, s2) => s1.stock.reservation.period.startTs - s2.stock.reservation.period.startTs)
  //     .map(s => ({
  //       id: s.stock.id,
  //       ticketInfo: s.stock.availableTickets.map(t => t.label).join(' | '),
  //       available: s.stock.available,
  //       start: localTime(s.stock.reservation.period.startTs),
  //       end: localTime(s.stock.reservation.period.endTs),
  //     }))
  // );

  const {hasFlexibleStocks, expertTicketFixedStocks, expertTicketFlexibleStocks, stocks} =
    stocksRes;
  const calendarStock = stocks.filter(isFixedStockItemWithOffer);
  const flexibleStock = stocks.filter(isFlexibleStockItemWithOffer);

  const stockByTicketInfo = groupBy(
    flexibleStock.flatMap(s =>
      s.stock.availableTickets.map(ticketInfo => ({
        quantity: s.stock.available,
        ticketInfo,
        bestOffer: findBestOffer(ticketInfo, s.offers),
        stock: s.stock,
        key: s.stock.id,
      }))
    ),
    s => [s.ticketInfo.label, s.ticketInfo.publicPrice, s.ticketInfo.youpiizPrice].join('-')
  );
  const aggregatedStocks = [...stockByTicketInfo.values()].map(arr => ({
    key: arr[0].key,
    ticketInfo: arr[0].ticketInfo,
    bestOffer: arr[0].bestOffer,
    stock: arr[0].stock,
  }));

  let bestPrice: {amount: CurrencyAmount; discountCount: number} | undefined;
  for (const {offers, stock} of stocks) {
    for (const ticketInfo of stock.availableTickets) {
      for (const offer of [{discount: noDiscount}, ...offers]) {
        const amount = applyDiscount(offer.discount, ticketInfo.youpiizPrice);
        const baseDiscountCount =
          compareCurrencyAmount(ticketInfo.publicPrice, ticketInfo.youpiizPrice) > 0 ? 1 : 0;
        const offerDiscountCount =
          roundCents(amount.cents) < roundCents(ticketInfo.youpiizPrice.cents) ? 1 : 0;
        const discountCount = baseDiscountCount + offerDiscountCount;
        if (
          !bestPrice ||
          compareCurrencyAmount(amount, bestPrice.amount) < 0 ||
          (compareCurrencyAmount(amount, bestPrice.amount) === 0 &&
            discountCount > bestPrice.discountCount)
        ) {
          bestPrice = {amount, discountCount};
        }
      }
    }
  }

  for (const {tickets} of expertTicketFixedStocks) {
    for (const ticket of tickets) {
      for (const {price} of ticket.prices) {
        const amount = cents(price);
        if (!bestPrice || compareCurrencyAmount(amount, bestPrice.amount) < 0) {
          bestPrice = {amount, discountCount: 0};
        }
      }
    }
  }

  let headerPriceElement = EmptyFragment;
  if (bestPrice) {
    const color =
      bestPrice.discountCount === 0
        ? Colors.Grey
        : bestPrice.discountCount === 1
          ? Colors.Green
          : Colors.Red;
    headerPriceElement = (
      <HeaderPrice $color={color}>
        <HeaderPriceLabel>Dès</HeaderPriceLabel>
        <HeaderPriceValue>{currencyAmountToString(bestPrice.amount)}</HeaderPriceValue>
      </HeaderPrice>
    );
  }

  const header = getHeaderText(headerOverride);

  return (
    <Wrapper {...rest}>
      <Header>
        <HeaderIcon height={32} />
        <HeaderContent>
          <HeaderTitle>Achetez dès maintenant</HeaderTitle>
          {headerPriceElement}
        </HeaderContent>
      </Header>
      <HeaderSection>
        <HeaderSectionLeft>
          <SvgIcon name="Check" size={24} color={Colors.Green} />
        </HeaderSectionLeft>
        <HeaderSectionRight>
          {header.split('\n').map(line => (
            <div key={line}>{line}</div>
          ))}
        </HeaderSectionRight>
      </HeaderSection>
      <Spacing height={28} />
      {!hasFlexibleStocks ? (
        <Fragment>
          <Title>DATES DISPONIBLES</Title>
          <HoobiizTicketDayPicker
            activityId={activityId}
            initialMonth={date.month}
            initialYear={date.year}
            initialStocks={calendarStock}
            initialExpertTicketStocks={expertTicketFixedStocks}
          />
        </Fragment>
      ) : (
        EmptyFragment
      )}
      {aggregatedStocks.length > 0 || expertTicketFlexibleStocks.length > 0 ? (
        <HoobiizFlexibleTicketPicker
          stocks={aggregatedStocks}
          expertTicketStocks={expertTicketFlexibleStocks}
        />
      ) : (
        EmptyFragment
      )}
    </Wrapper>
  );
};

HoobiizStockModule.displayName = 'HoobiizStockModule';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  border: solid 2px #00000010;
  border-radius: 16px;
  box-shadow: 0 0 20px -10px #00000036;
  width: 446px;
  flex-shrink: 0;
`;

const Title = styled.div`
  color: ${Colors.Grey};
  font-size: 20px;
  font-weight: 500;
  border-bottom: solid 3px #e8e8e8;
  padding-bottom: 8px;
  margin-bottom: 12px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;
const HeaderIcon = styled(HoobiizIconSvg)`
  flex-shrink: 0;
  background: linear-gradient(197deg, #b49a62 0%, #b49a62 50%, #e0c68e 100%);
  border-radius: 4px;
  padding: 3px 4px 0 4px;
`;
const HeaderContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 8px;
  border-bottom: solid 3px ${Colors.Gold};
  padding-bottom: 4px;
`;
const HeaderTitle = styled.div`
  font-size: 18px;
`;
const HeaderPrice = styled.div<{$color: string}>`
  display: flex;
  align-items: baseline;
  gap: 4px;
  color: ${p => p.$color};
`;
const HeaderPriceLabel = styled.div``;
const HeaderPriceValue = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const HeaderSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 80%;
  margin: 14px auto 0 auto;
`;
const HeaderSectionLeft = styled.div`
  flex-shrink: 0;
`;
const HeaderSectionRight = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.Green};
`;
