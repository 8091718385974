import {styled} from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Tooltip} from '@shared-frontend/components/core/tooltip';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';

interface HoobiizTicketLabelProps {
  label: string;
  description?: string;
}

export const HoobiizTicketLabel: Custom<HoobiizTicketLabelProps, 'div'> = props => {
  const {label, description, ...rest} = props;

  return (
    <Wrapper {...rest}>
      {label}
      {description === undefined || description.length === 0 ? (
        EmptyFragment
      ) : (
        <Tooltip content={description} position="top" maxWidth={300} fontSize={14}>
          <SvgIcon name="Info" color={Colors.Grey} size={16} />
        </Tooltip>
      )}
    </Wrapper>
  );
};

HoobiizTicketLabel.displayName = 'HoobiizTicketOptionsPicker';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  font-size: 18px;
`;
