import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {HoobiizOpeningHours, HoobiizWeekPeriods} from '@shared/dynamo_model';

import {HoobiizOpeningHoursOverridesForm} from '@src/components/admin/form/hoobiiz_opening_hours_overrides_form';
import {HoobiizWeekPeriodsForm} from '@src/components/admin/form/hoobiiz_week_periods_form';

interface OpeningHoursFormProps {
  openingHours: HoobiizOpeningHours;
  onChange?: (data: HoobiizOpeningHours) => void;
  disabled?: boolean;
}

export const OpeningHoursForm: FC<OpeningHoursFormProps> = props => {
  const {disabled, openingHours, onChange} = props;

  const handleHoursChange = useCallback(
    (weekdays: HoobiizWeekPeriods) => onChange?.({weekdays, overrides: openingHours.overrides}),
    [onChange, openingHours.overrides]
  );

  const handleOverridesChange = useCallback(
    (overrides: HoobiizOpeningHours['overrides']) =>
      onChange?.({weekdays: openingHours.weekdays, overrides}),
    [onChange, openingHours.weekdays]
  );

  return (
    <Wrapper>
      <HoobiizWeekPeriodsForm
        defaultLabel="Fermé"
        disabled={disabled}
        hours={openingHours.weekdays}
        onChange={handleHoursChange}
      />
      <HoobiizOpeningHoursOverridesForm
        disabled={disabled}
        overrides={openingHours.overrides}
        onChange={handleOverridesChange}
      />
    </Wrapper>
  );
};
OpeningHoursForm.displayName = 'OpeningHoursForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
