import {FC, Fragment, useCallback, useState} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {UserSessionItem} from '@shared/api/definitions/frontend_shared_api';
import {EmailString, FrontendUserAuthMethodType} from '@shared/dynamo_model';
import {useTheme} from '@shared/frontends/theme_context';
import {InviteId} from '@shared/lib/redis';

import {sharedApiCall} from '@shared-frontend/api';
import {RegisterForm} from '@shared-frontend/components/auth/register_form';
import {RegisterMfaCheckboxes} from '@shared-frontend/components/auth/register_invite_mfa_checkboxes';
import {Button} from '@shared-frontend/components/core/button';
import {QrCode} from '@shared-frontend/components/core/qr_code';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {setSession} from '@shared-frontend/lib/session_store';
import {
  useOptionalBooleanQueryString,
  useOptionalStringQueryString,
} from '@shared-frontend/lib/use_query_string';

export const RegisterInvite: FC = () => {
  const navigate = useNavigate();
  const {
    main: {api, textColor},
    auth: {postLoginPage},
  } = useTheme();
  const [inviteId] = useOptionalStringQueryString<InviteId>('inviteId');
  const [email] = useOptionalStringQueryString<EmailString>('email');
  const [authType] = useOptionalStringQueryString<FrontendUserAuthMethodType>('authType');
  const [isAdmin] = useOptionalBooleanQueryString('isAdmin');

  const [totpUri, setTotpUri] = useState<string | undefined>();
  const [nextSession, setNextSession] = useState<UserSessionItem | undefined>();

  const handleAccessClick = useCallback(() => {
    setSession(nextSession);
    navigate(postLoginPage);
  }, [navigate, nextSession, postLoginPage]);

  const register = useCallback(
    async (opts?: {password?: string}): Promise<void> => {
      const {password} = opts ?? {};
      if (email === undefined || inviteId === undefined || authType === undefined) {
        notifyError(new Error('Invalid form'));
        return;
      }
      return sharedApiCall(api, '/register-invite', {
        email,
        inviteId,
        password,
      })
        .then(data => {
          if (authType === FrontendUserAuthMethodType.MfaCode) {
            const method = data.authMethods.find(
              method => method.type === FrontendUserAuthMethodType.MfaCode
            );
            if (method?.type === FrontendUserAuthMethodType.MfaCode) {
              setTotpUri(method.totpUri);
            }
            setNextSession(data.session);
            return;
          }
          setSession(data.session);
          navigate(postLoginPage);
        })
        .catch(notifyError);
    },
    [authType, email, api, inviteId, navigate, postLoginPage]
  );

  const handleMfaRegisterSubmit = useCallback(async (): Promise<void> => register(), [register]);
  const handleMagicLinkRegisterSubmit = useCallback(
    async (): Promise<void> => register(),
    [register]
  );
  const handlePasswordRegisterSubmit = useCallback(
    async (email: EmailString, password: string): Promise<void> => {
      return register({password});
    },
    [register]
  );

  if (inviteId === undefined || email === undefined || authType === undefined) {
    return EmptyFragment;
  }

  return (
    <Wrapper $color={textColor}>
      {totpUri !== undefined ? (
        <Content>
          <Title>Your account is ready !</Title>
          <TitleSeparator />
          <div>Please save this QR Code into your MFA device.</div>
          <div>It will be displayed only once.</div>
          {IS_BROWSER ? <QrCode totpUri={totpUri} /> : EmptyFragment}
          <Button onClick={handleAccessClick}>Access my account now!</Button>
        </Content>
      ) : authType === FrontendUserAuthMethodType.MfaCode ? (
        <Fragment>
          <Title>Welcome</Title>
          <TitleSeparator />
          <RegisterMfaCheckboxes onSubmit={handleMfaRegisterSubmit} />
        </Fragment>
      ) : authType === FrontendUserAuthMethodType.MagicLink ? (
        <Fragment>
          <Title>Welcome</Title>
          <TitleSeparator />
          <Content>
            <div>
              We are happy to have you among us. An administrator just created your account.
            </div>
            <div>You will receive an email everytime you login with a Magic Link inside.</div>
          </Content>
          <ButtonSeparator />
          <Button onClickAsync={handleMagicLinkRegisterSubmit}>Access my account now!</Button>
        </Fragment>
      ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      authType === FrontendUserAuthMethodType.Password ? (
        <RegisterForm
          header={isAdmin ? `Administrateur` : undefined}
          onRegisterSubmit={handlePasswordRegisterSubmit}
          initialEmail={email}
          emailDisabled
          noLegalNotice
        />
      ) : (
        EmptyFragment
      )}
    </Wrapper>
  );
};
RegisterInvite.displayName = 'RegisterInvite';

const Wrapper = styled.div<{$color: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${p => p.$color};
  line-height: normal;
  text-align: center;
`;

const TitleSeparator = styled.div`
  height: 20px;
`;

const Content = styled.div`
  font-weight: 300;
  font-size: 34px;
`;

const Title = styled.h1`
  font-size: 60px;
  font-weight: 400;
  margin-top: 48px;
`;

const ButtonSeparator = styled.div`
  height: 40px;
`;
