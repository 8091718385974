import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {sharedApiCall} from '@shared-frontend/api';
import {notifyError} from '@shared-frontend/lib/notification';
import {setSession} from '@shared-frontend/lib/session_store';
import {useParams} from '@shared-frontend/lib/use_typed_params';

export const Link: FC<{disabled?: boolean}> = ({disabled}) => {
  const {id} = useParams<{id: string}>();
  const {
    main: {api},
    auth: {postLoginPage},
  } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (id === undefined) {
      navigate('/');
    } else {
      sharedApiCall(api, '/link', {id})
        .then(data => {
          if (data.success) {
            setSession(data.session);
            navigate(postLoginPage);
          } else {
            navigate('/');
          }
        })
        .catch(err => {
          notifyError(err);
          navigate('/');
        });
    }
  }, [navigate, id, api, postLoginPage, disabled]);

  return <Wrapper>Logging in...</Wrapper>;
};
Link.displayName = 'Link';

const Wrapper = styled.div`
  width: 95vw;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;
