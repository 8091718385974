import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {HoobiizOfferTargetActivity, HoobiizWeekPeriods} from '@shared/dynamo_model';

import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {HoobiizWeekPeriodsForm} from '@src/components/admin/form/hoobiiz_week_periods_form';
import {emptyWeekPeriods} from '@src/lib/hoobiiz_week_periods';

interface HoobiizActivityOfferTargetProps {
  target: HoobiizOfferTargetActivity;
  onChange: (newVal: HoobiizOfferTargetActivity) => void;
}

export const HoobiizActivityOfferTarget: FC<HoobiizActivityOfferTargetProps> = props => {
  const {target, onChange} = props;

  const toggleReservation = useCallback(() => {
    onChange({
      ...target,
      reservation: target.reservation === undefined ? {hours: emptyWeekPeriods()} : undefined,
    });
  }, [onChange, target]);

  const handleHoursChange = useCallback(
    (hours: HoobiizWeekPeriods) => onChange({...target, reservation: {hours}}),
    [onChange, target]
  );

  return (
    <Wrapper>
      <Checkbox checked={target.reservation !== undefined} syncState={toggleReservation}>
        Retreindre à certain créneaux
      </Checkbox>
      {target.reservation === undefined ? (
        EmptyFragment
      ) : (
        <div>
          <HoobiizWeekPeriodsForm
            hours={target.reservation.hours}
            onChange={handleHoursChange}
            defaultLabel="Pas de promotion"
          />
        </div>
      )}
    </Wrapper>
  );
};
HoobiizActivityOfferTarget.displayName = 'HoobiizActivityOfferTarget';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
