import {createContext} from 'react';

import {FrontendDataStore} from '@shared/frontends/frontend_data_store';

export interface DataStoreApi<GetData, SetData> {
  getData: () => GetData;
  setData: (data: SetData) => void;
  updateData: (fn: (current: GetData) => SetData) => void;
  useData: () => GetData;
  addChangeListener: (listener: () => void) => void;
}

export const DataStoreContext = createContext<
  FrontendDataStore[keyof FrontendDataStore] | undefined
>(undefined);
