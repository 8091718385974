import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';

import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {splitOnce} from '@shared/lib/array_utils';
import {asDate, asNumber, NumberBrand, StringBrand} from '@shared/lib/type_utils';

import {
  addHistoryStateListener,
  pushHistoryState,
  replaceHistoryState,
} from '@shared-frontend/lib/history_state';
import {useMemoCompare} from '@shared-frontend/lib/use_memo_compare';

interface Options {
  override?: boolean;
  noHistory?: boolean;
}

export function useStringQueryString<T extends StringBrand | string = string>(
  key: string,
  initial: string,
  opts?: Options
): [T, Dispatch<SetStateAction<T>>] {
  const serialize = useCallback((v: T) => v, []);
  const deserialize = useCallback((v: string) => v as T, []);
  return useGenericQueryString(key, initial as T, serialize, deserialize, opts);
}
export function useOptionalStringQueryString<T extends StringBrand | string = string>(
  key: string,
  initial?: T | undefined,
  opts?: Options
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const serialize = useCallback((v: T) => v, []);
  const deserialize = useCallback((v: string) => v as T, []);
  return useGenericOptionalQueryString(key, initial, serialize, deserialize, opts);
}

export function useNumberQueryString<T extends NumberBrand | number = number>(
  key: string,
  initial: number,
  opts?: Options
): [T, Dispatch<SetStateAction<T>>] {
  const deserialize = useCallback((v: string) => asNumber<T>(v, initial), [initial]);
  return useGenericQueryString(key, initial as T, String, deserialize, opts);
}
export function useOptionalNumberQueryString<T extends NumberBrand | number = number>(
  key: string,
  initial?: T | undefined,
  opts?: Options
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const deserialize = useCallback((v: string) => asNumber<T>(v, initial as T), [initial]);
  return useGenericOptionalQueryString(key, initial, String, deserialize, opts);
}

export function useStringArrayQueryString<T extends StringBrand | string = string>(
  key: string,
  initial: string[],
  opts?: Options
): [T[], Dispatch<SetStateAction<T[]>>] {
  const serialize = useCallback((arr: T[]) => arr.join(','), []);
  const deserialize = useCallback((v: string) => (v.length === 0 ? [] : v.split(',')) as T[], []);
  return useGenericQueryString(key, initial as T[], serialize, deserialize, opts);
}

export function useDateQueryString(
  key: string,
  initial: Date,
  opts?: Options
): [Date, Dispatch<SetStateAction<Date>>] {
  const serialize = useCallback((v: Date) => String(v.getTime()), []);
  const deserialize = useCallback((v: string) => asDate(v, initial), [initial]);
  return useGenericQueryString(key, initial, serialize, deserialize, opts);
}

export function useBooleanQueryString(
  key: string,
  initial: boolean,
  opts?: Options
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const serialize = useCallback((v: boolean) => (v ? '1' : '0'), []);
  const deserialize = useCallback((v: string) => v.length > 0 && v !== '0', []);
  return useGenericQueryString(key, initial, serialize, deserialize, opts);
}

export function useOptionalBooleanQueryString(
  key: string,
  initial?: boolean | undefined,
  opts?: Options
): [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>] {
  const serialize = useCallback((v: boolean) => (v ? '1' : '0'), []);
  const deserialize = useCallback((v: string) => v.length > 0 && v !== '0', []);
  return useGenericOptionalQueryString(key, initial, serialize, deserialize, opts);
}

////////////////

function useGenericQueryString<T>(
  key: string,
  initialValue: T,
  serializer: (val: T) => string,
  deserializer: (val: string) => T,
  opts?: Options
): [T, Dispatch<SetStateAction<T>>] {
  return useGenericOptionalQueryString(key, initialValue, serializer, deserializer, opts) as [
    T,
    Dispatch<SetStateAction<T>>,
  ];
}

function useGenericOptionalQueryString<T>(
  key: string,
  initialValue: T | undefined,
  serializer: (val: T) => string,
  deserializer: (val: string) => T,
  opts?: Options
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const cachedOpts = useMemoCompare(() => opts);

  const {initialUrl} = useSsrContext();
  const current = getQueryString(initialUrl, key);
  const [state, setState] = useState<T | undefined>(() =>
    current === undefined ? initialValue : deserializer(current)
  );
  const stateRef = useRef<T | undefined>(state);

  useEffect(() => {
    const current = getQueryString(initialUrl, key);
    if (current === undefined) {
      const serializedInitialValue =
        initialValue === undefined ? undefined : serializer(initialValue);
      setQueryString(key, serializedInitialValue, {...cachedOpts, noHistory: true});
    }
  }, [initialValue, key, serializer, initialUrl, cachedOpts, deserializer]);

  const setParam = useCallback(
    (setter: T | undefined | ((current: T | undefined) => T | undefined)) => {
      const stringVal = getQueryString(initialUrl, key);
      const newVal =
        typeof setter === 'function'
          ? (setter as (current: T | undefined) => T | undefined)(
              stringVal === undefined ? initialValue : deserializer(stringVal)
            )
          : setter;
      setQueryString(key, newVal === undefined ? undefined : serializer(newVal), cachedOpts);
      setState(newVal);
      stateRef.current = newVal;
    },
    [deserializer, initialValue, key, serializer, initialUrl, cachedOpts]
  );

  useEffect(() => {
    return addHistoryStateListener((): void => {
      const current = getQueryString(document.location.href, key);
      const deserialized = current === undefined ? undefined : deserializer(current);
      if (deserialized !== stateRef.current) {
        setState(deserialized);
        stateRef.current = deserialized;
      }
    });
  }, [deserializer, key, stateRef]);

  return [state, setParam];
}

function setQueryString(key: string, value: string | undefined, opts: Options | undefined): void {
  if (!IS_BROWSER) {
    return;
  }
  const {override, noHistory} = opts ?? {};
  const {protocol, host, pathname, search} = window.location;
  const qs = new URLSearchParams(override ? '' : search);

  if (value === undefined) {
    qs.delete(key);
  } else {
    qs.set(key, value);
  }
  const qss = qs.toString();
  const newUrl = `${protocol}//${host}${pathname}${qss.length === 0 ? '' : `?${qss}`}`;
  if (noHistory) {
    replaceHistoryState(newUrl);
  } else {
    pushHistoryState(newUrl);
  }
}

function getQueryString(initialUrl: string, key: string): string | undefined {
  return (
    new URLSearchParams(
      IS_BROWSER ? window.location.search : `?${splitOnce(initialUrl, '?')[1] ?? ''}`
    ).get(key) ?? undefined
  );
}

export function useHasQueryStringOnLoad(): boolean {
  const {initialUrl} = useSsrContext();
  return (
    new URLSearchParams(
      IS_BROWSER ? window.location.search : `?${splitOnce(initialUrl, '?')[1] ?? ''}`
    ).toString().length > 0
  );
}

export function deleteQueryStrings(keys: string[]): void {
  if (!IS_BROWSER) {
    return;
  }
  const {protocol, host, pathname, search} = window.location;
  const qs = new URLSearchParams(search);
  for (const key of keys) {
    qs.delete(key);
  }
  const qss = qs.toString();
  const newUrl = `${protocol}//${host}${pathname}${qss.length === 0 ? '' : `?${qss}`}`;
  window.history.pushState({path: newUrl}, '', newUrl);
}
