import {FC, useCallback, useRef, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockModeAutomatic,
  HoobiizStockModeType,
  HoobiizStockWeeklyTemplateId,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {Checkbox} from '@shared-frontend/components/core/checkbox';

import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {
  HoobiizTicketWeeklySchedule,
  HoobiizTicketWeeklyScheduleInfo,
  mergeTicketInfoAndInitialData,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_weekly_schedule';
import {
  FormBlockAuto,
  FormBlockFull,
  FormLabel,
  FormWrapper,
} from '@src/components/admin/form/form_fragments';

export interface FixedAndAutomaticTicket {
  id?: HoobiizStockWeeklyTemplateId;
  template?: {
    weeklyScheduleInfo: HoobiizTicketWeeklyScheduleInfo;
    mode: HoobiizStockModeAutomatic;
    terms: string;
  };
}

interface ActivityStockFixedAndAutomaticFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: FixedAndAutomaticTicket[];
  onChange: (tickets: FixedAndAutomaticTicket[] | undefined) => void;
}

export const ActivityStockFixedAndAutomaticForm: FC<
  ActivityStockFixedAndAutomaticFormProps
> = props => {
  const {initialData, vendorHours, onChange} = props;
  const [terms, setTerms] = useState<string>(initialData?.[0]?.template?.terms ?? '');

  const [sendEmail, setSendEmail] = useState(initialData?.[0]?.template?.mode.sendEmail ?? false);

  const initialDataRef = useRef(initialData);

  const handleTicketsChange = useCallback(
    (tickets: HoobiizTicketWeeklyScheduleInfo[] | undefined) => {
      if (tickets === undefined) {
        return onChange(undefined);
      }
      const newTickets = mergeTicketInfoAndInitialData(
        initialDataRef.current,
        tickets,
        {
          type: HoobiizStockModeType.Automatic,
          sendEmail,
        },
        terms
      );
      onChange(newTickets);
    },
    [onChange, sendEmail, terms]
  );

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <FormBlockAuto>
        <FormLabel $noMargin>EMAIL</FormLabel>
        <Checkbox checked={sendEmail} syncState={setSendEmail}>
          Envoyer un email au partenaire après chaque achat.
        </Checkbox>
      </FormBlockAuto>
      <FormBlockFull>
        <HoobiizTicketWeeklySchedule
          initialData={
            initialData
              ? removeUndefined(initialData.map(d => d.template?.weeklyScheduleInfo))
              : undefined
          }
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      </FormBlockFull>
    </FormWrapper>
  );
};
ActivityStockFixedAndAutomaticForm.displayName = 'ActivityStockFixedAndAutomaticForm';
