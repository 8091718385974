import {useMemo} from 'react';

import {UserSessionItem} from '@shared/api/definitions/frontend_shared_api';
import {FrontendUserDataContentType} from '@shared/dynamo_model';
import {DataStoreApi} from '@shared/frontends/data_store_api';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {createDataStore} from '@shared-frontend/lib/data_store';
import {notifyError} from '@shared-frontend/lib/notification';
import {getWindow} from '@shared-frontend/window';

type Force<T> = T extends undefined ? never : T;
type UserSession<T extends FrontendUserDataContentType | undefined = undefined> = Omit<
  UserSessionItem,
  'userData'
> &
  (T extends undefined ? {} : Force<UserSessionItem['userData']> & {type: T});

export function createTypedSessionDataStore<T extends FrontendUserDataContentType | undefined>(
  type: T
): DataStoreApi<UserSession<T> | undefined, UserSessionItem | undefined> {
  let setSessionCalled = false;
  const sessionStore = createDataStore<UserSession<T> | undefined>();

  function convertSession(item: UserSessionItem | undefined): UserSession<T> | undefined {
    if (!item) {
      return undefined;
    }
    if (type !== undefined && item.userData?.type !== type) {
      notifyError(new Error('Invalid session'));
      return undefined;
    }
    const {userData = {}, ...sessionInfo} = item;
    return {...sessionInfo, ...userData} as UserSession<T>;
  }

  function useSession(): UserSession<T> | undefined {
    const {initialSession} = useSsrContext();
    const convertedInitialSession = useMemo(() => convertSession(initialSession), [initialSession]);
    const storeSession = sessionStore.useData();
    return setSessionCalled ? storeSession : convertedInitialSession;
  }

  const setSessionInternal = (newSession: UserSessionItem | undefined): void => {
    setSessionCalled = true;
    sessionStore.setData(convertSession(newSession));
  };

  const updateSession = (
    fn: (session: UserSession<T> | undefined) => UserSessionItem | undefined
  ): void => {
    setSessionInternal(fn(sessionStore.getData()));
  };

  const getSession = (): UserSession<T> | undefined => {
    return setSessionCalled ? sessionStore.getData() : convertSession(getWindow()?.initialSession);
  };

  return {
    getData: getSession,
    setData: setSessionInternal,
    useData: useSession,
    updateData: updateSession,
    addChangeListener: sessionStore.addChangeListener,
  };
}

export const sessionDataStore = createTypedSessionDataStore(undefined);
export const getSession = sessionDataStore.getData;
export const setSession = sessionDataStore.setData;
export const useSession = sessionDataStore.useData;
