import {FC, useCallback, useRef} from 'react';
import styled from 'styled-components';

import {textColor} from '@shared/frontends/frontend_theme_utils';
import {useTheme} from '@shared/frontends/theme_context';

import {NavButton, NavLink} from '@shared-frontend/components/core/button';
import {EmptyFragment, NULL_REF} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

interface AdminBarProps {
  mode?: 'bar' | 'button';
  adminPath?: string;
}

export const AdminBar: FC<AdminBarProps> = props => {
  const {mode = 'bar', adminPath = ''} = props;
  const session = useSession();
  const {
    main: {accentColor, accentTextColor},
  } = useTheme();

  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);
  const handleWrapperClick = useCallback(() => {
    if (!wrapperRef.current) {
      return;
    }
    const nextChild = wrapperRef.current.nextSibling;
    if (!nextChild) {
      return;
    }
    if (nextChild instanceof HTMLElement) {
      nextChild.scrollIntoView({block: 'start'});
    }
  }, []);

  if (!session?.isAdmin) {
    return EmptyFragment;
  }

  const path = `/admin${adminPath}`;
  if (mode === 'bar') {
    return (
      <WrapperBar
        ref={wrapperRef}
        onClick={handleWrapperClick}
        $backgroundColor={accentColor}
        $color={accentTextColor}
      >
        <span>{`Administrateur ${session.email}`}</span>
        <NavLink to={path} overrides={{...textColor(accentTextColor)}}>
          Aller à l'admin
        </NavLink>
      </WrapperBar>
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (mode === 'button') {
    return (
      <WrapperButton>
        <NavButton to={path} overrides={{...textColor(accentTextColor)}}>
          Aller à l'admin
        </NavButton>
      </WrapperButton>
    );
  }
  return EmptyFragment;
};
AdminBar.displayName = 'AdminBar';

const WrapperBar = styled.div<{$backgroundColor: string; $color: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  padding: 16px;
  font-size: 14px;
  background-color: ${p => p.$backgroundColor};
  color: ${p => p.$color};
`;

const WrapperButton = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
`;
