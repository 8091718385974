import {FC, useEffect, useState} from 'react';

import {HoobiizActivityAddressManual, HoobiizActivityAddressType} from '@shared/dynamo_model';

import {AddressInput} from '@src/components/admin/form/address_input';

interface ActivityAddressManualFormProps {
  initialData?: HoobiizActivityAddressManual;
  onChange: (newVal: HoobiizActivityAddressManual | undefined) => void;
}

export const ActivityAddressManualForm: FC<ActivityAddressManualFormProps> = props => {
  const {initialData, onChange} = props;

  const [address, setAddress] = useState(initialData?.address);

  useEffect(() => {
    if (address) {
      onChange({type: HoobiizActivityAddressType.Manual, address});
    } else {
      onChange(undefined);
    }
  }, [address, onChange]);

  return <AddressInput address={address} syncState={setAddress} />;
};
ActivityAddressManualForm.displayName = 'ActivityAddressManualForm';
