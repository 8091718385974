import styled from 'styled-components';

import {UnthemedButton} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Custom} from '@shared-frontend/lib/react';

import {HoobiizIconSvg} from '@src/components/ui/hoobiiz_icon_svg';

interface HoobiizReservationCtaProps {}

export const HoobiizReservationCta: Custom<HoobiizReservationCtaProps, 'button'> = props => {
  const {...rest} = props;

  return (
    <Wrapper {...rest}>
      <Icon height={32} />
      Réserver
      <SvgIcon name="TriangleDown" color="#ffffff" size={12} />
    </Wrapper>
  );
};

HoobiizReservationCta.displayName = 'HoobiizReservationCta';

const Wrapper = styled(UnthemedButton)`
  display: flex;
  align-items: center;
  gap: 12px;
  background: linear-gradient(197deg, #b49a62 0%, #b49a62 50%, #e0c68e 100%);
  color: white;
  font-weight: 500;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 14px;

  transition: box-shadow 120ms ease-in-out;
  &:hover {
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
  }
`;

const Icon = styled(HoobiizIconSvg)`
  margin-top: 4px;
`;
