/* eslint-disable @typescript-eslint/no-magic-numbers */
const ONE_SECOND_MS = 1000;
const ONE_MINUTE_MS = ONE_SECOND_MS * 60;
const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
const ONE_DAY_MS = ONE_HOUR_MS * 24;
const ONE_MONTH_MS = ONE_DAY_MS * 30;
const ONE_YEAR_MS = ONE_DAY_MS * 365;
/* eslint-enable @typescript-eslint/no-magic-numbers */

export type TimeFormatLang = 'en-US' | 'fr-FR';

export type TimeSpanComponentName =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'months'
  | 'years';

interface TimeComponentMetadata {
  ms: number;
}

interface TimeComponentFormat {
  short: string;
  long: string;
  longPlural: string;
}

export interface TimeSpanComponent {
  metadata: TimeComponentMetadata;
  format: Record<TimeFormatLang, TimeComponentFormat>;
}
const MILLISECONDS_TIME_COMPONENT = {
  metadata: {ms: 1},
  format: {
    'en-US': {short: 'ms', long: 'millisecond', longPlural: 'milliseconds'},
    'fr-FR': {short: 'ms', long: 'milliseconde', longPlural: 'millisecondes'},
  },
};
const SECONDS_TIME_COMPONENT = {
  metadata: {ms: ONE_SECOND_MS},
  format: {
    'en-US': {short: 's', long: 'second', longPlural: 'seconds'},
    'fr-FR': {short: 's', long: 'seconde', longPlural: 'secondes'},
  },
};
const MINUTES_TIME_COMPONENT = {
  metadata: {ms: ONE_MINUTE_MS},
  format: {
    'en-US': {short: 'min', long: 'minute', longPlural: 'minutes'},
    'fr-FR': {short: 'min', long: 'minute', longPlural: 'minutes'},
  },
};
const HOURS_TIME_COMPONENT = {
  metadata: {ms: ONE_HOUR_MS},
  format: {
    'en-US': {short: 'h', long: 'hour', longPlural: 'hours'},
    'fr-FR': {short: 'h', long: 'heure', longPlural: 'heures'},
  },
};
const DAYS_TIME_COMPONENT = {
  metadata: {ms: ONE_DAY_MS},
  format: {
    'en-US': {short: 'd', long: 'day', longPlural: 'days'},
    'fr-FR': {short: 'j', long: 'jour', longPlural: 'jours'},
  },
};
const MONTHS_TIME_COMPONENT = {
  metadata: {ms: ONE_MONTH_MS},
  format: {
    'en-US': {short: 'mo', long: 'month', longPlural: 'months'},
    'fr-FR': {short: 'mo', long: 'mois', longPlural: 'mois'},
  },
};
const YEARS_TIME_COMPONENT = {
  metadata: {ms: ONE_YEAR_MS},
  format: {
    'en-US': {short: 'y', long: 'year', longPlural: 'years'},
    'fr-FR': {short: 'a', long: 'an', longPlural: 'ans'},
  },
};

export const AllTimeComponents = new Map<TimeSpanComponentName, TimeSpanComponent>([
  ['milliseconds', MILLISECONDS_TIME_COMPONENT],
  ['seconds', SECONDS_TIME_COMPONENT],
  ['minutes', MINUTES_TIME_COMPONENT],
  ['hours', HOURS_TIME_COMPONENT],
  ['days', DAYS_TIME_COMPONENT],
  ['months', MONTHS_TIME_COMPONENT],
  ['years', YEARS_TIME_COMPONENT],
]);

export const AllTimeComponentsBySize = [...AllTimeComponents.values()].sort(
  (a, b) => b.metadata.ms - a.metadata.ms
);
