import {
  AddressLookupResult,
  HoobiizActivityAddress,
  HoobiizActivityAddressType,
  HoobiizVendorLocation,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

export function getActivityLocations(opts: {
  activityLabel?: string;
  vendorName?: string;
  activityAddress?: HoobiizActivityAddress;
  vendorLocations?: HoobiizVendorLocation[];
}): Partial<HoobiizVendorLocation>[] {
  const {activityLabel, vendorName, activityAddress, vendorLocations} = opts;
  const vendorAddress = vendorLocations?.[0];

  const name = vendorName ?? activityLabel ?? '';
  const hideName = activityLabel === undefined ? vendorAddress?.hideName ?? false : false;

  let address: AddressLookupResult | undefined;
  let hideAddress: boolean | undefined;
  let phone: string | undefined;
  let hidePhone: boolean | undefined;
  if (activityAddress?.type === HoobiizActivityAddressType.Vendor) {
    return vendorLocations ?? [];
  } else if (activityAddress?.type === HoobiizActivityAddressType.Manual) {
    address = activityAddress.address;
  }

  return [{name, hideName, address, hideAddress, phone, hidePhone}];
}

export function addressToLines(address: AddressLookupResult): {line1: string; line2: string} {
  const {streetNumber, route, postalCode, city, country} = address.addressDetails;
  const chunk1 = removeUndefined([streetNumber, route]).join(' ');
  const chunk2 = removeUndefined([postalCode, city]).join(' ');
  const chunk3 = removeUndefined([country]).join(' ');
  return {line1: chunk1, line2: [chunk2, chunk3].filter(s => s.length > 0).join(', ')};
}
