import {FC, useCallback, useState} from 'react';

import {
  HoobiizStockId,
  HoobiizStockModePregenerated,
  HoobiizStockReservationFixed,
  HoobiizStockReservationType,
} from '@shared/dynamo_model';
import {hoobiizTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';

import {
  HoobiizStockModePregeneratedWithPeriodForm,
  TicketPregeneratedWithPeriod,
} from '@src/components/admin/activity_stock/hoobiiz_stock_mode_pregenerated_with_period_form';
import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {MaybeTicket} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {HoobiizTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_multi_form';
import {FormBlockFull, FormWrapper} from '@src/components/admin/form/form_fragments';

export interface FixedAndPregeneratedTicket {
  id?: HoobiizStockId;
  ticket: {
    reservation: HoobiizStockReservationFixed;
    mode: HoobiizStockModePregenerated;
    quantity: number;
    ticketInfo: MaybeTicket[];
    terms: string;
  };
}

interface ActivityStockFixedAndPregeneratedFormProps {
  initial?: FixedAndPregeneratedTicket[];
  onChange: (tickets: FixedAndPregeneratedTicket[]) => void;
}

export const ActivityStockFixedAndPregeneratedForm: FC<
  ActivityStockFixedAndPregeneratedFormProps
> = props => {
  const {initial, onChange} = props;

  const [ticketInfo, setTicketInfo] = useState<MaybeTicket[]>(
    initial?.[0]?.ticket.ticketInfo ?? [{id: hoobiizTicketInfoId()}]
  );
  const [terms, setTerms] = useState<string>(initial?.[0]?.ticket.terms ?? '');

  const handleTicketsChange = useCallback(
    (tickets: TicketPregeneratedWithPeriod[]) => {
      onChange(
        tickets.map(t => ({
          id: t.id,
          ticket: {
            mode: t.mode,
            reservation: {type: HoobiizStockReservationType.Fixed, period: t.period},
            quantity: 1,
            ticketInfo,
            terms,
          },
        }))
      );
    },
    [onChange, terms, ticketInfo]
  );

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <HoobiizTicketInfoMultiForm initialData={ticketInfo} onChange={setTicketInfo} />
      <FormBlockFull>
        <HoobiizStockModePregeneratedWithPeriodForm
          initialData={initial?.map(t => ({
            id: t.id,
            mode: t.ticket.mode,
            period: t.ticket.reservation.period,
          }))}
          onChange={handleTicketsChange}
        />
      </FormBlockFull>
    </FormWrapper>
  );
};
ActivityStockFixedAndPregeneratedForm.displayName = 'ActivityStockFixedAndPregeneratedForm';
