import {HoobiizStockModeType} from '@shared/dynamo_model';

export const HoobiizStockModeTypeLabels: Record<
  HoobiizStockModeType,
  {label: string; subLabel?: string}
> = {
  [HoobiizStockModeType.Pregenerated]: {
    label: `Stock PDF / Code`,
    subLabel: `ticket renseigné à l'avance`,
  },
  [HoobiizStockModeType.Automatic]: {
    label: `Automatique`,
    subLabel: `ticket généré automatiquement après l'achat`,
  },
  [HoobiizStockModeType.VendorConfirm]: {
    label: `Confirmation partenaire`,
    subLabel: `ticket généré automatiquement après confirmation du partenaire`,
  },
  [HoobiizStockModeType.AdminConfirm]: {
    label: `Manuel`,
    subLabel: `ticket validé et renseigné par un admin après l'achat`,
  },
};
