import {FC, JSX, useMemo} from 'react';
import {styled} from 'styled-components';

import {Markdown} from '@shared/dynamo_model';
import {MarkdownElement, parseMarkdown} from '@shared/lib/hoobiiz/markdown';

import {EmptyFragment} from '@shared-frontend/lib/react';

interface MarkdownRendererProps {
  markdown: Markdown;
}

function renderElement(element: MarkdownElement, key?: string | number): JSX.Element | string {
  if (element.type === 'title1') {
    return <Title1 key={key}>{element.value.map(renderElement)}</Title1>;
  } else if (element.type === 'title2') {
    return <Title2 key={key}>{element.value.map(renderElement)}</Title2>;
  } else if (element.type === 'bold') {
    return <BoldText key={key}>{element.value.map(renderElement)}</BoldText>;
  } else if (element.type === 'text') {
    return element.value;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (element.type === 'separator') {
    return <Separator key={key} />;
  }
  return EmptyFragment;
}

export const MarkdownRenderer: FC<MarkdownRendererProps> = props => {
  const {markdown} = props;
  const markdownElements = useMemo(() => parseMarkdown(markdown), [markdown]);

  return (
    <Wrapper>
      {markdownElements.map((elements, i) => {
        const [firstElement] = elements;
        if (firstElement && firstElement.type !== 'text' && elements.length === 1) {
          return renderElement(firstElement, i);
        }
        return (
          <Line key={i} data-key={i}>
            {elements.map(renderElement)}
          </Line>
        );
      })}
    </Wrapper>
  );
};
MarkdownRenderer.displayName = 'MarkdownRenderer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 100%;
`;

const Line = styled.div`
  display: inline-block;
  min-height: 24px;
  line-height: 24px;
`;

const Title1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 6px;
`;
const Title2 = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 29px;
  margin-bottom: 4px;
`;
const BoldText = styled.span`
  font-weight: bold;
`;
const Separator = styled.div`
  height: 1px;
  background-color: #00000033;
`;
