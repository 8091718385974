import {HoobiizStockReservation, HoobiizStockReservationType} from '@shared/dynamo_model';
import {SanitizedItem} from '@shared/model/search_tables';

export type FixedStockItem = SanitizedItem<'HoobiizStock'> & {
  reservation: HoobiizStockReservation & {type: HoobiizStockReservationType.Fixed};
};
export function isFixedStockItem(item: SanitizedItem<'HoobiizStock'>): item is FixedStockItem {
  return item.reservation.type === HoobiizStockReservationType.Fixed;
}
export function isFixedStockItemWithOffer(item: {
  stock: SanitizedItem<'HoobiizStock'>;
  offers: SanitizedItem<'HoobiizOffer'>[];
}): item is {
  stock: FixedStockItem;
  offers: SanitizedItem<'HoobiizOffer'>[];
} {
  return item.stock.reservation.type === HoobiizStockReservationType.Fixed;
}

export type FlexibleStockItem = SanitizedItem<'HoobiizStock'> & {
  reservation: HoobiizStockReservation & {type: HoobiizStockReservationType.Flexible};
};
export function isFlexibleStockItem(
  item: SanitizedItem<'HoobiizStock'>
): item is FlexibleStockItem {
  return item.reservation.type === HoobiizStockReservationType.Flexible;
}
export function isFlexibleStockItemWithOffer(item: {
  stock: SanitizedItem<'HoobiizStock'>;
  offers: SanitizedItem<'HoobiizOffer'>[];
}): item is {
  stock: FlexibleStockItem;
  offers: SanitizedItem<'HoobiizOffer'>[];
} {
  return item.stock.reservation.type === HoobiizStockReservationType.Flexible;
}
