import {FC, Fragment, MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {hoobiizTicketInfoOptionId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {isNull} from '@shared/lib/type_utils';

import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {
  HoobiizTicketInfoOptionsForm,
  MaybeTicketOption,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_options_form';
import {FormBlockFull} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {Colors} from '@src/components/core/theme_base';

interface HoobiizTicketInfoOptionsMultiFormProps {
  initialData?: MaybeTicketOption[];
  onChange?: (newVal: MaybeTicketOption[]) => void;
}

export const HoobiizTicketInfoOptionsMultiForm: FC<
  HoobiizTicketInfoOptionsMultiFormProps
> = props => {
  const {initialData, onChange} = props;

  const [ticketInfoOptions, setTicketInfoOptions] = useState<MaybeTicketOption[]>(
    initialData ?? [{id: hoobiizTicketInfoOptionId()}]
  );

  useEffect(() => {
    onChange?.(ticketInfoOptions);
  }, [onChange, ticketInfoOptions]);

  const handleTicketInfoOptionChange = useCallback(
    (newVal: MaybeTicketOption, el: HTMLDivElement) => {
      const idStr = el.getAttribute('data-id');
      if (isNull(idStr)) {
        return;
      }
      setTicketInfoOptions(ticketInfoOptions =>
        ticketInfoOptions.map(ti => (ti.id === idStr ? newVal : ti))
      );
    },
    []
  );

  const handleAddClick = useCallback(() => {
    setTicketInfoOptions(ticketInfoOptions => [
      ...ticketInfoOptions,
      {id: hoobiizTicketInfoOptionId()},
    ]);
  }, []);

  const handleDeleteClick = useCallback<MouseEventHandler>(evt => {
    const idStr = evt.currentTarget.getAttribute('data-id');
    if (isNull(idStr)) {
      return;
    }
    setTicketInfoOptions(ticketInfoOptions => ticketInfoOptions.filter(ti => ti.id !== idStr));
  }, []);

  const handleSwap = useCallback<MouseEventHandler>(evt => {
    const id1Str = evt.currentTarget.getAttribute('data-id1');
    const id2Str = evt.currentTarget.getAttribute('data-id2');
    if (isNull(id1Str) || isNull(id2Str)) {
      return;
    }
    setTicketInfoOptions(ticketInfoOptions => {
      const ticket1 = ticketInfoOptions.find(t => t.id === id1Str);
      const ticket2 = ticketInfoOptions.find(t => t.id === id2Str);
      if (!ticket1 || !ticket2) {
        return ticketInfoOptions;
      }
      return ticketInfoOptions.map(ti =>
        ti.id === id1Str ? ticket2 : ti.id === id2Str ? ticket1 : ti
      );
    });
  }, []);

  return (
    <FormBlockFull $gap={16}>
      {ticketInfoOptions.length > 0 ? (
        <TicketOptionForms>
          {ticketInfoOptions.map((ticketInfoOption, i) => {
            const nextOption = ticketInfoOptions[i + 1];
            return (
              <Fragment key={ticketInfoOption.id}>
                <FormSection title={`Option #${i + 1}`}>
                  <HoobiizTicketInfoOptionsForm
                    data-id={ticketInfoOption.id}
                    initialData={ticketInfoOption}
                    onChange={handleTicketInfoOptionChange}
                  />
                  <DeleteOptionButton data-id={ticketInfoOption.id} onClick={handleDeleteClick}>
                    <SvgIcon name="Trash" color={Colors.RedLight} size={14} />
                    Supprimer
                  </DeleteOptionButton>
                </FormSection>
                {nextOption === undefined ? (
                  EmptyFragment
                ) : (
                  <SwapButtonWrapper>
                    <SwapButton
                      data-id1={ticketInfoOption.id}
                      data-id2={nextOption.id}
                      onClick={handleSwap}
                    >
                      <SvgIcon name="Swap" size={20} color={Colors.Gold} />
                    </SwapButton>
                  </SwapButtonWrapper>
                )}
              </Fragment>
            );
          })}
        </TicketOptionForms>
      ) : (
        EmptyFragment
      )}
      <AddOptionButton onClick={handleAddClick}>
        <PlusIconWrapper>
          <SvgIcon name="Plus" color="#ffffff" size={8} />
        </PlusIconWrapper>
        Ajouter une option
      </AddOptionButton>
    </FormBlockFull>
  );
};
HoobiizTicketInfoOptionsMultiForm.displayName = 'HoobiizTicketInfoOptionsMultiForm';

const TicketOptionForms = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwapButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const SwapButton = styled(ButtonAsLink)`
  height: 30px;
  width: 35px;
  margin: -2px 0;
  border: solid 2px ${Colors.LightGold};
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  &:hover {
    background-color: ${Colors.LightGold};
    border-color: ${Colors.LightGold};
    svg {
      fill: ${Colors.White};
    }
  }
`;

const DeleteOptionButton = styled(ButtonAsLink)`
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  top: -2px;
  right: -2px;
  color: ${Colors.RedLight};
  font-size: 15px;
  background: transparent;
  border: solid 2px transparent;
  border-radius: 4px;
  padding: 7px 10px;
  transition: none;
  &:hover {
    color: ${Colors.Red};
    background: #fef4f4;
    border: solid 2px ${Colors.Red};
    & svg {
      fill: ${Colors.Red};
    }
  }
`;

const AddOptionButton = styled(ButtonAsLink)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const PlusIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  background-color: ${Colors.Gold};
  display: flex;
  align-items: center;
  justify-content: center;
`;
