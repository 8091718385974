import {FC} from 'react';
import {useParams} from 'react-router';
import {styled} from 'styled-components';

import {HoobiizActivityId} from '@shared/dynamo_model';

import {TabbedPage} from '@shared-frontend/components/core/tabbed_page';

import {ActivityUpdatePage} from '@src/components/admin/activity/activity_update_page';
import {HoobiizActivityOfferPage} from '@src/components/admin/activity_offer/hoobiiz_activity_offer_page';
import {ActivityExpertTicketStockPage} from '@src/components/admin/activity_stock/activity_expert_ticket_stock_page';
import {ActivityStockPage} from '@src/components/admin/activity_stock/activity_stock_page';
import {HoobiizAdminStockCalendar} from '@src/components/admin/activity_stock/hoobiiz_admin_stock_calendar';
import {PageWrapper} from '@src/components/admin/page_fragment';

export const ActivityPage: FC = () => {
  const {id = ''} = useParams();
  const activityId = id as HoobiizActivityId;
  return (
    <Wrapper>
      <TabbedPage
        tabs={[
          {name: 'info', label: 'Info', page: <ActivityUpdatePage activityId={activityId} />},
          {name: 'stock', label: 'Stocks', page: <ActivityStockPage activityId={activityId} />},
          {
            name: 'calendar',
            label: 'Calendrier',
            page: <HoobiizAdminStockCalendar activityId={activityId} />,
          },
          {
            name: 'expert-ticket-stock',
            label: 'ExperTicket',
            page: <ActivityExpertTicketStockPage activityId={activityId} />,
          },
          {
            name: 'offer',
            label: 'Promotions',
            page: <HoobiizActivityOfferPage activityId={activityId} />,
          },
        ]}
      />
    </Wrapper>
  );
};
ActivityPage.displayName = 'ActivityPage';

export const Wrapper = styled(PageWrapper)`
  width: 100%;
  height: 100%;
`;
