import {setupFrontend} from '@shared-frontend/setup_frontend';

import {App} from '@src/components/app';
import {theme} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';

setupFrontend({
  appComponent: App,
  theme,
  notifications: {
    successColor: Colors.Gold,
    duration: 4000,
  },
});
