import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizUserGroupId, HoobiizUserProfile} from '@shared/dynamo_model';
import {ALL_HOOBIIZ_PERMISSIONS} from '@shared/lib/hoobiiz/hoobiiz_groups_and_users_permissions';
import {keys} from '@shared/lib/map_utils';

import {AdminUserAndGroupUserProfilePermissionForm} from '@src/components/admin/user_and_group/admin_user_and_group_user_profile_permission_form';

export type HoobiizUserProfilePermissions = HoobiizUserProfile['permissions'];

interface AdminUserAndGroupUserProfilePermissionsFormProps {
  groupId: HoobiizUserGroupId;
}

export const AdminUserAndGroupUserProfilePermissionsForm: FC<
  AdminUserAndGroupUserProfilePermissionsFormProps
> = props => {
  const {groupId} = props;

  return (
    <Wrapper>
      {keys(ALL_HOOBIIZ_PERMISSIONS).map(name => (
        <AdminUserAndGroupUserProfilePermissionForm key={name} groupId={groupId} name={name} />
      ))}
    </Wrapper>
  );
};

AdminUserAndGroupUserProfilePermissionsForm.displayName =
  'AdminUserAndGroupUserProfilePermissionsForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
