import {FC, MouseEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType, SearchApiQueryType} from '@shared/api/definitions/search_api';
import {HoobiizCat1Id, HoobiizCat1Item} from '@shared/dynamo_model';
import {isNull} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {Category1Form} from '@src/components/admin/category/category1_form';
import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {AdminNavLink} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
import {HoobiizCat2View} from '@src/components/ui/hoobiiz_cat2_view';

export const Category1UpdatePage: FC = () => {
  const navigate = useNavigate();
  const {cat1Id: cat1IdStr = ''} = useParams();
  const cat1Id = cat1IdStr as HoobiizCat1Id;

  const [cat1, setCat1] = useState<FullItem<'HoobiizCat1'> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/admin/search/get', {
      table: 'HoobiizCat1',
      id: cat1Id,
      mode: 'full',
    })
      .then(res => {
        const {item} = res as SearchApiGetType<'HoobiizCat1', 'full'>['res'];
        setCat1(item);
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            'Échec du chargement de la catégorie 1. Vous pouvez rafraichir la page pour réessayer',
        });
        setIsLoading(false);
      });
  }, [cat1Id]);

  const handleSubmit = useCallback(
    async (cat1Data: Omit<HoobiizData<HoobiizCat1Item>, 'order'>) => {
      const {cat1} = await apiCall(HoobiizApi, '/admin/update-cat1', {
        id: cat1Id,
        updates: cat1Data,
      });
      showSuccess(`Catégorie 1 "${cat1.name}" mise à jour`);
      navigate('/admin/cat1');
    },
    [navigate, cat1Id]
  );

  const handleDelete = useCallback(async () => {
    await apiCall(HoobiizApi, '/admin/delete-cat1', {id: cat1Id});
    showSuccess(`Catégorie 1 "${cat1?.name}" supprimé`);
    navigate('/admin/cat1');
  }, [navigate, cat1?.name, cat1Id]);

  const {data, refreshData} = useApiCall(HoobiizApi, '/admin/search/query', {
    table: 'HoobiizCat2',
    mode: 'full',
    gsi: {name: 'cat1', where: {type: 'equal', value: cat1Id}},
    sort: {name: 'order', params: undefined},
  });
  const res = data as SearchApiQueryType<'HoobiizCat2', 'full'>['res'] | undefined;
  const cat2 = res?.items.map(i => i.item);

  const handleSwapClick = useCallback(
    async (evt: MouseEvent<HTMLElement>) => {
      const iStr = evt.currentTarget.getAttribute('data-index');
      if (isNull(iStr)) {
        return;
      }
      const i = parseFloat(iStr);
      const firstCat = cat2?.[i];
      const secondCat = cat2?.[i + 1];
      if (!firstCat || !secondCat) {
        return;
      }
      await Promise.all([
        apiCall(HoobiizApi, '/admin/update-cat2', {
          id: firstCat.id,
          updates: {order: secondCat.order},
        }),
        apiCall(HoobiizApi, '/admin/update-cat2', {
          id: secondCat.id,
          updates: {order: firstCat.order},
        }),
      ]);
      await refreshData();
    },
    [cat2, refreshData]
  );

  if (!cat1 || isLoading) {
    return (
      <LoadingPage>
        Chargement du partenaire
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }
  return (
    <PageWrapper>
      <Title>
        Sous catégories
        <AdminNavLink to={`/admin/cat1/${cat1Id}/cat2/create`}>Ajouter</AdminNavLink>
      </Title>
      <Carousel>
        {cat2?.map((cat, i, arr) => (
          <CatWrapper key={cat.id}>
            <HoobiizCat2View
              to={`/admin/cat1/${cat1Id}/cat2/${cat.id}`}
              name={cat.name}
              media={{id: cat.mediaId, media: cat.media}}
            />
            {i < arr.length - 1 ? (
              <SwapButtonWrapper>
                <SwapButton
                  data-index={i}
                  onClickAsync={handleSwapClick}
                  overrides={{backgroundActive: '#00000000', backgroundHover: '#00000011'}}
                >
                  <SvgIcon name="Swap" color="#000000" size={20} />
                </SwapButton>
              </SwapButtonWrapper>
            ) : (
              EmptyFragment
            )}
          </CatWrapper>
        ))}
      </Carousel>
      <FormWrapper>
        <FormTitle>{`Modifier la catégorie 1 ${cat1.name}`}</FormTitle>
        <Category1Form
          initialData={cat1}
          submitButtonText="Modifier"
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </FormWrapper>
    </PageWrapper>
  );
};
Category1UpdatePage.displayName = 'Category1UpdatePage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-size: 24px;
`;

const Carousel = styled.div`
  display: flex;
  height: 100px;
  overflow-x: auto;
  gap: 32px;
  margin-bottom: 32px;
`;

const CatWrapper = styled.div`
  position: relative;
`;

const SwapButtonWrapper = styled.div`
  position: absolute;
  right: -32px;
  top: calc(50% - 16px);
  transform: rotate(90deg);
  z-index: 1;
`;

const SwapButton = styled(Button)`
  padding: 6px;
`;
