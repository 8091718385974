import {CSSProperties, FC, ReactNode} from 'react';
import {keyframes, styled} from 'styled-components';

import {Palette} from '@shared-frontend/ui';

interface ProgressBarProps {
  current: number;
  max: number;
  stripes?: boolean;
  threshold?: number;
  children?: ReactNode;
  width?: CSSProperties['width'];
}

export const ProgressBar: FC<ProgressBarProps> = ({
  current,
  max,
  stripes,
  threshold,
  children,
  width,
}) => {
  return (
    // eslint-disable-next-line react/forbid-component-props
    <Wrapper style={{width}} $stripes={stripes}>
      <Progress
        // eslint-disable-next-line react/forbid-component-props
        style={{
          width: max > 0 ? `${(100 * current) / max}%` : '0%',
          backgroundColor:
            threshold !== undefined && current < threshold
              ? Palette.Warning.Normal
              : Palette.Success.Normal,
        }}
      />
      <Text>{children}</Text>
    </Wrapper>
  );
};
ProgressBar.displayName = 'ProgressBar';

const translateX = keyframes`
  from  { background-position: 0 0; }
  to    { background-position: 3000px 0; }
  /* to    { background-position: 30px 0; } */
`;

const Wrapper = styled.div<{$stripes?: boolean}>`
  background-color: #9b9b9b;
  position: relative;
  border-radius: 3px;
  &:after {
    ${p =>
      p.$stripes &&
      `
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -3000px;
    background-image: repeating-linear-gradient(-45deg, transparent 0 15px, #ffffff22 15px 30px);
    `}
    animation: ${translateX} 60s infinite linear;
  }
`;

const Text = styled.div`
  color: white;
  padding: 3px;
  position: relative;
  text-align: center;
`;

const Progress = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width linear 100ms;
`;
