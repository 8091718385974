import {Markdown} from '@shared/dynamo_model';

interface MarkdownElementText {
  type: 'text';
  value: string;
}

interface MarkdownElementBoldText {
  type: 'bold';
  value: MarkdownElement[];
}

interface MarkdownElementTitle1 {
  type: 'title1';
  value: MarkdownElement[];
}

interface MarkdownElementTitle2 {
  type: 'title2';
  value: MarkdownElement[];
}

interface MarkdownElementSeparator {
  type: 'separator';
}

export type MarkdownElement =
  | MarkdownElementText
  | MarkdownElementBoldText
  | MarkdownElementTitle1
  | MarkdownElementTitle2
  | MarkdownElementSeparator;

function parseMarkdownLine(str: string): MarkdownElement[] {
  if (str.startsWith('# ')) {
    return [{type: 'title1', value: parseMarkdownLine(str.slice(2))}];
  } else if (str.startsWith('## ')) {
    return [{type: 'title2', value: parseMarkdownLine(str.slice(2))}];
  } else if (str === '---') {
    return [{type: 'separator'}];
  }
  const elements: MarkdownElement[] = [];
  let scannedText = '';
  // Loop through the characters until we find special chars
  for (let i = 0; i < str.length; i++) {
    const c1 = str[i];
    const c2 = str[i + 1];
    // "**<text>**"
    // Detect start
    if (c1 === '*' && c2 === '*') {
      let boldContent = '';
      for (let j = i + 2; j < str.length; j++) {
        const j1 = str[j];
        const j2 = str[j + 1];
        // Detect end
        if (j1 === '*' && j2 === '*') {
          if (scannedText.length > 0) {
            elements.push({type: 'text', value: scannedText});
          }
          elements.push({type: 'bold', value: parseMarkdownLine(boldContent)});
          const rest = str.slice(j + 2);
          if (rest.length > 0) {
            elements.push(...parseMarkdownLine(rest));
          }
          return elements;
        }
        boldContent += j1;
      }
    }
    scannedText += c1;
  }
  return [{type: 'text', value: scannedText}];
}

export function parseMarkdown(markdown: Markdown): MarkdownElement[][] {
  return markdown.split('\n').map(parseMarkdownLine);
}
