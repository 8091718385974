import {FC, Fragment, JSX} from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components';

import {AdminBar} from '@shared-frontend/components/admin_bar';
import {Modal} from '@shared-frontend/components/core/modal';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {Footer, FOOTER_HEIGHT} from '@src/components/core/footer';
import {GlobalStyle} from '@src/components/core/global_styles';
import {NAV_HEIGHT, Navigation} from '@src/components/core/navigation';
import {Sizes} from '@src/components/core/theme_base';

interface AppWrapperProps {
  center?: boolean;
  ignoreBars?: boolean;
  children: JSX.Element;
}

export const AppWrapper: FC<AppWrapperProps> = props => {
  const {center, ignoreBars, children} = props;
  const {pathname} = useLocation();
  const params = useParams();

  let adminPath = '';
  if (pathname.startsWith('/a/')) {
    const {id} = params;
    if (id !== undefined) {
      adminPath = `/activity/${id}`;
    }
  }
  if (pathname.startsWith('/v/')) {
    const {id} = params;
    if (id !== undefined) {
      adminPath = `/vendor/${id}`;
    }
  }

  return (
    <Fragment>
      <AdminBar adminPath={adminPath} />
      <Main>
        <GlobalStyle />
        <Wrapper>
          <Navigation />
          <Content $center={center} $ignoreBars={ignoreBars}>
            {children}
          </Content>
          <Footer />
        </Wrapper>
      </Main>
      <Modal />
    </Fragment>
  );
};
AppWrapper.displayName = 'AppWrapper';

const Main = styled.div``;

const Wrapper = styled.div`
  height: 100%;
`;

const Content = styled.div<{$center?: boolean; $ignoreBars?: boolean}>`
  margin: auto;
  max-width: ${Sizes.Content};
  min-height: ${({$ignoreBars}) =>
    $ignoreBars ? '100svh' : `calc(100svh - ${NAV_HEIGHT}px - ${FOOTER_HEIGHT}px)`};
  margin-top: ${({$ignoreBars}) => ($ignoreBars ? `-${NAV_HEIGHT}px` : undefined)};
  display: flex;
  align-items: ${({$center}) => ($center ? 'center' : 'baseline')};
  justify-content: center;
`;
