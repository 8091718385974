// Part of the theme that does not import react so we can use it in the backend
// with the file `frontend_theme_registry_no_react.ts`

import {FrontendUserAuthMethodType} from '@shared/dynamo_model';
import {IS_LOCALHOST_ENV, IS_PRODUCTION_ENV} from '@shared/env_constants';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {DeepPartial} from '@shared/lib/type_utils';

export const Colors = {
  White: '#ffffff',
  Black: '#141824',
  DarkGrey: '#31374a',
  Grey: '#656c7b',
  LightGrey: '#f5f7fa',
  LightGrey2: '#dee0e3',
  DarkGold2: '#7b6831',
  DarkGold: '#857549',
  Gold: '#a8945f',
  LightGold: '#c8b47c',
  Green: '#488023',
  LightGreen: '#edfbe1',
  RedDark: '#BB7777',
  Red: '#b90000',
  RedLight: '#884444',
};

export const FontWeight = {
  Regular400: 400,
  Medium500: 500,
  Bold700: 700,
  Black900: 900,
};

export const Sizes = {
  Content: '1216px',
};

export const baseTheme = {
  main: {
    backgroundColor: Colors.White,
    accentColor: Colors.Gold,
    fontFamily: !IS_PRODUCTION_ENV ? 'Open Sans, monospace' : 'Open Sans, Helvetica, sans-serif',
    textColor: Colors.Black,
    accentTextColor: Colors.White,
    lineHeight: 1.2,
  },
  button: {
    paddingTop: 14,
    paddingRight: 24,
    paddingBottom: 14,
    paddingLeft: 24,
    fontWeight: FontWeight.Bold700,
    backgroundActive: Colors.Gold,
    backgroundHover: Colors.DarkGold,
    backgroundDisabled: Colors.LightGold,
    backgroundLoading: Colors.LightGold,
    focusBorderColor: Colors.DarkGold2,
    focusBorderWidth: 3,
  },
  link: {
    fontWeight: FontWeight.Medium500,
    textColorActive: Colors.DarkGold,
    textColorDisabled: `${Colors.DarkGold}80`,
    textColorHover: Colors.DarkGold,
    textColorLoading: `${Colors.DarkGold}80`,
    loaderColor: Colors.Gold,
  },
  checkbox: {
    labelHoverColor: '#00000009',
    backgroundColor: 'transparent',
    borderColor: Colors.Gold,
    backgroundColorChecked: Colors.Gold,
    borderColorChecked: Colors.Gold,
  },
  radio: {
    color: Colors.DarkGold,
    labelHoverColor: '#00000009',
  },
  input: {
    backgroundColor: Colors.White,
    backgroundColorDisabled: Colors.LightGrey,
    borderColor: Colors.LightGrey2,
    hoverBorderColor: Colors.Gold,
    textColor: Colors.Black,
    textColorDisabled: Colors.DarkGrey,
    borderRadius: 6,
    fontSize: 12,
    paddingRight: 16,
    paddingLeft: 16,
    focusBorderColor: Colors.Gold,
    focusOutlineColor: Colors.LightGold,
    focusOutlineWidth: 2,
  },
  textarea: {
    backgroundColor: Colors.White,
    backgroundColorDisabled: Colors.LightGrey,
    borderColor: Colors.LightGrey2,
    hoverBorderColor: Colors.Gold,
    textColor: Colors.Black,
    textColorDisabled: Colors.DarkGrey,
    borderRadius: 6,
    fontSize: 12,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    focusBorderColor: Colors.Gold,
    focusOutlineColor: Colors.LightGold,
    focusOutlineWidth: 2,
  },
  auth: {
    userAuthTypes: [
      FrontendUserAuthMethodType.Password,
      ...(IS_LOCALHOST_ENV
        ? [
            FrontendUserAuthMethodType.MagicLink,
            FrontendUserAuthMethodType.MfaCode,
            FrontendUserAuthMethodType.Password,
          ]
        : []),
    ],
  },
} satisfies DeepPartial<FrontendTheme>;
