import {ExpertTicketTaxBreakdown} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_common';
import {
  ExpertTicketAccessDateCriteria,
  ExpertTicketSalesDocumentSettings,
} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_product';
import {
  ExpertTicketProviderType,
  ExpertTicketPurchaseFlowType,
} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_provider';
import {ExpertTicketPriceMode} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_reservation';

export enum ExpertTicketPaymentStatus {
  NotCashed = 1,
  PartiallyCashed = 2,
  Cashed = 3,
  Refunded = 4,
  RefundPending = 5,
}

export enum ExpertTicketPartnerPaymentType {
  Debit = 1,
  Credit = 2,
  CreditExceptForGroups = 3,
  Prepaid = 4,
}

export interface ExpertTicketFinancialRatios {
  BasePrice: number;
  Quota: number;
  Total: number;
}

export enum ExpertTicketTransactionProductStatus {
  Initiated = 1,
  Confirmed = 2,
  Cancelled = 4,
  InProcess = 7,
}

export const ExpertTicketTransactionProductStatusStr: Record<
  ExpertTicketTransactionProductStatus,
  string
> = {
  [ExpertTicketTransactionProductStatus.Initiated]: 'Initiated',
  [ExpertTicketTransactionProductStatus.Confirmed]: 'Confirmed',
  [ExpertTicketTransactionProductStatus.Cancelled]: 'Cancelled',
  [ExpertTicketTransactionProductStatus.InProcess]: 'InProcess',
};

export interface ExpertTicketTransaction {
  EconomicMovementAmount: number;
  EconomicMovementAmountWithoutTax: number;
  SaleId: string;
  PaymentStatus: ExpertTicketPaymentStatus;
  PartnerPaymentType: ExpertTicketPartnerPaymentType;
  AccessDateTime: string;
  TransactionDateTime: string;
  PartnerSaleId?: string;
  TaxBreakdown: ExpertTicketTaxBreakdown[];
  Products: {
    ProductId: string;
    Tickets: {
      TicketId: string;
      AccessCode: string;
      BillingCode: string;
      AccessDateTime: string;
      AccessEndDateTime: string;
      SuggestedAccessDateMessage: string;
      EnclosureId: string;
    }[];
    ProviderId: string;
    Status: ExpertTicketTransactionProductStatus;
    Price: number;
    PriceWithoutVat: number;
    FinancialRatios: {
      ReferenceSalePrice: ExpertTicketFinancialRatios;
      Discount: ExpertTicketFinancialRatios;
      Commission: ExpertTicketFinancialRatios;
      SalePrice: ExpertTicketFinancialRatios;
    };
    AccessDateTime: string;
    PriceMode: ExpertTicketPriceMode;
    AccessDateCriteria: ExpertTicketAccessDateCriteria;
    SalesDocumentSettings: ExpertTicketSalesDocumentSettings;
    ProviderType: ExpertTicketProviderType;
    PurchaseFlowType: ExpertTicketPurchaseFlowType;
  }[];
  Documents: {SalesDocumentUrl: string}[];
}
