import {FC, useCallback} from 'react';

import {HoobiizGroupItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizUserGroupId} from '@shared/dynamo_model';
import {paddings} from '@shared/frontends/frontend_theme_utils';
import {ROOT_USER_GROUP_ID} from '@shared/model/hoobiiz/hoobiiz_common';

import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {
  AdminUserAndGroupTree,
  AdminUserAndGroupTreeActionContext,
  AdminUserAndGroupTreeProps,
} from '@src/components/admin/user_and_group/admin_user_and_group_tree';

interface AdminUserAndGroupPickerProps
  extends Omit<AdminUserAndGroupTreeProps, 'groupId' | 'groupAction'> {
  omitGroupId?: HoobiizUserGroupId;
  onSelect: (group: HoobiizGroupItem, context: AdminUserAndGroupTreeActionContext) => Promise<void>;
}

export const AdminUserAndGroupPicker: FC<AdminUserAndGroupPickerProps> = props => {
  const {omitGroupId, onSelect, ...opts} = props;

  const selectButton = useCallback(
    (group: HoobiizGroupItem, context: AdminUserAndGroupTreeActionContext) => {
      if (group.groupId === omitGroupId) {
        return EmptyFragment;
      }
      return (
        <ButtonAsLink
          // eslint-disable-next-line react/jsx-no-bind
          onClickAsync={async () => onSelect(group, context)}
          overrides={{
            backgroundHover: '#0000000a',
            borderRadius: 4,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...paddings(8),
          }}
        >
          Déplacer ici
        </ButtonAsLink>
      );
    },
    [omitGroupId, onSelect]
  );

  return (
    <AdminUserAndGroupTree groupId={ROOT_USER_GROUP_ID} groupAction={selectButton} {...opts} />
  );
};

AdminUserAndGroupPicker.displayName = 'AdminUserAndGroupPicker';
