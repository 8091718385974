import {Dispatch, FC, useCallback} from 'react';
import styled from 'styled-components';

import {AddressLookupResult, HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';

import {apiCall} from '@shared-frontend/api';
import {Autocomplete} from '@shared-frontend/components/core/autocomplete';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {adminInputTheme} from '@src/components/core/theme';

interface AddressInputProps {
  address?: AddressLookupResult;
  syncState?: Dispatch<AddressLookupResult | undefined>;
}

export const AddressInput: FC<AddressInputProps> = props => {
  const {address, syncState} = props;
  const addressLookup = useCallback(
    async (inputText: string) => apiCall(HoobiizApi, '/admin/address-lookup', {input: inputText}),
    []
  );

  const resultToString = useCallback((res: AddressLookupResult) => res.address, []);
  const resultToKey = useCallback((res: AddressLookupResult) => res.placeId, []);
  const resultToElement = useCallback((res: AddressLookupResult, highlighted: boolean) => {
    return (
      <Result $highlighted={highlighted}>
        <ResultIcon>
          <SvgIcon name="Info" size={21} />
        </ResultIcon>
        <ResultAddress>
          <ResultMainText>{res.main}</ResultMainText>
          <ResultSecondaryText>{res.secondary}</ResultSecondaryText>
        </ResultAddress>
      </Result>
    );
  }, []);

  return (
    <Autocomplete
      lookupItem={addressLookup}
      item={address}
      syncState={syncState}
      itemToInputString={resultToString}
      itemToKey={resultToKey}
      label="ADRESSE"
      overrides={adminInputTheme}
      itemElement={resultToElement}
    />
  );
};
AddressInput.displayName = 'AddressInput';

const Result = styled.div<{$highlighted?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 14px;
  cursor: pointer;
  ${p => (p.$highlighted ? `background-color: #f6f6f6;` : `background-color: #ffffff;`)};
  &:hover {
    background-color: #f6f6f6;
  }
`;

const ResultIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
`;

const ResultAddress = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultMainText = styled.span`
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
`;

const ResultSecondaryText = styled.span`
  line-height: 20px;
  font-size: 14px;
`;
