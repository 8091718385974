import {FC, useEffect, useState} from 'react';

import {HoobiizDiscountPercent, HoobiizDiscountType} from '@shared/dynamo_model';

import {Input} from '@shared-frontend/components/core/input_v2';

import {adminInputTheme} from '@src/components/core/theme';

interface PromotionDiscountPercentFormProps {
  initialData?: HoobiizDiscountPercent;
  onChange: (target: HoobiizDiscountPercent | undefined) => void;
  label: string;
}

function percentToInputString(percent: number | undefined): string {
  return percent !== undefined ? percent.toLocaleString() : '';
}

function inputStringToPercent(str: string): number | undefined {
  const value = parseFloat(str);
  if (isNaN(value)) {
    return undefined;
  }
  return value;
}

export const PromotionDiscountPercentForm: FC<PromotionDiscountPercentFormProps> = props => {
  const {initialData, onChange, label} = props;

  const [percent, setPercent] = useState<number | undefined>(initialData?.percent ?? 0);

  useEffect(() => {
    if (percent === undefined) {
      onChange(undefined);
      return;
    }
    onChange({type: HoobiizDiscountType.Percent, percent});
  }, [percent, onChange]);

  return (
    <Input
      width="100%"
      value={percent}
      syncState={setPercent}
      asString={percentToInputString}
      fromString={inputStringToPercent}
      label={label}
      overrides={adminInputTheme}
      noLabelOffset
    />
  );
};
PromotionDiscountPercentForm.displayName = 'PromotionDiscountPercentForm';
