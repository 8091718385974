// To add a new HTTP code to the API:
// PART 1 - Add its constant and type in the correct category
// PART 2 - Include the type you created to its corresponding categories
// PART 3 - Update the condition in the appropriate error code detection function

// -------------------------------------------------
// PART 1 - Error codes constant and type definition
// -------------------------------------------------

/* eslint-disable @typescript-eslint/no-redeclare */

// Success

type OK = 200;
export const OK: OK = 200;

// Redirect

type MOVED_PERMANENTLY = 301;
export const MOVED_PERMANENTLY: MOVED_PERMANENTLY = 301;

// Client Error

type BAD_REQUEST = 400;
export const BAD_REQUEST: BAD_REQUEST = 400;

type UNAUTHORIZED = 401;
export const UNAUTHORIZED: UNAUTHORIZED = 401;

type FORBIDDEN = 403;
export const FORBIDDEN: FORBIDDEN = 403;

type NOT_FOUND = 404;
export const NOT_FOUND: NOT_FOUND = 404;

type TOO_MANY_REQUESTS = 429;
export const TOO_MANY_REQUESTS: TOO_MANY_REQUESTS = 429;

// Server Error

type INTERNAL_SERVER_ERROR = 500;
export const INTERNAL_SERVER_ERROR: INTERNAL_SERVER_ERROR = 500;

type SERVICE_UNAVAILABLE = 503;
export const SERVICE_UNAVAILABLE: SERVICE_UNAVAILABLE = 503;

// --------------------------------
// PART 2 - Categorize status codes
// --------------------------------

export type SuccessCode = OK;
export type ClientErrorCode = BAD_REQUEST | UNAUTHORIZED | FORBIDDEN | NOT_FOUND;
export type ServerErrorCode = INTERNAL_SERVER_ERROR | SERVICE_UNAVAILABLE;
export type HttpCode = SuccessCode | ClientErrorCode | ServerErrorCode;

const successCodes = new Set([OK]);
const clientErrorCodes = new Set([BAD_REQUEST, UNAUTHORIZED, FORBIDDEN, NOT_FOUND]);
const serverErrorCodes = new Set([INTERNAL_SERVER_ERROR, SERVICE_UNAVAILABLE]);

//
// Utilities
//

export function isSuccess(
  code: SuccessCode | ClientErrorCode | ServerErrorCode
): code is SuccessCode {
  return successCodes.has(code as SuccessCode);
}

export function isClientError(
  code: SuccessCode | ClientErrorCode | ServerErrorCode
): code is ClientErrorCode {
  return clientErrorCodes.has(code as ClientErrorCode);
}

export function isServerError(
  code: SuccessCode | ClientErrorCode | ServerErrorCode
): code is ServerErrorCode {
  return serverErrorCodes.has(code as ServerErrorCode);
}
