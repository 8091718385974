import {createDataStore} from '@shared-frontend/lib/data_store';
// Options

interface SharedAdminOptions {
  sidebarCollapsed: boolean;
}

let optionsFromStorage = {
  sidebarCollapsed: false,
};
try {
  optionsFromStorage = JSON.parse(localStorage.getItem('AdminOptions') ?? '');
  // eslint-disable-next-line no-empty
} catch {}

const optionsStore = createDataStore<SharedAdminOptions>(optionsFromStorage);
export const getSharedAdminOptions = optionsStore.getData;
export const useSharedAdminOptions = optionsStore.useData;
export const setSharedAdminOptions = (options: SharedAdminOptions): void => {
  localStorage.setItem('AdminOptions', JSON.stringify(options));
  return optionsStore.setData(options);
};
export const setSidebarCollapsed = (sidebarCollapsed: boolean): void => {
  setSharedAdminOptions({...getSharedAdminOptions(), sidebarCollapsed});
};
