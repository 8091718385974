import {FC} from 'react';

import {SanitizedHoobiizOrderItemItem} from '@shared/api/definitions/public_api/hoobiiz_api';

import {HoobiizTicketItem} from '@src/components/ui/hoobiiz_ticket_item';

interface HoobiizOrderItemProps {
  orderItem: SanitizedHoobiizOrderItemItem;
}

export const HoobiizOrderItem: FC<HoobiizOrderItemProps> = props => {
  const {orderItem} = props;
  const {
    activity,
    quantity,
    total,
    ticketInfo,
    ticketInfoTotal,
    options,
    fees,
    documentUrls,
    validityDateStr,
    status,
  } = orderItem;

  return (
    <HoobiizTicketItem
      activity={activity}
      quantity={quantity}
      total={total}
      fees={fees}
      ticketInfo={ticketInfo}
      ticketInfoTotal={ticketInfoTotal}
      options={options}
      validityDateStr={validityDateStr}
      orderInfo={{documentUrls, status}}
    />
  );
};

HoobiizOrderItem.displayName = 'HoobiizOrderItem';
