import {ApiCallOptions, ApiMetadata, UnauthenticatedCallback} from '@shared/api/model';
import {ApiCallReq, ApiCallRes, ApiDef} from '@shared/api/registry';
import {safeStringify} from '@shared/api/safe_body';
import {asMap, asString} from '@shared/lib/type_utils';

const MIN_CLIENT_ERROR = 400;
const MIN_SERVER_ERROR = 500;

export type HttpPosterRes =
  | {
      status: number;
      rawRes: unknown;
      res: unknown;
    }
  | {
      status: number;
      rawRes: unknown;
      err: unknown;
    };

export type HttpPoster = (
  uri: string,
  method: 'GET' | 'POST' | 'DELETE',
  body: string,
  headers: Record<string, string>,
  overrides: {
    timeout?: number;
    rawResult?: boolean;
    noSslVerify?: boolean;
    ipv6Compatible?: boolean;
  },
  onUnauthenticated?: UnauthenticatedCallback
) => {
  promise: Promise<HttpPosterRes>;
};

function formUrlEncoded(name: string, val: unknown): string {
  if (['string', 'number', 'boolean'].includes(typeof val)) {
    return `${name}=${encodeURIComponent(String(val))}`;
  } else if (Array.isArray(val)) {
    return val.map((v, i) => formUrlEncoded(`${name}[${i}]`, v)).join('&');
    // eslint-disable-next-line no-null/no-null
  } else if (val === null) {
    return `${name}=null`;
  } else if (typeof val === 'object') {
    return Object.entries(val)
      .map(([k, v]) => formUrlEncoded(`${name}[${k}]`, v))
      .join('&');
  }
  return '';
}

function stringifyAsQueryParams(raw: unknown): string {
  const obj = asMap(raw, {});
  const lines: string[] = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      lines.push(formUrlEncoded(key, obj[key]));
    }
  }
  return lines.join('&');
}

export async function genericApiCall<
  Api extends ApiMetadata<unknown>,
  Path extends keyof ApiDef<Api>,
  Req extends ApiCallReq<ApiDef<Api>[Path]>,
>(
  api: Api,
  path: Path,
  req: Req,
  httpPoster: HttpPoster,
  options?: ApiCallOptions,
  onUnauthenticated?: UnauthenticatedCallback,
  logger?: (msg: string, data: unknown) => void
): Promise<ApiCallRes<ApiDef<Api>[Path]>> {
  const {hostOverride, timeout, urlReplace, extraQueryParams} = options ?? {};

  const {host, rawResult, formUrlEncoded, httpGet, httpDelete, noSslVerify, ipv6Compatible} = api;
  const h = hostOverride ?? host;
  const method = httpGet ? 'GET' : httpDelete ? 'DELETE' : 'POST';

  let finalPath = String(path);
  for (const [toReplace, replaceWith] of Object.entries(urlReplace ?? {})) {
    finalPath = finalPath.replaceAll(toReplace, replaceWith);
  }
  let uri = `${h}${finalPath}`;

  let queryParams = {};
  if (httpGet) {
    queryParams = {...queryParams, ...(req as Record<string, unknown>)};
  }
  if (extraQueryParams) {
    queryParams = {...queryParams, ...extraQueryParams};
  }
  const queryStr = stringifyAsQueryParams(queryParams);
  if (queryStr.length > 0) {
    uri += `?${queryStr}`;
  }

  let body = '';
  if (!httpGet) {
    body = formUrlEncoded ? stringifyAsQueryParams(req) : safeStringify(req);
  }

  const headers = options?.headers ?? {};
  if (formUrlEncoded) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  } else if (!httpGet) {
    headers['Content-Type'] = 'application/json';
  }
  // headers['Accept-Encoding'] = 'gzip';
  headers['Accept'] = '*/*';

  const {promise} = httpPoster(
    uri,
    method,
    body,
    headers,
    {timeout, rawResult, noSslVerify, ipv6Compatible},
    onUnauthenticated
  );

  let rawRes: unknown;
  let status: unknown;
  try {
    const res = await promise;
    rawRes = res.rawRes;
    status = res.status;
    // Server error. We check if a specific error was returned, otherwise use
    // a generic server error.
    if (res.status >= MIN_SERVER_ERROR) {
      const errMsg = asString(asMap(res, {}).err);
      throw new Error(errMsg ?? `Server error (${res.status})`);
    }
    // User error, the server should have returned a descriptive message in the response
    if (res.status >= MIN_CLIENT_ERROR) {
      const errMsg = asString(asMap(res, {}).err);
      throw new Error(errMsg ?? `Invalid request ${h} ${String(path)} (${res.status})`);
    }
    // No error returned, but `res` payload is not here or not an object.
    const resData = asMap(res, {}).res;
    if (resData === undefined) {
      throw new Error(`Invalid response ${h} ${String(path)} ${JSON.stringify(res)}`);
    }
    // Success!
    return resData as ApiCallRes<ApiDef<Api>[Path]>;
  } catch (err: unknown) {
    if (logger) {
      logger(`API call failed ${h} ${String(path)} (${String(err)})`, {
        uri,
        method,
        body: body.slice(0, 1000),
        headers,
        options,
        status,
        rawRes,
      });
    }
    throw err;
  }
}
