import {useCallback, useEffect, useRef, useState} from 'react';

function nearestSecond(): number {
  return Math.floor(Date.now() / 1000) * 1000;
}

// Heartbeat of the current second
export function useHeartBeat(): number | undefined {
  const [currentSecond, setCurrentSecond] = useState(nearestSecond());

  const autoRefreshTimeoutRef = useRef(0);
  const autoRefreshCancelled = useRef(false);

  const autoRefreshLoop = useCallback((targetSecond: number) => {
    const sleepDuration = targetSecond - Date.now();
    autoRefreshTimeoutRef.current = setTimeout(() => {
      if (autoRefreshCancelled.current) {
        return;
      }
      setCurrentSecond(targetSecond);
      autoRefreshLoop(targetSecond + 1000);
    }, sleepDuration);
    const cancelAutoRefresh = (): void => {
      autoRefreshCancelled.current = true;
      clearTimeout(autoRefreshTimeoutRef.current);
    };
    return cancelAutoRefresh;
  }, []);

  useEffect(() => {
    const cancelLoop = autoRefreshLoop(nearestSecond() + 1000);
    return cancelLoop;
  }, [autoRefreshLoop]);

  return currentSecond;
}
