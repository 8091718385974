import {ActivityAndSources} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizVendorLocation} from '@shared/dynamo_model';

export function getVendorLocationsWithPriority(
  item: ActivityAndSources
): HoobiizVendorLocation | HoobiizVendorLocation[] {
  for (const source of item.sources ?? []) {
    // There should only be one vendor address source
    if (source.type === 'vendor-address') {
      const address = item.item.vendor?.addresses.at(source.addressIndex);
      if (address !== undefined) {
        return address; // Priority address from search
      }
      break;
    }
  }
  return item.item.vendor?.addresses ?? [];
}
