import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';

import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {HoobiizCat1View} from '@src/components/ui/hoobiiz_cat1_view';
import {getCat1Url} from '@src/lib/hoobiiz_urls';

interface HoobiizCat1ListViewProps {}

export const HoobiizCat1ListView: FC<HoobiizCat1ListViewProps> = () => {
  const {data} = useApiCall(HoobiizApi, '/list-cat1', {});

  if (data === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <Title>Des milliers d’activités au meilleur prix !</Title>
      <Grid>
        {data.cat1.map(cat => (
          <HoobiizCat1View key={cat.id} to={getCat1Url(cat)} name={cat.name} media={cat.media} />
        ))}
      </Grid>
    </Wrapper>
  );
};

HoobiizCat1ListView.displayName = 'HoobiizCat1ListView';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 16px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;
