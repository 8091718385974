import {FC, useCallback} from 'react';
import {useNavigate} from 'react-router';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizVendorItem} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {showSuccess} from '@shared-frontend/components/core/notifications';

import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {VendorForm} from '@src/components/admin/vendor/vendor_form';

export const VendorCreatePage: FC = () => {
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (vendorData: HoobiizData<HoobiizVendorItem>) => {
      const {vendor} = await apiCall(HoobiizApi, '/admin/create-vendor', vendorData);
      showSuccess(`Partenaire "${vendor.name}" créé`);
      navigate('/admin/vendor');
    },
    [navigate]
  );

  return (
    <PageWrapper>
      <FormWrapper>
        <FormTitle>Ajouter un nouveau partenaire</FormTitle>
        <VendorForm submitButtonText="Créer" onSubmit={handleSubmit} />
      </FormWrapper>
    </PageWrapper>
  );
};
VendorCreatePage.displayName = 'VendorCreatePage';
