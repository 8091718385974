import {FC, useCallback} from 'react';
import {useNavigate} from 'react-router';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat3Item} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {Category3Form} from '@src/components/admin/category/category3_form';
import {FormTitleOld, FormWrapperOld} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';

export const Category3CreatePage: FC = () => {
  const navigate = useNavigate();
  const {cat1Id, cat2Id} = useParams();

  const handleSubmit = useCallback(
    async (cat3Data: Omit<HoobiizData<HoobiizCat3Item>, 'order'>) => {
      const {cat3} = await apiCall(HoobiizApi, '/admin/create-cat3', cat3Data);
      showSuccess(`Catégorie 3 "${cat3.name}" créé`);
      navigate(`/admin/cat1/${cat1Id}/cat2/${cat2Id}`);
    },
    [cat1Id, cat2Id, navigate]
  );

  return (
    <PageWrapper>
      <FormWrapperOld>
        <FormTitleOld>Ajouter une nouvelle catégorie 3</FormTitleOld>
        <Category3Form autoFocus submitButtonText="Créer" onSubmit={handleSubmit} />
      </FormWrapperOld>
    </PageWrapper>
  );
};
Category3CreatePage.displayName = 'Category3CreatePage';
