export function triggerTextFileDownload(opts: {
  contentType: string;
  content: string;
  fileName: string;
}): void {
  const {contentType, content, fileName} = opts;
  const element = document.createElement('a');
  const contentStr = `charset=utf-8,${encodeURIComponent(content)}`;
  element.setAttribute('href', `data:${contentType};${contentStr}`);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function triggerBinaryFileDownload(opts: {
  contentType: string;
  base64: string;
  fileName: string;
}): void {
  const {contentType, fileName, base64} = opts;
  const element = document.createElement('a');
  const contentStr = `base64,${base64}`;
  element.setAttribute('href', `data:${contentType};${contentStr}`);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function triggerUrlFileDownload(opts: {url: string}): void {
  const {url} = opts;
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
