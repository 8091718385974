import {HoobiizPermission, HoobiizUserGroupId} from '@shared/dynamo_model';

export enum HoobiizPermissionName {
  NonCseActivities = 'non-cse-activities',
  CseActivities = 'cse-activities',
}

export interface HoobiizPermissionDefinition {
  label: string;
  type: 'flag';
}

export const ALL_HOOBIIZ_PERMISSIONS: Record<HoobiizPermissionName, HoobiizPermissionDefinition> = {
  [HoobiizPermissionName.NonCseActivities]: {label: 'Activités non premium', type: 'flag'},
  [HoobiizPermissionName.CseActivities]: {label: 'Activités premium', type: 'flag'},
};

export function flattenHoobiizPermissions(
  groupPermissions: {
    groupId: HoobiizUserGroupId;
    permissions: HoobiizPermission[];
  }[]
): Partial<
  Record<HoobiizPermissionName, {permission: HoobiizPermission; fromGroupId: HoobiizUserGroupId}>
> {
  const flattenedPermissions = new Map<
    HoobiizPermissionName,
    {permission: HoobiizPermission; fromGroupId: HoobiizUserGroupId}
  >();
  for (const {groupId, permissions} of groupPermissions) {
    for (const permission of permissions) {
      flattenedPermissions.set(permission.name, {permission, fromGroupId: groupId});
    }
  }
  return Object.fromEntries(flattenedPermissions.entries());
}
