import {CommitHash} from '@shared/dynamo_model';
import {Brand, removeUndefined} from '@shared/lib/type_utils';
import {ServiceNodeId} from '@shared/model/devops';
import {ServiceName} from '@shared/model/terraform';

export const DEFAULT_MINUTES_SHOWN = 1;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const ALLOWED_MINUTES_SHOWN = [1, 5, 15, 30, 60, 120, 240, 360];

export enum LogLevel {
  Info = 30,
  Warning = 40,
  Error = 50,
}

export const AllLogLevels = [LogLevel.Info, LogLevel.Warning, LogLevel.Error];

export interface Log {
  level: LogLevel;
  time: [number, number];
  service: string;
  env: string;
  commitHash: CommitHash;
  serviceNodeId: ServiceNodeId;
  mem: number;
  cpu: number;
  disk: number;
  msg: string;
  extra?: unknown;
  stack?: string;
  errMsg?: string;
  timers: number;
  isMonitoring?: boolean;
  pid?: number;
}

export type LogId = Brand<string, 'LogId'>;

export function logId(log: Log): LogId {
  return `${log.serviceNodeId}-${log.time[0]}-${log.time[1]}` as LogId;
}

export function logEntrySort(a: Log, b: Log): number {
  return b.time[0] === a.time[0] ? b.time[1] - a.time[1] : b.time[0] - a.time[0];
}

export function linesToLogEntries(lines: string | string[]): Log[] {
  return removeUndefined(
    (typeof lines === 'string' ? lines.split('\n') : lines)
      .filter(l => l.trim().length > 0)
      .map<Log | undefined>(line => {
        try {
          return JSON.parse(line) as Log;
        } catch {
          return undefined;
        }
      })
    // time = [seconds, nanoseconds]
    // Sort by seconds first then nanoseconds
  ).sort(logEntrySort);
}

export interface LogsRequest {
  minutesTs: number[];
  noDataOnPartial: boolean;
}

export interface LogsRequestByService extends LogsRequest {
  serviceName: ServiceName;
}

export type LogsResponseStatus = 'full' | 'partial';

export interface LogsResponse {
  status: LogsResponseStatus;
  data: string;
}

export interface LogsRequest2 {
  serviceName?: ServiceName;
  datePrefix: string;
  warningsAndErrorsOnly: boolean;
  clientId: string;
}

export interface LogsResponse2 {
  logs: Log[];
}

export type LogGrepId = Brand<'LogGrepId', string>;

export interface LogsGrepResult {
  id: LogGrepId;
  status: {
    stage: 'listing' | 'downloading' | 'done';
    totalFiles: number;
    totalGzBytes: number;
    scannedFiles: number;
    scannedLogs: number;
    downloadedBytes: number;
    logsFound: number;
  };
  logs: string[];
  errors: string[];
}
