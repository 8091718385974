import {CSSProperties} from 'react';
import {styled} from 'styled-components';

import {cssPx, optional} from '@shared-frontend/lib/styled_utils';

export const GridColumns = styled.div<{
  $columns: number;
  $gap?: number | string;
  $alignItems?: CSSProperties['alignItems'];
}>`
  display: grid;
  grid-template-columns: repeat(${p => p.$columns}, minmax(min-content, max-content));
  grid-gap: ${p => cssPx(p.$gap ?? '16')};
  ${p => optional('align-items', p.$alignItems)}
`;
