import {FC, JSX, MouseEventHandler, useCallback} from 'react';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';
import {isNull} from '@shared/lib/type_utils';

import {UnthemedButton} from '@shared-frontend/components/core/button';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useStringQueryString} from '@shared-frontend/lib/use_query_string';

export interface PageTab {
  name: string;
  label: string;
  page: JSX.Element;
}

interface TabbedPageProps {
  tabs: PageTab[];
}

export const TabbedPage: FC<TabbedPageProps> = props => {
  const {tabs} = props;
  const {
    main: {accentColor, accentTextColor, textColor},
  } = useTheme();
  const [currentTab, setCurrentTab] = useStringQueryString('tab', tabs[0]?.name ?? '', {
    override: true,
  });

  const handleTabClick = useCallback<MouseEventHandler>(
    evt => {
      const name = evt.currentTarget.getAttribute('data-tab');
      if (!isNull(name)) {
        setCurrentTab(name);
      }
    },
    [setCurrentTab]
  );

  return (
    <Wrapper>
      <Top>
        {tabs.map(tab => (
          <StyledUnthemedButton
            $selected={tab.name === currentTab}
            $textColor={textColor}
            $accentColor={accentColor}
            $accentTextColor={accentTextColor}
            key={tab.name}
            data-tab={tab.name}
            onClick={handleTabClick}
          >
            {tab.label}
          </StyledUnthemedButton>
        ))}
      </Top>
      <Spacing height={24} />
      {tabs.find(({name}) => currentTab === name)?.page ?? EmptyFragment}
    </Wrapper>
  );
};
TabbedPage.displayName = 'TabbedPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  & > *:nth-child(1) {
    flex-shrink: 0;
  }
  & > *:nth-child(2) {
    flex-shrink: 0;
  }
  & > *:nth-child(3) {
    height: 1px;
    flex-grow: 1;
    overflow-y: auto;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const StyledUnthemedButton = styled(UnthemedButton)<{
  $selected: boolean;
  $textColor: string;
  $accentColor: string;
  $accentTextColor: string;
}>`
  cursor: pointer;
  font-size: 16px;
  min-width: 120px;
  padding: 0 8px;
  height: 40px;
  border-radius: 20px;
  border: solid 2px ${p => p.$accentColor};
  ${p =>
    p.$selected
      ? `
    background: ${p.$accentColor};
    color: ${p.$accentTextColor};
    `
      : `
        background: transparent;
        color: ${p.$textColor};
    &:hover {
        background: ${p.$accentColor}33;
    }
    `}
`;
