/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {NO_TIMEOUT} from '@shared/constants/uncategorized_constants';
import {HoobiizExpertTicketProductId} from '@shared/dynamo_model';
import {groupBy, zip} from '@shared/lib/array_utils';
import {removeUndefined} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {hideModal, showRawModal} from '@shared-frontend/components/core/modal';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {
  batchSetExpertTicketTransaction,
  setExpertTicketDocument,
  useAllExpertTicketReservation,
  useAllExpertTicketTransaction,
} from '@src/components/admin/expert_ticket/expert_ticket_admin_store';
import {ExpertTicketCertification} from '@src/components/admin/expert_ticket/expert_ticket_certification';
import {ExpertTicketPriceCalendar} from '@src/components/admin/expert_ticket/expert_ticket_price_calendar';
import {ExpertTicketProductActivity} from '@src/components/admin/expert_ticket/expert_ticket_product_activity';
import {FormFlex} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {Colors} from '@src/components/core/theme_base';

export interface ExpertTicketProductAndTickets extends FullItem<'HoobiizExpertTicketProduct'> {
  tickets: FullItem<'HoobiizExpertTicketTicket'>[];
}

const extractNames = (p: ExpertTicketProductAndTickets): string[] => {
  const {ProductName, productBase, tickets} = p;
  const ticketNames = tickets.map(t => t.TicketName);
  if (!productBase) {
    return ['', '', ProductName, ...ticketNames];
  }
  const {ProductBaseName, provider} = productBase;
  if (!provider) {
    return ['', ProductBaseName, ProductName, ...ticketNames];
  }
  const {ProviderName} = provider;
  return [ProviderName, ProductBaseName, ProductName, ...ticketNames];
};

export const ExpertTicketPage: FC = () => {
  const [tickets, setTickets] = useState<FullItem<'HoobiizExpertTicketTicket'>[]>();
  const reservations = useAllExpertTicketReservation();
  const transactions = useAllExpertTicketTransaction();
  const [selectedProducts, setSelectedProducts] = useState<HoobiizExpertTicketProductId[]>([]);

  const products: ExpertTicketProductAndTickets[] = useMemo(() => {
    const products = removeUndefined(
      [...groupBy(tickets ?? [], t => t.productId).values()].map(tickets => {
        const {product} = tickets[0];
        if (!product) {
          return undefined;
        }
        return {...product, tickets};
      })
    ).sort((p1, p2) => {
      const names1 = extractNames(p1);
      const names2 = extractNames(p2);
      return zip(names1, names2).reduce(
        (prev, curr) => (prev === 0 ? curr[0].localeCompare(curr[1]) : prev),
        0
      );
    });
    console.log('All products', products);
    console.log(
      'Real-time products',
      products.filter(p => p.RequiresRealTimePrice)
    );
    console.log(
      'Non-real-time products',
      products.filter(p => !p.RequiresRealTimePrice)
    );
    return products;
  }, [tickets]);

  const handleSelectedProductIdChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    evt => {
      const checked = evt.currentTarget.checked;
      const productId = (evt.currentTarget.getAttribute('data-id') ??
        '') as HoobiizExpertTicketProductId;
      setSelectedProducts(current =>
        checked ? [...current, productId] : current.filter(id => id !== productId)
      );
    },
    []
  );
  const handleAllProductsSelectedChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    evt => {
      const checked = evt.currentTarget.checked;
      setSelectedProducts(() => (checked ? products.map(p => p.ProductId) : []));
    },
    [products]
  );

  useEffect(() => {
    apiCall(HoobiizApi, '/admin/expert-ticket-catalog', {})
      .then(({items}) => setTickets(items))
      .catch(notifyError);
  }, []);

  useEffect(() => {
    apiCall(HoobiizApi, '/admin/expert-ticket-list-transaction', {}, {timeout: NO_TIMEOUT})
      .then(({transactions, documents}) => {
        batchSetExpertTicketTransaction(transactions.map(t => ({key: t.SaleId, value: t})));
        for (const [saleId, docs] of Object.entries(documents)) {
          setExpertTicketDocument(saleId, docs);
        }
      })
      .catch(notifyError);
  }, []);

  if (!tickets) {
    return <Wrapper>Loading...</Wrapper>;
  }

  return (
    <Wrapper>
      <ExpertTicketCertification
        products={products.filter(p => selectedProducts.includes(p.ProductId))}
      />
      {/* <Button onClickAsync={handleCheckPricesClick}>Check prices</Button> */}
      <Table>
        <THeader>
          <tr>
            <ChechboxHeaderCell>
              <Checkbox
                checked={products.every(({ProductId}) => selectedProducts.includes(ProductId))}
                onChange={handleAllProductsSelectedChange}
                overrides={{
                  marginRight: 0,
                  labelPaddingTop: 0,
                  labelPaddingRight: 0,
                  labelPaddingBottom: 0,
                  labelPaddingLeft: 0,
                }}
              >
                {EmptyFragment}
              </Checkbox>
            </ChechboxHeaderCell>
            <HeaderCell>Activity</HeaderCell>
            <HeaderCell>Provider</HeaderCell>
            <HeaderCell>Product Base</HeaderCell>
            <HeaderCell>Product</HeaderCell>
            <HeaderCell>Ticket</HeaderCell>
          </tr>
        </THeader>
        <tbody>
          {products.map(product => {
            const {productBase, ProductId, ProductName} = product;
            if (!productBase) {
              return EmptyFragment;
            }
            const {provider, ProductBaseName} = productBase;
            if (!provider) {
              return EmptyFragment;
            }
            const {ProviderName} = provider;
            const productReservations = [...reservations.values()].filter(r =>
              r.Products.map(p => p.ProductId).includes(ProductId)
            );
            const productTransactions = [...transactions.values()].filter(r =>
              r.Products.map(p => p.ProductId).includes(ProductId)
            );
            return (
              <Row key={ProductId}>
                <Cell>
                  <Checkbox
                    data-id={ProductId}
                    checked={selectedProducts.includes(ProductId)}
                    onChange={handleSelectedProductIdChange}
                    overrides={{
                      marginRight: 0,
                      labelPaddingTop: 0,
                      labelPaddingRight: 0,
                      labelPaddingBottom: 0,
                      labelPaddingLeft: 0,
                    }}
                  >
                    {EmptyFragment}
                  </Checkbox>
                </Cell>
                <Cell
                  onClick={() =>
                    console.log({
                      reservations: productReservations,
                      transactions: productTransactions,
                    })
                  }
                >
                  {productReservations.length + productTransactions.length === 0 ? (
                    EmptyFragment
                  ) : (
                    <ButtonAsLink
                      onClick={() => {
                        showRawModal({
                          children: <ExpertTicketProductActivity product={product} />,
                          mode: 'fade-center',
                          noCross: true,
                          backdropColor: '#0c0c0cf7',
                        });
                      }}
                    >
                      {removeUndefined([
                        productReservations.length > 0
                          ? `${productReservations.length} res`
                          : undefined,
                        productTransactions.length > 0
                          ? `${productTransactions.length} tx`
                          : undefined,
                      ]).join(' & ')}
                    </ButtonAsLink>
                  )}
                </Cell>
                <Cell onClick={() => console.log(provider)}>{ProviderName}</Cell>
                <Cell onClick={() => console.log(productBase)}>{ProductBaseName}</Cell>
                <Cell
                  onClick={() => {
                    console.log(product);
                  }}
                >
                  <FormFlex $align="center">
                    <ButtonAsLink
                      onClick={() => {
                        showRawModal({
                          children: (
                            <ExpertTicketPriceCalendar
                              product={product}
                              onReservation={() => hideModal()}
                            />
                          ),
                          mode: 'slide-right',
                          width: 450,
                          noCross: true,
                          backdropColor: '#0c0c0cf7',
                        });
                      }}
                    >
                      {ProductName}
                    </ButtonAsLink>
                  </FormFlex>
                </Cell>
                <Cell>
                  {product.tickets.map(ticket => (
                    <div key={ticket.TicketId} onClick={() => console.log(ticket)}>
                      {ticket.TicketName}
                    </div>
                  ))}
                </Cell>
              </Row>
            );
          })}
        </tbody>
      </Table>
    </Wrapper>
  );
};
ExpertTicketPage.displayName = 'ExpertTicketPage';

const Wrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Table = styled.table`
  width: 100%;
  border: solid 2px ${Colors.Gold};
  border-top: none;
`;
const THeader = styled.thead`
  position: sticky;
  top: 0;
`;
const Row = styled.tr`
  &:nth-child(even) {
    background-color: #00000011;
  }
`;
const Cell = styled.td`
  padding: 2px 4px;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 14px;
`;
const HeaderCell = styled.th`
  padding: 4px 8px;
  font-weight: 600;
  background: ${Colors.Gold};
  color: #ffffff;
  vertical-align: middle;
`;
const ChechboxHeaderCell = styled.th`
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding: 6px 0;
  border: solid 2px ${Colors.Gold};
  border-left: none;
  border-right: none;
`;
