import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizAdminAccounting, HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';
import {splitOnceOrThrow} from '@shared/lib/array_utils';
import {capitalize} from '@shared/lib/format_utils';

import {apiCall} from '@shared-frontend/api';
import {notifyError} from '@shared-frontend/lib/notification';

import {Colors} from '@src/components/core/theme_base';

interface AdminAccountingPageProps {}

type AccountingData = ApiDef<typeof HoobiizApi>['/admin/accounting']['res']['accounting'];

function zeroAccounting(): HoobiizAdminAccounting {
  return {cents: 0, orderCount: 0, ticketCount: 0};
}

export const AdminAccountingPage: FC<AdminAccountingPageProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountingData, setAccountingData] = useState<AccountingData | undefined>();

  const loadAccounting = useCallback(() => {
    setIsLoading(true);
    setAccountingData(undefined);
    apiCall(HoobiizApi, '/admin/accounting', {})
      .then(({accounting}) => {
        setAccountingData(accounting);
        setIsLoading(false);
      })
      .catch((err: unknown) => {
        setIsLoading(false);
        notifyError(err, {message: `Échec du chargement de la compta`});
      });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadAccounting();
  }, [loadAccounting]);

  const accountingByYear = useMemo(() => {
    if (!accountingData) {
      return undefined;
    }
    // Find the first and last dates
    const sortedDates = Object.keys(accountingData).sort((a, b) => a.localeCompare(b));
    const firstDate = sortedDates[0];
    const lastDate = sortedDates.at(-1);
    if (firstDate === undefined || lastDate === undefined) {
      return undefined;
    }
    const [firstYearStr, firstMonthStr] = splitOnceOrThrow(firstDate, '-');
    const [firstYear, firstMonth] = [parseFloat(firstYearStr), parseFloat(firstMonthStr)];
    const [lastYearStr, lastMonthStr] = splitOnceOrThrow(lastDate, '-');
    const [lastYear, lastMonth] = [parseFloat(lastYearStr), parseFloat(lastMonthStr)];

    // Iter over all the dates and find the associated accounting data
    interface MonthAccounting {
      month: number;
      accounting: HoobiizAdminAccounting;
    }
    const accountingByYear: {
      year: number;
      accounting: HoobiizAdminAccounting;
      months: MonthAccounting[];
    }[] = [];
    let currentYear = firstYear;
    let currentMonth = firstMonth;
    let currentYearAccounting = zeroAccounting();
    let currentYearMonths: MonthAccounting[] = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // Get the data for the month
      const dateKey = [currentYear, currentMonth].join('-');
      const accounting = accountingData[dateKey] ?? zeroAccounting();
      // Increase the year accounting with the data from the current month
      currentYearAccounting.cents += accounting.cents;
      currentYearAccounting.orderCount += accounting.orderCount;
      currentYearAccounting.ticketCount += accounting.ticketCount;
      // Store the month accounting for the current year
      currentYearMonths.push({month: currentMonth, accounting});

      // Increment to the next month
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (currentMonth >= 11) {
        // If we are changing year, we store the year accounting data and reset the variables
        accountingByYear.push({
          year: currentYear,
          accounting: currentYearAccounting,
          months: currentYearMonths,
        });
        currentYearAccounting = zeroAccounting();
        currentYearMonths = [];
        currentMonth = 0;
        currentYear++;
      } else {
        currentMonth++;
      }

      // If we've reached the final month, we stop the loop
      if (currentYear > lastYear || (currentYear === lastYear && currentMonth > lastMonth)) {
        // If we are not at the first month, we need to store the current (partial) year accounting data
        if (currentMonth !== 0) {
          accountingByYear.push({
            year: currentYear,
            accounting: currentYearAccounting,
            months: currentYearMonths,
          });
        }
        break;
      }
    }

    return accountingByYear;
  }, [accountingData]);

  if (isLoading || !accountingByYear) {
    return <Wrapper>Chargement de la compta en cours...</Wrapper>;
  }

  return (
    <Wrapper>
      <Table>
        <thead>
          <Row>
            <HeaderCell>Mois</HeaderCell>
            <HeaderNumberCell># Commandes</HeaderNumberCell>
            <HeaderNumberCell># Tickets</HeaderNumberCell>
            <HeaderNumberCell>Total</HeaderNumberCell>
          </Row>
        </thead>
        {accountingByYear.reverse().flatMap(({year, accounting, months}) => {
          const {cents, orderCount, ticketCount} = accounting;
          return [
            ...months.reverse().map(({month, accounting}, i) => {
              const monthStr = capitalize(
                new Date(year, month).toLocaleString('fr-FR', {month: 'long'})
              );
              const {cents, orderCount, ticketCount} = accounting;
              return (
                <Row $even={i % 2 === 1} key={`${year}-${month}`}>
                  <Cell>{monthStr}</Cell>
                  <NumberCell>{orderCount.toLocaleString()}</NumberCell>
                  <NumberCell>{ticketCount.toLocaleString()}</NumberCell>
                  <NumberCell>
                    {(cents / 100).toLocaleString('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'narrowSymbol',
                    })}
                  </NumberCell>
                </Row>
              );
            }),
            <Row key={year}>
              <HeaderCell>{year}</HeaderCell>
              <HeaderNumberCell>{orderCount.toLocaleString()}</HeaderNumberCell>
              <HeaderNumberCell>{ticketCount.toLocaleString()}</HeaderNumberCell>
              <HeaderNumberCell>
                {(cents / 100).toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                  currencyDisplay: 'narrowSymbol',
                })}
              </HeaderNumberCell>
            </Row>,
          ];
        })}
      </Table>
    </Wrapper>
  );
};

AdminAccountingPage.displayName = 'AdminAccountingPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

const Table = styled.table`
  border: solid 2px ${Colors.Gold};
  border-top: none;
  width: max-content;
`;
const Row = styled.tr<{$even?: boolean}>`
  background-color: ${p => (p.$even ? '#00000011' : 'transparent')};
  &:hover {
    cursor: pointer;
    background-color: #00000022;
  }
`;
const HeaderCell = styled.th`
  padding: 8px 12px;
  background: ${Colors.Gold};
  color: #ffffff;
  vertical-align: middle;
`;
const HeaderNumberCell = styled(HeaderCell)`
  text-align: right;
`;
const Cell = styled.td<{$alignRight?: boolean}>`
  padding: 8px 12px;
  white-space: nowrap;
  vertical-align: middle;
  ${p => p.$alignRight && 'text-align: right;'}
`;
const NumberCell = styled(Cell)`
  text-align: right;
`;
