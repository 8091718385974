import {DragEventHandler, useCallback, useState} from 'react';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {notifyError} from '@shared-frontend/lib/notification';
import {Custom} from '@shared-frontend/lib/react';

import {extractFiles} from '@src/components/admin/form/drop_zone_lib';

interface DropZoneDivProps {
  onFilesDropped: (files: File[]) => void;
}

export const DropZoneDiv: Custom<DropZoneDivProps, 'div'> = ({onFilesDropped, ...rest}) => {
  const {
    main: {accentColor},
  } = useTheme();
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = useCallback<DragEventHandler>(evt => {
    if (!evt.dataTransfer.types.includes('Files')) {
      setIsDraggingOver(false);
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    setIsDraggingOver(true);
  }, []);

  const handleDragLeave = useCallback<DragEventHandler>(evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsDraggingOver(false);
  }, []);

  const handleDrop = useCallback<DragEventHandler>(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      setIsDraggingOver(false);
      extractFiles(evt)
        .then(onFilesDropped)
        .catch(err => notifyError(err, {message: 'Erreur lors de la récupération des fichiers'}));
    },
    [onFilesDropped]
  );

  return (
    <Wrapper
      $dragging={isDraggingOver}
      $color={accentColor}
      {...rest}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    />
  );
};
DropZoneDiv.displayName = 'DropZoneDiv';

const Wrapper = styled.div<{$dragging: boolean; $color: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;

  color: ${p => (p.$dragging ? p.$color : '#00000055')};
  border: ${p => (p.$dragging ? p.$color : '#00000055')} 2px dashed;
  border-radius: 8px;

  cursor: pointer;
  &:hover {
    color: ${p => p.$color};
    border-color: ${p => p.$color};
  }

  transition: none 100ms ease-in-out;
  transition-property: color border-color;
`;
