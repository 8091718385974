import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {sharedApiCall} from '@shared-frontend/api';
import {notifyError} from '@shared-frontend/lib/notification';
import {setSession} from '@shared-frontend/lib/session_store';

export const Logout: FC<{disabled?: boolean}> = ({disabled}) => {
  const navigate = useNavigate();
  const {
    main: {api},
  } = useTheme();

  useEffect(() => {
    if (disabled) {
      return;
    }
    sharedApiCall(api, '/logout', {})
      .then(() => {
        setSession(undefined);
        navigate(-1);
      })
      .catch(notifyError);
  }, [disabled, api, navigate]);

  return <Wrapper>See you soon...</Wrapper>;
};

Logout.displayName = 'Logout';

const Wrapper = styled.div`
  width: 95vw;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;
