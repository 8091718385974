import {HoobiizVisibility} from '@shared/dynamo_model';
import {hoobiizUserGroupId} from '@shared/lib/hoobiiz/hoobiiz_ids';

const visibilityCombinations: Record<
  HoobiizVisibility,
  Record<HoobiizVisibility, HoobiizVisibility>
> = {
  [HoobiizVisibility.Public]: {
    [HoobiizVisibility.Public]: HoobiizVisibility.Public,
    [HoobiizVisibility.PrivateHidden]: HoobiizVisibility.PrivateHidden,
    [HoobiizVisibility.Private]: HoobiizVisibility.Private,
  },
  [HoobiizVisibility.PrivateHidden]: {
    [HoobiizVisibility.Public]: HoobiizVisibility.PrivateHidden,
    [HoobiizVisibility.PrivateHidden]: HoobiizVisibility.PrivateHidden,
    [HoobiizVisibility.Private]: HoobiizVisibility.Private,
  },
  [HoobiizVisibility.Private]: {
    [HoobiizVisibility.Public]: HoobiizVisibility.Private,
    [HoobiizVisibility.PrivateHidden]: HoobiizVisibility.Private,
    [HoobiizVisibility.Private]: HoobiizVisibility.Private,
  },
};

export function combineVisibility(
  visibility1: HoobiizVisibility,
  visibility2?: HoobiizVisibility
): HoobiizVisibility {
  if (visibility2 === undefined) {
    return visibility1;
  }
  return visibilityCombinations[visibility1][visibility2];
}

export const HOOBIIZ_ORDER_FAILURE_VERSION = 1; // Hot query used in Dynamo to index HoobiizOrderItemFailureItem

export const ROOT_USER_GROUP_ID = hoobiizUserGroupId('ROOT');
export const ROOT_USER_GROUP_PARENT_ID = hoobiizUserGroupId('');
