export interface FrontendDomainConfig {
  domains: string[];
}

export enum FrontendName {
  Admin = 'admin',
  Onescale = 'www',
  // BcSerrurierVitrier = 'bc-serrurier-vitrier',
  // ServiceAd = 'service-ad',
  // LaVitrineDuWeb = 'lavitrineduweb',
  Restaurant = 'restaurant',
  AgeOfEmpires = 'aoe2',
  Hoobiiz = 'hoobiiz',
}

export const FRONTEND_STATIC_SUBDOMAIN = 'static';
export const FRONTEND_API_SUBDOMAIN = 'api';
export const FRONTEND_WWW_PREFIX = 'www.';

export function trimWwwSubdomain(value: string): string {
  return value.startsWith(FRONTEND_WWW_PREFIX) ? value.slice(FRONTEND_WWW_PREFIX.length) : value;
}

export function getStaticDomain(host: string): string {
  return `//${FRONTEND_STATIC_SUBDOMAIN}.${trimWwwSubdomain(host)}`;
}
