import {ActivityAndSources} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizActivityId,
  HoobiizCat1Id,
  HoobiizCat2Id,
  HoobiizCat3Id,
  HoobiizVendorId,
} from '@shared/dynamo_model';
import {removeAccents} from '@shared/lib/string_utils';

import {getVendorLocationsWithPriority} from '@src/lib/hoobiiz_vendor_location';

export function toSlugName(str: string): string {
  return removeAccents(str)
    .replace(/[^a-zA-Z0-9]+/gu, ' ')
    .split(' ')
    .filter(s => s.length > 0)
    .join('-');
}

export function getCat1Url<T extends {name: string; id: HoobiizCat1Id}>(cat1: T): string {
  return `/c1/${toSlugName(cat1.name)}/${cat1.id}`;
}

export function getCat2Url<T extends {name: string; id: HoobiizCat2Id}>(cat2: T): string {
  return `/c2/${toSlugName(cat2.name)}/${cat2.id}`;
}

export function getCat3Url<T extends {name: string; id: HoobiizCat3Id}>(cat3: T): string {
  return `/c3/${toSlugName(cat3.name)}/${cat3.id}`;
}

export function getActivityUrl<
  T extends {slugName: string; id: HoobiizActivityId} | ActivityAndSources,
>(activity: T): string {
  if ('slugName' in activity) {
    return `/a/${activity.slugName}/${activity.id}`;
  }
  // Add city in the URL if search was made with a city
  let cityQuery = '';
  const vendorLocation = getVendorLocationsWithPriority(activity);
  if (!Array.isArray(vendorLocation) && vendorLocation.address.addressDetails.city !== undefined) {
    cityQuery = `?city=${encodeURIComponent(
      vendorLocation.address.addressDetails.city.toLowerCase()
    )}`;
  }
  return `/a/${activity.item.slugName}/${activity.item.id}${cityQuery}`;
}

export function getVendorUrl<T extends {slugName: string; id: HoobiizVendorId}>(vendor: T): string {
  return `/v/${vendor.slugName}/${vendor.id}`;
}
