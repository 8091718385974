import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType} from '@shared/api/definitions/search_api';
import {HoobiizVendorId, HoobiizVendorItem} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';

import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {VendorForm} from '@src/components/admin/vendor/vendor_form';
import {Colors} from '@src/components/core/theme_base';

export const VendorUpdatePage: FC = () => {
  const navigate = useNavigate();
  const {id = ''} = useParams();
  const vendorId = id as HoobiizVendorId;

  const [vendor, setVendor] = useState<FullItem<'HoobiizVendor'> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/admin/search/get', {
      table: 'HoobiizVendor',
      id: vendorId,
      mode: 'full',
    })
      .then(res => {
        const {item} = res as SearchApiGetType<'HoobiizVendor', 'full'>['res'];
        setVendor(item);
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            'Échec du chargement du partenaire. Vous pouvez rafraichir la page pour réessayer',
        });
        setIsLoading(false);
      });
  }, [vendorId]);

  const handleSubmit = useCallback(
    async (vendorData: HoobiizData<HoobiizVendorItem>) => {
      const {vendor} = await apiCall(HoobiizApi, '/admin/update-vendor', {
        id: vendorId,
        updates: vendorData,
      });
      showSuccess(`Partenaire "${vendor.name}" mis à jour`);
      navigate('/admin/vendor');
    },
    [navigate, vendorId]
  );

  const handleDelete = useCallback(async () => {
    await apiCall(HoobiizApi, '/admin/delete-vendor', {id: vendorId});
    showSuccess(`Partenaire "${vendor?.name}" supprimé`);
    navigate('/admin/vendor');
  }, [navigate, vendor?.name, vendorId]);

  if (!vendor || isLoading) {
    return (
      <LoadingPage>
        Chargement du partenaire
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }
  return (
    <PageWrapper>
      <FormWrapper>
        <FormTitle>{`Modifier le partenaire ${vendor.name}`}</FormTitle>
        <VendorForm
          initialData={vendor}
          submitButtonText="Modifier"
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </FormWrapper>
    </PageWrapper>
  );
};
VendorUpdatePage.displayName = 'VendorUpdatePage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;
