import {ExpertTicketReservationResponse} from '@shared/api/definitions/expert_ticket_api';
import {ExpertTicketTransaction} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_transaction';

import {createMapStore} from '@shared-frontend/lib/map_data_store';

// Reservation

const reservationStore = createMapStore<string, ExpertTicketReservationResponse>();

export const setExpertTicketReservation = reservationStore.setData;
export const useExpertTicketReservation = reservationStore.useData;
export const useAllExpertTicketReservation = reservationStore.useAllData;

// Transaction

const transactionStore = createMapStore<string, ExpertTicketTransaction>();

export const setExpertTicketTransaction = transactionStore.setData;
export const batchSetExpertTicketTransaction = transactionStore.batchSetData;
export const useExpertTicketTransaction = transactionStore.useData;
export const useAllExpertTicketTransaction = transactionStore.useAllData;

// Document

const documentStore = createMapStore<string, string[]>();

export const setExpertTicketDocument = documentStore.setData;
export const batchSetExpertTicketDocument = documentStore.batchSetData;
export const useExpertTicketDocument = documentStore.useData;
export const useAllExpertTicketDocument = documentStore.useAllData;
