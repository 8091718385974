import {FC} from 'react';
import styled from 'styled-components';

import {Spacing} from '@shared-frontend/components/core/spacing';

import {PageWrapper} from '@src/components/admin/page_fragment';
import {VendorTable} from '@src/components/admin/vendor/vendor_table';
import {AdminNavLink} from '@src/components/core/theme';

export const VendorDashboard: FC = () => {
  return (
    <PageWrapper>
      <Top>
        <AdminNavLink to={'/admin/vendor/create'}>Ajouter un nouveau partenaire</AdminNavLink>
      </Top>
      <Spacing height={24} />
      <VendorTable />
    </PageWrapper>
  );
};
VendorDashboard.displayName = 'VendorDashboard';

const Top = styled.div`
  display: flex;
  align-items: center;
`;
