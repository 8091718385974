export const LYON_CENTER_COORDINATE = {
  lat: 45.7549778,
  lng: 4.8556102,
};
const lyonLocationBiasRadius = 5000; // in meters
export const LYON_LOCATION_BIAS = `circle:${lyonLocationBiasRadius}@${LYON_CENTER_COORDINATE.lat},${LYON_CENTER_COORDINATE.lng}`;

export const FRANCE_CENTER_COORDINATE = {
  lat: 46.664517,
  lng: 1.999512,
};
const franceLocationBiasRadius = 549480; // in meters
export const FRANCE_LOCATION_BIAS = `circle:${franceLocationBiasRadius}@${FRANCE_CENTER_COORDINATE.lat},${FRANCE_CENTER_COORDINATE.lng}`;
