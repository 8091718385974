import {FC} from 'react';
import styled from 'styled-components';

import {TabbedPage} from '@shared-frontend/components/core/tabbed_page';

import {AdminOrderExplorerPage} from '@src/components/admin/explorer/admin_order_explorer_page';
import {AdminOrderFailureExplorerPage} from '@src/components/admin/explorer/admin_order_failure_explorer_page';
// import {AdminPaymentExplorerPage} from '@src/components/admin/explorer/admin_payment_explorer_page';

interface AdminExplorerPageProps {}

export const AdminExplorerPage: FC<AdminExplorerPageProps> = () => {
  return (
    <Wrapper>
      <TabbedPage
        tabs={[
          {
            name: 'orders',
            label: 'Commandes',
            page: <AdminOrderExplorerPage />,
          },
          {
            name: 'orders-errors',
            label: 'Échecs Commandes',
            page: <AdminOrderFailureExplorerPage />,
          },
          // {
          //   name: 'payments',
          //   label: 'Paiements',
          //   page: <AdminPaymentExplorerPage />,
          // },
        ]}
      />
    </Wrapper>
  );
};

AdminExplorerPage.displayName = 'AdminExplorerPage';

const Wrapper = styled.div`
  display: flex;
  padding-top: 32px;
  height: 100%;
`;
