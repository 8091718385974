import {createElement, FC, ReactNode, useCallback, useEffect, useMemo} from 'react';
import {Navigate} from 'react-router';
import {useLocation} from 'react-router-dom';

import {SeoObject} from '@shared/frontends/ssr_context';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {withTracker} from '@shared-frontend/components/core/with_tracker';
import {ComponentClass, EmptyFragment, useWrappedComponentClass} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

export interface WrapRouteOptions {
  useTracker?: boolean;
  disabled?: boolean;
  sessionRequired?: boolean;
}

export const useRoute = (
  ComponentClass: ComponentClass | ComponentClass[],
  seoObject: SeoObject,
  options?: WrapRouteOptions
): ReactNode => {
  const {title, description} = seoObject;
  const seo = useMemo<SeoObject>(() => ({title, description}), [description, title]);
  return useMemo(() => {
    if (options?.disabled) {
      return EmptyFragment;
    }
    const Component: FC = () => {
      const {pathname, hash, key} = useLocation();
      const {setSeo} = useSsrContext();
      const session = useSession();

      const updateSeo = useCallback(() => {
        setSeo(seo);
      }, [setSeo]);

      updateSeo();
      useEffect(() => {
        // Update SEO on page change
        updateSeo();
        // if not a hash link, scroll to top
        if (hash === '') {
          document.body.scrollTo({behavior: 'instant', left: 0, top: 0});
        }
        // else scroll to id
        else {
          setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView();
            }
          }, 0);
        }
      }, [pathname, hash, key, updateSeo]);

      const Classes = Array.isArray(ComponentClass) ? ComponentClass : [ComponentClass];
      const WrappedClass = useWrappedComponentClass(Classes);

      if (!session && options?.sessionRequired) {
        return <Navigate to="/login" state={{from: pathname}} replace />;
      }
      return <WrappedClass />;
    };
    Component.displayName = `useRoute`;

    return options?.useTracker ? withTracker(Component) : createElement(Component);
  }, [ComponentClass, options?.disabled, options?.sessionRequired, options?.useTracker, seo]);
};
