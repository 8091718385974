const handlers = new Set<() => void>();

export function pushHistoryState(url: string): void {
  window.history.pushState({path: url}, '', url);
  for (const fn of handlers.values()) {
    fn();
  }
}

export function replaceHistoryState(url: string): void {
  window.history.replaceState({path: url}, '', url);
  for (const fn of handlers.values()) {
    fn();
  }
}

export function addHistoryStateListener(fn: () => void): () => void {
  handlers.add(fn);
  return () => handlers.delete(fn);
}
