import {FC, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {HoobiizRegistrationCode, HoobiizUserGroupId} from '@shared/dynamo_model';

import {Button} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useStateRef} from '@shared-frontend/lib/use_state_ref';

import {FormFlex} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {AdminUserAndGroupChain} from '@src/components/admin/user_and_group/admin_user_and_group_chain';
import {useHoobiizUserGroupModalPath} from '@src/components/admin/user_and_group/admin_user_and_group_paths';
import {
  deleteHoobiizUserGroup,
  hoobiizUserGroupHierarchyToGroupIds,
  updateHoobiizUserGroup,
  useHoobiizUserGroup,
  useHoobiizUserGroupHierarchy,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {AdminUserAndGroupTree} from '@src/components/admin/user_and_group/admin_user_and_group_tree';
import {AdminUserAndGroupUserProfilePermissionsForm} from '@src/components/admin/user_and_group/admin_user_and_group_user_profile_permissions_form';
import {adminInputTheme, AlertButton} from '@src/components/core/theme';

interface AdminUserAndGroupModalProps {
  groupId: HoobiizUserGroupId;
}

const emptyGroupRegistrationCode = '' as HoobiizRegistrationCode;

export const AdminUserAndGroupModal: FC<AdminUserAndGroupModalProps> = props => {
  const {groupId} = props;
  const navigate = useNavigate();
  const group = useHoobiizUserGroup({groupId});
  const groupIds = hoobiizUserGroupHierarchyToGroupIds(useHoobiizUserGroupHierarchy({groupId}));
  const parentGroupModalPath = useHoobiizUserGroupModalPath({
    groupId: group?.item.parentGroupId ?? groupId,
  });

  //
  // Handle rename
  //
  const [newGroupLabel, setNewGroupLabel, newGroupLabelRef] = useStateRef('');
  const [newGroupRegistrationCode, setNewGroupRegistrationCode, newGroupRegistrationCodeRef] =
    useStateRef(emptyGroupRegistrationCode);
  useEffect(() => {
    if (!group) {
      return;
    }
    setNewGroupLabel(group.item.groupLabel);
    setNewGroupRegistrationCode(group.item.groupRegistrationCode ?? emptyGroupRegistrationCode);
  }, [group, setNewGroupRegistrationCode, setNewGroupLabel]);

  const handleEditClick = useCallback(async () => {
    await updateHoobiizUserGroup({
      groupId,
      newLabel: newGroupLabelRef.current,
      newRegistrationCode: newGroupRegistrationCodeRef.current,
    });
  }, [groupId, newGroupLabelRef, newGroupRegistrationCodeRef]);

  //
  // Handle delete
  //
  const handleDeleteClick = useCallback(async () => {
    if (!group) {
      return;
    }
    await deleteHoobiizUserGroup({groupId: group.item.groupId});
    navigate(parentGroupModalPath);
  }, [group, navigate, parentGroupModalPath]);

  //
  // Tree UI
  //
  const isGroupHighlighted = useCallback(
    (id: HoobiizUserGroupId): boolean => {
      // Highlight the group hierarchy
      return groupIds.includes(id);
    },
    [groupIds]
  );
  const isCurrentGroup = useCallback(
    (id: HoobiizUserGroupId): boolean => {
      return groupId === id;
    },
    [groupId]
  );
  const isGroupExpanded = useCallback(
    (groupIdToExpand: HoobiizUserGroupId): boolean => {
      // Don't open the current group to not overwhelm the UI
      return groupIdToExpand !== groupId && groupIds.includes(groupIdToExpand);
    },
    [groupId, groupIds]
  );
  const preventUserAutoLoad = useCallback(
    (groupIdToAutoLoad: HoobiizUserGroupId): boolean => {
      // Don't auto load the groups users unless this is the current group to not overwhelm the UI
      return groupIdToAutoLoad !== groupId;
    },
    [groupId]
  );

  if (!group) {
    return EmptyFragment;
  }

  return (
    <Wrapper>
      <AdminUserAndGroupChain groupId={groupId} />
      <FormSection title="Information">
        <FormFlex>
          <FlexInput
            placeholder="Nom"
            value={newGroupLabel}
            syncState={setNewGroupLabel}
            overrides={adminInputTheme}
          />
        </FormFlex>
        <FormFlex>
          <FlexInput
            placeholder="Code premium"
            value={newGroupRegistrationCode}
            syncState={setNewGroupRegistrationCode}
            overrides={adminInputTheme}
          />
        </FormFlex>
        <FormFlex>
          <Button onClickAsync={handleEditClick}>Enregistrer</Button>
        </FormFlex>
      </FormSection>
      <FormSection title="Permissions">
        <AdminUserAndGroupUserProfilePermissionsForm groupId={groupId} />
      </FormSection>
      <FormSection title="Hiérarchie">
        <AdminUserAndGroupTree
          isGroupHighlighted={isGroupHighlighted}
          isCurrentGroup={isCurrentGroup}
          isGroupExpanded={isGroupExpanded}
          preventUserAutoLoad={preventUserAutoLoad}
        />
      </FormSection>
      <AlertButton onClickAsync={handleDeleteClick}>Supprimer le groupe</AlertButton>
    </Wrapper>
  );
};

AdminUserAndGroupModal.displayName = 'AdminUserAndGroupModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border-radius: 16px;
  background-color: #fff;
  width: 90vw;
  max-width: 800px;
`;

const FlexInput = styled(Input)`
  flex-grow: 1;
`;
