import {FC} from 'react';

import {
  HoobiizFlexibleStockSchedule,
  HoobiizFlexibleStockScheduleType,
  HoobiizOpeningHours,
} from '@shared/dynamo_model';

import {HoobiizFlexibleStockScheduleInheritForm} from '@src/components/admin/activity_stock/hoobiiz_flexible_stock_schedule_inherit_form';
import {HoobiizFlexibleStockScheduleOverrideForm} from '@src/components/admin/activity_stock/hoobiiz_flexible_stock_schedule_override_form';
import {MultiForm} from '@src/components/admin/form/multi_form';

interface HoobiizFlexibleStockScheduleFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: HoobiizFlexibleStockSchedule;
  onChange: (newVal: HoobiizFlexibleStockSchedule | undefined) => void;
}

export const HoobiizFlexibleStockScheduleForm: FC<
  HoobiizFlexibleStockScheduleFormProps
> = props => {
  const {vendorHours, initialData, onChange} = props;

  return (
    <MultiForm
      forms={{
        [HoobiizFlexibleStockScheduleType.Inherit]: HoobiizFlexibleStockScheduleInheritForm,
        [HoobiizFlexibleStockScheduleType.Override]: HoobiizFlexibleStockScheduleOverrideForm,
      }}
      labels={[
        {label: 'Horaires du partenaire', value: HoobiizFlexibleStockScheduleType.Inherit},
        {label: 'Autre', value: HoobiizFlexibleStockScheduleType.Override},
      ]}
      onChange={onChange}
      initialType={initialData?.type ?? HoobiizFlexibleStockScheduleType.Inherit}
      initialData={initialData}
      extra={{vendorHours}}
    />
  );
};
HoobiizFlexibleStockScheduleForm.displayName = 'HoobiizFlexibleStockScheduleForm';
