import {FC, Fragment, JSX, ReactElement} from 'react';
import {Route} from 'react-router';

import {FrontendUserAuthMethodType} from '@shared/dynamo_model';
import {IS_PRODUCTION_ENV} from '@shared/env_constants';
import {FrontendName} from '@shared/frontends/frontend_constant';
import {SeoObject} from '@shared/frontends/ssr_context';
import {useTheme} from '@shared/frontends/theme_context';
import {removeUndefined} from '@shared/lib/type_utils';
import {FrontendUserDataType} from '@shared/model/auth/user';

import {AdminApp} from '@shared-frontend/components/admin/admin_app';
import {FrontendUserDataFormProps} from '@shared-frontend/components/auth/frontend_user_data_form';
import {Link} from '@shared-frontend/components/auth/link';
import {Login} from '@shared-frontend/components/auth/login';
import {Logout} from '@shared-frontend/components/auth/logout';
import {Register} from '@shared-frontend/components/auth/register';
import {RegisterInvite} from '@shared-frontend/components/auth/register_invite';
import {RegisterLink} from '@shared-frontend/components/auth/register_link';
import {useRoute, WrapRouteOptions} from '@shared-frontend/components/core/use_route';
import {FrontendUi} from '@shared-frontend/components/frontend_ui';
import {ComponentClass, useComponentClass} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

export interface AdminRoute {
  path: string;
  navItem?: string | JSX.Element;
  page: ComponentClass;
}

interface SharedRoutesOpts<Name extends FrontendName> extends WrapRouteOptions {
  appWrapper?: ComponentClass;
  adminWrapper?: ComponentClass;
  adminRoutes?: AdminRoute[];
  seo: SeoObject;
  userDataForm: FrontendUserDataType<Name> extends undefined
    ? undefined
    : FC<FrontendUserDataFormProps<Exclude<FrontendUserDataType<Name>, undefined>>>;
}

function useSharedRoute<Name extends FrontendName>(
  path: string,
  ComponentClasses: (ComponentClass | undefined)[],
  opts: Omit<SharedRoutesOpts<Name>, 'appWrapper' | 'adminWrapper' | 'userDataForm'>
): JSX.Element {
  const {seo, useTracker, disabled} = opts;
  const element = useRoute(removeUndefined(ComponentClasses), seo, {useTracker, disabled});
  return disabled ? (
    <Fragment key={path}></Fragment>
  ) : (
    <Route key={path} path={path} element={element} />
  );
}

export function useSharedRoutes<Name extends FrontendName>(
  sharedRoutesOpts: SharedRoutesOpts<Name>
): ReactElement[] {
  const {
    auth: {userAuthTypes},
  } = useTheme();
  const {adminWrapper, appWrapper, adminRoutes, userDataForm, ...opts} = sharedRoutesOpts;
  const session = useSession();
  const noPasswordAuth = !userAuthTypes.includes(FrontendUserAuthMethodType.Password);
  const notAdmin = !session?.isAdmin;

  return [
    useSharedRoute('/login', [appWrapper, Login], {...opts}),
    useSharedRoute('/register-invite', [appWrapper, RegisterInvite], {...opts}),
    useSharedRoute('/logout', [appWrapper, Logout], {...opts}),
    useSharedRoute('/link/:id', [appWrapper, Link], {...opts}),
    useSharedRoute('/ui', [appWrapper, FrontendUi], {...opts, disabled: IS_PRODUCTION_ENV}),
    useSharedRoute(
      '/register',
      [appWrapper, useComponentClass(<Register UserDataForm={userDataForm} />)],
      {...opts, disabled: noPasswordAuth}
    ),
    useSharedRoute('/register-link/:id', [appWrapper, RegisterLink], {
      ...opts,
      disabled: noPasswordAuth,
    }),
    useSharedRoute(
      '/admin/*',
      [adminWrapper, useComponentClass(<AdminApp routes={adminRoutes} />)],
      {
        ...opts,
        disabled: notAdmin,
      }
    ),
  ];
}
