/**
 * Random number between min and max (inclusive)
 */
export function randUnsafe(min: number, max: number): number {
  return Math.floor(min + Math.random() * (max - min + 1));
}

const minPort = 10000;
const maxPort = 65535;
export function getRandomPortUnsafe(): number {
  return randUnsafe(minPort, maxPort);
}

const minIp = 1;
const maxIp = 254;
export function getRandomIpUnsafe(): string {
  return `${randUnsafe(minIp, maxIp)}.${randUnsafe(minIp, maxIp)}.${randUnsafe(
    minIp,
    maxIp
  )}.${randUnsafe(minIp, maxIp)}`;
}

export const DEFAULT_SET = 'abcdefghijklmnopqrstuvwxyz0123456789';
export const ALPHANUM_SET = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
export const URL_SAFE_SET = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~';
export function randomStringUnsafe(length: number, set?: string): string {
  let result = '';
  const characters = set ?? DEFAULT_SET;
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const UID_LENGTH = 16;

// [...new Array(16)].map(_ => Math.random().toString(36)[2]).join('')
export function uidUnsafe(prefix?: string): string {
  const id = randomStringUnsafe(UID_LENGTH);
  return prefix === undefined ? id : `${prefix}${id}`;
}
export function smallUidUnsafe(prefix?: string): string {
  const id = randomStringUnsafe(6); // eslint-disable-line @typescript-eslint/no-magic-numbers
  return prefix === undefined ? id : `${prefix}${id}`;
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export function randomHexUnsafe(length: number): string {
  const maxlen = 8;
  const min = 16 ** (Math.min(length, maxlen) - 1);
  const max = 16 ** Math.min(length, maxlen) - 1;
  const n = randUnsafe(min, max);
  let r = n.toString(16);
  while (r.length < length) {
    r += randomHexUnsafe(length - maxlen);
  }
  return r;
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

export function randomDigitsUnsafe(length: number): string {
  const min = 10 ** (length - 1);
  const max = 10 ** length - 1;
  return String(randUnsafe(min, max));
}

export function arrayRandomUnsafe<T>(arr: T[]): T | undefined {
  if (arr.length === 0) {
    return undefined;
  }
  return arr[Math.floor(Math.random() * arr.length)];
}

export function chooseRandomItemsInArray<T>(arr: T[], {qty}: {qty: number}): T[] {
  const pool = [...arr]; // clone so we can splice without changing the original array
  const chosen: T[] = [];
  for (let counter = 0; counter < qty; counter++) {
    const [element] = pool.splice(Math.floor(Math.random() * pool.length), 1);
    if (element === undefined) {
      break;
    }
    chosen.push(element);
  }
  return chosen;
}

export function shuffleArray<T>(arr: T[]): T[] {
  return chooseRandomItemsInArray(arr, {qty: arr.length});
}

export function randBetween(min: number, max: number): number {
  return min + Math.random() * (max - min);
}
