import {FC, useEffect, useState} from 'react';

import {HoobiizExpertTicketStockId} from '@shared/dynamo_model';
import {hoobiizExpertTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {removeUndefined} from '@shared/lib/type_utils';

import {MaybeExpertTicket} from '@src/components/admin/activity_stock/hoobiiz_expert_ticket_info_form';
import {HoobiizExpertTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_expert_ticket_info_multi_form';
import {FormWrapper} from '@src/components/admin/form/form_fragments';

export interface ExpertTicketStockAndId {
  id?: HoobiizExpertTicketStockId;
  ticketInfo?: MaybeExpertTicket[];
}

interface ActivityExpertTicketStockTicketsFormProps {
  initial?: ExpertTicketStockAndId;
  onChange?: (ticket: ExpertTicketStockAndId) => void;
}

export const ActivityExpertTicketStockTicketsForm: FC<
  ActivityExpertTicketStockTicketsFormProps
> = props => {
  const {initial, onChange} = props;

  const [ticketInfo, setTicketInfo] = useState<MaybeExpertTicket[]>(
    initial?.ticketInfo ?? [{id: hoobiizExpertTicketInfoId()}]
  );

  useEffect(() => {
    onChange?.({
      id: initial?.id,
      ticketInfo: removeUndefined(ticketInfo),
    });
  }, [onChange, initial?.id, ticketInfo]);

  return (
    <FormWrapper>
      <HoobiizExpertTicketInfoMultiForm initialData={ticketInfo} onChange={setTicketInfo} />
    </FormWrapper>
  );
};
ActivityExpertTicketStockTicketsForm.displayName = 'ActivityExpertTicketStockTicketsForm';
