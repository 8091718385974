import {FC} from 'react';
import styled from 'styled-components';

interface AdminUserAndGroupUserCountProps {
  userCount: number;
}

export const AdminUserAndGroupUserCount: FC<AdminUserAndGroupUserCountProps> = props => {
  const {userCount} = props;
  return <Wrapper>{userCount.toLocaleString()}</Wrapper>;
};

AdminUserAndGroupUserCount.displayName = 'AdminUserAndGroupUserCount';

const Wrapper = styled.div`
  display: flex;
  padding: 2px 5px;
  font-size: 80%;
  background-color: #aaa;
  border-radius: 6px;
  color: #fff;
`;
