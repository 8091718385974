import {FC} from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

import {HoobiizCat1Id} from '@shared/dynamo_model';

import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizCat2ListView} from '@src/components/ui/hoobiiz_cat2_list_view';

interface Cat1PageProps {}

export const Cat1Page: FC<Cat1PageProps> = () => {
  const {cat1Id: cat1IdStr = ''} = useParams();
  const cat1Id = cat1IdStr as HoobiizCat1Id;

  return (
    <Wrapper>
      <HoobiizCat2ListView cat1Id={cat1Id} />
      <HoobiizActivityModule catId={cat1Id} cse={{mode: 'all', showCheckbox: true}} />
    </Wrapper>
  );
};

Cat1Page.displayName = 'Cat1Page';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;
