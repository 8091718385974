import {Dispatch, FC, SetStateAction} from 'react';

import {HoobiizVisibility} from '@shared/dynamo_model';

import {Radios} from '@shared-frontend/components/core/radios';

import {adminRadioTheme} from '@src/components/core/theme';
import {HoobiizVisibilityLabels} from '@src/lib/hoobiiz_visibility';

interface HoobiizVisibilityFormProps {
  value: HoobiizVisibility;
  syncState: Dispatch<SetStateAction<HoobiizVisibility>>;
  tall?: boolean;
}

export const HoobiizVisibilityForm: FC<HoobiizVisibilityFormProps> = props => {
  const {value, syncState, tall} = props;

  return (
    <Radios
      value={value}
      values={[HoobiizVisibility.Private, HoobiizVisibility.Public].map(visibility => ({
        label: HoobiizVisibilityLabels[visibility],
        value: visibility,
      }))}
      label={'VISIBILITÉ'}
      syncState={syncState}
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      overrides={{...adminRadioTheme, inputHeight: tall ? 40 : undefined}}
    />
  );
};
HoobiizVisibilityForm.displayName = 'HoobiizVisibilityForm';
