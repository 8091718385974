import {FC} from 'react';
import styled from 'styled-components';

import {NavLink} from '@shared-frontend/components/core/button';

import {Sizes} from '@src/components/core/theme_base';

export const TermsPage: FC = () => {
  return (
    <Wrapper>
      <Text>
        Le site présent est la propriété de la société ONESCALE. Les informations recueillies sur
        nos pages font l'objet d'un traitement informatique.
      </Text>
      <Title>
        Le site web <CustomNavLink to="/">youpiiz.com</CustomNavLink>
      </Title>
      <Text>
        Le destinataire des données est la société ONESCALE, société française basée à Lyon.
        Conformément à la loi « informatique et libertés » du 6 Janvier 1978, vous bénéficiez d'un
        droit de rectification des informations qui vous concernent.
        <Text>
          Le site web <CustomNavLink to="/">youpiiz.com</CustomNavLink> est édité par la société
          ONESCALE.
        </Text>
        <Text>Siège social : 4 rue de la République 69001 Lyon</Text>
        <Text>SIRET : 87748309900025</Text>
        <Text>RC Lyon : 877 483 099</Text>
      </Text>
      <Title>Hébergement</Title>
      <Text>
        Le site <CustomNavLink to="/">youpiiz.com</CustomNavLink> est hébergé par AWS (Amazon Web
        Services).
      </Text>
      <Text>Siège social : 38 avenue John F. Kennedy, L - 1885 Luxembourg</Text>
      <Text>
        L'ensemble de ce site relève des législations françaises et internationales sur le droit
        d'auteur et la propriété intellectuelle.
      </Text>
      <Text>
        Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques
        et photographiques.
      </Text>
    </Wrapper>
  );
};

TermsPage.displayName = 'TermsPage';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${Sizes.Content};
  margin: auto;
  padding: 60px 16px;
`;

const CustomNavLink = styled(NavLink)`
  text-decoration: underline;
`;

const Title = styled.div`
  font-size: 30px;
  margin: 20px 0;
`;

const Text = styled.p`
  margin: 10px 0;
`;
