import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat1Id} from '@shared/dynamo_model';

import {NavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizCat2View} from '@src/components/ui/hoobiiz_cat2_view';
import {getCat2Url} from '@src/lib/hoobiiz_urls';

interface HoobiizCat2ListViewProps {
  cat1Id: HoobiizCat1Id;
}

export const HoobiizCat2ListView: FC<HoobiizCat2ListViewProps> = props => {
  const {cat1Id} = props;
  const {data} = useApiCall(HoobiizApi, '/list-cat2', {cat1Id});

  if (data === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <BackNavLink to="/">
        <SvgIcon name="ChevronLeft" size={11} color={Colors.DarkGold} />
        Retour
      </BackNavLink>
      <Title>{data.cat1.name}</Title>
      <Description>{data.cat1.description}</Description>
      {data.cat2.length === 0 ? (
        EmptyFragment
      ) : (
        <Gradient>
          <Carousel>
            {data.cat2.map(cat => (
              <HoobiizCat2View
                key={cat.id}
                to={getCat2Url(cat)}
                name={cat.name}
                media={cat.media}
              />
            ))}
          </Carousel>
        </Gradient>
      )}
    </Wrapper>
  );
};

HoobiizCat2ListView.displayName = 'HoobiizCat2ListView';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Gradient = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(to right, transparent, #ffffff);
    pointer-events: none;
  }
`;

const Carousel = styled.div`
  display: flex;
  height: 100px;
  overflow-x: auto;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${Colors.DarkGrey};
`;

const BackNavLink = styled(NavLink)`
  gap: 8px;
`;
