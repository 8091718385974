import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType} from '@shared/api/definitions/search_api';
import {HoobiizActivityId, HoobiizActivityItem} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';

import {ActivityForm} from '@src/components/admin/activity/activity_form';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {Colors} from '@src/components/core/theme_base';

interface ActivityUpdatePageProps {
  activityId: HoobiizActivityId;
}

export const ActivityUpdatePage: FC<ActivityUpdatePageProps> = props => {
  const {activityId} = props;

  const navigate = useNavigate();

  const [activity, setActivity] = useState<FullItem<'HoobiizActivity'> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/admin/search/get', {
      table: 'HoobiizActivity',
      id: activityId,
      mode: 'full',
    })
      .then(res => {
        const {item} = res as SearchApiGetType<'HoobiizActivity', 'full'>['res'];
        setActivity(item);
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            "Échec du chargement de l'activité. Vous pouvez rafraichir la page pour réessayer",
        });
        setIsLoading(false);
      });
  }, [activityId]);

  const handleSubmit = useCallback(
    async (activityData: HoobiizData<HoobiizActivityItem>) => {
      const {activity} = await apiCall(HoobiizApi, '/admin/update-activity', {
        id: activityId,
        updates: activityData,
      });
      showSuccess(`Activité "${activity.label}" mise à jour`);
      navigate('/admin/activity');
    },
    [navigate, activityId]
  );

  const handleDelete = useCallback(async () => {
    await apiCall(HoobiizApi, '/admin/delete-activity', {id: activityId});
    showSuccess(`Activité "${activity?.label}" supprimée`);
    navigate('/admin/activity');
  }, [navigate, activity?.label, activityId]);

  if (!activity || isLoading) {
    return (
      <LoadingPage>
        Chargement de l'activité
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }
  return (
    <ActivityForm
      initialData={activity}
      submitButtonText="Modifier"
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  );
};
ActivityUpdatePage.displayName = 'ActivityUpdatePage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;
