import {useCallback} from 'react';
import {styled} from 'styled-components';

import {HoobiizTicketData, HoobiizTimePeriod} from '@shared/dynamo_model';

import {Button} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {FormFlex} from '@src/components/admin/form/form_fragments';
import {HoobiizTimePeriodForm} from '@src/components/admin/form/hoobiiz_time_period_form';
import {adminInputTheme} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';

interface HoobiizTicketDataWithPeriodFormProps {
  data: HoobiizTicketData;
  period: HoobiizTimePeriod;
  onDelete: (data: HoobiizTicketData) => void;
  onChange: (
    oldData: HoobiizTicketData,
    newData: HoobiizTicketData,
    newPeriod: HoobiizTimePeriod
  ) => void;
}

export const HoobiizTicketDataWithPeriodForm: Custom<
  HoobiizTicketDataWithPeriodFormProps,
  'div',
  'onChange'
> = props => {
  const {data, period, onDelete, onChange, ...rest} = props;

  const handleDeleteClick = useCallback(() => onDelete(data), [data, onDelete]);

  const handleTicketCodeChange = useCallback(
    (newCode: string) => onChange(data, {...data, code: {value: newCode}}, period),
    [data, onChange, period]
  );
  const handlePeriodChange = useCallback(
    (newPeriod: HoobiizTimePeriod) => onChange(data, data, newPeriod),
    [data, onChange]
  );

  const title = data.files !== undefined ? 'Ticket PDF :' : 'Ticket Code :';
  let content = EmptyFragment;
  if (data.code !== undefined) {
    content = (
      <Input
        value={data.code.value}
        syncState={handleTicketCodeChange}
        overrides={adminInputTheme}
      />
    );
  }
  if (data.files !== undefined) {
    content = (
      <div>
        {data.files.map(f => (
          <div key={f.id}>{f.id}</div>
        ))}
      </div>
    );
  }

  return (
    <Wrapper {...rest}>
      <Band>
        <SvgIcon name="Ticket" color="#ffffff" size={24} rotate={90} />
      </Band>
      <Content>
        <Title>{title}</Title>
        {content}
      </Content>
      <HoobiizTimePeriodForm
        startLabel="DÉBUT CRÉNEAUX"
        endLabel="FIN CRÉNEAUX"
        initialData={period}
        onChange={handlePeriodChange}
      />
      <DeleteButton onClick={handleDeleteClick}>
        <SvgIcon name="Trash" color="#ffffff" size={24} />
      </DeleteButton>
    </Wrapper>
  );
};
HoobiizTicketDataWithPeriodForm.displayName = 'HoobiizTicketDataWithPeriodForm';

const Wrapper = styled(FormFlex)`
  position: relative;
  border: solid 2px ${Colors.Gold};
  border-radius: 8px;
  align-items: center;
  padding: 8px 8px 8px 58px;
`;
const Title = styled.div``;

const Band = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  background-color: ${Colors.Gold};
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(Button)`
  height: 48px;
  width: 48px;
  padding: 0;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
`;
