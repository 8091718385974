import {FC, Fragment, MouseEvent, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCartItemId, StripePaymentIntentId} from '@shared/dynamo_model';
import {arrayJoin, groupBy} from '@shared/lib/array_utils';
import {currencyAmountToString} from '@shared/lib/hoobiiz/currency_amount';

import {apiCall} from '@shared-frontend/api';
import {Button, Link} from '@shared-frontend/components/core/button';
import {showAlert} from '@shared-frontend/components/core/notifications';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {HoobiizCartItem} from '@src/components/ui/hoobiiz_cart_item';
import {HoobiizOrderItem} from '@src/components/ui/hoobiiz_order_item';
import {PaymentForm} from '@src/components/ui/payment_form';
import {Total} from '@src/components/ui/total';
import {computeCartTotal, computeOrdersTotal} from '@src/lib/currency_amount';
import {increaseCartCount, setCartCount} from '@src/lib/stores';

export const CartPage: FC = () => {
  const {
    data: cart,
    setData: setCart,
    refreshData: refreshCart,
  } = useApiCall(
    HoobiizApi,
    '/list-cart',
    {},
    {errMsg: `Échec du chargement du panier. Vous pouvez rafraichir la page pour réessayer`}
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (cart) {
      setCartCount(cart.cartItems.length + cart.pendingOrders.length);
    }
  }, [cart]);

  const itemCount = useCallback(
    (paymentId: StripePaymentIntentId) => {
      if (!cart) {
        return 0;
      }
      return cart.pendingOrders.filter(o => o.paymentId === paymentId).length;
    },
    [cart]
  );

  const handlePaymentSuccess = useCallback(
    (paymentId: StripePaymentIntentId) => {
      increaseCartCount(-itemCount(paymentId));
      navigate(`/orders/${paymentId}`);
    },
    [itemCount, navigate]
  );

  const handleCancelPayment = useCallback(
    async (evt: MouseEvent) => {
      const paymentId = (evt.currentTarget.getAttribute('data-id') ?? '') as StripePaymentIntentId;
      await apiCall(HoobiizApi, '/cancel-purchase', {paymentId});
      await refreshCart();
    },
    [refreshCart]
  );

  const handleDeleteClick = useCallback(
    async (id: HoobiizCartItemId) => {
      await apiCall(HoobiizApi, '/remove-from-cart', {id});
      setCart(cart =>
        cart === undefined
          ? undefined
          : {...cart, cartItems: cart.cartItems.filter(item => item.cartItem.id !== id)}
      );
      increaseCartCount(-1);
    },
    [setCart]
  );

  const handlePurchaseCart = useCallback(async () => {
    const {failedItems, payment} = await apiCall(HoobiizApi, '/start-cart-purchase', {});
    if (!payment) {
      showAlert(`Échec lors du démarrage de la commande`);
      return;
    }
    if (failedItems.length === 1) {
      showAlert(`Un billet n'a pas pu être ajouté à la commande`);
    } else if (failedItems.length > 1) {
      showAlert(`${failedItems.length} billets n'ont pas pu être ajoutés à la commande`);
    }
    await refreshCart();
  }, [refreshCart]);

  if (!cart) {
    return <Wrapper>Chargement...</Wrapper>;
  }

  const payments = [
    ...groupBy(cart.pendingOrders, pendingOrder => pendingOrder.paymentId).values(),
  ];

  const cartTotal = computeCartTotal(cart.cartItems);

  return (
    <Wrapper>
      {payments.length === 0
        ? EmptyFragment
        : payments.map(orders => {
            const orderTotal = computeOrdersTotal(orders);
            return (
              <Fragment key={orders[0].paymentId}>
                <Title>Commande en cours</Title>
                <Section>
                  <ListWrapper>
                    {arrayJoin(
                      orders.map(order => <HoobiizOrderItem key={order.id} orderItem={order} />),
                      i => (
                        <Separator key={i} />
                      )
                    )}
                  </ListWrapper>
                  <PurchaseForm>
                    <PaymentFormTotal>{`Montant à payer : ${currencyAmountToString(
                      orderTotal.total.price3
                    )}`}</PaymentFormTotal>
                    <PaymentForm
                      total={orderTotal.total.price3}
                      paymentId={orders[0].paymentId}
                      clientSecret={orders[0].paymentClientSecret ?? ''}
                      onPaymentSuccess={handlePaymentSuccess}
                    />
                    <CancelWrapper>
                      <Link data-id={orders[0].paymentId} onClickAsync={handleCancelPayment}>
                        Annuler et remettre dans le panier
                      </Link>
                    </CancelWrapper>
                  </PurchaseForm>
                </Section>
                <Spacing height={64} />
              </Fragment>
            );
          })}
      {cart.cartItems.length === 0 ? (
        payments.length === 0 ? (
          <div>Panier vide</div>
        ) : (
          EmptyFragment
        )
      ) : (
        <Fragment>
          <Title>Panier</Title>
          <Section>
            <ListWrapper>
              {arrayJoin(
                cart.cartItems.map(cartItem => (
                  <HoobiizCartItem
                    key={cartItem.cartItem.id}
                    fullCartItem={cartItem}
                    onDeleteClick={handleDeleteClick}
                  />
                )),
                i => (
                  <Separator key={i} />
                )
              )}
            </ListWrapper>
            <CartTotal>
              <Total
                itemCount={cart.cartItems.length}
                total={cartTotal.total}
                fees={cartTotal.fees}
              />
              <TotalButton onClickAsync={handlePurchaseCart}>Passer la commande</TotalButton>
            </CartTotal>
          </Section>
        </Fragment>
      )}
    </Wrapper>
  );
};

CartPage.displayName = 'CartPage';

const Wrapper = styled.div`
  width: 100%;
  padding: 32px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #dddddd;
`;

const Section = styled.div`
  display: flex;
  gap: 64px;
  align-items: flex-start;
  & > *:first-child {
    flex-grow: 1;
  }
  & > *:nth-child(2) {
    flex-shrink: 0;
    position: sticky;
    top: 180px;
    flex-shrink: 0;
    padding: 28px 32px;
    border: solid 2px #00000010;
    border-radius: 16px;
    box-shadow: 0 0 20px -10px #00000036;
  }
  @media (max-width: 864px) {
    flex-direction: column-reverse;
    align-items: stretch;
    & > *:nth-child(2) {
      position: static;
    }
  }
`;

const PurchaseForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CancelWrapper = styled.div`
  text-align: center;
`;

const CartTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
`;

const TotalButton = styled(Button)`
  width: 100%;
`;

const PaymentFormTotal = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
