import {Fragment, useEffect, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizTicketInfoOption} from '@shared/dynamo_model';
import {joinWithLastJoiner} from '@shared/lib/string_utils';

import {Input} from '@shared-frontend/components/core/input_v2';
import {Custom, EmptyFragment, NULL_REF} from '@shared-frontend/lib/react';

import {
  FormBlock,
  FormBlockFix,
  FormBlockFull,
  FormFlex,
} from '@src/components/admin/form/form_fragments';
import {HoobiizWarnings} from '@src/components/admin/form/hoobiiz_warnings';
import {adminInputTheme} from '@src/components/core/theme';
import {discountToString, getDiscount} from '@src/lib/discount';
import {
  currencyAmountToInputString,
  inputStringToCurrencyAmount,
  inputStringToQty,
  qtyToInputString,
} from '@src/lib/hoobiiz_input_string';

export type MaybeTicketOption = HoobiizTicketInfoOption | Pick<HoobiizTicketInfoOption, 'id'>;

export function ensureTicketInfoOption(
  tickets: MaybeTicketOption[]
): {success: false; err: string} | {success: true; tickets: HoobiizTicketInfoOption[]} {
  const ticketInfos: HoobiizTicketInfoOption[] = [];
  const incompleteIndexes: number[] = [];
  for (const [index, ticket] of tickets.entries()) {
    if ('label' in ticket) {
      ticketInfos.push(ticket);
    } else {
      incompleteIndexes.push(index);
    }
  }
  if (incompleteIndexes.length > 0) {
    const plural = incompleteIndexes.length > 1 ? 's' : '';
    return {
      success: false,
      err: `Ticket option${plural} ${joinWithLastJoiner(
        incompleteIndexes.map(i => `#${i + 1}`),
        {sep: ', ', lastSep: ' et '}
      )} incomplète${plural}`,
    };
  }
  return {success: true, tickets: ticketInfos};
}

interface HoobiizTicketInfoOptionsFormProps {
  initialData: MaybeTicketOption;
  onChange?: (newVal: MaybeTicketOption, el: HTMLDivElement) => void;
}

export const HoobiizTicketInfoOptionsForm: Custom<
  HoobiizTicketInfoOptionsFormProps,
  'div',
  'onChange'
> = props => {
  const ref = useRef<HTMLDivElement>(NULL_REF);
  const {initialData, onChange, ...rest} = props;

  const [label, setLabel] = useState('label' in initialData ? initialData.label : '');
  const [description, setDescription] = useState(
    'description' in initialData ? initialData.description ?? '' : ''
  );
  const [buyingPrice, setBuyingPrice] = useState(
    'buyingPrice' in initialData ? initialData.buyingPrice : undefined
  );
  const [publicPrice, setPublicPrice] = useState(
    'publicPrice' in initialData ? initialData.publicPrice : undefined
  );
  const [youpiizPrice, setYoupiizPrice] = useState(
    'youpiizPrice' in initialData ? initialData.youpiizPrice : undefined
  );
  const [minQuantity, setMinQuantity] = useState(
    'minQuantity' in initialData ? initialData.minQuantity : undefined
  );
  const [maxQuantity, setMaxQuantity] = useState(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    'maxQuantity' in initialData ? initialData.maxQuantity : 20
  );
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (
      !buyingPrice ||
      !publicPrice ||
      !youpiizPrice ||
      label.length === 0 ||
      (minQuantity !== undefined && maxQuantity !== undefined && maxQuantity < minQuantity)
    ) {
      setIsComplete(false);
      onChange?.({id: initialData.id}, ref.current);
      return;
    }
    setIsComplete(true);
    onChange?.(
      {
        id: initialData.id,
        label,
        description,
        buyingPrice,
        publicPrice,
        youpiizPrice,
        minQuantity,
        maxQuantity,
      },
      ref.current
    );
  }, [
    buyingPrice,
    description,
    initialData.id,
    label,
    maxQuantity,
    minQuantity,
    onChange,
    publicPrice,
    youpiizPrice,
  ]);

  const discount = getDiscount(
    publicPrice !== undefined && youpiizPrice !== undefined
      ? {publicPrice, youpiizPrice}
      : undefined,
    []
  );

  return (
    <Fragment>
      <FormFlex ref={ref} $align="flex-end" {...rest}>
        <FormBlock>
          <Input
            width="100%"
            value={label}
            syncState={setLabel}
            placeholder={'label' in initialData ? initialData.label : ''}
            label="LIBELLÉ"
            overrides={adminInputTheme}
          />
        </FormBlock>
        <FormBlock>
          <Input
            width="100%"
            value={description}
            syncState={setDescription}
            placeholder={'description' in initialData ? initialData.description : ''}
            label="INFOBULLE"
            overrides={adminInputTheme}
          />
        </FormBlock>
        <FormFlex>
          <FormBlockFix $width={96}>
            <Input
              width="100%"
              value={publicPrice}
              syncState={setPublicPrice}
              asString={currencyAmountToInputString}
              fromString={inputStringToCurrencyAmount}
              label="PRIX PUBLIC"
              overrides={adminInputTheme}
            />
          </FormBlockFix>
          <FormBlockFix $width={96}>
            <Input
              width="100%"
              value={youpiizPrice}
              syncState={setYoupiizPrice}
              asString={currencyAmountToInputString}
              fromString={inputStringToCurrencyAmount}
              label={
                <YoupiizPrice>
                  <div>PRIX YOUPIIZ</div>
                  {discount.totalDiscount.percent > 0 ? (
                    <DiscountPreview>{discountToString(discount.totalDiscount)}</DiscountPreview>
                  ) : (
                    EmptyFragment
                  )}
                </YoupiizPrice>
              }
              overrides={adminInputTheme}
            />
          </FormBlockFix>
          <FormBlockFix $width={96}>
            <Input
              width="100%"
              value={buyingPrice}
              syncState={setBuyingPrice}
              asString={currencyAmountToInputString}
              fromString={inputStringToCurrencyAmount}
              label="PRIX ACHAT"
              overrides={adminInputTheme}
            />
          </FormBlockFix>
        </FormFlex>
        <FormFlex>
          <FormBlockFix $width={96}>
            <Input
              width="100%"
              value={minQuantity}
              syncState={setMinQuantity}
              asString={qtyToInputString}
              fromString={inputStringToQty}
              label="QTÉ MIN"
              overrides={adminInputTheme}
            />
          </FormBlockFix>
          <FormBlockFix $width={96}>
            <Input
              width="100%"
              value={maxQuantity}
              syncState={setMaxQuantity}
              asString={qtyToInputString}
              fromString={inputStringToQty}
              label="QTÉ MAX"
              overrides={adminInputTheme}
            />
          </FormBlockFix>
        </FormFlex>
      </FormFlex>
      {isComplete ? (
        EmptyFragment
      ) : (
        <FormBlockFull>
          <HoobiizWarnings warnings={['Option incomplète ou invalide']} />
        </FormBlockFull>
      )}
    </Fragment>
  );
};
HoobiizTicketInfoOptionsForm.displayName = 'HoobiizTicketInfoOptionsForm';

const YoupiizPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DiscountPreview = styled.div`
  text-align: center;
  color: green;
`;
