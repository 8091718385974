import {FrontendName} from '@shared/frontends/frontend_constant';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {createTheme} from '@shared/frontends/theme_context';

import {createButton} from '@shared-frontend/components/core/button';

import {baseTheme} from '@src/components/core/theme_base';
import {HoobiizLogoSvg} from '@src/components/ui/hoobiiz_logo_svg';

export const theme = createTheme(FrontendName.Hoobiiz, baseTheme, {
  logo: <HoobiizLogoSvg height={60} />,
});

const alertButtonTheme: Partial<FrontendTheme['button']> = {
  textColorActive: '#eee',
  textColorDisabled: '#eeeebbaa',
  textColorHover: '#eee',
  backgroundActive: '#AA5555',
  backgroundDisabled: '#BB7777',
  backgroundLoading: '#BB7777',
  backgroundHover: '#884444',
};
export const AlertButton = createButton({
  type: 'button',
  theme: 'button',
  themeOverrides: {...theme.button, ...alertButtonTheme},
});

const whiteButtonTheme: Partial<FrontendTheme['button']> = {
  textColorActive: '#000000aa',
  textColorDisabled: '#00000088',
  textColorHover: '#000000cc',
  backgroundActive: '#ffffffbb',
  backgroundDisabled: '#ffffff88',
  backgroundLoading: '#ffffff88',
  backgroundHover: '#ffffffdd',
  // focusBorderWidth: 0,
};
export const WhiteButton = createButton({
  type: 'button',
  theme: 'button',
  themeOverrides: {...theme.button, ...whiteButtonTheme},
});

const smallButtonTheme: Partial<FrontendTheme['button']> = {
  borderRadius: 4,
  fontSize: 14,
  paddingTop: 8,
  paddingRight: 16,
  paddingBottom: 8,
  paddingLeft: 16,
};
export const SmallButton = createButton({
  type: 'button',
  theme: 'button',
  themeOverrides: {...theme.button, ...smallButtonTheme},
});
export const SmallAlertButton = createButton({
  type: 'button',
  theme: 'button',
  themeOverrides: {
    ...theme.button,
    ...alertButtonTheme,
    ...smallButtonTheme,
  },
});
export const SmallWhiteButton = createButton({
  type: 'button',
  theme: 'button',
  themeOverrides: {
    ...theme.button,
    ...whiteButtonTheme,
    ...smallButtonTheme,
  },
});

export const largeInputTheme: Partial<FrontendTheme['input']> = {
  height: 46,
  fontSize: 16,
};

export const WhiteNavLink = createButton({
  type: 'NavLink',
  theme: 'link',
  themeOverrides: {
    textColorActive: '#ffffff',
    textColorDisabled: '#ffffff',
    textColorHover: '#ffffff',
    textColorLoading: '#ffffff',
  },
});

//
// Admin
//

export const AdminNavLink = createButton({
  type: 'NavLink',
  theme: 'button',
  themeOverrides: {
    ...theme.button,
  },
});
export const adminInputTheme: Partial<FrontendTheme['input']> = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 56,
  fontSize: 18,
};

export const adminRadioTheme: Partial<FrontendTheme['radio']> = {
  fontSize: 18,
};
