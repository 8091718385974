import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizUserGroupId} from '@shared/dynamo_model';

import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {createHoobiizUserGroup} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {Colors} from '@src/components/core/theme_base';

interface AdminUserAndGroupCreateSubgroupFormProps {
  groupId: HoobiizUserGroupId;
}

export const AdminUserAndGroupCreateSubgroupForm: FC<
  AdminUserAndGroupCreateSubgroupFormProps
> = props => {
  const {groupId} = props;

  const [isFormVisible, setIsFormVisible] = useState(false);
  const handleShowForm = useCallback(() => {
    setIsFormVisible(true);
  }, []);
  const handleHideForm = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const [subgroupName, setSubgroupName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await createHoobiizUserGroup({groupId, label: subgroupName});
      setSubgroupName('');
      setIsFormVisible(false);
      setIsSubmitting(false);
    } catch (err: unknown) {
      setIsSubmitting(false);
      throw err;
    }
  }, [groupId, subgroupName]);

  return (
    <Wrapper>
      {!isFormVisible ? (
        // "Add" button
        <AddButton onClick={handleShowForm}>
          <AddButtonIconWrapper>
            <SvgIcon size={7} color={'#fff'} name="Plus" />
          </AddButtonIconWrapper>
          <div>Ajouter un groupe</div>
        </AddButton>
      ) : (
        // "Create subgroup" form
        <Form>
          <Input
            type="text"
            value={subgroupName}
            syncState={setSubgroupName}
            placeholder="Nom du groupe"
            disabled={isSubmitting}
            overrides={{paddingLeft: 10, paddingRight: 10}}
            autoFocus
          />
          <ButtonAsLink
            keyboardSubmit
            onClickAsync={handleSubmit}
            disabled={subgroupName.length === 0}
          >
            Créer
          </ButtonAsLink>
          <ButtonAsLink onClick={handleHideForm} disabled={isSubmitting}>
            Annuler
          </ButtonAsLink>
        </Form>
      )}
    </Wrapper>
  );
};

AdminUserAndGroupCreateSubgroupForm.displayName = 'AdminUserAndGroupCreateSubgroupForm';

const Wrapper = styled.div`
  display: flex;
  padding: 6px 8px;
`;

const AddButtonIconWrapper = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.DarkGold};
  border-radius: 3px;
`;

const AddButton = styled(ButtonAsLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
`;

const Form = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
