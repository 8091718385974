export function trim(str: string, char: string | string[]): string {
  const chars = Array.isArray(char) ? char : [char];
  let firstIndex = 0;
  let lastIndex = str.length;
  for (const c of str) {
    if (chars.includes(c)) {
      firstIndex++;
    } else {
      break;
    }
  }
  for (let i = str.length - 1; i >= firstIndex; i--) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (chars.includes(str[i]!)) {
      lastIndex = i;
    } else {
      break;
    }
  }
  return str.slice(firstIndex, lastIndex);
}

const NUM_SPLITTER_REGEX = /(?<alphaOrNum>[^0-9]+|[0-9]+)/gu;
export const smartSorter = (s1: string, s2: string): number => {
  const s1Fragments = s1.match(NUM_SPLITTER_REGEX);
  const s2Fragments = s2.match(NUM_SPLITTER_REGEX);
  // eslint-disable-next-line no-null/no-null
  if (s1Fragments === null || s2Fragments === null) {
    return s1.localeCompare(s2);
  }
  while (s1Fragments.length > 0 || s2Fragments.length > 0) {
    const s1Frag = s1Fragments.shift();
    const s2Frag = s2Fragments.shift();
    if (s1Frag === undefined) {
      return -1;
    }
    if (s2Frag === undefined) {
      return 1;
    }
    const s1Num = parseFloat(s1Frag);
    const s2Num = parseFloat(s2Frag);
    if (isNaN(s1Num) || isNaN(s2Num)) {
      const res = s1Frag.localeCompare(s2Frag);
      if (res !== 0) {
        return res;
      }
      continue;
    }
    if (s1Num !== s2Num) {
      return s1Num - s2Num;
    }
  }
  return 0;
};

export const alphabeticalSorter = (s1: string, s2: string): number => s1.localeCompare(s2);
export const localeCompare = (
  s1: string | undefined,
  s2: string | undefined,
  opts?: {desc: boolean}
): number => {
  const modifier = opts?.desc ? -1 : 1;
  return (
    modifier *
    (() => {
      if (s1 === undefined) {
        if (s2 === undefined) {
          return 0;
        }
        return 1; // s2 before s1
      }
      if (s2 === undefined) {
        return -1; // s1 before s2
      }
      return s1.localeCompare(s2);
    })()
  );
};

//

const latinLettersNormalizationMap: Record<string, string> = {
  // Latin-1 Supplement block.
  '\u00C0': 'A',
  '\u00C1': 'A',
  '\u00C2': 'A',
  '\u00C3': 'A',
  '\u00C4': 'A',
  '\u00C5': 'A',
  '\u00E0': 'a',
  '\u00E1': 'a',
  '\u00E2': 'a',
  '\u00E3': 'a',
  '\u00E4': 'a',
  '\u00E5': 'a',
  '\u00C7': 'C',
  '\u00E7': 'c',
  '\u00D0': 'D',
  '\u00F0': 'd',
  '\u00C8': 'E',
  '\u00C9': 'E',
  '\u00CA': 'E',
  '\u00CB': 'E',
  '\u00E8': 'e',
  '\u00E9': 'e',
  '\u00EA': 'e',
  '\u00EB': 'e',
  '\u00CC': 'I',
  '\u00CD': 'I',
  '\u00CE': 'I',
  '\u00CF': 'I',
  '\u00EC': 'i',
  '\u00ED': 'i',
  '\u00EE': 'i',
  '\u00EF': 'i',
  '\u00D1': 'N',
  '\u00F1': 'n',
  '\u00D2': 'O',
  '\u00D3': 'O',
  '\u00D4': 'O',
  '\u00D5': 'O',
  '\u00D6': 'O',
  '\u00D8': 'O',
  '\u00F2': 'o',
  '\u00F3': 'o',
  '\u00F4': 'o',
  '\u00F5': 'o',
  '\u00F6': 'o',
  '\u00F8': 'o',
  '\u00D9': 'U',
  '\u00DA': 'U',
  '\u00DB': 'U',
  '\u00DC': 'U',
  '\u00F9': 'u',
  '\u00FA': 'u',
  '\u00FB': 'u',
  '\u00FC': 'u',
  '\u00DD': 'Y',
  '\u00FD': 'y',
  '\u00FF': 'y',
  '\u00C6': 'Ae',
  '\u00E6': 'ae',
  '\u00DE': 'Th',
  '\u00FE': 'th',
  '\u00DF': 'ss',
  // Latin Extended-A block.
  '\u0100': 'A',
  '\u0102': 'A',
  '\u0104': 'A',
  '\u0101': 'a',
  '\u0103': 'a',
  '\u0105': 'a',
  '\u0106': 'C',
  '\u0108': 'C',
  '\u010A': 'C',
  '\u010C': 'C',
  '\u0107': 'c',
  '\u0109': 'c',
  '\u010B': 'c',
  '\u010D': 'c',
  '\u010E': 'D',
  '\u0110': 'D',
  '\u010F': 'd',
  '\u0111': 'd',
  '\u0112': 'E',
  '\u0114': 'E',
  '\u0116': 'E',
  '\u0118': 'E',
  '\u011A': 'E',
  '\u0113': 'e',
  '\u0115': 'e',
  '\u0117': 'e',
  '\u0119': 'e',
  '\u011B': 'e',
  '\u011C': 'G',
  '\u011E': 'G',
  '\u0120': 'G',
  '\u0122': 'G',
  '\u011D': 'g',
  '\u011F': 'g',
  '\u0121': 'g',
  '\u0123': 'g',
  '\u0124': 'H',
  '\u0126': 'H',
  '\u0125': 'h',
  '\u0127': 'h',
  '\u0128': 'I',
  '\u012A': 'I',
  '\u012C': 'I',
  '\u012E': 'I',
  '\u0130': 'I',
  '\u0129': 'i',
  '\u012B': 'i',
  '\u012D': 'i',
  '\u012F': 'i',
  '\u0131': 'i',
  '\u0134': 'J',
  '\u0135': 'j',
  '\u0136': 'K',
  '\u0137': 'k',
  '\u0138': 'k',
  '\u0139': 'L',
  '\u013B': 'L',
  '\u013D': 'L',
  '\u013F': 'L',
  '\u0141': 'L',
  '\u013A': 'l',
  '\u013C': 'l',
  '\u013E': 'l',
  '\u0140': 'l',
  '\u0142': 'l',
  '\u0143': 'N',
  '\u0145': 'N',
  '\u0147': 'N',
  '\u014A': 'N',
  '\u0144': 'n',
  '\u0146': 'n',
  '\u0148': 'n',
  '\u014B': 'n',
  '\u014C': 'O',
  '\u014E': 'O',
  '\u0150': 'O',
  '\u014D': 'o',
  '\u014F': 'o',
  '\u0151': 'o',
  '\u0154': 'R',
  '\u0156': 'R',
  '\u0158': 'R',
  '\u0155': 'r',
  '\u0157': 'r',
  '\u0159': 'r',
  '\u015A': 'S',
  '\u015C': 'S',
  '\u015E': 'S',
  '\u0160': 'S',
  '\u015B': 's',
  '\u015D': 's',
  '\u015F': 's',
  '\u0161': 's',
  '\u0162': 'T',
  '\u0164': 'T',
  '\u0166': 'T',
  '\u0163': 't',
  '\u0165': 't',
  '\u0167': 't',
  '\u0168': 'U',
  '\u016A': 'U',
  '\u016C': 'U',
  '\u016E': 'U',
  '\u0170': 'U',
  '\u0172': 'U',
  '\u0169': 'u',
  '\u016B': 'u',
  '\u016D': 'u',
  '\u016F': 'u',
  '\u0171': 'u',
  '\u0173': 'u',
  '\u0174': 'W',
  '\u0175': 'w',
  '\u0176': 'Y',
  '\u0177': 'y',
  '\u0178': 'Y',
  '\u0179': 'Z',
  '\u017B': 'Z',
  '\u017D': 'Z',
  '\u017A': 'z',
  '\u017C': 'z',
  '\u017E': 'z',
  '\u0132': 'IJ',
  '\u0133': 'ij',
  '\u0152': 'Oe',
  '\u0153': 'oe',
  '\u0149': "'n",
  '\u017F': 's',
};
const normalizeLatinLetter = (char: string): string => {
  return latinLettersNormalizationMap[char] ?? char;
};

const latinRegex = /[\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F]/gu;

export function removeAccents(str: string): string {
  return str.replace(latinRegex, normalizeLatinLetter);
}

export function normalizeString(str: string): string {
  return removeAccents(str)
    .toLowerCase()
    .replace(/[^a-z]+/gu, ' ');
}

export function joinWithLastJoiner(arr: string[], opts: {sep: string; lastSep: string}): string {
  const {sep, lastSep} = opts;
  const allButLast = arr.slice(0, -1);
  const last = arr.at(-1);
  if (last === undefined) {
    return allButLast.join(sep);
  }
  if (allButLast.length === 0) {
    return last;
  }
  return [allButLast.join(sep), last].join(lastSep);
}

export function htmlentities(str: string | undefined): string {
  return (
    str?.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;') ?? 'undefined'
  );
}
