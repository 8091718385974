import {FC} from 'react';
import styled from 'styled-components';

import {Spacing} from '@shared-frontend/components/core/spacing';

import {ActivityTable} from '@src/components/admin/activity/activity_table';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {AdminNavLink} from '@src/components/core/theme';

export const ActivityDashboard: FC = () => {
  return (
    <PageWrapper>
      <Top>
        <AdminNavLink to={'/admin/activity/create'}>Ajouter une nouvelle activité</AdminNavLink>
      </Top>
      <Spacing height={24} />
      <ActivityTable />
    </PageWrapper>
  );
};
ActivityDashboard.displayName = 'ActivityDashboard';

const Top = styled.div`
  display: flex;
  align-items: center;
`;
