import {padNumber} from '@shared/lib/format_utils';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function shortUtcDateWithHourMinute(ts: number): string {
  const date = new Date(ts);
  const minutes = padNumber(date.getUTCMinutes(), 2);
  const hours = padNumber(date.getUTCHours(), 2);
  const day = padNumber(date.getUTCDate(), 2);
  const weekDay = DAYS[date.getUTCDay()] ?? '?';
  const month = MONTHS[date.getUTCMonth()] ?? '?';
  const year = date.getUTCFullYear();
  return `${weekDay}, ${day} ${month} ${year}, ${hours}:${minutes}`;
}

export function shortDateWithFullTime(ts: number): string {
  const date = new Date(ts);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const milliseconds = padNumber(date.getMilliseconds(), 3);
  const seconds = padNumber(date.getSeconds(), 2);
  const minutes = padNumber(date.getMinutes(), 2);
  const hours = padNumber(date.getHours(), 2);
  const day = padNumber(date.getDate(), 2);
  const weekDay = DAYS[date.getDay()] ?? '?';
  const month = MONTHS[date.getMonth()] ?? '?';
  const year = date.getFullYear();
  return `${weekDay}, ${day} ${month} ${year}, ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function localTime(ts: number): string {
  const d = new Date(ts);
  return `${padNumber(d.getHours(), 2)}:${padNumber(d.getMinutes(), 2)}`;
}
export function localDuration(minutes: number, opts?: {padHours?: boolean}): string {
  const {padHours} = opts ?? {};
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  if (h === 0) {
    return `${padNumber(m, 2)} min`;
  }
  return `${padNumber(h, padHours ? 2 : 1)}h${padNumber(m, 2)}`;
}
