import {ComponentProps, createElement, Fragment, JSX, useEffect, useState} from 'react';

import {Radios} from '@shared-frontend/components/core/radios';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {adminRadioTheme} from '@src/components/core/theme';

interface FormProps<T> {
  initialData?: T;
  onChange: (newVal: T | undefined) => void;
}
type Component<T> = React.FunctionComponent<T> | React.ComponentClass<T>;
type FormsComponents<Types extends string, Extra extends Record<string, unknown>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Type in Types]: Component<FormProps<any> & Extra>;
};

interface MultiFormProps<
  Types extends string,
  Extra extends Record<string, unknown>,
  Forms extends FormsComponents<Types, Extra>,
> {
  labels: {label: string; value: Types}[];
  forms: Forms;
  initialType?: Types;
  initialData?: {[K in Types]: ComponentProps<Forms[Types]>['initialData']}[Types];
  extra: Extra;
  onChange: (newVal: {[K in Types]: ComponentProps<Forms[Types]>['initialData']}[Types]) => void;
}

export function MultiForm<
  Types extends string,
  Extra extends Record<string, unknown>,
  Forms extends FormsComponents<Types, Extra>,
>(props: MultiFormProps<Types, Extra, Forms>): JSX.Element {
  const {labels, forms, initialType, initialData, extra, onChange} = props;

  const [data, setData] =
    useState<{[K in Types]: ComponentProps<Forms[Types]>['initialData']}[Types]>(initialData);

  const [type, setType] = useState<Types | undefined>(initialType);

  useEffect(() => onChange(data), [onChange, data]);

  let form = EmptyFragment;
  if (type !== undefined) {
    const FormClass = forms[type];
    form = createElement(FormClass, {initialData, ...extra, onChange: setData});
  }

  return (
    <Fragment>
      <Radios<Types> value={type} values={labels} syncState={setType} overrides={adminRadioTheme} />
      {form}
    </Fragment>
  );
}
MultiForm.displayName = 'MultiForm';
