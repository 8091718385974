import {FC, useCallback} from 'react';
import {useNavigate} from 'react-router';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizActivityItem} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {showSuccess} from '@shared-frontend/components/core/notifications';

import {ActivityForm} from '@src/components/admin/activity/activity_form';
import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';

export const ActivityCreatePage: FC = () => {
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (activityData: HoobiizData<HoobiizActivityItem>) => {
      const {activity} = await apiCall(HoobiizApi, '/admin/create-activity', activityData);
      showSuccess(`Activité "${activity.label}" créée`);
      navigate('/admin/activity');
    },
    [navigate]
  );

  return (
    <PageWrapper>
      <FormWrapper>
        <FormTitle>Ajouter une nouvelle offre</FormTitle>
        <ActivityForm submitButtonText="Créer" onSubmit={handleSubmit} />
      </FormWrapper>
    </PageWrapper>
  );
};
ActivityCreatePage.displayName = 'ActivityCreatePage';
