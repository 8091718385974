import {TimeFormatLang} from '@shared/lib/time/time_component';
import {
  firstTimeComponent,
  formatTimeComponent,
  getNextTimeComponent,
} from '@shared/lib/time/time_span';
import {Tuple3, Tuple6} from '@shared/lib/tuple_utils';
import {neverHappens} from '@shared/lib/type_utils';

export interface TimeFormatOptions {
  short: boolean;
  ago: boolean;
  components: number;
  lang?: TimeFormatLang;
}

function addAgo(str: string, lang: TimeFormatLang): string {
  if (lang === 'en-US') {
    return `${str} ago`;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (lang === 'fr-FR') {
    return `Il y a ${str}`;
  }
  neverHappens(lang, `Unsupported lang "${lang}"`);
}

function andString(lang: TimeFormatLang): string {
  if (lang === 'en-US') {
    return `and`;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (lang === 'fr-FR') {
    return `et`;
  }
  neverHappens(lang, `Unsupported lang "${lang}"`);
}

export function ageString(ageMs: number, options: Partial<TimeFormatOptions> = {}): string {
  const {short, ago, components, lang}: TimeFormatOptions = {
    short: false,
    ago: false,
    components: 1,
    lang: 'en-US',
    ...options,
  };

  if (components === 0) {
    return '';
  }

  const [firstComp, firstValue] = firstTimeComponent(ageMs, 1);
  const labels = [formatTimeComponent(firstComp, firstValue, short, lang)];

  let msLeft = ageMs - firstValue * firstComp.metadata.ms;
  for (let i = 0; i < components - 1; i++) {
    const nextComp = getNextTimeComponent(firstComp, i + 1);
    if (nextComp !== undefined) {
      const nextValue = Math.floor(msLeft / nextComp.metadata.ms);
      msLeft -= ageMs - nextValue * nextComp.metadata.ms;
      if (nextValue > 0) {
        labels.push(formatTimeComponent(nextComp, nextValue, short, lang));
      }
    }
  }

  let label = '';
  if (short) {
    label = labels.join(' ');
  } else if (labels.length <= 2) {
    label = labels.join(` ${andString(lang)} `);
  } else {
    label = [labels.slice(0, -1).join(', '), ...labels.slice(-1)].join(` ${andString(lang)} `);
  }
  return ago ? addAgo(label, lang) : label;
}

// /**
//  * @param durationMs Format a millisecond duration into a short string.
//  * Examples:
//  * - 300 ms
//  * - 01 sec
//  * - 11 sec
//  * - 59 sec
//  * - 01 min 00 sec
//  * - 01 min 01 sec
//  * - 20 min 30 sec
//  * - 240 min 51 sec
//  */
// function durationString(durationMs: number): string {
//   const ms = Math.abs(durationMs);
//   const prefix = durationMs < 0 ? '- ' : '';
//   if (ms < 1000) {
//     return `${prefix}${ms} ms`;
//   }
//   let seconds = Math.floor(ms / 1000);
//   if (seconds < 60) {
//     return `${prefix}${padNumber(seconds, 2)} sec`;
//   }
//   const minutes = Math.floor(seconds / 60);
//   seconds = seconds - minutes * 60;
//   return `${prefix}${padNumber(minutes, 2)} min ${padNumber(seconds, 2)} sec`;
// }

export function localeDateString(date: Date): string {
  const dateStr = [
    date.getFullYear().toString(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
  ].join('-');
  return dateStr;
}

export function utcDateString(date: Date): string {
  const dateStr = [
    date.getUTCFullYear().toString(),
    (date.getUTCMonth() + 1).toString().padStart(2, '0'),
    date.getUTCDate().toString().padStart(2, '0'),
  ].join('-');
  return dateStr;
}

export function localeTimeString(date: Date): string {
  const timeStr = [
    date.getHours().toString().padStart(2, '0'),
    date.getMinutes().toString().padStart(2, '0'),
    date.getSeconds().toString().padStart(2, '0'),
  ].join(':');
  return timeStr;
}

export function localeDateTimeString(date: Date): string {
  const dateStr = localeDateString(date);
  const timeStr = localeTimeString(date);
  return [dateStr, timeStr].join(' ');
}

export function localeDate(str: string): Date {
  const [yearStr, monthStr, dayStr] = str.split('-');

  const dateComponents: number[] = [];
  for (const val of [yearStr, monthStr, dayStr]) {
    if (val === undefined) {
      return new Date(NaN);
    }
    const component = parseFloat(val);
    if (isNaN(component)) {
      return new Date(NaN);
    }
    dateComponents.push(component);
  }

  const [year, month, day] = dateComponents as Tuple3<number>;
  return new Date(year, month - 1, day);
}

export function localeDateTime(str: string): Date {
  const [dateStr, timeStr] = str.split(' ');
  if (dateStr === undefined || timeStr === undefined) {
    return new Date(NaN);
  }
  const [yearStr, monthStr, dayStr] = dateStr.split('-');
  const [hoursStr, minutesStr, secondsStr] = timeStr.split(':');

  const dateComponents: number[] = [];
  for (const val of [yearStr, monthStr, dayStr, hoursStr, minutesStr, secondsStr]) {
    if (val === undefined) {
      return new Date(NaN);
    }
    const component = parseFloat(val);
    if (isNaN(component)) {
      return new Date(NaN);
    }
    dateComponents.push(component);
  }

  const [year, month, day, hours, minutes, seconds] = dateComponents as Tuple6<number>;
  return new Date(year, month - 1, day, hours, minutes, seconds);
}
