import {CurrencyAmount} from '@shared/dynamo_model';
import {cents, roundCents} from '@shared/lib/hoobiiz/currency_amount';

//
// CURRENCY AMOUNT

export function currencyAmountToInputString(amount: CurrencyAmount | undefined): string {
  return amount
    ? (roundCents(amount.cents) / 100).toLocaleString(undefined, {minimumFractionDigits: 2})
    : '';
}

export function inputStringToCurrencyAmount(str: string): CurrencyAmount | undefined {
  const value = parseFloat(str);
  if (isNaN(value)) {
    return undefined;
  }
  return cents(Math.round(value * 100));
}

//
// QUANTITY

export function qtyToInputString(qty: number | undefined): string {
  if (qty === undefined || qty < 0) {
    return '';
  }
  return Math.round(qty).toString();
}

export function inputStringToQty(str: string): number | undefined {
  const value = parseFloat(str);
  if (isNaN(value) || value < 0) {
    return undefined;
  }
  return Math.round(value);
}
