import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizExpertTicketFlexibleStock} from '@shared/api/definitions/search_api';
import {nonEmptyArrayOrThrow} from '@shared/lib/type_utils';
import {FlexibleStockItem} from '@shared/model/hoobiiz/hoobiiz_stock';
import {SanitizedItem, SanitizedTicketInfo} from '@shared/model/search_tables';

import {Colors} from '@src/components/core/theme_base';
import {AddToCartButton} from '@src/components/ui/add_to_cart_button';
import {
  ExpertTicketQuantityData,
  HoobiizTicketQuantityPicker,
  TicketQuantityData,
} from '@src/components/ui/hoobiiz_ticket_quantity_picker';

interface StockInfo {
  ticketInfo: SanitizedTicketInfo;
  bestOffer: SanitizedItem<'HoobiizOffer'> | undefined;
  stock: FlexibleStockItem;
}

interface HoobiizFlexibleTicketPickerProps {
  stocks: StockInfo[];
  expertTicketStocks: HoobiizExpertTicketFlexibleStock[];
}

function initialTicketData(stocks: StockInfo[]): TicketQuantityData[] {
  return stocks.map(s => ({
    type: 'stock',
    stocks: nonEmptyArrayOrThrow([s.stock]),
    ticketInfo: s.ticketInfo,
    bestOffer: s.bestOffer,
    quantity: 0,
    options: s.ticketInfo.options.map((o, i) => ({ticketInfoOption: o, order: i, quantity: 0})),
  }));
}

function initialExpertTicketData(
  expertTicketStocks: HoobiizExpertTicketFlexibleStock[]
): ExpertTicketQuantityData[] {
  return expertTicketStocks.flatMap(s =>
    s.tickets.map(t => ({
      type: 'expert-ticket',
      ticket: t,
      price: t.price,
      originalPrice: t.originalPrice,
      quantity: 0,
    }))
  );
}

function hashTicketQuantityData(data: TicketQuantityData | ExpertTicketQuantityData): string {
  if (data.type === 'stock') {
    return [data.stocks.map(s => s.id).join(':'), data.ticketInfo.id].join('::');
  }
  return [data.ticket.stockId, data.ticket.ticketInfoId].join(':');
}

export const HoobiizFlexibleTicketPicker: FC<HoobiizFlexibleTicketPickerProps> = props => {
  const {stocks, expertTicketStocks} = props;

  const [ticketData, setTicketData] = useState<(TicketQuantityData | ExpertTicketQuantityData)[]>([
    ...initialTicketData(stocks),
    ...initialExpertTicketData(expertTicketStocks),
  ]);

  // Reset selection when a prop changes
  useEffect(() => {
    setTicketData([...initialTicketData(stocks), ...initialExpertTicketData(expertTicketStocks)]);
  }, [stocks, expertTicketStocks]);

  const handleAddToCartSubmit = useCallback(() => {
    setTicketData([...initialTicketData(stocks), ...initialExpertTicketData(expertTicketStocks)]);
  }, [expertTicketStocks, stocks]);

  const handleTicketDataChange = useCallback(
    (newData: TicketQuantityData | ExpertTicketQuantityData) => {
      setTicketData(data =>
        data.map(d => (hashTicketQuantityData(d) === hashTicketQuantityData(newData) ? newData : d))
      );
    },
    []
  );

  return (
    <Wrapper>
      {ticketData.map(data => (
        <HoobiizTicketQuantityPicker
          key={hashTicketQuantityData(data)}
          data={data}
          noTitle
          onChange={handleTicketDataChange}
        />
      ))}
      <AddToCartButton ticketData={ticketData} onSubmit={handleAddToCartSubmit} />
    </Wrapper>
  );
};

HoobiizFlexibleTicketPicker.displayName = 'HoobiizFlexibleTicketPicker';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    padding: 14px 0;
    border-top: 2px solid ${Colors.LightGrey2};
  }
`;
