import {useEffect, useRef, useState} from 'react';

import {isCat1Id, isCat2Id, isCat3Id} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {FullItem} from '@shared/model/search_tables';

import {Custom, NULL_REF} from '@shared-frontend/lib/react';

import {Category1Input} from '@src/components/admin/category/category1_input';
import {Category2Input} from '@src/components/admin/category/category2_input';
import {Category3Input} from '@src/components/admin/category/category3_input';
import {FormGridWidth} from '@src/components/admin/form/form_fragments';

export type AnyCat = FullItem<'HoobiizCat1' | 'HoobiizCat2' | 'HoobiizCat3'>;

function isCat1(val?: AnyCat): val is FullItem<'HoobiizCat1'> {
  return val !== undefined && isCat1Id(val.id);
}
function isCat2(val?: AnyCat): val is FullItem<'HoobiizCat2'> {
  return val !== undefined && isCat2Id(val.id);
}
function isCat3(val?: AnyCat): val is FullItem<'HoobiizCat3'> {
  return val !== undefined && isCat3Id(val.id);
}

interface CategoryMultiInputProps {
  initialData?: AnyCat;
  onChange?: (data: AnyCat | undefined, element: HTMLDivElement) => void;
}

export const CategoryMultiInput: Custom<CategoryMultiInputProps, 'div', 'onChange'> = props => {
  const ref = useRef<HTMLDivElement>(NULL_REF);
  const {initialData, onChange, ...rest} = props;

  const [cat1, setCat1] = useState(isCat1(initialData) ? initialData : undefined);
  const [cat2, setCat2] = useState(isCat2(initialData) ? initialData : undefined);
  const [cat3, setCat3] = useState(isCat3(initialData) ? initialData : undefined);

  useEffect(() => {
    if (cat3 !== undefined) {
      setCat2(cat3.cat2);
      setCat1(cat3.cat2?.cat1);
    } else if (cat2 !== undefined) {
      setCat1(cat2.cat1);
      setCat3(undefined);
    } else if (cat1 !== undefined) {
      setCat2(undefined);
      setCat3(undefined);
    }
    if (!ref.current) {
      return;
    }
    onChange?.(cat3 ?? cat2 ?? cat1, ref.current);
  }, [cat1, cat2, cat3, onChange]);

  // useEffect(() => {
  //   if (!media) {
  //     return onChange(undefined);
  //   }
  //   return onChange({
  //     name,
  //     description,
  //     mediaId: media.id,
  //   });
  // }, [media, name, onChange, description]);

  return (
    <FormGridWidth ref={ref} {...rest}>
      <Category1Input label="CATÉGORIE 1" category1={cat1} syncState={setCat1} />
      <Category2Input
        label="CATÉGORIE 2"
        category2={cat2}
        syncState={setCat2}
        cat1Filter={cat1?.id}
      />
      <Category3Input
        label="CATÉGORIE 3"
        category3={cat3}
        syncState={setCat3}
        cat1Filter={cat1?.id}
        cat2Filter={cat2?.id}
      />
    </FormGridWidth>
  );
};
CategoryMultiInput.displayName = 'CategoryMultiInput';
