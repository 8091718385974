import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {arrayJoin, groupBy} from '@shared/lib/array_utils';
import {localeDateTimeString} from '@shared/lib/time/format';

import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {HoobiizOrderItem} from '@src/components/ui/hoobiiz_order_item';

export const OrdersPage: FC = () => {
  const {data: orders} = useApiCall(
    HoobiizApi,
    '/list-purchase',
    {},
    {errMsg: `Échec du chargement des commandes. Vous pouvez rafraichir la page pour réessayer`}
  );

  if (!orders) {
    return <Wrapper>Chargement...</Wrapper>;
  }

  const byId = groupBy(orders, o => o.paymentId);
  const sorted = [...byId.values()].sort((o1, o2) => (o2[0].endedAt ?? 0) - (o1[0].endedAt ?? 0));

  return (
    <Wrapper>
      {sorted.map(orders => {
        return (
          <PaymentWrapper key={orders[0].paymentId}>
            <Title>{`Commande du ${localeDateTimeString(new Date(orders[0].endedAt ?? 0))}`}</Title>
            <ListWrapper>
              {arrayJoin(
                orders.map(order => <HoobiizOrderItem key={order.id} orderItem={order} />),
                i => (
                  <Separator key={i} />
                )
              )}
            </ListWrapper>
          </PaymentWrapper>
        );
      })}
    </Wrapper>
  );
};

OrdersPage.displayName = 'OrdersPage';

const Wrapper = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const PaymentWrapper = styled.div``;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #dddddd;
`;
