import {FC, MouseEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {NO_TIMEOUT} from '@shared/constants/uncategorized_constants';
import {Currency} from '@shared/dynamo_model';
import {datesAreSameDay, startOfFranceDay} from '@shared/lib/date_utils';
import {padNumber} from '@shared/lib/format_utils';
import {currencyToSymbol} from '@shared/lib/hoobiiz/currency_amount';
import {parseExpertTicketPricesAndDates} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_common';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {Calendar} from '@shared-frontend/components/core/calendar';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {setExpertTicketReservation} from '@src/components/admin/expert_ticket/expert_ticket_admin_store';
import {Colors} from '@src/components/core/theme_base';

interface ExpertTicketPriceCalendarProps {
  product: FullItem<'HoobiizExpertTicketProduct'>;
  onReservation?: () => void;
}

export const ExpertTicketPriceCalendar: FC<ExpertTicketPriceCalendarProps> = props => {
  const {product, onReservation} = props;
  const {
    PricesAndDates,
    ProductId,
    productBase,
    ProductName,
    realTimePrices,
    RequiresRealTimePrice,
  } = product;
  const {provider} = productBase ?? {};
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();

  useEffect(() => {
    setMonth(new Date().getMonth());
    setYear(new Date().getFullYear());
    setSelectedDay(undefined);
  }, [product]);

  const prices = useMemo(
    () =>
      RequiresRealTimePrice
        ? realTimePrices.map(p => ({date: new Date(p.ts), price: p.cents / 100}))
        : parseExpertTicketPricesAndDates(PricesAndDates),
    [PricesAndDates, RequiresRealTimePrice, realTimePrices]
  );

  const handlePreviousMonthClick = useCallback(() => {
    const newDate = new Date(year, month);
    newDate.setMonth(newDate.getMonth() - 1);
    setMonth(newDate.getMonth());
    setYear(newDate.getFullYear());
  }, [month, year]);
  const handleNextMonthClick = useCallback(() => {
    const newDate = new Date(year, month);
    newDate.setMonth(newDate.getMonth() + 1);
    setMonth(newDate.getMonth());
    setYear(newDate.getFullYear());
  }, [month, year]);

  const handleDayClick = useCallback<MouseEventHandler>(evt => {
    const date = new Date(parseFloat(evt.currentTarget.getAttribute('data-ts') ?? ''));
    if (isNaN(date.getTime())) {
      return;
    }
    setSelectedDay(date);
  }, []);

  const handleReserveClick = useCallback(async () => {
    if (!selectedDay) {
      return;
    }
    const accessDateTime = [
      selectedDay.getFullYear(),
      padNumber(selectedDay.getMonth() + 1, 2),
      padNumber(selectedDay.getDate(), 2),
    ].join('-');
    const res = await apiCall(
      HoobiizApi,
      '/admin/expert-ticket-reservation',
      {
        products: [{id: ProductId, quantity: 1, accessDateTime}],
      },
      {timeout: NO_TIMEOUT}
    );
    if (res.Success) {
      setExpertTicketReservation(res.ReservationId, res);
      onReservation?.();
    }
  }, [ProductId, onReservation, selectedDay]);

  const renderCell = useCallback(
    (date: Date) => {
      const startOfFrDay = startOfFranceDay();
      const today = datesAreSameDay(startOfFrDay, date);

      const datePrices = prices
        .filter(p => datesAreSameDay(p.date, date))
        .map(
          p =>
            `${p.price.toLocaleString(undefined, {
              minimumFractionDigits: Math.abs(p.price) - p.price !== 0 ? 2 : 0,
            })} ${currencyToSymbol[Currency.Euro]}`
        );

      const accentColor = datePrices.length > 0 ? Colors.Gold : Colors.Grey;
      const accentTextColor = Colors.Grey;

      return (
        <CalendarCell
          $past={!today && date.getTime() < startOfFrDay.getTime()}
          $disabled={datePrices.length === 0}
          $selected={selectedDay?.getTime() === date.getTime()}
          key={date.getTime()}
          data-ts={date.getTime()}
          onClick={handleDayClick}
          $accentColor={accentColor}
          $accentTextColor={accentTextColor}
        >
          <CalendarCellDay>
            <CalendarCellDayNumber $today={today}>{date.getDate()}</CalendarCellDayNumber>
          </CalendarCellDay>
          {datePrices.length > 0 ? (
            <CalendarCellPromo $accentColor={accentColor} $accentTextColor="#ffffff">
              {datePrices.join(', ')}
            </CalendarCellPromo>
          ) : (
            EmptyFragment
          )}
        </CalendarCell>
      );
    },
    [handleDayClick, prices, selectedDay]
  );

  return (
    <Wrapper>
      <Titles>
        <Title>
          <div>Provider:</div>
          <div>{provider?.ProviderName}</div>
        </Title>
        <Title>
          <div>Product Base:</div>
          <div>{productBase?.ProductBaseName}</div>
        </Title>
        <Title>
          <div>Product:</div>
          <div>{ProductName}</div>
        </Title>
      </Titles>
      <Calendar
        month={month}
        year={year}
        onPreviousClick={handlePreviousMonthClick}
        onNextClick={handleNextMonthClick}
        renderCell={renderCell}
      />
      {selectedDay ? (
        <Form>
          {prices
            .filter(p => datesAreSameDay(p.date, selectedDay))
            .map(p => (
              <FormLine key={p.date.getTime()}>
                <div>{p.date.toLocaleString()}</div>
                <Button onClickAsync={handleReserveClick}>RESERVE</Button>
              </FormLine>
            ))}
        </Form>
      ) : (
        EmptyFragment
      )}
    </Wrapper>
  );
};

ExpertTicketPriceCalendar.displayName = 'ExpertTicketPriceCalendar';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border-radius: 16px;
  background-color: white;
`;

const Title = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  :nth-child(1) {
    font-weight: bold;
    color: #333;
  }
  :nth-child(2) {
    color: #666;
  }
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CalendarCell = styled.div<{
  $disabled: boolean;
  $past: boolean;
  $selected: boolean;
  $accentColor: string;
  $accentTextColor: string;
}>`
  display: flex;
  flex-direction: column;
  width: 48px;
  height: 52px;
  border-radius: 6px;
  margin: 3px;
  cursor: ${p => (p.$disabled ? 'default' : 'pointer')};
  pointer-events: ${p => (p.$disabled ? 'none' : 'inherit')};
  background: #00000006;
  color: ${p => (p.$selected ? p.$accentColor : '#00000060')};
  border: solid 2px ${p => (p.$selected ? p.$accentColor : '#00000010')};
  ${p => p.$past && `opacity: 0.5;`}
  ${p =>
    !p.$disabled &&
    `&:hover {
        border: solid 2px ${p.$accentColor};
        color: ${p.$accentColor};
    }`}
`;

const CalendarCellDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
const CalendarCellDayNumber = styled.div<{
  $today: boolean;
}>`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  ${p => (p.$today ? 'background-color: #00000010;' : false)};
`;
const CalendarCellPromo = styled.div<{$accentColor: string; $accentTextColor: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: ${p => p.$accentColor};
  color: ${p => p.$accentTextColor};
  font-size: 12px;
  padding: 2px 0;
  margin: 0 -2px -2px -2px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
