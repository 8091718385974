import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 24px;
`;

export const PageTitle = styled.div`
  font-size: 32px;
  margin-bottom: 24px;
`;
