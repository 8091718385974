import {useEffect, useState} from 'react';

import {deepEqual} from '@shared/lib/object_utils';

export function useMemoCompare<T>(fn: () => T, deps?: unknown[]): T {
  const [cachedVal, setCachedVal] = useState(fn);

  useEffect(() => {
    const newVal = fn();
    if (cachedVal !== newVal && !deepEqual(cachedVal, newVal)) {
      setCachedVal(newVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps ?? []), cachedVal, fn]);

  return cachedVal;
}
