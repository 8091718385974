import {FC} from 'react';

import {Tooltip} from '@shared-frontend/components/core/tooltip';

interface BytesSizeProps {
  bytes: number;
}

const units = ['b', 'kb', 'mb', 'gb', 'tb'];

export function bytesToString(bytes?: number): string {
  if (bytes === undefined) {
    return '';
  }
  if (bytes === 0) {
    return '0 b';
  }
  // Find the power of 1024 for that number
  const power = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length);

  const value = bytes / 1024 ** power;
  const valueStr = value.toLocaleString(undefined, {maximumSignificantDigits: 2});
  const unit = units[power];

  return `${valueStr} ${unit}`;
}

export const BytesSize: FC<BytesSizeProps> = ({bytes}) => {
  const str = bytesToString(bytes).toUpperCase();
  return (
    <Tooltip content={`${bytes.toLocaleString()} bytes`}>
      <span>{str}</span>
    </Tooltip>
  );
};
BytesSize.displayName = 'BytesSize';
