import {FC, useCallback} from 'react';
import styled from 'styled-components';

import {CityLookupResult, HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';

import {apiCall} from '@shared-frontend/api';
import {Autocomplete} from '@shared-frontend/components/core/autocomplete';

interface HoobiizSearchLocationCityProps {
  city: CityLookupResult | undefined;
  onChange: (newCity: CityLookupResult | undefined) => void;
  disabled?: boolean;
}

export const HoobiizSearchLocationCity: FC<HoobiizSearchLocationCityProps> = props => {
  const {city, onChange, disabled} = props;

  const handleLookupItem = useCallback(
    async (inputText: string) => apiCall(HoobiizApi, '/search-city', {query: inputText}),
    []
  );

  const resultToString = useCallback((res: CityLookupResult) => res.description, []);
  const resultToKey = useCallback((res: CityLookupResult) => res.description, []);
  const resultToElement = useCallback((res: CityLookupResult, highlighted: boolean) => {
    return <Result $highlighted={highlighted}>{res.description}</Result>;
  }, []);

  return (
    <Wrapper>
      Choisir une ville
      <StyledAutocomplete
        disabled={disabled}
        lookupItem={handleLookupItem}
        item={city}
        syncState={onChange}
        itemToInputString={resultToString}
        itemToKey={resultToKey}
        itemElement={resultToElement}
        minChar={3}
        overrides={{fontSize: 16, paddingLeft: 8, paddingRight: 8}}
        autoFocus={!disabled}
      />
    </Wrapper>
  );
};

HoobiizSearchLocationCity.displayName = 'HoobiizSearchLocationCity';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const StyledAutocomplete = styled(Autocomplete)`
  flex-grow: 1;
  background-color: #ffffff;
`;

const Result = styled.div<{$highlighted: boolean}>`
  background-color: ${p => (p.$highlighted ? '#eeeeee' : '#ffffff')};
  padding: 6px 6px;
  &:hover {
    background-color: #eeeeee;
  }
`;
