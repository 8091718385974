import {HoobiizOpeningHours, HoobiizTimeOfDay, HoobiizWeekPeriods} from '@shared/dynamo_model';
import {padNumber} from '@shared/lib/format_utils';

export type Weekday = keyof HoobiizOpeningHours['weekdays'];

export const DAYS: {label: string; value: Weekday; weekDay: number}[] = [
  {label: 'LUNDI', value: 'Monday', weekDay: 1},
  {label: 'MARDI', value: 'Tuesday', weekDay: 2},
  {label: 'MERCREDI', value: 'Wednesday', weekDay: 3},
  {label: 'JEUDI', value: 'Thursday', weekDay: 4},
  {label: 'VENDREDI', value: 'Friday', weekDay: 5},
  {label: 'SAMEDI', value: 'Saturday', weekDay: 6},
  {label: 'DIMANCHE', value: 'Sunday', weekDay: 0},
];

export function emptyWeekPeriods(): HoobiizWeekPeriods {
  return {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  };
}

export function emptyHoobiizOpeningHours(): HoobiizOpeningHours {
  return {weekdays: emptyWeekPeriods(), overrides: []};
}

export function formatWeekday(weekday: Weekday): string {
  return DAYS.find(d => d.value === weekday)?.label ?? '';
}

export function formatTimeOfDay(time: HoobiizTimeOfDay): string {
  const start = `${padNumber(time.startHour, 2)}:${padNumber(time.startMinute, 2)}`;
  const end = `${padNumber(time.endHour, 2)}:${padNumber(time.endMinute, 2)}`;
  return [start, end].join(` — `);
}
