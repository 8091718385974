import {
  ActivityExpertTicketStockForm,
  ActivityExpertTicketStockFormProps,
} from './activity_expert_ticket_stock_form';
import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType, SearchApiQueryType} from '@shared/api/definitions/search_api';
import {ApiDef} from '@shared/api/registry';
import {HoobiizActivityId} from '@shared/dynamo_model';
import {expertTicketStockHasChanged} from '@shared/lib/hoobiiz/compare_hoobiiz_model';
import {isNonEmptyArray} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {NavButton} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';

import {FormWrapper} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {Colors} from '@src/components/core/theme_base';

interface ActivityExpertTicketStockPageProps {
  activityId: HoobiizActivityId;
}

export const ActivityExpertTicketStockPage: FC<ActivityExpertTicketStockPageProps> = props => {
  const {activityId} = props;

  const [activity, setActivity] = useState<FullItem<'HoobiizActivity'> | undefined>();
  const [stocks, setStocks] = useState<FullItem<'HoobiizExpertTicketStock'>[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const reload = useCallback(() => {
    // Reset states
    setActivity(undefined);
    setStocks(undefined);
    setIsLoading(true);

    // Load all data
    Promise.all([
      apiCall(HoobiizApi, '/admin/search/get', {
        table: 'HoobiizActivity',
        id: activityId,
        mode: 'full',
      }),
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizExpertTicketStock',
        gsi: {
          name: 'activityId',
          where: {type: 'equal', value: activityId},
        },
        mode: 'full',
      }),
    ])
      .then(([res1, res2]) => {
        const {item: activityItem} = res1 as SearchApiGetType<'HoobiizActivity', 'full'>['res'];
        if (!activityItem) {
          throw new Error('Activity not found');
        }
        setActivity(activityItem);
        const {items: stockItems} = res2 as SearchApiQueryType<
          'HoobiizExpertTicketStock',
          'full'
        >['res'];
        setStocks(stockItems.map(i => i.item).sort((s1, s2) => s1.createdAt - s2.createdAt));
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            "Échec du chargement des stocks de l'activité. Vous pouvez rafraichir la page pour réessayer",
        });
        setIsLoading(false);
      });
  }, [activityId]);

  useEffect(reload, [reload]);

  const handleSubmit = useCallback<ActivityExpertTicketStockFormProps['onSubmit']>(
    async data => {
      const operations: ApiDef<typeof HoobiizApi>['/admin/manage-expert-ticket-stocks']['req'] = [];
      for (const d of data) {
        // CREATION
        if (d.id === undefined) {
          if (d.ticket) {
            operations.push({createStock: d.ticket});
          } else {
            // should not happen
            notifyError('Invalid state', {extra: d});
          }
          continue;
        }
        if ('ticket' in d) {
          if (d.ticket !== undefined) {
            const previousTicket = stocks?.find(s => s.id === d.id);
            if (!previousTicket) {
              throw new Error(`Failure to identify stock with id ${d.id}`);
            }
            if (expertTicketStockHasChanged(d.ticket, previousTicket)) {
              operations.push({updateStock: {id: d.id, updates: d.ticket}});
            }
          } else {
            operations.push({deleteStock: d.id});
          }
        } else {
          // should not happen
        }
      }
      await apiCall(HoobiizApi, '/admin/manage-expert-ticket-stocks', operations)
        .then(() => {
          reload();
          showSuccess(`Stocks mis à jour`);
        })
        .catch(err => {
          reload();
          notifyError(err, {message: `Erreur lors de la mise à jour des stocks`});
        });
    },
    [reload, stocks]
  );

  if (!activity || !stocks || isLoading) {
    return (
      <LoadingPage>
        Chargement des stocks ExperTicket de l'activité
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }

  return (
    <FormWrapper>
      {isNonEmptyArray(stocks) ? (
        <FormSection title={`Stock`}>
          <ActivityExpertTicketStockForm
            activityId={activityId}
            initialData={stocks}
            submitButtonText="Modifier"
            onSubmit={handleSubmit}
          />
        </FormSection>
      ) : (
        <NavButton to={`/admin/activity/${activityId}/expert-ticket-stock/create`}>
          Créer un stock ExperTicket
        </NavButton>
      )}
    </FormWrapper>
  );
};
ActivityExpertTicketStockPage.displayName = 'ActivityExpertTicketStockPage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;
