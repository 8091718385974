import {FC, MouseEventHandler, useCallback, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizTimeOfDay, HoobiizWeekPeriods} from '@shared/dynamo_model';
import {useTheme} from '@shared/frontends/theme_context';
import {fromEntries} from '@shared/lib/map_utils';
import {isNull} from '@shared/lib/type_utils';

import {Button, ButtonAsLink, UnthemedButton} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {adminInputTheme} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
import {DAYS, Weekday} from '@src/lib/hoobiiz_week_periods';

interface HoobiizWeekPeriodsFormProps {
  hours: HoobiizWeekPeriods;
  onChange?: (data: HoobiizWeekPeriods) => void;
  disabled?: boolean;
  defaultLabel: string;
}

const fromString = (val: string): number => {
  const res = parseFloat(val);
  if (isNaN(res)) {
    throw new Error('Invalid value');
  }
  return res;
};

const toString = (val: number): string => {
  return String(val).padStart(2, '0');
};

export const HoobiizWeekPeriodsForm: FC<HoobiizWeekPeriodsFormProps> = props => {
  const {
    input: {borderColor, borderRadius},
  } = useTheme();
  const {disabled, hours, onChange, defaultLabel} = props;
  const [forceFormat, setForceFormat] = useState<number | undefined>();
  const [lastAdded, setLastAdded] = useState<{weekday: Weekday; period: number} | undefined>();

  const handleAddPeriod = useCallback<MouseEventHandler>(
    evt => {
      const dayStr = evt.currentTarget.getAttribute('data-weekday');
      if (isNull(dayStr)) {
        return;
      }
      const day = dayStr as Weekday;
      const dayHours = hours[day];
      setLastAdded({weekday: day, period: dayHours.length});
      const newDayHours = [...dayHours, {startHour: 0, startMinute: 0, endHour: 0, endMinute: 0}];
      onChange?.({...hours, [day]: newDayHours});
    },
    [onChange, hours]
  );

  const handleDeletePeriod = useCallback<MouseEventHandler>(
    evt => {
      const dayStr = evt.currentTarget.getAttribute('data-weekday');
      const periodStr = evt.currentTarget.getAttribute('data-period');
      if (isNull(dayStr) || isNull(periodStr)) {
        return;
      }
      const day = dayStr as Weekday;
      const periodIndex = parseFloat(periodStr);
      const dayHours = hours[day];
      const newDayHours = [...dayHours];
      newDayHours.splice(periodIndex, 1);
      onChange?.({...hours, [day]: newDayHours});
    },
    [onChange, hours]
  );

  const setPeriodValue = useCallback(
    (value: number, evt: React.ChangeEvent<HTMLInputElement>) => {
      const dayStr = evt.currentTarget.getAttribute('data-weekday');
      const periodStr = evt.currentTarget.getAttribute('data-period');
      const periodKeyStr = evt.currentTarget.getAttribute('data-period-key');
      if (isNull(dayStr) || isNull(periodStr) || isNull(periodKeyStr)) {
        return;
      }
      const day = dayStr as Weekday;
      const periodIndex = parseFloat(periodStr);
      const periodKey = periodKeyStr as keyof HoobiizTimeOfDay;
      const dayHours = hours[day];
      const newDayHours = [...dayHours];
      const period = newDayHours[periodIndex];
      if (!period) {
        return;
      }
      newDayHours[periodIndex] = {...period, [periodKey]: value};
      onChange?.({...hours, [day]: newDayHours});
    },
    [onChange, hours]
  );

  const handleApplyAll = useCallback<MouseEventHandler>(
    evt => {
      const dayStr = evt.currentTarget.getAttribute('data-weekday');
      if (isNull(dayStr)) {
        return;
      }
      const day = dayStr as Weekday;
      const dayHours = hours[day];
      onChange?.(fromEntries(DAYS.map(d => [d.value, [...dayHours]] as const)));
      setForceFormat(Math.random());
    },
    [onChange, hours]
  );

  return (
    <Wrapper $borderColor={borderColor} $borderRadius={borderRadius}>
      {DAYS.map(({label, value}) => (
        <Line key={value}>
          <DayLabel>{label}</DayLabel>
          <DayForm>
            {hours[value].length === 0 ? <ClosedLabel>{defaultLabel}</ClosedLabel> : EmptyFragment}
            {hours[value].map((period, i) => (
              <PeriodWrapper key={i}>
                De
                <PeriodInner>
                  <PeriodInput
                    data-weekday={value}
                    data-period={i}
                    data-period-key="startHour"
                    value={period.startHour}
                    asString={toString}
                    fromString={fromString}
                    syncStateWithEvent={setPeriodValue}
                    overrides={adminInputTheme}
                    forceFormat={forceFormat}
                    autoFocus={!disabled && lastAdded?.weekday === value && lastAdded.period === i}
                    disabled={disabled}
                  />
                  h
                  <PeriodInput
                    data-weekday={value}
                    data-period={i}
                    data-period-key="startMinute"
                    value={period.startMinute}
                    asString={toString}
                    fromString={fromString}
                    syncStateWithEvent={setPeriodValue}
                    overrides={adminInputTheme}
                    forceFormat={forceFormat}
                    disabled={disabled}
                  />
                </PeriodInner>
                à
                <PeriodInner>
                  <PeriodInput
                    data-weekday={value}
                    data-period={i}
                    data-period-key="endHour"
                    value={period.endHour}
                    asString={toString}
                    fromString={fromString}
                    syncStateWithEvent={setPeriodValue}
                    overrides={adminInputTheme}
                    forceFormat={forceFormat}
                    disabled={disabled}
                  />
                  h
                  <PeriodInput
                    data-weekday={value}
                    data-period={i}
                    data-period-key="endMinute"
                    value={period.endMinute}
                    asString={toString}
                    fromString={fromString}
                    syncStateWithEvent={setPeriodValue}
                    overrides={adminInputTheme}
                    forceFormat={forceFormat}
                    disabled={disabled}
                  />
                </PeriodInner>
                {disabled ? (
                  EmptyFragment
                ) : (
                  <UnthemedButton data-weekday={value} data-period={i} onClick={handleDeletePeriod}>
                    <SvgIcon name="Trash" size={16} colorHover={Colors.Gold} />
                  </UnthemedButton>
                )}
              </PeriodWrapper>
            ))}
            {disabled ? (
              EmptyFragment
            ) : (
              <AddPeriod data-weekday={value} onClick={handleAddPeriod}>
                <SvgIcon name="Plus" color="#ffffff" size={7} />
              </AddPeriod>
            )}
            {!disabled && hours[value].length > 0 ? (
              <ButtonAsLink data-weekday={value} onClick={handleApplyAll}>
                Copier sur tous
              </ButtonAsLink>
            ) : (
              EmptyFragment
            )}
          </DayForm>
        </Line>
      ))}
    </Wrapper>
  );
};
HoobiizWeekPeriodsForm.displayName = 'HoobiizWeekPeriodsForm';

const Wrapper = styled.div<{$borderColor?: string; $borderRadius?: number}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 16px;
  border: solid 1px ${p => p.$borderColor};
  border-radius: ${p => p.$borderRadius}px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 42px;
`;

const DayLabel = styled.div`
  flex-shrink: 0;
  width: 80px;
  text-align: right;
  color: ${Colors.Grey}cc;
  font-size: 15px;
  font-weight: bold;
`;

const DayForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f8f8f8;
  padding: 4px 8px;
  border-radius: 8px;
`;

const PeriodInner = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const AddPeriod = styled(Button)`
  width: 19px;
  height: 19px;
  background-color: ${Colors.Gold};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const PeriodInput = styled(Input)`
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 0;
`;

const ClosedLabel = styled.div`
  color: #888888;
  font-style: italic;
  font-size: 16px;
  margin-left: 8px;
`;
