// This file is just a registry of stub log functions.
// The actual implementation is defined by the caller of `registerLogFunctions`.
// This allows us to have the log functions available from @shared.

/* eslint-disable @typescript-eslint/no-explicit-any */
let errorInternal: (msg: string, err: unknown, extra?: any) => void = () => {};
let warnInternal: (msg: string, extra?: any) => void = () => {};
let infoInternal: (msg: string, extra?: any) => void = () => {};
let debugInternal: (msg: string, extra?: any) => void = () => {};

export function error(msg: string, err: unknown, extra?: any): void {
  errorInternal(msg, err, extra);
}

export function warn(msg: string, extra?: any): void {
  warnInternal(msg, extra);
}

export function info(msg: string, extra?: any): void {
  infoInternal(msg, extra);
}

export function debug(msg: string, extra?: any): void {
  debugInternal(msg, extra);
}

export function registerLogFunctions(functions: {
  error: (msg: string, err: unknown, extra?: any) => void;
  warn: (msg: string, extra?: any) => void;
  info: (msg: string, extra?: any) => void;
  debug: (msg: string, extra?: any) => void;
}): void {
  errorInternal = functions.error;
  warnInternal = functions.warn;
  infoInternal = functions.info;
  debugInternal = functions.debug;
}
