import {useLocation} from 'react-router';

import {FrontendUserId, HoobiizUserGroupId} from '@shared/dynamo_model';
import {isHoobiizUserGroupId} from '@shared/lib/hoobiiz/hoobiiz_ids';

import {
  getHoobiizUserGroupHierarchy,
  useHoobiizUserGroupHierarchy,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';

export function getHoobiizUserGroupRootPath(): string {
  return `/admin/users`;
}

// Assume that if it's not a HoobiizUserGroupId, it is a FrontendUserId
function isFrontendUserId(str?: string): str is FrontendUserId {
  return !isHoobiizUserGroupId(str);
}

export function useHoobiizUserGroupPath(): {
  groupIds: HoobiizUserGroupId[];
  groupModal?: HoobiizUserGroupId;
  userModal?: FrontendUserId;
} {
  const {pathname} = useLocation();
  if (!pathname.startsWith(getHoobiizUserGroupRootPath())) {
    return {groupIds: []};
  }
  const path = pathname.slice(getHoobiizUserGroupRootPath().length);
  const pathComponents = path.split('/');
  const lastPathComponent = pathComponents.at(-1);
  const groupIds = pathComponents.filter(isHoobiizUserGroupId);
  const groupModal = isHoobiizUserGroupId(lastPathComponent) ? lastPathComponent : undefined;
  const userModal = isFrontendUserId(lastPathComponent) ? lastPathComponent : undefined;
  return {groupIds, groupModal, userModal};
}

export function getHoobiizUserGroupModalPath(opts: {hierarchy: HoobiizUserGroupId[]}): string {
  const {hierarchy} = opts;
  return [getHoobiizUserGroupRootPath(), ...hierarchy].join('/');
}

export function useHoobiizUserGroupModalPath(opts: {groupId: HoobiizUserGroupId}): string {
  const {groupId} = opts;
  const hierarchy = getHoobiizUserGroupHierarchy({groupId});
  return hierarchy.at(-1)?.modalPath ?? getHoobiizUserGroupRootPath();
}

export function getHoobiizUserGroupUserModalPath(opts: {
  hierarchy: HoobiizUserGroupId[];
  userId: FrontendUserId;
}): string {
  const {hierarchy, userId} = opts;
  return [getHoobiizUserGroupRootPath(), ...hierarchy, userId].join('/');
}

export function useHoobiizUserGroupUserModalPath(opts: {userId: FrontendUserId}): string {
  const {userId} = opts;
  const userAndGroup = useHoobiizUserGroupHierarchy({userId});
  return userAndGroup.at(-1)?.modalPath ?? getHoobiizUserGroupRootPath();
}
