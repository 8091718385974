import {ReactNode} from 'react';
import styled from 'styled-components';

import {HoobiizUserGroupId} from '@shared/dynamo_model';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {useHoobiizUserGroup} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {AdminUserAndGroupUserCount} from '@src/components/admin/user_and_group/admin_user_and_group_user_count';
import {Colors} from '@src/components/core/theme_base';

interface AdminUserAndGroupChainNodeGroupProps {
  before?: ReactNode;
  groupId: HoobiizUserGroupId;
  isCircled?: boolean;
  isHighlighted?: boolean;
  after?: ReactNode;
}

export const AdminUserAndGroupChainNodeGroup: Custom<
  AdminUserAndGroupChainNodeGroupProps,
  'div'
> = props => {
  const {before, groupId, isCircled, isHighlighted, after, ...rest} = props;
  const group = useHoobiizUserGroup({groupId});
  return (
    <Wrapper $isCircled={isCircled} $isHighlighted={isHighlighted} {...rest}>
      {before}
      <SvgIcon name={'Directory'} color="#888" width={14} height={14} />
      <Label>{group?.item.groupLabel ?? 'chargement...'}</Label>
      {group ? (
        <AdminUserAndGroupUserCount userCount={group.item.groupNestedUserCount} />
      ) : (
        EmptyFragment
      )}
      {after}
    </Wrapper>
  );
};

AdminUserAndGroupChainNodeGroup.displayName = 'AdminUserAndGroupChainNodeGroup';

const Wrapper = styled.div<{$isCircled?: boolean; $isHighlighted?: boolean}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  border-radius: 4px;
  ${p => p.$isCircled && `border: solid 1px ${Colors.DarkGold};`}
  ${p => p.$isHighlighted && `background-color: ${Colors.DarkGold}30 !important;`}
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #0000000a;
  }
`;

const Label = styled.div``;
