import {FC, useCallback} from 'react';
import {useNavigate} from 'react-router';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat2Item} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {Category2Form} from '@src/components/admin/category/category2_form';
import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';

export const Category2CreatePage: FC = () => {
  const navigate = useNavigate();
  const {cat1Id} = useParams();

  const handleSubmit = useCallback(
    async (cat2Data: Omit<HoobiizData<HoobiizCat2Item>, 'order'>) => {
      const {cat2} = await apiCall(HoobiizApi, '/admin/create-cat2', cat2Data);
      showSuccess(`Catégorie 2 "${cat2.name}" créé`);
      navigate(`/admin/cat1/${cat1Id}`);
    },
    [cat1Id, navigate]
  );

  return (
    <PageWrapper>
      <FormWrapper>
        <FormTitle>Ajouter une nouvelle catégorie 2</FormTitle>
        <Category2Form autoFocus submitButtonText="Créer" onSubmit={handleSubmit} />
      </FormWrapper>
    </PageWrapper>
  );
};
Category2CreatePage.displayName = 'Category2CreatePage';
