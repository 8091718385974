import {FC, MouseEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {SanitizedItem} from '@shared/model/search_tables';

import {hideModal} from '@shared-frontend/components/core/modal';
import {NULL_REF} from '@shared-frontend/lib/react';
import {useGlobalKeyPress} from '@shared-frontend/lib/use_global_key_press';

import {HoobiizMediaView} from '@src/components/ui/hoobiiz_media_view';

interface HoobiizMediaGalleryModalProps {
  media: SanitizedItem<'HoobiizMedia'>[];
  initialIndex?: number;
}

export const HoobiizMediaGalleryModal: FC<HoobiizMediaGalleryModalProps> = props => {
  const {media, initialIndex = 0} = props;
  const previewContainerRef = useRef<HTMLDivElement>(NULL_REF);

  const [shownIndex, setShownIndex] = useState(initialIndex);
  const updateShownIndex = useCallback((setState: (prev: number) => number) => {
    setShownIndex(current => {
      const newIndex = setState(current);
      if (previewContainerRef.current) {
        const previewElement = previewContainerRef.current.childNodes[newIndex];
        if (previewElement instanceof HTMLElement) {
          previewElement.scrollIntoView({behavior: 'smooth', inline: 'center'});
        }
      }
      return newIndex;
    });
  }, []);

  useEffect(() => {
    updateShownIndex(() => initialIndex);
  }, [initialIndex, updateShownIndex]);

  const handlePreviewClick = useCallback<MouseEventHandler>(
    evt => {
      evt.stopPropagation();
      const index = parseFloat(evt.currentTarget.getAttribute('data-index') ?? '');
      if (isNaN(index)) {
        return;
      }
      updateShownIndex(() => index);
    },
    [updateShownIndex]
  );

  const previous = useCallback(
    (evt: KeyboardEvent) => {
      evt.preventDefault();
      updateShownIndex(current => Math.max(0, current - 1));
    },
    [updateShownIndex]
  );
  const after = useCallback(
    (evt: KeyboardEvent) => {
      evt.preventDefault();
      updateShownIndex(current => Math.min(media.length - 1, current + 1));
    },
    [updateShownIndex, media.length]
  );
  useGlobalKeyPress(['ArrowLeft'], previous);
  useGlobalKeyPress(['ArrowRight'], after);

  return (
    <Wrapper onClick={hideModal}>
      <MainMedia>
        <HoobiizMediaView
          key={media[shownIndex]?.id}
          media={media[shownIndex]}
          size={{width: '100%', height: '100%'}}
          cover={false}
        />
      </MainMedia>
      <MediaPreviews ref={previewContainerRef}>
        {media.map((media, i) => (
          <MediaPreview
            data-index={i}
            key={media.id}
            onClick={handlePreviewClick}
            media={media}
            cover={false}
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            brightness={i === shownIndex ? 100 : 50}
            size={{height: '100%'}}
          />
        ))}
      </MediaPreviews>
    </Wrapper>
  );
};

HoobiizMediaGalleryModal.displayName = 'HoobiizMediaGalleryModal';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const MainMedia = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin: 16px auto 0 auto;
  width: 80%;
`;
const MediaPreviews = styled.div`
  flex-shrink: 0;
  display: flex;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto 16px auto;
  padding: 0 16px;
  height: 15vh;
  gap: 16px;
  overflow-x: auto;
`;
const MediaPreview = styled(HoobiizMediaView)`
  cursor: pointer;
  overflow: initial;
  & > img {
    border-radius: 8px;
  }
`;
