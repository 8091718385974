import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {
  HoobiizStockId,
  HoobiizStockModePregenerated,
  HoobiizStockModeType,
  HoobiizTicketData,
  HoobiizTicketFileItem,
} from '@shared/dynamo_model';

import {Button} from '@shared-frontend/components/core/button';
import {GridColumns} from '@shared-frontend/components/core/grid';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {AddFilesButton} from '@src/components/admin/activity_stock/add_files_button';
import {HoobiizTicketDataForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_data_form';
import {HoobiizTicketFilesUploadLine} from '@src/components/admin/activity_stock/hoobiiz_ticket_files_upload_line';
import {FormBlockAuto, FormLabel} from '@src/components/admin/form/form_fragments';

export interface HoobiizStockModePregeneratedTicket {
  id?: HoobiizStockId;
  mode: HoobiizStockModePregenerated;
}
interface HoobiizStockModePregeneratedFormProps {
  initialData?: HoobiizStockModePregeneratedTicket[];
  onChange?: (newData: HoobiizStockModePregeneratedTicket[]) => void;
}

export const HoobiizStockModePregeneratedForm: FC<
  HoobiizStockModePregeneratedFormProps
> = props => {
  const {initialData, onChange} = props;

  const [files, setFiles] = useState<File[]>([]);

  const [tickets, setTickets] = useState<HoobiizStockModePregeneratedTicket[]>(initialData ?? []);
  useEffect(() => onChange?.(tickets), [onChange, tickets]);

  // Callback when the file upload completes
  const handleFileUploadSuccess = useCallback((file: File, item: HoobiizTicketFileItem) => {
    setFiles(files => files.filter(f => f !== file));
    setTickets(tickets => [
      ...tickets,
      {
        mode: {
          type: HoobiizStockModeType.Pregenerated,
          data: {files: [{id: item.id}]},
        },
      },
    ]);
  }, []);

  // Callback when clicking to add a ticket with a code
  const addCodeTicket = useCallback(() => {
    setTickets(tickets => [
      ...tickets,
      {mode: {type: HoobiizStockModeType.Pregenerated, data: {code: {value: ''}}}},
    ]);
  }, []);

  // Callback when a file upload is canceled
  const handleFileDelete = useCallback((file: File) => {
    setFiles(files => files.filter(f => f !== file));
  }, []);

  // Callback when a ticket is deleted
  const handleTicketDelete = useCallback((data: HoobiizTicketData) => {
    setTickets(tickets => tickets.filter(t => t.mode.data !== data));
  }, []);

  // Callback when a ticket data changes
  const handleTicketChange = useCallback(
    (oldData: HoobiizTicketData, newData: HoobiizTicketData) => {
      setTickets(tickets => {
        const index = tickets.findIndex(t => t.mode.data === oldData);
        const ticket = tickets[index];
        if (!ticket) {
          return tickets;
        }
        return [
          ...tickets.slice(0, index),
          {...ticket, mode: {...ticket.mode, data: newData}},
          ...tickets.slice(index + 1),
        ];
      });
    },
    []
  );

  return (
    <FormBlockAuto>
      <FormLabel $noMargin>TICKETS</FormLabel>
      <Buttons>
        <AddFilesButton syncState={setFiles}>Ajouter des tickets PDF</AddFilesButton>
        <Button onClick={addCodeTicket}>Ajouter un ticket avec code</Button>
      </Buttons>
      {files.length > 0 ? (
        <Fragment>
          <Spacing height={16} />
          <GridColumns $columns={4} $alignItems="center">
            {files.map(f => (
              <HoobiizTicketFilesUploadLine
                key={f.name + f.size + f.type + f.lastModified}
                file={f}
                onSuccess={handleFileUploadSuccess}
                onDelete={handleFileDelete}
              />
            ))}
          </GridColumns>
        </Fragment>
      ) : (
        EmptyFragment
      )}
      <Spacing height={16} />
      <Tickets>
        {tickets.map((ticket, i) => (
          <HoobiizTicketDataForm
            key={i}
            data={ticket.mode.data}
            onDelete={handleTicketDelete}
            onChange={handleTicketChange}
          />
        ))}
      </Tickets>
    </FormBlockAuto>
  );
};
HoobiizStockModePregeneratedForm.displayName = 'HoobiizStockModePregeneratedForm';

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;

const Tickets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;
