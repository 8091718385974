import {JSX, ReactNode} from 'react';
import styled from 'styled-components';

import {Custom} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';

interface FormSectionProps {
  title: string;
  titleComponent?: JSX.Element;
  children?: ReactNode;
  noMargin?: boolean;
}

export const FormSection: Custom<FormSectionProps, 'div'> = ({
  title,
  titleComponent,
  children,
  noMargin,
  ...rest
}) => {
  return (
    <Wrapper $noMargin={noMargin} {...rest}>
      <Title>
        <TitleText>{title}</TitleText>
        {titleComponent}
      </Title>
      {children}
    </Wrapper>
  );
};
FormSection.displayName = 'FormSection';

const Wrapper = styled.div<{$noMargin?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${p => (p.$noMargin ? 0 : 24)}px;
  border-radius: 8px;
  border: solid 2px ${Colors.LightGold};
  position: relative;
`;

const Title = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
  & + * {
    margin-top: 24px;
  }
  background: ${Colors.LightGold};
  padding: 8px;
  border-bottom-right-radius: 3px;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
`;
const TitleText = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: white;
`;
