import {FC} from 'react';
import styled from 'styled-components';

import {Button, Link} from '@shared-frontend/components/core/button';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {Input} from '@shared-frontend/components/core/input_v2';

export const FrontendUi: FC = () => {
  return (
    <Wrapper>
      <Line>
        <Button>Button</Button>
        <Link href="/ui">Link</Link>
        <Button disabled>Disabled</Button>
        <Link href="/ui" disabled>
          Disabled
        </Link>
        <Button loading>Loading</Button>
        <Link href="/ui" loading>
          Loading
        </Link>
      </Line>
      <Column>
        <Input value="" placeholder="placeholder" label="LABEL" />
      </Column>
      <Checkbox value="checkbox-value">Checkbox</Checkbox>
    </Wrapper>
  );
};

FrontendUi.displayName = 'FrontendUi';

const Wrapper = styled.div`
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Line = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
