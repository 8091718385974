import {FC} from 'react';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {AdminRoute} from '@shared-frontend/components/shared_routes';

interface AdminNavProps {
  routes?: AdminRoute[];
}

export const AdminNav: FC<AdminNavProps> = props => {
  const {routes = []} = props;
  const {
    main: {accentColor, accentTextColor, logo},
  } = useTheme();
  return (
    <Container $backgroundColor={accentColor} $color={accentTextColor}>
      <LogoWrapper>
        <LogoNavLink to="/">{logo}</LogoNavLink>
      </LogoWrapper>
      {routes
        .filter(({navItem}) => navItem !== undefined)
        .map(({navItem, path}) => (
          <NavigationLink to={`/admin${path}`} key={path}>
            {navItem}
          </NavigationLink>
        ))}
      {/* <NavigationLink to="/admin/analytics/">Analytics</NavigationLink> */}
    </Container>
  );
};
AdminNav.displayName = 'AdminNav';

const Container = styled.div<{$backgroundColor: string; $color: string}>`
  width: 100%;
  height: 100%;
  background-color: ${p => p.$backgroundColor};
  overflow-y: auto;
  color: ${p => p.$color};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const NavigationLink = styled(UnthemedNavLink)`
  display: block;
  width: 100%;
  padding: 20px;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    background-color: #ffffff15;
  }

  &.active {
    background-color: #00000040;
  }
`;

const LogoNavLink = styled(UnthemedNavLink)`
  height: 64px;
  /* & > * {
    width: 100%;
    height: 100%;
  } */
`;
