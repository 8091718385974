import {FC, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {notifyError} from '@shared-frontend/lib/notification';
import {NULL_REF} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';
import {AddToCartButton} from '@src/components/ui/add_to_cart_button';
import {ExpertTicketStock} from '@src/components/ui/hoobiiz_ticket_day_picker';
import {
  ExpertTicketQuantityData,
  HoobiizTicketQuantityPicker,
  TicketQuantityData,
} from '@src/components/ui/hoobiiz_ticket_quantity_picker';

interface HoobiizExpertTicketPickerProps {
  day: Date;
  stocks: ExpertTicketStock[];
}

function stockToTicketData(stocks: ExpertTicketStock[]): ExpertTicketQuantityData[] {
  return stocks.map(s => ({
    type: 'expert-ticket',
    ticket: s.ticket,
    date: s.price.ts,
    price: s.price.price,
    originalPrice: s.ticket.originalPrice,
    quantity: 0,
  }));
}

export const HoobiizExpertTicketPicker: FC<HoobiizExpertTicketPickerProps> = props => {
  const {day, stocks} = props;

  const [ticketData, setTicketData] = useState(stockToTicketData(stocks));

  // Reset selection when a prop changes
  useEffect(() => {
    setTicketData(stockToTicketData(stocks));
  }, [day, stocks]);

  // Update quantity
  const handleQuantityChange = useCallback(
    (newTicket: TicketQuantityData | ExpertTicketQuantityData) => {
      if (newTicket.type !== 'expert-ticket') {
        notifyError('Unexpected stock data');
        return;
      }
      setTicketData(current =>
        current.map(t => (t.ticket.ticketInfoId === newTicket.ticket.ticketInfoId ? newTicket : t))
      );
    },
    []
  );

  const titleRef = useRef<HTMLDivElement>(NULL_REF);

  const handleAddToCartSubmit = useCallback(() => {
    setTicketData(stockToTicketData(stocks));
  }, [stocks]);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({block: 'start'});
    }
  }, [ticketData]);

  if (stocks.length === 0) {
    return <div>Stock épuisé</div>;
  }

  return (
    <Fragment>
      <StockTitle ref={titleRef}>{`BILLETS DU ${day
        .toLocaleDateString('fr', {
          weekday: 'long',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
        .toUpperCase()}`}</StockTitle>
      <Pickers>
        {ticketData.map(data => (
          <HoobiizTicketQuantityPicker
            key={data.ticket.ticketInfoId}
            data={data}
            onChange={handleQuantityChange}
            noTitle
          />
        ))}
      </Pickers>
      <AddToCartButton ticketData={ticketData} onSubmit={handleAddToCartSubmit} />
    </Fragment>
  );
};

HoobiizExpertTicketPicker.displayName = 'HoobiizExpertTicketPicker';

const StockTitle = styled.div`
  color: ${Colors.Grey};
  font-size: 20px;
  font-weight: 500;
  border-bottom: solid 3px #e8e8e8;
  padding-bottom: 8px;
  margin-bottom: 12px;
`;

const Pickers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 12px 0 0 0;
`;
