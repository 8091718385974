import {Currency, CurrencyAmount} from '@shared/dynamo_model';

export function roundCents<T extends number | (number | undefined)>(cents: T): T {
  return (cents === undefined ? undefined : Math.ceil(cents)) as T;
}

export function cents(cents: number): CurrencyAmount {
  return {cents: roundCents(cents), currency: Currency.Euro};
}

export function addCurrencyAmount(
  amount1: CurrencyAmount,
  amount2: CurrencyAmount | undefined
): CurrencyAmount {
  if (!amount2) {
    return amount1;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (amount1.currency !== amount2.currency) {
    throw new Error(
      `Could not add together different currencies (${amount1.currency} + ${amount2.currency})`
    );
  }

  return {cents: roundCents(amount1.cents) + roundCents(amount2.cents), currency: amount1.currency};
}

export function substractCurrencyAmount(
  amount1: CurrencyAmount,
  amount2: CurrencyAmount | undefined
): CurrencyAmount {
  if (!amount2) {
    return amount1;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (amount1.currency !== amount2.currency) {
    throw new Error(
      `Could not substract together different currencies (${amount1.currency} + ${amount2.currency})`
    );
  }

  return {cents: roundCents(amount1.cents) - roundCents(amount2.cents), currency: amount1.currency};
}

export function multiplyCurrencyAmount(multiplier: number, amount: CurrencyAmount): CurrencyAmount {
  return {cents: roundCents(amount.cents) * multiplier, currency: amount.currency};
}

export function divideCurrencyAmount(divider: number, amount: CurrencyAmount): CurrencyAmount {
  return {cents: roundCents(roundCents(amount.cents) / divider), currency: amount.currency};
}

export const currencyToSymbol: Record<Currency, string> = {
  [Currency.Euro]: '€',
};

export function currencyAmountToString(amount: CurrencyAmount, locale?: string): string {
  return `${(roundCents(amount.cents) / 100).toLocaleString(locale, {minimumFractionDigits: 2})} ${
    currencyToSymbol[amount.currency]
  }`;
}
export function currencyAmountToShortString(amount: CurrencyAmount, locale?: string): string {
  return `${(roundCents(amount.cents) / 100).toLocaleString(locale)} ${
    currencyToSymbol[amount.currency]
  }`;
}

export function compareCurrencyAmount(c1: CurrencyAmount, c2: CurrencyAmount): number {
  return roundCents(c1.cents) - roundCents(c2.cents);
}
