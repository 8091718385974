import {FC} from 'react';
import styled from 'styled-components';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {useCartCount} from '@src/lib/stores';

interface CartNavLinkProps {}

export const CartNavLink: FC<CartNavLinkProps> = () => {
  const cartCount = useCartCount();
  return (
    <GradientNavLink to="/cart">
      <SvgIcon name="Cart" color="#ffffff" height={30} />
      {cartCount === undefined ? EmptyFragment : `(${cartCount})`}
    </GradientNavLink>
  );
};

CartNavLink.displayName = 'CartNavLink';

const GradientNavLink = styled(UnthemedNavLink)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 6px;
  background: linear-gradient(197deg, #b49a62 0%, #b49a62 50%, #e0c68e 100%);
  color: white;
  font-weight: 500;
  font-size: 18px;

  transition: box-shadow 120ms ease-in-out;
  &:hover {
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
  }
`;
