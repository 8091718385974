/* eslint-disable no-underscore-dangle */
import {asMap, asString, isNull} from '@shared/lib/type_utils';
import {debug} from '@shared/logger';

const DATE_PREFIX = 'Date::';

//
// STRINGIFY
//

function replacer(this: Record<string, unknown>, key: string, value: unknown): unknown {
  // We use this[key] because value may have already been transformed (example: Date.toJSON)
  // eslint-disable-next-line no-invalid-this
  const rawValue = this[key];
  if (rawValue instanceof Date) {
    return `${DATE_PREFIX}${rawValue.toISOString()}`;
  }
  if (rawValue instanceof Map) {
    return {
      dataType: 'Map',
      value: [...rawValue.entries()],
    };
  }
  return value;
}

export function safeStringify(data: unknown): string {
  if (typeof data !== 'object' || isNull(data)) {
    return JSON.stringify(data, replacer);
  }
  const references: Record<string, unknown> = {};
  try {
    const converted = extractReferences(data, references);
    if (Object.keys(references).length > 0) {
      return JSON.stringify({__references: references, data: converted});
    }
    return JSON.stringify(data, replacer);
  } catch (err) {
    debug('safeStringify', JSON.stringify(data));
    throw err;
  }
}

function extractReferences(data: unknown, references: Record<string, unknown>): unknown {
  if (Array.isArray(data)) {
    return data.map(d => extractReferences(d, references));
  } else if (typeof data === 'object' && !isNull(data)) {
    if ('__ref' in data && typeof data.__ref === 'string') {
      const {__ref, ...rest} = data;
      if (!(__ref in references)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ref: any = {};
        references[__ref] = ref;
        const extracted = extractReferences(rest, references);
        for (const [key, value] of Object.entries(extracted as {})) {
          ref[key] = value;
        }
        ref.__ref = __ref;
      }
      return {__ref};
    }
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, extractReferences(value, references)])
    );
  }
  return data;
}

//
// PARSE
//

function reviver(key: string, value: unknown): unknown {
  const obj = asMap(value);
  if (obj !== undefined && obj.dataType === 'Map' && Array.isArray(obj.value)) {
    return new Map(obj.value);
  }
  const str = asString(value);
  if (str?.startsWith(DATE_PREFIX)) {
    return new Date(str.slice(DATE_PREFIX.length));
  }
  return value;
}

export function safeParse(data: string): unknown {
  const parsed = JSON.parse(data, reviver);
  if (
    typeof parsed === 'object' &&
    !isNull(parsed) &&
    '__references' in parsed &&
    typeof parsed.__references === 'object' &&
    'data' in parsed &&
    typeof parsed.data === 'object'
  ) {
    const {__references, data} = parsed;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return expandReferences(data, __references, {});
  }
  return parsed;
}

function expandReferences(
  data: unknown,
  references: Record<string, unknown>,
  expandedReferences: Record<string, unknown>
): unknown {
  if (Array.isArray(data)) {
    return data.map(d => expandReferences(d, references, expandedReferences));
  } else if (typeof data === 'object' && !isNull(data)) {
    if (Object.keys(data).length === 1 && '__ref' in data && typeof data.__ref === 'string') {
      if (data.__ref in expandedReferences) {
        return expandedReferences[data.__ref];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ref: any = {};
      expandedReferences[data.__ref] = ref;
      const expanded = expandReferences(references[data.__ref], references, expandedReferences);
      for (const [key, value] of Object.entries(expanded as {})) {
        ref[key] = value;
      }
      return ref;
    }
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        expandReferences(value, references, expandedReferences),
      ])
    );
  }
  return data;
}
