import {FC, useCallback, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizOpeningHoursOverride} from '@shared/dynamo_model';

import {UnthemedButton} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {DateTimeInput} from '@src/components/admin/form/date_time_input';
import {adminInputTheme} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';

interface HoobiizOpeningHoursOverrideFormProps {
  trackIndex: number;
  override?: HoobiizOpeningHoursOverride;
  onChange?: (trackIndex: number, data: HoobiizOpeningHoursOverride | undefined) => void;
  onDelete?: (trackIndex: number) => void;
  disabled?: boolean;
}

export const HoobiizOpeningHoursOverrideForm: FC<HoobiizOpeningHoursOverrideFormProps> = props => {
  const {trackIndex, disabled, override, onChange, onDelete} = props;

  const [ts, setTs] = useState<number | undefined>(override?.day);
  const [reason, setReason] = useState<string>(override?.reason ?? '');

  useEffect(() => setTs(ts), [ts]);
  useEffect(() => setReason(reason), [reason]);

  const handleDeleteClick = useCallback(() => {
    onDelete?.(trackIndex);
  }, [onDelete, trackIndex]);

  useEffect(() => {
    onChange?.(trackIndex, ts === undefined ? undefined : {day: ts, reason});
  }, [onChange, reason, trackIndex, ts]);

  return (
    <Wrapper>
      <Line key={trackIndex}>
        <DateTimeInput
          noTimePicker
          ts={ts}
          syncState={setTs}
          overrides={adminInputTheme}
          label="Jour de fermeture"
          utc
        />
        <div>
          <Input value={reason} syncState={setReason} overrides={adminInputTheme} label="Raison" />
        </div>
        {disabled ? (
          EmptyFragment
        ) : (
          <UnthemedButton onClick={handleDeleteClick}>
            <SvgIcon name="Trash" size={16} colorHover={Colors.Gold} />
          </UnthemedButton>
        )}
      </Line>
    </Wrapper>
  );
};
HoobiizOpeningHoursOverrideForm.displayName = 'HoobiizOpeningHoursOverrideForm';

const Wrapper = styled.div<{$borderColor?: string; $borderRadius?: number}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 16px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 42px;
`;
