import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import {styled} from 'styled-components';

import {HoobiizApi, HoobiizCartItemItemFull} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCartItemId} from '@shared/dynamo_model';
import {sum} from '@shared/lib/array_utils';
import {cents} from '@shared/lib/hoobiiz/currency_amount';
import {
  addHoobiizPrices,
  baseHoobiizPrices,
  computeCartItemPrice,
} from '@shared/lib/hoobiiz/hoobiiz_prices';

import {apiCall} from '@shared-frontend/api';
import {Button, Link} from '@shared-frontend/components/core/button';
import {hideModal} from '@shared-frontend/components/core/modal';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizCartItem} from '@src/components/ui/hoobiiz_cart_item';
import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import {increaseCartCount} from '@src/lib/stores';

interface HoobiizCartSidebarProps {
  cartItems: HoobiizCartItemItemFull[];
}

export const HoobiizCartSidebar: FC<HoobiizCartSidebarProps> = props => {
  const {cartItems} = props;

  const [removed, setRemoved] = useState<HoobiizCartItemId[]>([]);

  const navigate = useNavigate();

  const cartItemsWithoutRemoved = useMemo(
    () => cartItems.filter(item => !removed.includes(item.cartItem.id)),
    [cartItems, removed]
  );
  const plural = useMemo(
    () =>
      sum(cartItemsWithoutRemoved.map(item => item.cartItem.itemData.quantity)) > 1 ? 's' : '',
    [cartItemsWithoutRemoved]
  );

  useEffect(() => setRemoved([]), [cartItems]);
  useEffect(() => {
    if (cartItemsWithoutRemoved.length === 0) {
      hideModal();
    }
  }, [cartItemsWithoutRemoved]);

  const handleDeleteClick = useCallback(
    async (id: HoobiizCartItemId) => {
      await apiCall(HoobiizApi, '/remove-from-cart', {id});
      showSuccess('Ticket retiré de votre panier');
      increaseCartCount(-1);
      setRemoved(removed => {
        const newRemoved = [...removed, id];
        if (newRemoved.length === cartItems.length) {
          hideModal();
        }
        return newRemoved;
      });
    },
    [cartItems.length]
  );

  const handleGoToCartClick = useCallback(() => {
    navigate('/cart');
  }, [navigate]);
  const handleContinueClick = useCallback(() => {
    hideModal();
  }, []);

  const totalPrice = cartItemsWithoutRemoved.reduce(
    (curr, val) => addHoobiizPrices(curr, computeCartItemPrice(val).totalWithoutFees),
    baseHoobiizPrices(cents(0))
  );

  return (
    <Wrapper>
      <Title>
        <SvgIcon name="Check" color={Colors.Green} size={32} />
        {`Ticket${plural} ajouté${plural} à votre panier`}
      </Title>
      {cartItemsWithoutRemoved.map(item => (
        <HoobiizCartItem
          key={item.cartItem.id}
          fullCartItem={item}
          onDeleteClick={handleDeleteClick}
        />
      ))}
      <Total>
        Total
        <HoobiizPricesView prices={totalPrice} />
      </Total>
      <Form>
        <Button onClick={handleGoToCartClick} expand>
          Aller au panier
        </Button>
        <ContinueLink>
          <Link onClick={handleContinueClick}>Continuer vos achats</Link>
        </ContinueLink>
      </Form>
    </Wrapper>
  );
};

HoobiizCartSidebar.displayName = 'HoobiizCartSidebar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 20px 20px #00000060;
  padding: 32px;
  gap: 32px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 20px;
  font-weight: bold;
  color: ${Colors.Green};
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContinueLink = styled.div`
  text-align: center;
`;

const Total = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 16px;
  font-size: 22px;
`;
