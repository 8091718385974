import QRious from 'qrious';
import {FC, useEffect} from 'react';
import styled from 'styled-components';

interface QrCodeProps {
  totpUri: string;
}

export const QrCode: FC<QrCodeProps> = ({totpUri}) => {
  useEffect(() => {
    const element = document.getElementById('qrcode');
    // eslint-disable-next-line no-null/no-null
    if (element !== null) {
      // eslint-disable-next-line no-new
      new QRious({element, value: totpUri, size: 250});
    }
  }, [totpUri]);

  return (
    <Wrapper>
      {
        // eslint-disable-next-line react/forbid-dom-props
        <canvas id="qrcode" />
      }
    </Wrapper>
  );
};
QrCode.displayName = 'QrCode';

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;
