import {InitMessage, MessageFromServiceWorker} from '@shared/frontends/service_worker_message';
import {asMap} from '@shared/lib/type_utils';

import {notifyError} from '@shared-frontend/lib/notification';
// Injected at compile time by InjectSwScriptWebpackPlugin
declare const INJECTED_FILE_URL: string;

export function setupServiceWorker(): void {
  if ('serviceWorker' in navigator) {
    const messageChannel = new MessageChannel();
    navigator.serviceWorker
      .register(INJECTED_FILE_URL)
      .then(registration => {
        messageChannel.port1.onmessage = event => {
          const message = asMap(event.data) as MessageFromServiceWorker | undefined;
          if (!message) {
            notifyError('Unknown message from service worker', {extra: {data: event.data}});
            return;
          }
          handleServiceWorkerMessage(message);
        };
        const initMessage: InitMessage = {type: 'INIT'};
        registration.active?.postMessage(initMessage, [messageChannel.port2]);
      })
      .catch(notifyError);
  }
}

function handleServiceWorkerMessage(msg: MessageFromServiceWorker): void {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (msg.type === 'SHOULD_RELOAD') {
    document.location.reload();
  } else {
    notifyError('Unknown message from service worker', {extra: msg});
  }
}
