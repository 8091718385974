import {FC, Fragment, useCallback, useEffect} from 'react';
import {Route, Routes} from 'react-router';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {
  setSharedAdminOptions,
  setSidebarCollapsed,
  useSharedAdminOptions,
} from '@shared-frontend/components/admin/admin_data_store';
import {AdminNav} from '@shared-frontend/components/admin/admin_nav';
import {UnthemedButton} from '@shared-frontend/components/core/button';
import {Modal} from '@shared-frontend/components/core/modal';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {AdminRoute} from '@shared-frontend/components/shared_routes';
import {useIsMobile} from '@shared-frontend/lib/use_is_mobile';
import {ZINDEX} from '@shared-frontend/lib/zindex';

interface AdminAppProps {
  routes?: AdminRoute[];
}

export const AdminApp: FC<AdminAppProps> = props => {
  const {routes} = props;
  const {
    main: {backgroundColor, textColor, accentColor, accentTextColor},
    admin: {navWidth},
  } = useTheme();
  const isMobile = useIsMobile();
  const appOptions = useSharedAdminOptions();
  const {sidebarCollapsed} = appOptions;

  useEffect(() => {
    if (isMobile) {
      setSidebarCollapsed(true);
    }
  }, [isMobile]);

  const handleToggleSidebarClick = useCallback(() => {
    setSharedAdminOptions({...appOptions, sidebarCollapsed: !sidebarCollapsed});
  }, [appOptions, sidebarCollapsed]);

  return (
    <Fragment>
      <Wrapper $backgroundColor={backgroundColor} $color={textColor}>
        <Left $sidebarCollapsed={sidebarCollapsed} $navWidth={navWidth}>
          <AdminNav routes={routes} />
        </Left>
        <Right $sidebarCollapsed={sidebarCollapsed} $navWidth={navWidth}>
          <Routes>
            {(routes ?? []).map(({path, page}) => (
              <Route key={path} path={path} Component={page} />
            ))}
            {/* <Route path="/analytics" element={<div>Analytics</div>} /> */}
            <Route path="*" element={<div>Not found</div>} />
          </Routes>
        </Right>
        <ToggleSidebarButton onClick={handleToggleSidebarClick} $backgroundColor={accentColor}>
          {sidebarCollapsed ? (
            <SideBarRightIcon name="SideBarRight" color={accentTextColor} />
          ) : (
            <SideBarLeftIcon name="SideBarLeft" color={accentTextColor} />
          )}
        </ToggleSidebarButton>
      </Wrapper>
      <Modal />
    </Fragment>
  );
};
AdminApp.displayName = 'AdminApp';

const Wrapper = styled.div<{$backgroundColor: string; $color: string}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: ${p => p.$backgroundColor};
  color: ${p => p.$color};
`;

const Left = styled.div<{$sidebarCollapsed: boolean; $navWidth: number}>`
  ${p => (p.$sidebarCollapsed ? 'display: none;' : undefined)}
  position: fixed;
  top: 0;
  left: 0;
  width: ${p => p.$navWidth}px;
  bottom: 0;
  box-shadow: -16px 0 20px 20px #00000060;
  z-index: ${ZINDEX.AdminSidebar};
`;

const Right = styled.div<{$sidebarCollapsed: boolean; $navWidth: number}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${p => (p.$sidebarCollapsed ? 0 : p.$navWidth)}px;
  overflow: auto;
`;

const ToggleSidebarButton = styled(UnthemedButton)<{$backgroundColor: string}>`
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0;
  padding: 0;
  height: 32px;
  background-color: ${p => p.$backgroundColor};
  border-bottom-right-radius: 4px;
`;

const SideBarRightIcon = styled(SvgIcon)`
  margin: 0 5px 0 10px;
`;

const SideBarLeftIcon = styled(SvgIcon)`
  margin: 0 10px 0 5px;
`;
