import {FC, JSX} from 'react';
import styled from 'styled-components';

interface HoobiizWarningsProps {
  warnings: (string | JSX.Element)[];
}

export const HoobiizWarnings: FC<HoobiizWarningsProps> = props => {
  const {warnings} = props;
  return (
    <Warnings>
      {warnings.map((w, i) => (
        <Warning key={String(i)}>{w}</Warning>
      ))}
    </Warnings>
  );
};

HoobiizWarnings.displayName = 'HoobiizWarnings';

const Warnings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f8edc2;
  border-radius: 8px;
  padding: 16px;
`;

const Warning = styled.div``;
