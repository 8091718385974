import {ReactNode} from 'react';
import styled from 'styled-components';

import {FrontendUserId} from '@shared/dynamo_model';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Custom} from '@shared-frontend/lib/react';

import {useHoobiizUserGroupUser} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {Colors} from '@src/components/core/theme_base';

interface AdminUserAndGroupChainNodeUserProps {
  before?: ReactNode;
  userId: FrontendUserId;
  isHighlighted?: boolean;
  after?: ReactNode;
}

export const AdminUserAndGroupChainNodeUser: Custom<
  AdminUserAndGroupChainNodeUserProps,
  'div'
> = props => {
  const {before, userId, isHighlighted, after, ...rest} = props;
  const userAndGroup = useHoobiizUserGroupUser({userId});
  return (
    <Wrapper $isCircled={isHighlighted} $isHighlighted={isHighlighted} {...rest}>
      {before}
      <SvgIcon name={'User'} color="#888" width={14} height={14} />
      <Label>{userAndGroup?.user.userEmail ?? 'chargement...'}</Label>
      {after}
    </Wrapper>
  );
};

AdminUserAndGroupChainNodeUser.displayName = 'AdminUserAndGroupChainNodeUser';

const Wrapper = styled.div<{$isCircled?: boolean; $isHighlighted?: boolean}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  user-select: none;
  border-radius: 4px;
  ${p => `border: solid 1px ${p.$isCircled ? Colors.DarkGold : 'transparent'};`}
  ${p => p.$isHighlighted && `background-color: ${Colors.DarkGold}30 !important;`}
  cursor: pointer;
  &:hover {
    background-color: #0000000a;
  }
`;

const Label = styled.div``;
