import {
  AllTimeComponents,
  AllTimeComponentsBySize,
  TimeFormatLang,
  TimeSpanComponent,
  TimeSpanComponentName,
} from '@shared/lib/time/time_component';

export type TimeSpan = Record<TimeSpanComponentName, number>;

export function formatTimeComponent(
  component: TimeSpanComponent,
  value: number,
  short: boolean,
  lang: TimeFormatLang
): string {
  if (short) {
    return `${value}${component.format[lang].short}`;
  } else if (value > 1) {
    return `${value} ${component.format[lang].longPlural}`;
  }
  return `${value} ${component.format[lang].long}`;
}

// function msToTimeSpan(ms: number): Record<keyof TimeSpan, number> {
//   let msLeft = ms;

//   const consumeTime = (c: keyof TimeSpan): number => {
//     const spanDuration = AllTimeComponents[c].metadata.ms;
//     const value = Math.floor(msLeft / spanDuration);
//     msLeft -= value * spanDuration;
//     return value;
//   };

//   const years = consumeTime('years');
//   const months = consumeTime('months');
//   const days = consumeTime('days');
//   const hours = consumeTime('hours');
//   const minutes = consumeTime('minutes');
//   const seconds = consumeTime('seconds');
//   const milliseconds = msLeft;
//   return {years, months, days, hours, minutes, seconds, milliseconds};
// }

export function timeSpanToMs(time: Partial<TimeSpan>): number {
  let ms = 0;
  for (const [key, value] of Object.entries(time)) {
    const size = AllTimeComponents.get(key as TimeSpanComponentName)?.metadata.ms ?? 0;
    ms += size * value;
  }
  return ms;
}

export function firstTimeComponent(ms: number, minValue: number): [TimeSpanComponent, number] {
  for (const timeComponent of AllTimeComponentsBySize) {
    const size = timeComponent.metadata.ms;
    if (minValue * size <= ms) {
      return [timeComponent, Math.floor(ms / size)];
    }
  }
  const msComponent = AllTimeComponents.get('milliseconds');
  if (!msComponent) {
    throw new Error(`Milliseconds time component not available as a default.`);
  }
  return [msComponent, ms];
}

export function getNextTimeComponent(
  current: TimeSpanComponent,
  offset = 1
): TimeSpanComponent | undefined {
  for (let i = 0; i < AllTimeComponentsBySize.length - 1; i++) {
    if (AllTimeComponentsBySize[i] === current) {
      return AllTimeComponentsBySize[i + offset];
    }
  }
  return undefined;
}
